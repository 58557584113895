<script>
  import {
    Button,
    Card,
    CardBody,
    Offcanvas,
    Container,
    Row,
    Col,
    TabContent,
    TabPane,
  } from "sveltestrap";
  import Modal from "../components/Modal.svelte";
  import Tablekeranjangcolok from "../permainan/Tablekeranjangcolok.svelte";
  import Loader from "../components/Loader.svelte";
  import { createEventDispatcher } from "svelte";
  import { notifications } from "../components/Noti.svelte";
  import { excryptData } from "../lib/encrypter";
  import { useVisitorData } from "@fingerprintjs/fingerprintjs-pro-svelte";
  import PeriodePanel from "../components/PeriodePanel.svelte";
  import Swal from "sweetalert2";
  import Fa from "svelte-fa";
  import { faDice } from "@fortawesome/free-solid-svg-icons";
  import dayjs from "dayjs";
  import utc from "dayjs/plugin/utc";
  import timezone from "dayjs/plugin/timezone";
  import { UiDeleteLeft } from "../lib/icons";
  import BtnAngka from "../components/ButtonAngka.svelte";
  import BtnBet from "../components/ButtonInputBet.svelte";

  export let idcomppasaran = "";
  export let idtrxkeluaran = "";
  export let client_token = "";
  export let client_company = "";
  export let client_username = "";
  export let client_ipaddress = "";
  export let client_device = "";
  export let source = "";
  export let client_timezone = "";
  export let pasaran_code = "";
  export let pasaran_name = "";
  export let pasaran_periode = 0;
  export let permainan_title = "COLOK";
  export let daylight = false;
  export let balance_credit;
  export let betHistoryView = false;
  export let loadDisplay;
  export let agent_home_url;
  export let immediate = false;
  export let portrait_mode;

  dayjs.extend(utc);
  dayjs.extend(timezone);

  var cd = new Date();
  var cnow = dayjs(cd).tz("Asia/Jakarta").unix();

  const { getData, data, isLoading, error } = useVisitorData(
    { extendedResult: true },
    { immediate }
  );

  const swalWithBootstrapButtons = Swal.mixin({
    customClass: {
      confirmButton: "mx-2 rounded-2 btn btn-success",
      cancelButton: "mx-2 rounded-2 btn btn-danger",
    },
    buttonsStyling: false,
  });

  let gametype = "";
  let keranjang = [];
  let nomor_global = 0;
  let totalkeranjang = 0;
  let group_btn_beli = false;
  let record = "";
  let temp_bulk_error = "";
  let min_bet_colokbebas = 0;
  let max_bet_colokbebas = 0;
  let disc_bet_colokbebas = 0;
  let win_bet_colokbebas = 0;
  let limittotal_bet_colokbebas = 0;
  let min_bet_colokmacau = 0;
  let max_bet_colokmacau = 0;
  let disc_bet_colokmacau = 0;
  let win_bet_colokmacau = 0;
  let win3_bet_colokmacau = 0;
  let win4_bet_colokmacau = 0;
  let limittotal_bet_colokmacau = 0;
  let min_bet_coloknaga = 0;
  let max_bet_coloknaga = 0;
  let disc_bet_coloknaga = 0;
  let win_bet_coloknaga = 0;
  let win4_bet_coloknaga = 0;
  let limittotal_bet_coloknaga = 0;
  let min_bet_colokjitu = 0;
  let max_bet_colokjitu = 0;
  let disc_bet_colokjitu = 0;
  let winas_bet_colokjitu = 0;
  let winkop_bet_colokjitu = 0;
  let winkepala_bet_colokjitu = 0;
  let winekor_bet_colokjitu = 0;
  let limittotal_bet_colokjitu = 0;

  let count_line_colokbebas = 0;
  let count_line_colokmacau = 0;
  let count_line_coloknaga = 0;
  let count_line_colokjitu = 0;

  let db_formcolok_colokbebas_count_temp = 0;
  let db_formcolok_colokmacau_count_temp = 0;
  let db_formcolok_coloknaga_count_temp = 0;
  let db_formcolok_colokjitu_count_temp = 0;

  let dispatch = createEventDispatcher();
  let bottomOpen = false;
  const toggleBottom = () => {
    bottomOpen = !bottomOpen;
  };
  let canvasHeigth = 50;

  let State = {
    idle: "idle",
    requesting: "requesting",
    success: "success",
  };
  let ctoken = "";
  let state = State.idle;

  function doRecaptcha() {
    let captchaKey = sessionStorage.getItem("siteKey");
    return new Promise((resolve, reject) => {
      grecaptcha.ready(function () {
        grecaptcha.execute(captchaKey, { action: "submit" }).then(function (t) {
          state = State.success;
          resolve(t);
        });
      });
    });
  }

  async function inittogel_432d(e) {
    loadDisplay = true;
    const res = await fetch("/api/inittogel_432d", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        t: excryptData(
          JSON.stringify({
            company: client_company.toUpperCase(),
            pasaran_code: pasaran_code,
            permainan: e,
          })
        ),
      }),
    });
    group_btn_beli = true;
    const json = await res.json();
    loadDisplay = false;
    record = json.record;
    min_bet_colokbebas = record[0]["min_bet"];

    for (var i = 0; i < record.length; i++) {
      min_bet_colokbebas = parseInt(record[i]["min_bet_colokbebas"]);
      max_bet_colokbebas = parseInt(record[i]["max_bet_colokbebas"]);
      disc_bet_colokbebas = parseFloat(record[i]["disc_bet_colokbebas"]);
      win_bet_colokbebas = parseFloat(record[i]["win_bet_colokbebas"]);
      limittotal_bet_colokbebas = parseInt(
        record[i]["limittotal_bet_colokbebas"]
      );
      min_bet_colokmacau = parseInt(record[i]["min_bet_colokmacau"]);
      max_bet_colokmacau = parseFloat(record[i]["max_bet_colokmacau"]);
      disc_bet_colokmacau = parseFloat(record[i]["disc_bet_colokmacau"]);
      win_bet_colokmacau = parseFloat(record[i]["win_bet_colokmacau"]);
      win3_bet_colokmacau = parseFloat(record[i]["win3_bet_colokmacau"]);
      win4_bet_colokmacau = parseFloat(record[i]["win4_bet_colokmacau"]);
      limittotal_bet_colokmacau = parseInt(
        record[i]["limittotal_bet_colokmacau"]
      );
      min_bet_coloknaga = parseInt(record[i]["min_bet_coloknaga"]);
      max_bet_coloknaga = parseInt(record[i]["max_bet_coloknaga"]);
      disc_bet_coloknaga = parseFloat(record[i]["disc_bet_coloknaga"]);
      win_bet_coloknaga = parseFloat(record[i]["win_bet_coloknaga"]);
      win4_bet_coloknaga = parseFloat(record[i]["win4_bet_coloknaga"]);
      limittotal_bet_coloknaga = parseInt(
        record[i]["limittotal_bet_coloknaga"]
      );
      min_bet_colokjitu = parseInt(record[i]["min_bet_colokjitu"]);
      max_bet_colokjitu = parseInt(record[i]["max_bet_colokjitu"]);
      disc_bet_colokjitu = parseFloat(record[i]["disc_bet_colokjitu"]);
      winas_bet_colokjitu = parseFloat(record[i]["winas_bet_colokjitu"]);
      winkop_bet_colokjitu = parseFloat(record[i]["winkop_bet_colokjitu"]);
      winkepala_bet_colokjitu = parseFloat(
        record[i]["winkepala_bet_colokjitu"]
      );
      winekor_bet_colokjitu = parseFloat(record[i]["winekor_bet_colokjitu"]);
      limittotal_bet_colokjitu = parseInt(
        record[i]["limittotal_bet_colokjitu"]
      );
    }
  }
  async function savetransaksi() {
    ctoken = await doRecaptcha();
    group_btn_beli = false;
    const res = await fetch("/api/save/transaksi", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        t: excryptData(
          JSON.stringify({
            pasaran_idtransaction: idtrxkeluaran,
            pasaran_idcomp: idcomppasaran,
            pasaran_code: pasaran_code,
            pasaran_periode: pasaran_periode,
            token: client_token,
            company: client_company,
            username: client_username,
            ipaddress: client_ipaddress,
            devicemember: source,
            timezone: client_timezone,
            total: totalkeranjang,
            data: keranjang,
          })
        ),
        remoteip: client_ipaddress,
        token: ctoken,
      }),
    });
    const json = await res.json();
    if (json.status == "200") {
      loadDisplay = false;
      balance_credit = json.balance;
      Swal.fire({
        position: "center",
        icon: "success",
        title: "Tiket Anda sedang di proses",
        html:
          "<p>Mohon menunggu beberapa saat</p> <br/>" +
          "Total taruhan : " +
          new Intl.NumberFormat().format(json.totalbet),
        showConfirmButton: false,
        timer: 5000,
        background: daylight ? "#fff" : "#171717",
        color: daylight ? "#00a86b" : "#ff9900",
        toast: true,
      });
      dispatch("handleInvoice", "call");
      reset();
    } else {
      loadDisplay = false;
      group_btn_beli = true;
      if (json.status == "500" || json.status == "400") {
        group_btn_beli = true;
        Swal.fire({
          position: "center",
          icon: "error",
          title: json.message,
          showConfirmButton: false,
          timer: 3000,
          background: daylight ? "#fff" : "#171717",
          color: daylight ? "#00a86b" : "#ff9900",
          toast: true,
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          html: json.message,
          background: daylight ? "#fff" : "#171717",
          color: daylight ? "#00a86b" : "#ff9900",
        });
      }
    }
    ctoken = "";
    sessionStorage.removeItem("Colokcaptcha");
  }

  function reset() {
    keranjang = [];
    group_btn_beli = true;
    totalkeranjang = 0;
    count_line_colokbebas = 0;
    count_line_colokmacau = 0;
    count_line_coloknaga = 0;
    count_line_colokjitu = 0;
    inittogel_432d("colok");
  }
  inittogel_432d("colok");
  function addKeranjang(
    nomor,
    game,
    bet,
    diskon_percen,
    diskon,
    bayar,
    win,
    kei,
    kei_percen,
    tipetoto
  ) {
    let total_data = keranjang.length;
    let flag_data = false;
    temp_bulk_error = "";
    for (var i = 0; i < total_data; i++) {
      switch (game) {
        case "COLOK_BEBAS":
          if (nomor == keranjang[i].nomor.toString()) {
            let maxtotal_bayarcolokbebas = 0;
            for (var j = 0; j < keranjang.length; j++) {
              if ("COLOK_BEBAS" == keranjang[j].permainan) {
                if (parseInt(nomor) == parseInt(keranjang[j].nomor)) {
                  maxtotal_bayarcolokbebas =
                    parseInt(maxtotal_bayarcolokbebas) +
                    parseInt(keranjang[j].bet);
                }
              }
            }
            if (
              parseInt(limittotal_bet_colokbebas) <
              parseInt(maxtotal_bayarcolokbebas) + parseInt(bet)
            ) {
              temp_bulk_error =
                "Nomor ini : " +
                nomor +
                " sudah melebihi LIMIT TOTAL COLOK BEBAS<br />";
              flag_data = true;
            }
          }
          break;
        case "COLOK_MACAU":
          if (nomor == keranjang[i].nomor.toString()) {
            let maxtotal_bayarcolokmacau = 0;
            for (var j = 0; j < keranjang.length; j++) {
              if ("COLOK_MACAU" == keranjang[j].permainan) {
                if (parseInt(nomor) == parseInt(keranjang[j].nomor)) {
                  maxtotal_bayarcolokmacau =
                    parseInt(maxtotal_bayarcolokmacau) +
                    parseInt(keranjang[j].bet);
                }
              }
            }
            if (
              parseInt(limittotal_bet_colokmacau) <
              parseInt(maxtotal_bayarcolokmacau) + parseInt(bet)
            ) {
              temp_bulk_error =
                "Nomor ini : " +
                nomor +
                " sudah melebihi LIMIT TOTAL COLOK MACAU<br />";
              flag_data = true;
            }
          }
          break;
        case "COLOK_NAGA":
          if (nomor == keranjang[i].nomor.toString()) {
            let maxtotal_bayarcoloknaga = 0;
            for (var j = 0; j < keranjang.length; j++) {
              if ("COLOK_NAGA" == keranjang[j].permainan) {
                if (parseInt(nomor) == parseInt(keranjang[j].nomor)) {
                  maxtotal_bayarcoloknaga =
                    parseInt(maxtotal_bayarcoloknaga) +
                    parseInt(keranjang[j].bet);
                }
              }
            }
            if (
              parseInt(limittotal_bet_coloknaga) <
              parseInt(maxtotal_bayarcoloknaga) + parseInt(bet)
            ) {
              temp_bulk_error =
                "Nomor ini : " +
                nomor +
                " sudah melebihi LIMIT TOTAL COLOK NAGA<br />";
              flag_data = true;
            }
          }
          break;
        case "COLOK_JITU":
          if (nomor == keranjang[i].nomor.toString()) {
            let maxtotal_bayarcolokjitu = 0;
            for (var j = 0; j < keranjang.length; j++) {
              if ("COLOK_JITU" == keranjang[j].permainan) {
                if (nomor == keranjang[j].nomor) {
                  maxtotal_bayarcolokjitu =
                    parseInt(maxtotal_bayarcolokjitu) +
                    parseInt(keranjang[j].bet);
                }
              }
            }
            if (
              parseInt(limittotal_bet_colokjitu) <
              parseInt(maxtotal_bayarcolokjitu) + parseInt(bet)
            ) {
              temp_bulk_error =
                "Nomor ini : " +
                nomor +
                " sudah melebihi LIMIT TOTAL COLOK JITU<br />";
              flag_data = true;
            }
          }
          break;
      }
    }

    if (flag_data == false) {
      nomor_global = nomor_global + 1;
      const data = {
        id: nomor_global,
        nomor,
        permainan: game,
        bet,
        diskon,
        diskonpercen: diskon_percen,
        bayar,
        win,
        kei,
        kei_percen,
        tipetoto,
      };
      keranjang = [data, ...keranjang];
      count_keranjang();
    } else {
      totalkeranjang = totalkeranjang - bayar;
    }
  }
  const removekeranjang = (e) => {
    swalWithBootstrapButtons
      .fire({
        title:
          "Anda akan menghapus nomor pasangan : <strong>" +
          e.detail.nomor +
          "</strong>",
        html: "<h5>Apakah anda ingin melanjutkan?</h5>",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Ya, hapus sekarang!",
        cancelButtonText: "Tidak!",
        reverseButtons: true,
        background: daylight ? "#fff" : "#171717",
        color: daylight ? "#00a86b" : "#ff9900",
      })
      .then((result) => {
        if (result.isConfirmed) {
          keranjang = keranjang.filter(
            (keranjang) => keranjang.id != e.detail.idkeranjang
          );
          totalkeranjang = totalkeranjang - e.detail.bayar;
          count_keranjang();
        }
      });
  };
  const removekeranjangall = (e) => {
    if (keranjang.length > 0) {
      swalWithBootstrapButtons
        .fire({
          title: "Anda akan menghapus semua nomor pasangan!",
          html: "<h5>Apakah anda ingin melanjutkan?</h5>",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Ya, hapus sekarang!",
          cancelButtonText: "Tidak!",
          reverseButtons: true,
          background: daylight ? "#fff" : "#171717",
          color: daylight ? "#00a86b" : "#ff9900",
        })
        .then((result) => {
          if (result.isConfirmed) {
            reset();
            count_keranjang();
          }
        });
    } else {
      Swal.fire({
        position: "center",
        icon: "warning",
        title: "Tidak ada list transaksi",
        showConfirmButton: false,
        timer: 1500,
        background: daylight ? "#fff" : "#171717",
        color: daylight ? "#00a86b" : "#ff9900",
        toast: true,
      });
    }
  };
  const handleSave = (e) => {
    e.preventDefault();
    if (keranjang.length > 0) {
      let linkColor = daylight ? "#00a86b" : "#ff9900";
      swalWithBootstrapButtons
        .fire({
          title: "Apakah anda ingin melanjutkan?",
          html:
            "Total taruhan anda sebesar : <strong>IDR. " +
            new Intl.NumberFormat().format(totalkeranjang) +
            "</strong>",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Ya, pasang sekarang!",
          cancelButtonText: "Tidak!",
          reverseButtons: true,
          background: daylight ? "#fff" : "#171717",
          color: daylight ? "#00a86b" : "#ff9900",
          footer: `<small style="text-align:center">This site is protected by reCAPTCHA and the Google 
                <a style="color:${linkColor}" href="https://policies.google.com/privacy" target="_blank">Privacy Policy</a> and
                <a style="color:${linkColor}" href="https://policies.google.com/terms" target="_blank">Terms of Service</a> apply.
            </small>`,
        })
        .then((result) => {
          if (result.isConfirmed) {
            loadDisplay = true;
            savetransaksi();
          } else if (
            /* Read more about handling dismissals below */
            result.dismiss === Swal.DismissReason.cancel
          ) {
            loadDisplay = false;
            Swal.fire({
              position: "center",
              icon: "info",
              title: "Transaksi dibatalkan",
              showConfirmButton: false,
              timer: 3000,
              background: daylight ? "#fff" : "#171717",
              color: daylight ? "#00a86b" : "#ff9900",
              toast: true,
            });
          }
        });
    } else {
      loadDisplay = false;
      Swal.fire({
        position: "center",
        icon: "warning",
        title: "Tidak ada list transaksi",
        showConfirmButton: false,
        timer: 1500,
        background: daylight ? "#fff" : "#171717",
        color: daylight ? "#00a86b" : "#ff9900",
        toast: true,
      });
    }
  };
  function count_keranjang() {
    let count_colokbebas = 0;
    let count_colokmacau = 0;
    let count_coloknaga = 0;
    let count_colokjitu = 0;
    for (let i = 0; i < keranjang.length; i++) {
      switch (keranjang[i].permainan.toString()) {
        case "COLOK_BEBAS":
          count_colokbebas = count_colokbebas + 1;
          break;
        case "COLOK_MACAU":
          count_colokmacau = count_colokmacau + 1;
          break;
        case "COLOK_NAGA":
          count_coloknaga = count_coloknaga + 1;
          break;
        case "COLOK_JITU":
          count_colokjitu = count_colokjitu + 1;
          break;
      }
    }
    count_line_colokbebas =
      count_colokbebas + db_formcolok_colokbebas_count_temp;
    count_line_colokmacau =
      count_colokmacau + db_formcolok_colokmacau_count_temp;
    count_line_coloknaga = count_coloknaga + db_formcolok_coloknaga_count_temp;
    count_line_colokjitu = count_colokjitu + db_formcolok_colokjitu_count_temp;
    loadDisplay = false;
  }

  //COLOK BEBAS - INIT FORM
  let nomor_colokbebas = "";
  let nomor_colokbebas_input;
  let bet_colokbebas = "";

  //COLOK MACAU - INIT FORM
  let nomor_colokmacau_1 = "";
  let nomor_colokmacau_1_input;
  let nomor_colokmacau_2 = "";
  let nomor_colokmacau_2_input;
  let bet_colokmacau = "";

  //COLOK NAGA - INIT FORM
  let nomor_coloknaga_1 = "";
  let nomor_coloknaga_1_input;
  let nomor_coloknaga_2 = "";
  let nomor_coloknaga_2_input;
  let nomor_coloknaga_3 = "";
  let nomor_coloknaga_3_input;
  let bet_coloknaga = "";

  //COLOK JITU - INIT FORM
  let nomor_colokjitu = "";
  let nomor_colokjitu_input;
  let select_pilihancolokjitu = "";
  let select_pilihancolokjitu_input;
  let bet_colokjitu = "";

  //POLA COLOK - INIT FORM
  let nomor_polacolok = "";
  let nomor_polacolok_input;
  let bet_polacolokbebas = "";
  let bet_polacolokmacau = "";
  let bet_polacoloknaga = "";

  const clearNomor = (e) => {
    switch (e) {
      case "bebas":
        nomor_colokbebas = "";
        break;
      case "macau1":
        nomor_colokmacau_1 = "";
        break;
      case "macau2":
        nomor_colokmacau_2 = "";
        break;
      case "naga1":
        nomor_coloknaga_1 = "";
        break;
      case "naga2":
        nomor_coloknaga_2 = "";
        break;
      case "naga3":
        nomor_coloknaga_3 = "";
        break;
      case "jitu":
        nomor_colokjitu = "";
        break;
      case "pola":
        nomor_polacolok = "";
        break;
    }
  };

  let minimal_bet = 0;
  let maxbet = 0;
  let flag_fulldiskon = "DISC";
  function form_clear(e) {
    switch (e) {
      case "colokbebas":
        nomor_colokbebas = "";
        nomor_colokbebas_input.focus();
        bet_colokbebas = 0;
        break;
      case "colokmacau":
        nomor_colokmacau_1 = "";
        nomor_colokmacau_2 = "";
        nomor_colokmacau_1_input.focus();
        bet_colokmacau = 0;
        break;
      case "coloknaga":
        nomor_coloknaga_1 = "";
        nomor_coloknaga_2 = "";
        nomor_coloknaga_3 = "";
        nomor_coloknaga_1_input.focus();
        bet_coloknaga = 0;
        break;
      case "colokjitu":
        nomor_colokjitu = "";
        select_pilihancolokjitu = "";
        nomor_colokjitu_input.focus();
        bet_colokjitu = 0;
        break;
      case "polacolok":
        nomor_polacolok = "";
        nomor_polacolok_input.focus();
        bet_polacolokbebas = "";
        bet_polacolokmacau = "";
        bet_polacoloknaga = "";
        break;
    }
  }
  function formcolokbebas_add() {
    let flag = true;
    let nomor = nomor_colokbebas;
    let bet = bet_colokbebas;
    let diskon = 0;
    let diskonpercen = 0;
    let win = 0;
    let bayar = 0;
    let nmgame = "COLOK_BEBAS";
    if (nomor == "") {
      nomor_colokbebas_input.focus();
      flag = false;
    }
    if (bet == "") {
      flag = false;
      Swal.fire({
        position: "center",
        icon: "error",
        title: "Amount tidak boleh kosong",
        showConfirmButton: false,
        timer: 1500,
        background: daylight ? "#fff" : "#171717",
        color: daylight ? "#00a86b" : "#ff9900",
        toast: true,
      });
    }
    if (parseInt(bet) < parseInt(min_bet_colokbebas)) {
      flag = false;
      bet_colokbebas = min_bet_colokbebas;
      Swal.fire({
        position: "center",
        icon: "error",
        title: "Minimal Bet : " + min_bet_colokbebas,
        showConfirmButton: false,
        timer: 3000,
        background: daylight ? "#fff" : "#171717",
        color: daylight ? "#00a86b" : "#ff9900",
        toast: true,
      });
    }
    if (parseInt(bet) > parseInt(max_bet_colokbebas)) {
      flag = false;
      bet_colokbebas = max_bet_colokbebas;
      Swal.fire({
        position: "center",
        icon: "error",
        title: "Maximal Bet : " + max_bet_colokbebas,
        showConfirmButton: false,
        timer: 3000,
        background: daylight ? "#fff" : "#171717",
        color: daylight ? "#00a86b" : "#ff9900",
        toast: true,
      });
    }
    if (flag == true) {
      diskon = (bet * disc_bet_colokbebas).toFixed(0);
      diskonpercen = disc_bet_colokbebas;
      win = win_bet_colokbebas;
      bayar = parseInt(bet) - parseInt(Math.round(diskon));
      totalkeranjang = bayar + totalkeranjang;

      addKeranjang(
        nomor,
        nmgame,
        bet_colokbebas,
        diskonpercen,
        diskon,
        bayar,
        win,
        0,
        0,
        flag_fulldiskon
      );
      form_clear("colokbebas");
      if (temp_bulk_error != "") {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          html: temp_bulk_error,
          background: daylight ? "#fff" : "#171717",
          color: daylight ? "#00a86b" : "#ff9900",
        });
      }
    }
  }
  function formcolokmacau_add() {
    let flag = true;
    let nomor = nomor_colokmacau_1;
    let nomor2 = nomor_colokmacau_2;
    let bet = bet_colokmacau;
    let diskon = 0;
    let diskonpercen = 0;
    let win = 0;
    let bayar = 0;
    let nmgame = "COLOK_MACAU";
    if (nomor == "") {
      nomor_colokmacau_1_input.focus();
      flag = false;
    }
    if (nomor2 == "") {
      flag = false;
    }
    if (nomor == nomor2) {
      Swal.fire({
        position: "center",
        icon: "error",
        title: "Nomor tidak boleh sama",
        showConfirmButton: false,
        timer: 3000,
        background: daylight ? "#fff" : "#171717",
        color: daylight ? "#00a86b" : "#ff9900",
        toast: true,
      });
      flag = false;
      form_clear("colokmacau");
    }
    if (bet == "") {
      flag = false;
      Swal.fire({
        position: "center",
        icon: "error",
        title: "Amount tidak boleh kosong",
        showConfirmButton: false,
        timer: 1500,
        background: daylight ? "#fff" : "#171717",
        color: daylight ? "#00a86b" : "#ff9900",
        toast: true,
      });
    }
    if (parseInt(bet) < parseInt(min_bet_colokmacau)) {
      bet_colokmacau = min_bet_colokmacau;
      flag = false;
      Swal.fire({
        position: "center",
        icon: "error",
        title: "Minimal Bet : " + min_bet_colokmacau,
        showConfirmButton: false,
        timer: 3000,
        background: daylight ? "#fff" : "#171717",
        color: daylight ? "#00a86b" : "#ff9900",
        toast: true,
      });
    }
    if (parseInt(bet) > parseInt(max_bet_colokmacau)) {
      bet_colokmacau = max_bet_colokmacau;
      flag = false;
      Swal.fire({
        position: "center",
        icon: "error",
        title: "Maximal Bet : " + max_bet_colokmacau,
        showConfirmButton: false,
        timer: 3000,
        background: daylight ? "#fff" : "#171717",
        color: daylight ? "#00a86b" : "#ff9900",
        toast: true,
      });
    }
    if (flag == true) {
      diskon = bet * disc_bet_colokmacau;
      diskonpercen = disc_bet_colokmacau;
      win = win_bet_colokmacau;
      bayar = parseInt(bet) - parseInt(Math.ceil(diskon));
      totalkeranjang = bayar + totalkeranjang;

      addKeranjang(
        nomor + "" + nomor2,
        nmgame,
        bet_colokmacau,
        diskonpercen,
        diskon,
        bayar,
        win,
        0,
        0,
        flag_fulldiskon
      );
      form_clear("colokmacau");
      if (temp_bulk_error != "") {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          html: temp_bulk_error,
          background: daylight ? "#fff" : "#171717",
          color: daylight ? "#00a86b" : "#ff9900",
        });
      }
    }
  }
  function formcoloknaga_add() {
    let flag = true;
    let nomor = nomor_coloknaga_1;
    let nomor2 = nomor_coloknaga_2;
    let nomor3 = nomor_coloknaga_3;
    let bet = bet_coloknaga;
    let diskon = 0;
    let diskonpercen = 0;
    let win = 0;
    let bayar = 0;
    let nmgame = "COLOK_NAGA";

    if (nomor == "") {
      nomor_coloknaga_1_input.focus();
      flag = false;
    }
    if (nomor2 == "") {
      nomor_coloknaga_2_input.focus();
      flag = false;
    }
    if (nomor3 == "") {
      nomor_coloknaga_3_input.focus();
      flag = false;
    }
    if (nomor == nomor2) {
      Swal.fire({
        position: "center",
        icon: "error",
        title: "Nomor tidak boleh sama",
        showConfirmButton: false,
        timer: 3000,
        background: daylight ? "#fff" : "#171717",
        color: daylight ? "#00a86b" : "#ff9900",
        toast: true,
      });
      flag = false;
      form_clear("coloknaga");
    }
    if (nomor == nomor3) {
      Swal.fire({
        position: "center",
        icon: "error",
        title: "Nomor tidak boleh sama",
        showConfirmButton: false,
        timer: 3000,
        background: daylight ? "#fff" : "#171717",
        color: daylight ? "#00a86b" : "#ff9900",
        toast: true,
      });
      flag = false;
      form_clear("coloknaga");
    }
    if (nomor2 == nomor3) {
      Swal.fire({
        position: "center",
        icon: "error",
        title: "Nomor tidak boleh sama",
        showConfirmButton: false,
        timer: 3000,
        background: daylight ? "#fff" : "#171717",
        color: daylight ? "#00a86b" : "#ff9900",
        toast: true,
      });
      flag = false;
      form_clear("coloknaga");
    }
    if (bet == "") {
      flag = false;
      Swal.fire({
        position: "center",
        icon: "error",
        title: "Amount tidak boleh kosong",
        showConfirmButton: false,
        timer: 1500,
        background: daylight ? "#fff" : "#171717",
        color: daylight ? "#00a86b" : "#ff9900",
        toast: true,
      });
    }
    if (parseInt(bet) < parseInt(min_bet_coloknaga)) {
      bet_coloknaga = min_bet_coloknaga;
      flag = false;
      Swal.fire({
        position: "center",
        icon: "error",
        title: "Minimal Bet : " + min_bet_coloknaga,
        showConfirmButton: false,
        timer: 3000,
        background: daylight ? "#fff" : "#171717",
        color: daylight ? "#00a86b" : "#ff9900",
        toast: true,
      });
    }
    if (parseInt(bet) > parseInt(max_bet_coloknaga)) {
      bet_coloknaga = max_bet_coloknaga;
      flag = false;
      Swal.fire({
        position: "center",
        icon: "error",
        title: "Maximal Bet : " + max_bet_coloknaga,
        showConfirmButton: false,
        timer: 3000,
        background: daylight ? "#fff" : "#171717",
        color: daylight ? "#00a86b" : "#ff9900",
        toast: true,
      });
    }
    if (flag == true) {
      diskon = bet * disc_bet_coloknaga;
      diskonpercen = disc_bet_coloknaga;
      win = win4_bet_coloknaga;
      bayar = parseInt(bet) - parseInt(Math.ceil(diskon));
      totalkeranjang = bayar + totalkeranjang;
      addKeranjang(
        nomor + "" + nomor2 + "" + nomor3,
        nmgame,
        bet_coloknaga,
        diskonpercen,
        diskon,
        bayar,
        win,
        0,
        0,
        flag_fulldiskon
      );
      form_clear("coloknaga");
      if (temp_bulk_error != "") {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          html: temp_bulk_error,
          background: daylight ? "#fff" : "#171717",
          color: daylight ? "#00a86b" : "#ff9900",
        });
      }
    }
  }
  function formcolokjitu_add() {
    let flag = true;
    let nomor = nomor_colokjitu;
    let posisi = select_pilihancolokjitu;
    let bet = bet_colokjitu;
    let diskon = 0;
    let diskonpercen = 0;
    let win = 0;
    let bayar = 0;
    let nmgame = "COLOK_JITU";

    if (nomor == "") {
      nomor_colokjitu_input.focus();
      flag = false;
    }
    if (posisi == "") {
      select_pilihancolokjitu_input.focus();
      flag = false;
      Swal.fire({
        position: "center",
        icon: "error",
        title: "Posisi wajib diisi",
        showConfirmButton: false,
        timer: 3000,
        background: daylight ? "#fff" : "#171717",
        color: daylight ? "#00a86b" : "#ff9900",
        toast: true,
      });
    }
    if (bet == "") {
      flag = false;
      Swal.fire({
        position: "center",
        icon: "error",
        title: "Amount tidak boleh kosong",
        showConfirmButton: false,
        timer: 1500,
        background: daylight ? "#fff" : "#171717",
        color: daylight ? "#00a86b" : "#ff9900",
        toast: true,
      });
    }
    if (parseInt(bet) < parseInt(min_bet_colokjitu)) {
      bet_colokjitu = min_bet_colokjitu;
      flag = false;
      Swal.fire({
        position: "center",
        icon: "error",
        title: "Minimal Bet : " + min_bet_colokjitu,
        showConfirmButton: false,
        timer: 3000,
        background: daylight ? "#fff" : "#171717",
        color: daylight ? "#00a86b" : "#ff9900",
        toast: true,
      });
    }
    if (parseInt(bet) > parseInt(max_bet_colokjitu)) {
      bet_colokjitu = max_bet_colokjitu;
      flag = false;
      Swal.fire({
        position: "center",
        icon: "error",
        title: "Maximal Bet : " + max_bet_colokjitu,
        showConfirmButton: false,
        timer: 3000,
        background: daylight ? "#fff" : "#171717",
        color: daylight ? "#00a86b" : "#ff9900",
        toast: true,
      });
    }
    if (flag == true) {
      diskon = bet * disc_bet_colokjitu;
      diskonpercen = disc_bet_colokjitu;

      switch (posisi) {
        case "AS":
          win = winas_bet_colokjitu;
          break;
        case "KOP":
          win = winkop_bet_colokjitu;
          break;
        case "KEPALA":
          win = winkepala_bet_colokjitu;
          break;
        case "EKOR":
          win = winekor_bet_colokjitu;
          break;
      }

      bayar = parseInt(bet) - parseInt(Math.ceil(diskon));
      totalkeranjang = bayar + totalkeranjang;

      addKeranjang(
        nomor + "_" + posisi,
        nmgame,
        bet_colokjitu,
        diskonpercen,
        diskon,
        bayar,
        win,
        0,
        0,
        flag_fulldiskon
      );
      form_clear("colokjitu");
      if (temp_bulk_error != "") {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          html: temp_bulk_error,
          background: daylight ? "#fff" : "#171717",
          color: daylight ? "#00a86b" : "#ff9900",
        });
      }
    }
  }

  function formpolacolok_add() {
    let flag = true;
    let diskon = 0;
    let diskonpercen = 0;
    let win = 0;
    let bayar = 0;
    let found = false;
    let msg = "";
    if (nomor_polacolok == "") {
      nomor_polacolok.focus();
      flag = false;
    }
    if (nomor_polacolok.length < 4 || nomor_polacolok.length > 7) {
      nomor_polacolok_input.focus();
      flag = false;
      msg += "Minimal 4 Digit dan Maximal 7 Digit";
    }

    if (parseInt(bet_polacolokbebas) > 1) {
      if (parseInt(bet_polacolokbebas) < parseInt(min_bet_colokbebas)) {
        flag = false;
        bet_polacolokbebas = min_bet_colokbebas;
        msg += "Minimal Bet Colok Bebas : " + min_bet_colokbebas;
      }
      if (parseInt(bet_polacolokbebas) > parseInt(max_bet_colokbebas)) {
        flag = false;
        bet_polacolokbebas = max_bet_colokbebas;
        msg += "Maximal Bet Colok Bebas : " + max_bet_colokbebas;
      }
      if (flag) {
        for (let i = 0; i < nomor_polacolok.length; i++) {
          diskon = bet_polacolokbebas * disc_bet_colokbebas;
          diskonpercen = disc_bet_colokbebas;
          win = win_bet_colokbebas;
          bayar = parseInt(bet_polacolokbebas) - parseInt(Math.ceil(diskon));
          totalkeranjang = bayar + totalkeranjang;
          addKeranjang(
            nomor_polacolok[i],
            "COLOK_BEBAS",
            bet_polacolokbebas,
            diskonpercen,
            diskon,
            bayar,
            win,
            0,
            0,
            flag_fulldiskon
          );
        }
      }
    }
    if (parseInt(bet_polacolokmacau) > 1) {
      let temp2d = [];
      if (parseInt(bet_polacolokmacau) < parseInt(min_bet_colokmacau)) {
        flag = false;
        bet_polacolokmacau = min_bet_colokmacau;
        msg += "Minimal Bet Colok Macau : " + min_bet_colokmacau;
      }
      if (parseInt(bet_polacolokmacau) > parseInt(max_bet_colokmacau)) {
        flag = false;
        bet_polacolokmacau = max_bet_colokmacau;
        msg += "Maximal Bet Colok Macau : " + max_bet_colokmacau;
      }
      if (flag) {
        let dat = "";
        for (let i = 0; i < nomor_polacolok.length; i++) {
          for (let j = 0; j < nomor_polacolok.length; j++) {
            dat = "";
            found = false;
            if (nomor_polacolok[i] != nomor_polacolok[j]) {
              dat = nomor_polacolok[i] + nomor_polacolok[j];
              found = true;
            }
            if (found) {
              if (dat != "") {
                temp2d.push(dat);
              }
            }
          }
        }
        for (let i = 0; i < temp2d.length; i++) {
          diskon = bet_polacolokmacau * disc_bet_colokmacau;
          diskonpercen = disc_bet_colokmacau;
          win = win_bet_colokmacau;
          bayar = parseInt(bet_polacolokmacau) - parseInt(Math.ceil(diskon));
          totalkeranjang = bayar + totalkeranjang;
          addKeranjang(
            temp2d[i],
            "COLOK_MACAU",
            bet_polacolokmacau,
            diskonpercen,
            diskon,
            bayar,
            win,
            0,
            0,
            flag_fulldiskon
          );
        }
      }
    }
    if (parseInt(bet_polacoloknaga) > 1) {
      let temp3d = [];
      if (parseInt(bet_polacoloknaga) < parseInt(min_bet_coloknaga)) {
        flag = false;
        bet_polacoloknaga = min_bet_coloknaga;
        msg += "Minimal Bet Colok Naga : " + min_bet_coloknaga;
      }
      if (parseInt(bet_polacoloknaga) > parseInt(max_bet_coloknaga)) {
        flag = false;
        bet_polacoloknaga = max_bet_coloknaga;
        msg += "Maximal Bet Colok Naga : " + max_bet_coloknaga;
      }
      if (flag) {
        let dat = "";
        let flagcompare = false;
        for (let i = 0; i < nomor_polacolok.length; i++) {
          for (let j = 0; j < nomor_polacolok.length; j++) {
            for (let x = 0; x < nomor_polacolok.length; x++) {
              dat = "";
              found = false;
              flagcompare = true;

              if (nomor_polacolok[i] == nomor_polacolok[j]) {
                flagcompare = false;
              }
              if (nomor_polacolok[i] == nomor_polacolok[x]) {
                flagcompare = false;
              }
              if (nomor_polacolok[j] == nomor_polacolok[x]) {
                flagcompare = false;
              }
              if (flagcompare) {
                dat =
                  nomor_polacolok[i] + nomor_polacolok[j] + nomor_polacolok[x];
                found = true;
              }
              if (found) {
                if (dat != "") {
                  temp3d.push(dat);
                }
              }
            }
          }
        }
        for (let i = 0; i < temp3d.length; i++) {
          diskon = bet_polacoloknaga * disc_bet_coloknaga;
          diskonpercen = disc_bet_coloknaga;
          win = win4_bet_coloknaga;
          bayar = parseInt(bet_polacoloknaga) - parseInt(Math.ceil(diskon));
          totalkeranjang = bayar + totalkeranjang;
          addKeranjang(
            temp3d[i],
            "COLOK_NAGA",
            bet_polacoloknaga,
            diskonpercen,
            diskon,
            bayar,
            win,
            0,
            0,
            flag_fulldiskon
          );
        }
      }
    }

    if (msg != "") {
      Swal.fire({
        position: "center",
        icon: "error",
        title: msg,
        showConfirmButton: false,
        timer: 3000,
        background: daylight ? "#fff" : "#171717",
        color: daylight ? "#00a86b" : "#ff9900",
        toast: true,
      });
    }
    form_clear("polacolok");
    if (temp_bulk_error != "") {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        html: temp_bulk_error,
        background: daylight ? "#fff" : "#171717",
        color: daylight ? "#00a86b" : "#ff9900",
      });
    }
  }

  function checkInteger(number) {
    if (!parseInt(number)) {
      return "0";
    }
    return number;
  }

  const handleTambah = (e) => {
    if (client_device !== "WEBSITE") {
      bottomOpen = !bottomOpen;
    }
    switch (e) {
      case "colokbebas":
        bet_colokbebas = checkInteger(bet_colokbebas);
        if (
          nomor_colokbebas == "" &&
          parseInt(bet_colokbebas) < min_bet_colokbebas
        ) {
          nomor_colokbebas_input.focus();
        } else {
          formcolokbebas_add();
        }
        break;
      case "colokmacau":
        bet_colokmacau = checkInteger(bet_colokmacau);
        if (
          nomor_colokmacau_1 == "" &&
          nomor_colokmacau_2 == "" &&
          parseInt(bet_colokmacau) < min_bet_colokmacau
        ) {
          nomor_colokmacau_1_input.focus();
        } else {
          formcolokmacau_add();
        }
        break;
      case "coloknaga":
        bet_coloknaga = checkInteger(bet_coloknaga);
        if (
          nomor_coloknaga_1 == "" &&
          nomor_coloknaga_2 == "" &&
          nomor_coloknaga_3 == "" &&
          parseInt(bet_coloknaga) < min_bet_coloknaga
        ) {
          nomor_coloknaga_1_input.focus();
        } else {
          formcoloknaga_add();
        }
        break;
      case "colokjitu":
        bet_colokjitu = checkInteger(bet_colokjitu);
        if (
          nomor_colokjitu == "" &&
          parseInt(bet_colokjitu) < min_bet_colokjitu
        ) {
          nomor_colokjitu_input.focus();
        } else {
          formcolokjitu_add();
        }
        break;
      case "polacolok":
        if (nomor_polacolok == "") {
          nomor_polacolok_input.focus();
        } else {
          formpolacolok_add();
        }
        break;
    }
  };
  const handleKeyboard_format = (e) => {
    let key = e.target.value.replace(/\s/g, "");
    var numbers = /^\d+$/;

    let result = numbers.test(e.target.value);
    if (result) {
      return (e.target.value = key);
    } else {
      return (e.target.value = "");
    }
  };

  const handleKeyboard_number = (e) => {
    var numbers = /^\d+$/;
    let result = numbers.test(e.target.value);
    if (!result) {
      return (e.target.value = 0);
    } else {
      return (e.target.value = parseInt(e.target.value.replace(/\s/g, "")));
    }
  };

  const handleKeyboard_checkenter = (e) => {
    let keyCode = e.which || e.keyCode;
    if (keyCode === 13) {
      handleTambah("colokbebas");
      // formcolokbebas_add();
    }
  };
  const handleKeyboardcolokmacau_checkenter = (e) => {
    let keyCode = e.which || e.keyCode;
    if (keyCode === 13) {
      handleTambah("colokmacau");
      // formcolokmacau_add();
    }
  };
  const handleKeyboardcoloknaga_checkenter = (e) => {
    let keyCode = e.which || e.keyCode;
    if (keyCode === 13) {
      handleTambah("coloknaga");
      // formcoloknaga_add();
    }
  };
  const handleKeyboardcolokjitu_checkenter = (e) => {
    let keyCode = e.which || e.keyCode;
    if (keyCode === 13) {
      handleTambah("colokjitu");
      // formcolokjitu_add();
    }
  };
  const handleKeyboardpolacolok_checkenter = (e) => {
    let keyCode = e.which || e.keyCode;
    if (keyCode === 13) {
      handleTambah("polacolok");
      // formpolacolok_add();
    }
  };

  $: {
    if (betHistoryView) {
      dispatch("handleInvoice", "all");
    }
    switch (gametype) {
      case "COLOK BEBAS":
        minimal_bet = min_bet_colokbebas;
        maxbet = max_bet_colokbebas;
        break;
      case "COLOK MACAU":
        minimal_bet = min_bet_colokmacau;
        maxbet = max_bet_colokmacau;
        break;
      case "COLOK NAGA":
        minimal_bet = min_bet_coloknaga;
        maxbet = max_bet_coloknaga;
        break;
      case "COLOK JITU":
        minimal_bet = min_bet_colokjitu;
        maxbet = max_bet_colokjitu;
        break;
    }
  }
</script>

<svelte:head>
  <title>SDSB4D - {pasaran_name} - {gametype}</title>
</svelte:head>

<Card class={daylight ? "" : "bg-dark"}>
  <PeriodePanel
    {pasaran_name}
    {permainan_title}
    {pasaran_periode}
    {pasaran_code}
    {daylight}
    {client_device}
  />
  <CardBody class={daylight ? "" : "dark"}>
    {#if client_device == "WEBSITE"}
      <TabContent
        pills
        class="periode-menu {daylight ? '' : 'dark'}"
        on:tab={(e) => (gametype = e.detail)}
      >
        <!-- BEBAS -->
        <TabPane tabId="COLOK BEBAS" tab="BEBAS" active>
          <div style="margin:10px 0;">
            <div class="row">
              <div class="col-md-3">
                <div class="mb-3">
                  <div class="form-floating">
                    <!-- svelte-ignore a11y-autofocus -->
                    <input
                      bind:this={nomor_colokbebas_input}
                      bind:value={nomor_colokbebas}
                      on:keyup={handleKeyboard_format}
                      on:keypress={handleKeyboard_checkenter}
                      type="text"
                      class="form-control fs-5 text-center button-bet-default"
                      class:dark={daylight === false}
                      placeholder="Input 1 Digit"
                      id="inputNomorBebas"
                      minlength="1"
                      maxlength="1"
                      tab_index="-1"
                      autocomplete="off"
                    />
                    <label for="inputNomorBebas" class="form-label"
                      >Input 1 Digit (0-9)</label
                    >
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="mb-3">
                  <div class="form-floating">
                    <input
                      bind:value={bet_colokbebas}
                      on:keyup={handleKeyboard_number}
                      on:keypress={handleKeyboard_checkenter}
                      type="text"
                      class="form-control fs-5 text-end button-bet-default"
                      class:dark={daylight === false}
                      placeholder="Bet"
                      id="inputBetBebas"
                      style=""
                      minlength="3"
                      maxlength={max_bet_colokbebas.toString().length}
                      tab_index="0"
                      autocomplete="off"
                    />
                    <label for="inputBetBebas" class="form-label"
                      >Bet (min : {new Intl.NumberFormat().format(
                        min_bet_colokbebas
                      )} dan max : {new Intl.NumberFormat().format(
                        max_bet_colokbebas
                      )})</label
                    >
                    <span style="text-align:right;font-size:12px;color:#8a8a8a;"
                      >{new Intl.NumberFormat().format(bet_colokbebas)}</span
                    >
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="mb-3">
                  <Button
                    id="btn2"
                    class="form-control mt-2"
                    style="border-radius:5px"
                    on:click={() => {
                      handleTambah("colokbebas");
                    }}>TAMBAH</Button
                  >
                </div>
              </div>
            </div>
          </div>
        </TabPane>

        <!-- MACAU -->
        <TabPane tabId="COLOK MACAU" tab="MACAU">
          <div style="margin:10px 0;">
            <div class="row">
              <div class="col-md">
                <div class="form-floating">
                  <!-- svelte-ignore a11y-autofocus -->
                  <input
                    bind:this={nomor_colokmacau_1_input}
                    bind:value={nomor_colokmacau_1}
                    on:keyup={handleKeyboard_format}
                    on:keypress={handleKeyboard_checkenter}
                    type="text"
                    id="inputNomorMacau1"
                    class="form-control fs-5 text-center button-bet-default"
                    class:dark={daylight === false}
                    placeholder="Input 1 Digit"
                    minlength="1"
                    maxlength="1"
                    tab_index="-1"
                    autocomplete="off"
                  />
                  <label for="inputNomorMacau1" class="form-label"
                    >Input 1 Digit (0-9)</label
                  >
                </div>
              </div>
              <div class="col-md">
                <div class="form-floating">
                  <!-- svelte-ignore a11y-autofocus -->
                  <input
                    bind:this={nomor_colokmacau_2_input}
                    bind:value={nomor_colokmacau_2}
                    on:keyup={handleKeyboard_format}
                    on:keypress={handleKeyboard_checkenter}
                    type="text"
                    id="inputNomorMacau2"
                    class="form-control fs-5 text-center button-bet-default"
                    class:dark={daylight === false}
                    placeholder="Input 1 Digit"
                    minlength="1"
                    maxlength="1"
                    tab_index="-1"
                    autocomplete="off"
                  />
                  <label for="inputNomorMacau2" class="form-label"
                    >Input 1 Digit (0-9)</label
                  >
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-floating">
                  <input
                    bind:value={bet_colokmacau}
                    on:keyup={handleKeyboard_number}
                    on:keypress={handleKeyboardcolokmacau_checkenter}
                    type="text"
                    class="form-control fs-5 text-end button-bet-default"
                    class:dark={daylight === false}
                    placeholder="Bet"
                    id="inputBetMacau"
                    style=""
                    minlength="3"
                    maxlength={max_bet_colokmacau.toString().length}
                    tab_index="0"
                    autocomplete="off"
                  />
                  <label for="inputBetMacau" class="form-label"
                    >Bet (min : {new Intl.NumberFormat().format(
                      min_bet_colokmacau
                    )} dan max : {new Intl.NumberFormat().format(
                      max_bet_colokmacau
                    )})</label
                  >
                  <span style="float:right;font-size:12px;color:#8a8a8a;"
                    >{new Intl.NumberFormat().format(bet_colokmacau)}</span
                  >
                </div>
              </div>
              <div class="col">
                <div class="mb-3">
                  <Button
                    id="btn2"
                    class="form-control mt-2"
                    style="border-radius:5px"
                    on:click={() => {
                      handleTambah("colokmacau");
                    }}>TAMBAH</Button
                  >
                </div>
              </div>
            </div>
          </div>
        </TabPane>

        <!-- NAGA -->
        <TabPane tabId="COLOK NAGA" tab="NAGA">
          <div style="margin:10px 0;">
            <div class="row gap-3">
              <div class="col-md">
                <div class="form-floating">
                  <!-- svelte-ignore a11y-autofocus -->
                  <input
                    bind:this={nomor_coloknaga_1_input}
                    bind:value={nomor_coloknaga_1}
                    on:keyup={handleKeyboard_format}
                    on:keypress={handleKeyboard_checkenter}
                    type="text"
                    id="inputNomorNaga1"
                    class="form-control fs-5 text-center button-bet-default"
                    class:dark={daylight === false}
                    placeholder="Input 1 Digit"
                    minlength="1"
                    maxlength="1"
                    tab_index="-1"
                    autocomplete="off"
                  />
                  <label for="inputNomorNaga1" class="form-label"
                    >Input 1 Digit (0-9)</label
                  >
                </div>
              </div>
              <div class="col-md">
                <div class="form-floating">
                  <!-- svelte-ignore a11y-autofocus -->
                  <input
                    bind:this={nomor_coloknaga_2_input}
                    bind:value={nomor_coloknaga_2}
                    on:keyup={handleKeyboard_format}
                    on:keypress={handleKeyboard_checkenter}
                    type="text"
                    id="inputNomorNaga2"
                    class="form-control fs-5 text-center button-bet-default"
                    class:dark={daylight === false}
                    placeholder="Input 1 Digit"
                    minlength="1"
                    maxlength="1"
                    tab_index="-1"
                    autocomplete="off"
                  />
                  <label for="inputNomorNaga2" class="form-label"
                    >Input 1 Digit (0-9)</label
                  >
                </div>
              </div>
              <div class="col-md">
                <div class="form-floating">
                  <!-- svelte-ignore a11y-autofocus -->
                  <input
                    bind:this={nomor_coloknaga_3_input}
                    bind:value={nomor_coloknaga_3}
                    on:keyup={handleKeyboard_format}
                    on:keypress={handleKeyboard_checkenter}
                    type="text"
                    id="inputNomorNaga3"
                    class="form-control fs-5 text-center button-bet-default"
                    class:dark={daylight === false}
                    placeholder="Input 1 Digit"
                    minlength="1"
                    maxlength="1"
                    tab_index="-1"
                    autocomplete="off"
                  />
                  <label for="inputNomorNaga3" class="form-label"
                    >Input 1 Digit (0-9)</label
                  >
                </div>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-md">
                <div class="form-floating">
                  <input
                    bind:value={bet_coloknaga}
                    on:keyup={handleKeyboard_number}
                    on:keypress={handleKeyboardcoloknaga_checkenter}
                    type="text"
                    class="form-control fs-5 text-end button-bet-default"
                    class:dark={daylight === false}
                    placeholder="Bet"
                    id="inputBetNaga"
                    style=""
                    minlength="3"
                    maxlength={max_bet_coloknaga.toString().length}
                    tab_index="0"
                    autocomplete="off"
                  />
                  <label for="inputBetNaga" class="form-label">
                    Bet (min : {new Intl.NumberFormat().format(
                      min_bet_coloknaga
                    )}
                    dan max : {new Intl.NumberFormat().format(
                      max_bet_coloknaga
                    )})</label
                  >
                  <span style="float:right;font-size:12px;color:#8a8a8a;"
                    >{new Intl.NumberFormat().format(bet_coloknaga)}</span
                  >
                </div>
              </div>
              <div class="col-md">
                <div class="mb-3">
                  <Button
                    id="btn2"
                    class="form-control mt-2"
                    style="border-radius:5px"
                    on:click={() => {
                      handleTambah("coloknaga");
                    }}>TAMBAH</Button
                  >
                </div>
              </div>
            </div>
          </div>
        </TabPane>

        <!-- JITU -->
        <TabPane tabId="COLOK JITU" tab="JITU">
          <div style="margin:10px 0;">
            <div class="row gap-3">
              <div class="col-md">
                <div class="form-floating">
                  <!-- svelte-ignore a11y-autofocus -->
                  <input
                    bind:this={nomor_colokjitu_input}
                    bind:value={nomor_colokjitu}
                    on:keyup={handleKeyboard_format}
                    on:keypress={handleKeyboard_checkenter}
                    type="text"
                    id="inputNomorJitu"
                    class="form-control fs-5 text-center button-bet-default"
                    class:dark={daylight === false}
                    placeholder="Input 1 Digit"
                    minlength="1"
                    maxlength="1"
                    tab_index="-1"
                    autocomplete="off"
                  />
                  <label for="inputNomorJitu" class="form-label"
                    >Input 1 Digit (0-9)</label
                  >
                </div>
              </div>
              <div class="col-md">
                <div class="form-floating">
                  <select
                    class="form-select button-bet-default"
                    class:dark={daylight === false}
                    bind:value={select_pilihancolokjitu}
                    bind:this={select_pilihancolokjitu_input}
                    id="selectOptJitu"
                    aria-label="Floating label select"
                  >
                    <option value="">--Pilih--</option>
                    <option value="AS">AS</option>
                    <option value="KOP">KOP</option>
                    <option value="KEPALA">KEPALA</option>
                    <option value="EKOR">EKOR</option>
                  </select>
                  <label for="selectOptJitu">Posisi</label>
                </div>
              </div>
              <div class="col-md">
                <div class="form-floating">
                  <input
                    bind:value={bet_colokjitu}
                    on:keyup={handleKeyboard_number}
                    on:keypress={handleKeyboardcolokjitu_checkenter}
                    type="text"
                    id="betColokJitu"
                    class="form-control fs-5 text-end button-bet-default"
                    class:dark={daylight === false}
                    placeholder="Bet"
                    minlength="3"
                    maxlength={max_bet_colokjitu.toString().length}
                    tab_index="0"
                    autocomplete="off"
                  />
                  <span style="float:right;font-size:12px;color:#8a8a8a;"
                    >{new Intl.NumberFormat().format(bet_colokjitu)}</span
                  >
                  <label for="betColokJitu" class="form-label"
                    >Bet (min : {new Intl.NumberFormat().format(
                      min_bet_colokjitu
                    )} dan max : {new Intl.NumberFormat().format(
                      max_bet_colokjitu
                    )})</label
                  >
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <Button
                  id="btn2"
                  class="form-control mt-2"
                  style="border-radius:5px"
                  on:click={() => {
                    handleTambah("colokjitu");
                  }}>TAMBAH</Button
                >
              </div>
            </div>
          </div>
        </TabPane>

        <!-- POLA COLOK -->
        <TabPane tabId="POLA COLOK" tab="POLA">
          <div style="margin:10px 0;">
            <div class="row gap-3">
              <div class="col-md">
                <div class="mb-3">
                  <div class="form-floating">
                    <!-- svelte-ignore a11y-autofocus -->
                    <input
                      bind:this={nomor_polacolok_input}
                      bind:value={nomor_polacolok}
                      on:keyup={handleKeyboard_format}
                      on:keypress={handleKeyboard_checkenter}
                      type="text"
                      id="inputNomorPola"
                      class="form-control fs-5 text-center button-bet-default"
                      class:dark={daylight === false}
                      placeholder="Input 4 - 7 Digit"
                      minlength="4"
                      maxlength="7"
                      tab_index="-1"
                      autocomplete="off"
                    />
                    <label for="inputNomorPola" class="form-label"
                      >Input 4 - 7 Digit</label
                    >
                  </div>
                </div>
              </div>
              <div class="col-md">
                <div class="mb-3">
                  <div class="form-floating">
                    <!-- svelte-ignore a11y-autofocus -->
                    <input
                      bind:value={bet_polacolokbebas}
                      on:keyup={handleKeyboard_number}
                      on:keypress={handleKeyboardpolacolok_checkenter}
                      type="text"
                      id="BetNomorBebas"
                      class="form-control fs-5 text-end button-bet-default"
                      class:dark={daylight === false}
                      placeholder="Input 1 Digit"
                      minlength="3"
                      maxlength={max_bet_colokbebas.toString().length}
                      tab_index="0"
                      autocomplete="off"
                    />
                    <label for="BetNomorBebas" class="form-label">
                      Bet Colok Bebas</label
                    >
                    <span style="float:right;font-size:12px;color:#8a8a8a;"
                      >{new Intl.NumberFormat().format(
                        bet_polacolokbebas
                      )}</span
                    >
                  </div>
                </div>
              </div>
              <div class="col-md">
                <div class="mb-3">
                  <div class="form-floating">
                    <!-- svelte-ignore a11y-autofocus -->
                    <input
                      bind:value={bet_polacolokmacau}
                      on:keyup={handleKeyboard_number}
                      on:keypress={handleKeyboardpolacolok_checkenter}
                      type="text"
                      id="BetColokMacau"
                      class="form-control fs-5 text-end button-bet-default"
                      class:dark={daylight === false}
                      placeholder="Bet Colok Macau"
                      minlength="3"
                      maxlength={max_bet_colokmacau.toString().length}
                      tab_index="0"
                      autocomplete="off"
                    />
                    <label for="BetColokMacau" class="form-label"
                      >Bet Colok Macau</label
                    >
                    <span style="float:right;font-size:12px;color:#8a8a8a;"
                      >{new Intl.NumberFormat().format(
                        bet_polacolokmacau
                      )}</span
                    >
                  </div>
                </div>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-md">
                <div class="form-floating">
                  <input
                    bind:value={bet_polacoloknaga}
                    on:keyup={handleKeyboard_number}
                    on:keypress={handleKeyboardpolacolok_checkenter}
                    type="text"
                    class="form-control fs-5 text-end button-bet-default"
                    class:dark={daylight === false}
                    placeholder="Bet"
                    id="BetPolaNaga"
                    style=""
                    minlength="3"
                    maxlength={max_bet_coloknaga.toString().length}
                    tab_index="0"
                  />
                  <label for="BetPolaNaga" class="form-label">
                    Bet Colok Naga</label
                  >
                  <span style="float:right;font-size:12px;color:#8a8a8a;"
                    >{new Intl.NumberFormat().format(bet_polacoloknaga)}</span
                  >
                </div>
              </div>
              <div class="col">
                <div class="mb-3">
                  <Button
                    id="btn2"
                    class="form-control mt-2"
                    style="border-radius:5px"
                    on:click={() => {
                      handleTambah("polacolok");
                    }}>TAMBAH</Button
                  >
                </div>
              </div>
            </div>
          </div>
        </TabPane>
      </TabContent>
    {:else}
      <button
        class="btn btn-play mobile"
        class:dark={daylight === false}
        type="button"
        aria-controls="offcanvasdata"
        on:click={toggleBottom}
      >
        Mulai Bermain <Fa
          icon={faDice}
          size="1.5x"
          style="padding-left: .75rem;vertical-align: middle;"
        />
      </button>
    {/if}
  </CardBody>
</Card>
<Offcanvas
  isOpen={bottomOpen}
  placement="bottom"
  backdrop={true}
  scroll
  class="mx-auto"
  style="height: {canvasHeigth}%;background-color:{daylight
    ? '#fff'
    : '#171717'}; border-top-left-radius:20px; 
    border-top-right-radius:20px;
    box-shadow:0 0 1px 0px #888888; 
    max-width:540px;"
>
  <div slot="header">
    <Container>
      <Row>
        <Col class="col-8">
          <h5
            class="offcanvas-title fw-bold text-{daylight ? 'dark' : 'light'}"
            id="ColokCanvasMobile"
          >
            {pasaran_name} - COLOK
          </h5>
        </Col>
        <Col class="text-end">
          <button
            type="button"
            class="btn-close text-reset"
            class:btn-close-white={daylight === false}
            data-bs-dismiss="offcanvas"
            aria-label="Close"
            on:click={() => (bottomOpen = false)}
          />
        </Col>
      </Row>
    </Container>
  </div>

  <TabContent
    pills
    class="periode-menu {daylight ? '' : 'dark'}"
    on:tab={(e) => {
      gametype = e.detail;
      switch (gametype) {
        case "COLOK MACAU":
          canvasHeigth = 70;
          break;
        case "COLOK NAGA":
          canvasHeigth = 85;
          break;
        case "COLOK JITU":
          canvasHeigth = 70;
          break;
        case "POLA COLOK":
          canvasHeigth = 66;
          break;
        default:
          canvasHeigth = 70;
          break;
      }
    }}
  >
    <!-- BEBAS -->
    <TabPane tabId="COLOK BEBAS" tab="BEBAS" active>
      <div style="margin:10px 0;">
        <div class="row">
          <div class="col-xs">
            <div class="mb-3">
              <div class="form-floating">
                <!-- svelte-ignore a11y-autofocus -->
                <input
                  bind:this={nomor_colokbebas_input}
                  bind:value={nomor_colokbebas}
                  on:keyup={handleKeyboard_format}
                  on:keypress={handleKeyboard_checkenter}
                  type="text"
                  class="form-control fs-5 text-center button-bet-default"
                  class:dark={daylight === false}
                  placeholder="Input 1 Digit"
                  id="inputNomorBebas"
                  minlength="1"
                  maxlength="1"
                  tab_index="-1"
                  autocomplete="off"
                  disabled
                />
                <label for="inputNomorBebas" class="form-label"
                  >Input 1 Digit (0-9)</label
                >
                <span class="field-icon" on:click={() => clearNomor("bebas")}
                  ><UiDeleteLeft /></span
                >
              </div>

              <BtnAngka bind:angka={nomor_colokbebas} maxlength={1} />
            </div>
          </div>
          <div class="col-xs">
            <BtnBet
              {daylight}
              bind:betval={bet_colokbebas}
              {maxbet}
              elemid="inputBetBebasMobile"
              gametype="Colok Bebas"
              {minimal_bet}
            />
          </div>
          <div class="col">
            <div class="mb-3">
              <Button
                id="btn2"
                class="form-control mt-2"
                style="border-radius:5px"
                on:click={() => {
                  handleTambah("colokbebas");
                }}>TAMBAH</Button
              >
            </div>
          </div>
        </div>
      </div>
    </TabPane>

    <!-- MACAU -->
    <TabPane tabId="COLOK MACAU" tab="MACAU">
      <div style="margin:10px 0;">
        <div class="row gap-3">
          <div class="col-xs">
            <div class="form-floating">
              <!-- svelte-ignore a11y-autofocus -->
              <input
                bind:this={nomor_colokmacau_1_input}
                bind:value={nomor_colokmacau_1}
                on:keyup={handleKeyboard_format}
                on:keypress={handleKeyboard_checkenter}
                type="text"
                id="inputNomorMacau1"
                class="form-control fs-5 text-center button-bet-default"
                class:dark={daylight === false}
                placeholder="Input 1 Digit"
                minlength="1"
                maxlength="1"
                tab_index="-1"
                autocomplete="off"
                disabled
              />
              <label for="inputNomorMacau1" class="form-label"
                >Input 1 Digit (0-9)</label
              >
              <span class="field-icon" on:click={() => clearNomor("macau1")}
                ><UiDeleteLeft /></span
              >
            </div>

            <BtnAngka bind:angka={nomor_colokmacau_1} maxlength={1} />
          </div>
          <div class="col-xs">
            <div class="form-floating">
              <!-- svelte-ignore a11y-autofocus -->
              <input
                bind:this={nomor_colokmacau_2_input}
                bind:value={nomor_colokmacau_2}
                on:keyup={handleKeyboard_format}
                on:keypress={handleKeyboard_checkenter}
                type="text"
                id="inputNomorMacau2"
                class="form-control fs-5 text-center button-bet-default"
                class:dark={daylight === false}
                placeholder="Input 1 Digit"
                minlength="1"
                maxlength="1"
                tab_index="-1"
                autocomplete="off"
                disabled
              />
              <label for="inputNomorMacau2" class="form-label"
                >Input 1 Digit (0-9)</label
              >
              <span class="field-icon" on:click={() => clearNomor("macau2")}
                ><UiDeleteLeft /></span
              >
            </div>

            <BtnAngka bind:angka={nomor_colokmacau_2} maxlength={1} />
          </div>
          <div class="col-xs">
            <BtnBet
              {daylight}
              bind:betval={bet_colokmacau}
              {maxbet}
              elemid="inputBetMacauMobile"
              gametype="Macau"
              {minimal_bet}
            />
          </div>
          <div class="col">
            <div class="mb-3">
              <Button
                id="btn2"
                class="form-control mt-2"
                style="border-radius:5px"
                on:click={() => {
                  handleTambah("colokmacau");
                }}>TAMBAH</Button
              >
            </div>
          </div>
        </div>
      </div>
    </TabPane>

    <!-- NAGA -->
    <TabPane tabId="COLOK NAGA" tab="NAGA">
      <div style="margin:10px 0;">
        <div class="row gap-3">
          <div class="col-xs">
            <div class="form-floating">
              <!-- svelte-ignore a11y-autofocus -->
              <input
                bind:this={nomor_coloknaga_1_input}
                bind:value={nomor_coloknaga_1}
                on:keyup={handleKeyboard_format}
                on:keypress={handleKeyboard_checkenter}
                type="text"
                id="inputNomorNaga1"
                class="form-control fs-5 text-center button-bet-default"
                class:dark={daylight === false}
                placeholder="Input 1 Digit"
                minlength="1"
                maxlength="1"
                tab_index="-1"
                autocomplete="off"
                disabled
              />
              <label for="inputNomorNaga1" class="form-label"
                >Input 1 Digit (0-9)</label
              >
              <span class="field-icon" on:click={() => clearNomor("naga1")}
                ><UiDeleteLeft /></span
              >
            </div>

            <BtnAngka bind:angka={nomor_coloknaga_1} maxlength={1} />
          </div>
          <div class="col-xs">
            <div class="form-floating">
              <!-- svelte-ignore a11y-autofocus -->
              <input
                bind:this={nomor_coloknaga_2_input}
                bind:value={nomor_coloknaga_2}
                on:keyup={handleKeyboard_format}
                on:keypress={handleKeyboard_checkenter}
                type="text"
                id="inputNomorNaga2"
                class="form-control fs-5 text-center button-bet-default"
                class:dark={daylight === false}
                placeholder="Input 1 Digit"
                minlength="1"
                maxlength="1"
                tab_index="-1"
                autocomplete="off"
                disabled
              />
              <label for="inputNomorNaga2" class="form-label"
                >Input 1 Digit (0-9)</label
              >
              <span class="field-icon" on:click={() => clearNomor("naga2")}
                ><UiDeleteLeft /></span
              >
            </div>

            <BtnAngka bind:angka={nomor_coloknaga_2} maxlength={1} />
          </div>
          <div class="col-xs">
            <div class="form-floating">
              <!-- svelte-ignore a11y-autofocus -->
              <input
                bind:this={nomor_coloknaga_3_input}
                bind:value={nomor_coloknaga_3}
                on:keyup={handleKeyboard_format}
                on:keypress={handleKeyboard_checkenter}
                type="text"
                id="inputNomorNaga3"
                class="form-control fs-5 text-center button-bet-default"
                class:dark={daylight === false}
                placeholder="Input 1 Digit"
                minlength="1"
                maxlength="1"
                tab_index="-1"
                autocomplete="off"
                disabled
              />
              <label for="inputNomorNaga3" class="form-label"
                >Input 1 Digit (0-9)</label
              >
              <span class="field-icon" on:click={() => clearNomor("naga3")}
                ><UiDeleteLeft /></span
              >
            </div>

            <BtnAngka bind:angka={nomor_coloknaga_3} maxlength={1} />
          </div>
        </div>
        <div class="row mt-3">
          <!-- <div class="col-md">
            <div class="form-floating">
              <input
                bind:value={bet_coloknaga}
                on:keyup={handleKeyboard_number}
                on:keypress={handleKeyboardcoloknaga_checkenter}
                type="text"
                class="form-control fs-5 text-end button-bet-default"
                class:dark={daylight === false}
                placeholder="Bet"
                id="inputBetNaga"
                style=""
                minlength="3"
                maxlength={max_bet_coloknaga.toString().length}
                tab_index="0"
                autocomplete="off"
              />
              <label for="inputBetNaga" class="form-label">
                Bet (min : {new Intl.NumberFormat().format(min_bet_coloknaga)}
                dan max : {new Intl.NumberFormat().format(
                  max_bet_coloknaga
                )})</label
              >
              <span style="float:right;font-size:12px;color:#8a8a8a;"
                >{new Intl.NumberFormat().format(bet_coloknaga)}</span
              >
            </div>
          </div> -->
          <div class="col-xs">
            <BtnBet
              {daylight}
              bind:betval={bet_coloknaga}
              {maxbet}
              elemid="inputBetNagaMobile"
              gametype="Naga"
              {minimal_bet}
            />
          </div>
          <div class="col-xs">
            <div class="mb-3">
              <Button
                id="btn2"
                class="form-control mt-2"
                style="border-radius:5px"
                on:click={() => {
                  handleTambah("coloknaga");
                }}>TAMBAH</Button
              >
            </div>
          </div>
        </div>
      </div>
    </TabPane>

    <!-- JITU -->
    <TabPane tabId="COLOK JITU" tab="JITU">
      <div style="margin:10px 0;">
        <div class="row gap-3">
          <div class="col-xs">
            <div class="form-floating">
              <!-- svelte-ignore a11y-autofocus -->
              <input
                bind:this={nomor_colokjitu_input}
                bind:value={nomor_colokjitu}
                on:keyup={handleKeyboard_format}
                on:keypress={handleKeyboard_checkenter}
                type="text"
                id="inputNomorJitu"
                class="form-control fs-5 text-center button-bet-default"
                class:dark={daylight === false}
                placeholder="Input 1 Digit"
                minlength="1"
                maxlength="1"
                tab_index="-1"
                autocomplete="off"
                disabled
              />
              <label for="inputNomorJitu" class="form-label"
                >Input 1 Digit (0-9)</label
              >
              <span class="field-icon" on:click={() => clearNomor("jitu")}
                ><UiDeleteLeft /></span
              >
            </div>

            <BtnAngka bind:angka={nomor_colokjitu} maxlength={1} />
          </div>
          <div class="col-xs">
            <div class="form-floating">
              <select
                class="form-select button-bet-default"
                class:dark={daylight === false}
                bind:value={select_pilihancolokjitu}
                bind:this={select_pilihancolokjitu_input}
                id="selectOptJitu"
                aria-label="Floating label select"
              >
                <option value="">--Pilih--</option>
                <option value="AS">AS</option>
                <option value="KOP">KOP</option>
                <option value="KEPALA">KEPALA</option>
                <option value="EKOR">EKOR</option>
              </select>
              <label for="selectOptJitu">Posisi</label>
            </div>
          </div>
          <!-- <div class="col-xs">
            <div class="form-floating">
              <input
                bind:value={bet_colokjitu}
                on:keyup={handleKeyboard_number}
                on:keypress={handleKeyboardcolokjitu_checkenter}
                type="text"
                id="betColokJitu"
                class="form-control fs-5 text-end button-bet-default"
                class:dark={daylight === false}
                placeholder="Bet"
                minlength="3"
                maxlength={max_bet_colokjitu.toString().length}
                tab_index="0"
                autocomplete="off"
              />
              <span style="float:right;font-size:12px;color:#8a8a8a;"
                >{new Intl.NumberFormat().format(bet_colokjitu)}</span
              >
              <label for="betColokJitu" class="form-label"
                >Bet (min : {new Intl.NumberFormat().format(min_bet_colokjitu)} dan
                max : {new Intl.NumberFormat().format(
                  max_bet_colokjitu
                )})</label
              >
            </div>
          </div> -->
          <div class="col-xs">
            <BtnBet
              {daylight}
              bind:betval={bet_colokjitu}
              {maxbet}
              elemid="betColokJituMobile"
              gametype="Umum"
              {minimal_bet}
            />
          </div>
        </div>
        <div class="row">
          <div class="col">
            <Button
              id="btn2"
              class="form-control mt-2"
              style="border-radius:5px"
              on:click={() => {
                handleTambah("colokjitu");
              }}>TAMBAH</Button
            >
          </div>
        </div>
      </div>
    </TabPane>

    <!-- POLA COLOK -->
    <TabPane tabId="POLA COLOK" tab="POLA">
      <div style="margin:10px 0;">
        <div class="row gap-2">
          <div class="col-xs">
            <div class="mb-3">
              <div class="form-floating">
                <!-- svelte-ignore a11y-autofocus -->
                <input
                  bind:this={nomor_polacolok_input}
                  bind:value={nomor_polacolok}
                  on:keyup={handleKeyboard_format}
                  on:keypress={handleKeyboard_checkenter}
                  type="text"
                  id="inputNomorPolaMobile"
                  class="form-control fs-5 text-center button-bet-default"
                  class:dark={daylight === false}
                  placeholder="Input 4 - 7 Digit"
                  minlength="4"
                  maxlength="7"
                  tab_index="-1"
                  autocomplete="off"
                  disabled
                />
                <label for="inputNomorPolaMobile" class="form-label"
                  >Input 4 - 7 Digit</label
                >
                <span class="field-icon" on:click={() => clearNomor("pola")}
                  ><UiDeleteLeft /></span
                >
              </div>

              <BtnAngka bind:angka={nomor_polacolok} maxlength={7} />
            </div>
          </div>
          <!-- <div class="col-xs"> 
            <div class="mb-3">
              <div class="form-floating">
                <input
                  bind:value={bet_polacolokbebas}
                  on:keyup={handleKeyboard_number}
                  on:keypress={handleKeyboardpolacolok_checkenter}
                  type="text"
                  id="BetNomorBebas"
                  class="form-control fs-5 text-end button-bet-default"
                  class:dark={daylight === false}
                  placeholder="Input 1 Digit"
                  minlength="3"
                  maxlength={max_bet_colokbebas.toString().length}
                  tab_index="0"
                  autocomplete="off"
                />
                <label for="BetNomorBebas" class="form-label">
                  Bet Colok Bebas</label
                >
                <span style="float:right;font-size:12px;color:#8a8a8a;"
                  >{new Intl.NumberFormat().format(bet_polacolokbebas)}</span
                >
              </div>
            </div>
          </div> -->
          <div class="col-xs">
            <BtnBet
              {daylight}
              bind:betval={bet_polacolokbebas}
              maxbet={max_bet_colokbebas}
              elemid="BetNomorPolaBebasMobile"
              gametype="Bebas"
              minimal_bet={min_bet_colokbebas}
            />
          </div>
          <div class="col-xs">
            <BtnBet
              {daylight}
              bind:betval={bet_polacolokmacau}
              maxbet={max_bet_colokmacau}
              elemid="BetNomorPolaMacauMobile"
              gametype="Macau"
              minimal_bet={min_bet_colokmacau}
            />
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-xs">
            <BtnBet
              {daylight}
              bind:betval={bet_polacoloknaga}
              maxbet={max_bet_coloknaga}
              elemid="BetNomorPolaNagaMobile"
              gametype="Naga"
              minimal_bet={min_bet_coloknaga}
            />
          </div>
          <div class="col">
            <div class="mb-3">
              <Button
                id="btn2"
                class="form-control mt-2"
                style="border-radius:5px"
                on:click={() => {
                  handleTambah("polacolok");
                }}>TAMBAH</Button
              >
            </div>
          </div>
        </div>
      </div></TabPane
    >
  </TabContent>
</Offcanvas>
<Modal modal_id={"modalError"} modal_size={"modal-dialog-centered"}>
  <slot:template slot="header">
    <div class="float-end">
      <img
        style="cursor:pointer;"
        data-bs-dismiss="modal"
        aria-label="Close"
        width="25"
        src="https://i.ibb.co/9yNF25L/outline-close-white-48dp.png"
        alt=""
      />
    </div>
    <h5 class="modal-title" id="exampleModalLabel">ERROR</h5>
  </slot:template>
  <slot:template slot="body">
    {@html temp_bulk_error}
  </slot:template>
</Modal>
<div class="clearfix" />
<br />
<Tablekeranjangcolok
  on:removekeranjang={removekeranjang}
  on:removekeranjangall={removekeranjangall}
  on:handleSave={handleSave}
  bind:betHistoryView
  {client_device}
  {count_line_colokbebas}
  {count_line_colokmacau}
  {count_line_coloknaga}
  {count_line_colokjitu}
  {keranjang}
  {totalkeranjang}
  {min_bet_colokbebas}
  {max_bet_colokbebas}
  {disc_bet_colokbebas}
  {win_bet_colokbebas}
  {min_bet_colokmacau}
  {max_bet_colokmacau}
  {disc_bet_colokmacau}
  {win_bet_colokmacau}
  {win3_bet_colokmacau}
  {win4_bet_colokmacau}
  {min_bet_coloknaga}
  {max_bet_coloknaga}
  {disc_bet_coloknaga}
  {win_bet_coloknaga}
  {win4_bet_coloknaga}
  {min_bet_colokjitu}
  {max_bet_colokjitu}
  {disc_bet_colokjitu}
  {winas_bet_colokjitu}
  {winkop_bet_colokjitu}
  {winkepala_bet_colokjitu}
  {winekor_bet_colokjitu}
  {daylight}
  {agent_home_url}
  {portrait_mode}
/>

<style>
  input,
  input::-webkit-input-placeholder {
    font-size: 13px;
    color: #8a8a8a;
  }
  input::placeholder {
    color: #8a8a8a;
  }
  .field-icon {
    float: right;
    padding-right: 10px;
    margin-top: -32px;
    position: relative;
    z-index: 2;
  }
</style>
