<script>
  import { CardHeader } from "sveltestrap";
  export let pasaran_name;
  export let permainan_title;
  export let pasaran_periode;
  export let pasaran_code;
  export let daylight = false;
  export let client_device = "WEBSITE";
  export let column_width = "6";

  let mobile = false;
  let year = new Date().getFullYear();

  $: {
    if (client_device != "WEBSITE") {
      mobile = true;
    }
  }
</script>

<CardHeader class="card-periode {daylight ? '' : 'dark'}">
  <div class="row">
    <div class="col-{column_width}">
      <h4 class="pt-2">
        {pasaran_name}
        {#if client_device !== "WEBSITE"}
          <br />
        {/if}
        ({permainan_title})
      </h4>
    </div>
    <div class="col">
      <div class="float-end">
        <h5 class="pt-2 fw-normal text-black-60 text-end fs-4">
          PERIODE : <br class:d-none={!mobile} />{pasaran_code.toUpperCase() +
            "-" +
            pasaran_periode +
            "-" +
            year}
        </h5>
      </div>
    </div>
  </div>
</CardHeader>
