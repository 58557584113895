<script>
  import { Row, Col, Container, Button } from "sveltestrap";
  import Headerback from "../components/Header.svelte";
  import Panel from "../components/Panel.svelte";
  import PanelFull from "../components/Panelfull.svelte";
  import Riwayat from "../components/Riwayat.svelte";
  import ModalRiwayat from "../components/History/ModalHistory.svelte";
  import Modal from "../components/ModalMenu.svelte";
  import Placeholder from "../components/Placeholder.svelte";
  import Form432d from "../permainan/Form432d.svelte";
  import Formcolok from "../permainan/Formcolok.svelte";
  import Form5050 from "../permainan/Form5050.svelte";
  import Formkombinasi from "../permainan/Formkombinasi.svelte";
  import Formdasar from "../permainan/Formdasar.svelte";
  import Formshio from "../permainan/Formshio.svelte";
  import Fa from "svelte-fa";
  import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
  import dayjs from "dayjs";
  import isBetween from "dayjs/plugin/isBetween";
  import Swal from "sweetalert2";
  import { tweened } from "svelte/motion";
  import { excryptData } from "../lib/encrypter";

  let original = 10 * 60; // TYPE NUMBER OF SECONDS HERE
  let timer = tweened(original);
  let openGame = false;
  const toggleGame = () => {
    openGame = !openGame;
    changePermainan(permainan);
  };

  setInterval(() => {
    if ($timer > 0) {
      $timer--;
      if ($timer == 0) location.reload();
    }
  }, 1000);

  dayjs.extend(isBetween);

  export let client_token = "";
  export let client_company = "";
  export let client_username = "";
  export let client_credit = 0;
  export let client_ipaddress = "";
  export let client_timezone = "Asia/Jakarta";
  export let client_device = "";
  export let source = "";
  export let pasaran_code = "";
  export let pasaran_name = "";
  export let pasaran_periode = 0;
  export let permainan = "";
  export let announce_note = "";
  export let daylight = false;
  export let checked;
  export let balance_credit;
  export let agent_home_url = "";
  export let loadDisplay;
  export let preloader;
  export let portrait_mode;

  let home = false;
  let css_loader = "display:none;";

  let betHistoryView = false;
  let resultinvoice = [];
  let resultslipbet = [];
  let filterBukuMimpi = [];
  let filterResultInvoice = [];
  let listBukumimpi = [];
  let getResInvoiceList = [];
  let totalResInvoiceList = 0;
  let pageResInvoiceList = 1;
  let record = "";
  let idcomppasaran = "";
  let idtrxkeluaran = "";
  let statuspasaran = "";
  let permainan_title = "4D / 3D / 2D";
  // let permainan_periode = pasaran_periode + " - " + pasaran_code;
  let totalbayar_invoice = 0;
  let totalbet_invoice = 0;
  let searchbukumimpi = "";
  let searchangkapasangan = "";
  let tipe = "";
  let message_err = "";
  let tabs = ["4-3-2", "colok", "5050", "kombinasi", "dasar", "shio"];
  let selected = tabs[0];
  let mobile = false;
  let historyViewStatus = "close";
  let accesskey = false;

  const checkPreloader = () => {
    if (preloader) {
      return false;
    }
    return true;
  };
  const changePermainan = (e) => {
    permainan = e;
    switch (e) {
      case "4-3-2":
        permainan_title = "4D / 3D / 2D";
        selected = tabs[0];
        break;
      case "colok":
        permainan_title = "COLOK";
        selected = tabs[1];
        break;
      case "5050":
        permainan_title = "50 - 50";
        selected = tabs[2];
        break;
      case "kombinasi":
        permainan_title = "MACAU / KOMBINASI";
        selected = tabs[3];
        break;
      case "dasar":
        permainan_title = "DASAR";
        selected = tabs[4];
        break;
      case "shio":
        permainan_title = "SHIO";
        selected = tabs[5];
        break;
    }
  };

  const dateJS = new Date();
  let getNowTime = dayjs(dateJS);
  let nowtime = getNowTime.format("YYYY-MM-DD HH:mm:ss");
  const time = Number(sessionStorage.getItem("timestamp"));
  let nowUnixTime = getNowTime.unix();
  var expired = dayjs(time * 1000)
    .add(1, "hour")
    .unix();

  async function checkpasaran() {
    loadDisplay = checkPreloader();
    const res = await fetch("/api/checkpasaran", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        t: excryptData(
          JSON.stringify({
            company: client_company,
            timezone: client_timezone,
            pasaran_code: pasaran_code,
          })
        ),
      }),
    });

    const json = await res.json();
    record = json;
    switch (json.status) {
      case 400:
        message_err =
          "Market Not Found, please back to operator website first!";
        Swal.fire({
          title: message_err,
          icon: "error",
          confirmButtonText: "Go back",
          confirmButtonColor: "#00a86b",
          heightAuto: false,
          allowOutsideClick: false,
          background: daylight ? "#fff" : "#171717",
          color: daylight ? "#00a86b" : "#ff9900",
        }).then((result) => {
          if (result.isConfirmed) {
            location.href = agent_home_url;
          }
        });
      default:
        loadDisplay = false;
    }

    idcomppasaran = record["pasaran_idcomp"];
    idtrxkeluaran = record["pasaran_idtransaction"];
    statuspasaran = record["pasaran_status"];
    pasaran_periode = record["pasaran_periode"];
    pasaran_name = record["pasaran_name"];
    if (statuspasaran == "OFFLINE") {
      loadDisplay = checkPreloader();
      message_err = `Pasaran ${pasaran_name} sedang OFFLINE`;
      Swal.fire({
        title: message_err,
        icon: "error",
        confirmButtonText: "Go back",
        confirmButtonColor: "#00a86b",
        heightAuto: false,
        allowOutsideClick: false,
        background: daylight ? "#fff" : "#171717",
        color: daylight ? "#00a86b" : "#ff9900",
      }).then((result) => {
        if (result.isConfirmed) {
          location.href = agent_home_url;
        }
      });
    }
    // invoicebet("all");
  }

  async function checkrevisi() {
    loadDisplay = checkPreloader();
    const res = await fetch("/api/checkrevisi", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        t: excryptData(
          JSON.stringify({
            company: client_company,
            timezone: client_timezone,
            pasaran_code: pasaran_code,
          })
        ),
      }),
    });

    const json = await res.json();
    record = json;
    loadDisplay = false;
    if (json.status == "200") {
      announce_note += `Revisi pasaran ${record["pasaran_name"]} #${record["pasaran_periode"]} - <strong>${record["pasaran_revisi"]}</strong> | \n`;
    }
  }

  async function checkannounce() {
    loadDisplay = checkPreloader();
    const res = await fetch("/api/checkannounce", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        t: excryptData(
          JSON.stringify({
            company: client_company,
          })
        ),
      }),
    });

    const json = await res.json();
    record = json.record;
    loadDisplay = false;
    let countNumber = 0;
    if (json.status == "200") {
      for (var i = 0; i < record.length; i++) {
        let dbtime = dayjs(record[i]["announce_expired"]).format(
          "YYYY-MM-DD HH:mm:ss"
        );

        let date1 = dayjs(nowtime);
        let date2 = dayjs(dbtime);
        const diff = date2.diff(date1, "minute", true);
        const minutes = Math.floor(diff);
        if (minutes > 0) {
          countNumber++;
          announce_note += `Pengumuman #${countNumber}: ${record[i]["announce_title"]} - <strong>${record[i]["announce_description"]}</strong> | \n`;
        }
      }
    }
  }

  async function invoicebet(e) {
    loadDisplay = checkPreloader();
    const res = await fetch("/api/invoicebet", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        company: client_company,
        username: client_username,
        pasaran_code: pasaran_code,
        pasaran_periode: pasaran_periode,
        permainan: e,
        pasaran_idtransaction: parseInt(idtrxkeluaran),
      }),
    });

    const json = await res.json();
    record = json.record;

    if (json.status == 200) {
      totalbayar_invoice = json.totalbayar;
      totalbet_invoice = json.totalbet;
      record = json.record;
      if (record != null) {
        for (var i = 0; i < record.length; i++) {
          resultinvoice = [
            ...resultinvoice,
            {
              permainan: record[i]["permainan"],
              periode: record[i]["periode"],
              nomor: record[i]["nomor"],
              bet: record[i]["bet"],
              diskon: record[i]["diskon"],
              kei: record[i]["kei"],
              bayar: record[i]["bayar"],
              win: record[i]["win"],
              menang: record[i]["estimasi_win"],
              tipemain: record[i]["tipe_main"],
            },
          ];
        }

        totalResInvoiceList =
          record.length > 100 ? Math.round(record.length / 100) : 1;
        pagelistlistInvoice(100, pageResInvoiceList);
      }
    }
    loadDisplay = false;
  }

  const pagelistlistInvoice = (pagesize, page_number) => {
    let getDataUserBet = paginate(resultinvoice, pagesize, page_number);
    for (let x = 0; x < getDataUserBet.length; x++) {
      getResInvoiceList = [...getResInvoiceList, getDataUserBet[x]];
    }
    return getResInvoiceList;
  };

  function paginate(array, page_size, page_number) {
    // human-readable page numbers usually start with 1, so we reduce 1 in the first argument
    return array.slice((page_number - 1) * page_size, page_number * page_size);
  }

  const handleLoadMorePage = (e) => {
    let pagesize = e.detail.pageSize;
    let page_number = e.detail.pageNumber;
    pagelistlistInvoice(pagesize, page_number);
  };

  async function slipbet() {
    loadDisplay = checkPreloader();
    const res = await fetch("/api/slipperiode", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        company: client_company,
        username: client_username,
        pasaran_code: pasaran_code,
      }),
    });

    const json = await res.json();
    record = json.record;

    loadDisplay = false;
    if (json.status == 200) {
      record = json.record;
      if (record != null) {
        for (var i = 0; i < record.length; i++) {
          resultslipbet = [
            ...resultslipbet,
            {
              status: record[i]["status"],
              tglkeluaran: record[i]["tglkeluaran"],
              idinvoice: record[i]["idinvoice"],
              periode: record[i]["periode"],
              totallose: record[i]["totallose"],
              backgroundstatus: record[i]["background"],
            },
          ];
        }
      }
    }
  }

  async function fetch_bukumimpi() {
    loadDisplay = checkPreloader();
    const res = await fetch("/api/bookdream", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        tipe: tipe,
        nama: searchbukumimpi.toLowerCase(),
      }),
    });

    loadDisplay = false;
    const json = await res.json();
    if (json.status == 200) {
      record = json.record;
      if (record != null) {
        for (var i = 0; i < record.length; i++) {
          listBukumimpi = [
            ...listBukumimpi,
            {
              bukumimpi_tipe: record[i]["bukumimpi_type"],
              bukumimpi_nama: record[i]["bukumimpi_name"],
              bukumimpi_nomor: record[i]["bukumimpi_nomor"],
            },
          ];
        }
      } else {
        Swal.fire({
          position: "center",
          icon: "error",
          title: "Pencarian tidak ditemukan",
          showConfirmButton: false,
          timer: 3000,
          background: daylight ? "#fff" : "#171717",
          color: daylight ? "#00a86b" : "#ff9900",
          toast: true,
        });
      }
    } else {
      Swal.fire({
        position: "center",
        icon: "error",
        title: "Terjadi kesalahan pada server",
        showConfirmButton: false,
        timer: 3000,
        background: daylight ? "#fff" : "#171717",
        color: daylight ? "#00a86b" : "#ff9900",
        toast: true,
      });
    }
  }

  async function openGameChooser() {
    openGame = true;
    permainan = "";
  }

  const handleInvoice = (e) => {
    resultinvoice = [];
    getResInvoiceList = [];
    // resultslipbet = [];
    // invoicebet("all");
    // slipbet();
  };
  checkpasaran();
  checkrevisi();
  checkannounce();

  openGameChooser();

  const handleSelect = (event) => {
    changePermainan(event.target.value);
  };

  const handleClickTab = (e) => {
    switch (e) {
      case "SLIP":
        handleInvoice();
        break;
      case "BUKUMIMPI":
        filterBukuMimpi = [];
        listBukumimpi = [];
        searchbukumimpi = "";
        tipe = "";
        fetch_bukumimpi();
        break;
    }
  };

  const handleClickBukuMimpi = (e) => {
    filterBukuMimpi = [];
    listBukumimpi = [];
    switch (e) {
      case "ALL":
        tipe = "";
        searchbukumimpi = "";
        break;
      case "4D":
        tipe = "4D";
        searchbukumimpi = "";
        break;
      case "3D":
        tipe = "3D";
        searchbukumimpi = "";
        break;
      case "2D":
        tipe = "2D";
        searchbukumimpi = "";
        break;
    }
    fetch_bukumimpi();
  };

  const handleKeyboardbukumimpi_checkenter = (e) => {
    let keyCode = e.which || e.keyCode;
    if (keyCode === 13) {
      // tipe = "";
      filterBukuMimpi = [];
      listBukumimpi = [];
      fetch_bukumimpi();
    }
  };

  const handleKeyboardAngkaPasangan_checkenter = (e) => {
    let keyCode = e.which || e.keyCode;
    if (keyCode === 13) {
      // tipe = "";
      filterResultInvoice = [];
      resultinvoice = [];
      getResInvoiceList = [];
      // invoicebet("all");
    }
  };

  $: {
    if (searchbukumimpi) {
      filterBukuMimpi = listBukumimpi.filter((item) =>
        item.bukumimpi_nama
          .toLowerCase()
          .includes(searchbukumimpi.toLowerCase())
      );
    } else {
      filterBukuMimpi = [...listBukumimpi];
    }
  }

  $: {
    if (client_device !== "WEBSITE") {
      mobile = true;
    }
    if (nowUnixTime > expired) {
      let message_err = "your session has expired!";
      openGame = false;
      let home_location = agent_home_url;
      if (agent_home_url == null) {
        home_location = "/";
      }
      Swal.fire({
        title: message_err,
        icon: "error",
        confirmButtonText: "Go back",
        confirmButtonColor: "#00a86b",
        heightAuto: false,
        allowOutsideClick: false,
        background: daylight ? "#fff" : "#171717",
        color: daylight ? "#00a86b" : "#ff9900",
      }).then((result) => {
        if (result.isConfirmed) {
          location.href = home_location;
        }
      });
    }

    if (mobile) {
      if (!portrait_mode) {
        openGame = false;
      }
    }
  }
</script>

{#if statuspasaran == "ONLINE"}
  <Headerback
    {client_username}
    {client_company}
    {client_credit}
    {client_token}
    {client_ipaddress}
    {client_timezone}
    {client_device}
    bind:checked
    bind:loadDisplay
    {announce_note}
    {daylight}
    {home}
  />
  {#if client_device == "WEBSITE"}
    <Col xs="12" style="padding:0;">
      <Panel {daylight}>
        <slot:template slot="body">
          <nav class="navbar">
            {#if agent_home_url !== ""}
              <a class="navbar-brand branding" href={agent_home_url}
                ><Fa icon={faArrowLeft} size="1x" /> Back</a
              >
            {:else}
              <a
                class="navbar-brand branding"
                href="/?token={client_token}&agent={client_company}"
                ><Fa icon={faArrowLeft} size="1x" /> Back</a
              >
            {/if}
            <ul
              class="nav top-menu nav-pills justify-content-center"
              class:dark={daylight === false}
            >
              <li class="nav-item">
                <!-- svelte-ignore a11y-missing-attribute -->
                <a
                  class="nav-link"
                  class:active={selected === "4-3-2"}
                  on:click={() => {
                    changePermainan("4-3-2");
                  }}>4D/3D/2D</a
                >
              </li>
              <li class="nav-item">
                <!-- svelte-ignore a11y-missing-attribute -->
                <a
                  class="nav-link"
                  class:active={selected === "colok"}
                  on:click={() => {
                    changePermainan("colok");
                  }}>Colok</a
                >
              </li>
              <li class="nav-item">
                <!-- svelte-ignore a11y-missing-attribute -->
                <a
                  class="nav-link"
                  class:active={selected === "5050"}
                  on:click={() => {
                    changePermainan("5050");
                  }}>50-50</a
                >
              </li>
              <li class="nav-item">
                <!-- svelte-ignore a11y-missing-attribute -->
                <a
                  class="nav-link"
                  class:active={selected === "kombinasi"}
                  on:click={() => {
                    changePermainan("kombinasi");
                  }}>KOMBINASI</a
                >
              </li>
              <li class="nav-item">
                <!-- svelte-ignore a11y-missing-attribute -->
                <a
                  class="nav-link"
                  class:active={selected === "dasar"}
                  on:click={() => {
                    changePermainan("dasar");
                  }}>DASAR</a
                >
              </li>
              <li class="nav-item">
                <!-- svelte-ignore a11y-missing-attribute -->
                <a
                  class="nav-link"
                  class:active={selected === "shio"}
                  on:click={() => {
                    changePermainan("shio");
                  }}>SHIO</a
                >
              </li>
            </ul>
            <div class="justify-content-center" />
          </nav>
        </slot:template>
      </Panel>
    </Col>
    <div class="clearfix mt-2" />
    <Col
      xxl="7"
      xl="7"
      lg="7"
      md="7"
      sm="12"
      xs="12"
      style="padding:0px;padding-right:2px;"
    >
      {#if permainan == "4-3-2"}
        <Form432d
          on:handleInvoice={handleInvoice}
          {idcomppasaran}
          {idtrxkeluaran}
          {client_token}
          {client_company}
          {client_username}
          {client_timezone}
          {client_ipaddress}
          {client_device}
          {source}
          {pasaran_name}
          {pasaran_code}
          {pasaran_periode}
          {permainan_title}
          {daylight}
          bind:balance_credit
          bind:css_loader
          bind:loadDisplay
        />
      {/if}
      {#if permainan == "colok"}
        <Formcolok
          on:handleInvoice={handleInvoice}
          {idcomppasaran}
          {idtrxkeluaran}
          {client_token}
          {client_company}
          {client_username}
          {client_timezone}
          {client_ipaddress}
          {client_device}
          {source}
          {pasaran_name}
          {pasaran_code}
          {pasaran_periode}
          {permainan_title}
          {daylight}
          bind:balance_credit
          bind:loadDisplay
        />
      {/if}
      {#if permainan == "5050"}
        <Form5050
          on:handleInvoice={handleInvoice}
          {idcomppasaran}
          {idtrxkeluaran}
          {client_token}
          {client_company}
          {client_username}
          {client_timezone}
          {client_ipaddress}
          {client_device}
          {source}
          {pasaran_name}
          {pasaran_code}
          {pasaran_periode}
          {permainan_title}
          {daylight}
          bind:balance_credit
          bind:loadDisplay
        />
      {/if}
      {#if permainan == "kombinasi"}
        <Formkombinasi
          on:handleInvoice={handleInvoice}
          {idcomppasaran}
          {idtrxkeluaran}
          {client_token}
          {client_company}
          {client_username}
          {client_timezone}
          {client_ipaddress}
          {client_device}
          {source}
          {pasaran_name}
          {pasaran_code}
          {pasaran_periode}
          {permainan_title}
          {daylight}
          bind:balance_credit
          bind:loadDisplay
        />
      {/if}
      {#if permainan == "dasar"}
        <Formdasar
          on:handleInvoice={handleInvoice}
          {idcomppasaran}
          {idtrxkeluaran}
          {client_token}
          {client_company}
          {client_username}
          {client_timezone}
          {client_ipaddress}
          {client_device}
          {source}
          {pasaran_name}
          {pasaran_code}
          {pasaran_periode}
          {permainan_title}
          {daylight}
          bind:balance_credit
          bind:loadDisplay
        />
      {/if}
      {#if permainan == "shio"}
        <Formshio
          on:handleInvoice={handleInvoice}
          {idcomppasaran}
          {idtrxkeluaran}
          {client_token}
          {client_company}
          {client_username}
          {client_timezone}
          {client_ipaddress}
          {client_device}
          {source}
          {pasaran_name}
          {pasaran_code}
          {pasaran_periode}
          {permainan_title}
          {daylight}
          bind:balance_credit
          bind:loadDisplay
        />
      {/if}
    </Col>
    <Col
      xxl="5"
      xl="5"
      lg="5"
      md="5"
      sm="12"
      xs="12"
      style="padding:0px;padding-left:2px;"
    >
      <ul
        class="nav nav-pills bet-pannel mb-3"
        class:dark={daylight === false}
        id="pills-tab"
        role="tablist"
      >
        <li
          on:click={() => {
            handleClickTab("BUKUMIMPI");
          }}
          class="nav-item"
          role="presentation"
        >
          <button
            class="nav-link active"
            id="pills-contact-tab"
            data-bs-toggle="pill"
            data-bs-target="#pills-bukumimpi"
            type="button"
            role="tab"
            aria-controls="pills-bukumimpi"
            aria-selected="false">BUKU MIMPI</button
          >
        </li>
        <!-- <li
          on:click={() => {
            handleClickTab("SLIP");
          }}
          class="nav-item"
          role="presentation"
        >
          <button
            class="nav-link "
            id="pills-home-tab"
            data-bs-toggle="pill"
            data-bs-target="#pills-pasangan"
            type="button"
            role="tab"
            aria-controls="pills-pasangan"
            aria-selected="true">BET HISTORY</button
          >
        </li> -->
      </ul>
      <div class="tab-content" id="pills-tabContent">
        <div
          class="tab-pane fade"
          id="pills-pasangan"
          role="tabpanel"
          aria-labelledby="pills-pasangan-tab"
        >
          <Riwayat
            {daylight}
            {totalbayar_invoice}
            {totalbet_invoice}
            bind:value={searchangkapasangan}
            on:searchangka={handleKeyboardAngkaPasangan_checkenter}
            on:loadmore={handleLoadMorePage}
            {filterResultInvoice}
            resultinvoice={getResInvoiceList}
            {mobile}
            {pageResInvoiceList}
            {totalResInvoiceList}
          />
        </div>
        <div
          class="tab-pane fade show active"
          id="pills-bukumimpi"
          role="tabpanel"
          aria-labelledby="pills-result-tab"
        >
          <div
            class="tab-content periode-menu"
            class:dark={daylight === false}
            id="pills-tabContent"
          >
            <ul
              class="nav nav-tabs"
              id="pills-tab"
              role="tablist"
              style="background-color: {daylight
                ? '#fff'
                : '#181818'};overflow-x:hidden;"
            >
              <li
                on:click={() => {
                  handleClickBukuMimpi("ALL");
                }}
                class="nav-item"
                role="presentation"
              >
                <button
                  class="nav-link active"
                  id="pills-home-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-bukumimpiall"
                  type="button"
                  role="tab"
                  aria-controls="pills-bukumimpiall"
                  aria-selected="true">ALL</button
                >
              </li>
              <li
                on:click={() => {
                  handleClickBukuMimpi("4D");
                }}
                class="nav-item"
                role="presentation"
              >
                <button
                  class="nav-link"
                  id="pills-profile-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-bukumimpi4d"
                  type="button"
                  role="tab"
                  aria-controls="pills-bukumimpi4d"
                  aria-selected="false">4D</button
                >
              </li>
              <li
                on:click={() => {
                  handleClickBukuMimpi("3D");
                }}
                class="nav-item"
                role="presentation"
              >
                <button
                  class="nav-link"
                  id="pills-contact-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-bukumimpi3d"
                  type="button"
                  role="tab"
                  aria-controls="pills-bukumimpi3d"
                  aria-selected="false">3D</button
                >
              </li>
              <li
                on:click={() => {
                  handleClickBukuMimpi("2D");
                }}
                class="nav-item"
                role="presentation"
              >
                <button
                  class="nav-link"
                  id="pills-contact-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-bukumimpi2d"
                  type="button"
                  role="tab"
                  aria-controls="pills-bukumimpi2d"
                  aria-selected="false">2D</button
                >
              </li>
            </ul>
            <div class="form-floating">
              <input
                bind:value={searchbukumimpi}
                on:keypress={handleKeyboardbukumimpi_checkenter}
                style="border-radius: none;border: none; background: {daylight
                  ? '#fff'
                  : '#303030'} none repeat scroll 0% 0%; color: {daylight
                  ? '#171717'
                  : '#fff'}; font-size: 15px; "
                placeholder="Ketik apa yang telah kamu impikan"
                class="form-control button-bet-default"
                type="text"
                id="cariMimpi"
                autocomplete="off"
              />
              <label for="cariMimpi" class="form-label"
                >Ketik apa yang telah kamu impikan</label
              >
            </div>

            <div
              class="tab-pane fade show active"
              id="pills-bukumimpiall"
              role="tabpanel"
              aria-labelledby="pills-bukumimpiall-tab"
            >
              <PanelFull
                header={false}
                footer={false}
                body_style="padding:0px;margin:0px;height:650px;"
                {daylight}
              >
                <slot:template slot="body">
                  {#if filterBukuMimpi !== ""}
                    <table>
                      <tbody>
                        {#each filterBukuMimpi as rec}
                          <tr>
                            <td
                              NOWRAP
                              width="30px"
                              style="text-align:center;vertical-align:top;font-size:14px;"
                              class="color-font"
                              class:dark={daylight === false}
                              >{rec.bukumimpi_tipe}</td
                            >
                            <td
                              width="*"
                              style="text-align:left;vertical-align:top;font-size:15px;color:#8b8989;"
                              >{rec.bukumimpi_nama}
                              <br />
                              <span
                                style="font-size:14px;"
                                class="color-font"
                                class:dark={daylight === false}
                                >{rec.bukumimpi_nomor}</span
                              >
                            </td>
                          </tr>
                        {/each}
                      </tbody>
                    </table>
                  {:else}
                    <Placeholder
                      total_placeholder="20"
                      card_style="background-color:#2c2c2c;border:none;margin-top:5px;"
                    />
                  {/if}
                </slot:template>
              </PanelFull>
            </div>
            <div
              class="tab-pane fade"
              id="pills-bukumimpi4d"
              role="tabpanel"
              aria-labelledby="pills-bukumimpi4d-tab"
            >
              <PanelFull
                header={false}
                footer={false}
                body_style="padding:0px;margin:0px;height:650px;"
                {daylight}
              >
                <slot:template slot="body">
                  {#if filterBukuMimpi !== ""}
                    <table>
                      <tbody>
                        {#each filterBukuMimpi as rec}
                          <tr>
                            <td
                              NOWRAP
                              width="30px"
                              style="text-align:center;vertical-align:top;font-size:14px;"
                              class="color-font"
                              class:dark={daylight === false}
                              >{rec.bukumimpi_tipe}</td
                            >
                            <td
                              width="*"
                              style="text-align:left;vertical-align:top;font-size:15px;color:#8b8989;"
                              >{rec.bukumimpi_nama}
                              <br />
                              <span
                                style="font-size:14px;"
                                class="color-font"
                                class:dark={daylight === false}
                                >{rec.bukumimpi_nomor}</span
                              >
                            </td>
                          </tr>
                        {/each}
                      </tbody>
                    </table>
                  {:else}
                    <Placeholder
                      total_placeholder="20"
                      card_style="background-color:#2c2c2c;border:none;margin-top:5px;"
                    />
                  {/if}
                </slot:template>
              </PanelFull>
            </div>
            <div
              class="tab-pane fade"
              id="pills-bukumimpi3d"
              role="tabpanel"
              aria-labelledby="pills-bukumimpi3d-tab"
            >
              <PanelFull
                header={false}
                footer={false}
                body_style="padding:0px;margin:0px;height:650px;"
                {daylight}
              >
                <slot:template slot="body">
                  {#if filterBukuMimpi != ""}
                    <table>
                      <tbody>
                        {#each filterBukuMimpi as rec}
                          <tr>
                            <td
                              NOWRAP
                              width="30px"
                              style="text-align:center;vertical-align:top;font-size:14px;"
                              class="color-font"
                              class:dark={daylight === false}
                              >{rec.bukumimpi_tipe}</td
                            >
                            <td
                              width="*"
                              style="text-align:left;vertical-align:top;font-size:15px;color:#8b8989;"
                              class="color-font"
                              class:dark={daylight === false}
                              >{rec.bukumimpi_nama}
                              <br />
                              <span style="font-size:14px;"
                                >{rec.bukumimpi_nomor}</span
                              >
                            </td>
                          </tr>
                        {/each}
                      </tbody>
                    </table>
                  {:else}
                    <Placeholder
                      total_placeholder="20"
                      card_style="background-color:#2c2c2c;border:none;margin-top:5px;"
                    />
                  {/if}
                </slot:template>
              </PanelFull>
            </div>
            <div
              class="tab-pane fade"
              id="pills-bukumimpi2d"
              role="tabpanel"
              aria-labelledby="pills-bukumimpi2d-tab"
            >
              <PanelFull
                header={false}
                footer={false}
                body_style="padding:0px;margin:0px;height:650px;"
                {daylight}
              >
                <slot:template slot="body">
                  {#if filterBukuMimpi !== ""}
                    <table>
                      <tbody>
                        {#each filterBukuMimpi as rec}
                          <tr>
                            <td
                              NOWRAP
                              width="30px"
                              style="text-align:center;vertical-align:top;font-size:14px;"
                              class="color-font"
                              class:dark={daylight === false}
                              >{rec.bukumimpi_tipe}</td
                            >
                            <td
                              width="*"
                              style="text-align:left;vertical-align:top;font-size:15px;color:#8b8989;"
                              >{rec.bukumimpi_nama}
                              <br />
                              <span
                                style="font-size:14px;"
                                class="color-font"
                                class:dark={daylight === false}
                                >{rec.bukumimpi_nomor}</span
                              >
                            </td>
                          </tr>
                        {/each}
                      </tbody>
                    </table>
                  {:else}
                    <Placeholder
                      total_placeholder="20"
                      card_style="background-color:#2c2c2c;border:none;margin-top:5px;"
                    />
                  {/if}
                </slot:template>
              </PanelFull>
            </div>
          </div>
        </div>
      </div>
    </Col>
  {:else}
    <div style="margin-top:5px;">&nbsp;</div>
    <Container>
      <Row cols={1}>
        <Col>
          <Button
            block
            color={"success"}
            class="rounded-pill"
            on:click={() => {
              openGame = true;
            }}>Pilih Permainan</Button
          >
        </Col>
      </Row>
      <Row class="mt-3">
        <Col>
          {#if permainan == "4-3-2"}
            <Form432d
              on:handleInvoice={handleInvoice}
              {idcomppasaran}
              {idtrxkeluaran}
              {client_token}
              {client_company}
              {client_username}
              {client_timezone}
              {client_ipaddress}
              {client_device}
              {source}
              {pasaran_name}
              {pasaran_code}
              {pasaran_periode}
              {permainan_title}
              {daylight}
              {agent_home_url}
              {portrait_mode}
              bind:balance_credit
              bind:betHistoryView
              bind:css_loader
              bind:loadDisplay
            />
          {/if}
          {#if permainan == "colok"}
            <Formcolok
              on:handleInvoice={handleInvoice}
              {idcomppasaran}
              {idtrxkeluaran}
              {client_token}
              {client_company}
              {client_username}
              {client_timezone}
              {client_ipaddress}
              {client_device}
              {source}
              {pasaran_name}
              {pasaran_code}
              {pasaran_periode}
              {permainan_title}
              {daylight}
              {agent_home_url}
              {portrait_mode}
              bind:balance_credit
              bind:betHistoryView
              bind:loadDisplay
            />
          {/if}
          {#if permainan == "5050"}
            <Form5050
              on:handleInvoice={handleInvoice}
              {idcomppasaran}
              {idtrxkeluaran}
              {client_token}
              {client_company}
              {client_username}
              {client_timezone}
              {client_ipaddress}
              {client_device}
              {source}
              {pasaran_name}
              {pasaran_code}
              {pasaran_periode}
              {permainan_title}
              {daylight}
              {agent_home_url}
              {portrait_mode}
              bind:balance_credit
              bind:betHistoryView
              bind:loadDisplay
            />
          {/if}
          {#if permainan == "kombinasi"}
            <Formkombinasi
              on:handleInvoice={handleInvoice}
              {idcomppasaran}
              {idtrxkeluaran}
              {client_token}
              {client_company}
              {client_username}
              {client_timezone}
              {client_ipaddress}
              {client_device}
              {source}
              {pasaran_name}
              {pasaran_code}
              {pasaran_periode}
              {permainan_title}
              {daylight}
              {agent_home_url}
              {portrait_mode}
              bind:balance_credit
              bind:betHistoryView
              bind:loadDisplay
            />
          {/if}
          {#if permainan == "dasar"}
            <Formdasar
              on:handleInvoice={handleInvoice}
              {idcomppasaran}
              {idtrxkeluaran}
              {client_token}
              {client_company}
              {client_username}
              {client_timezone}
              {client_ipaddress}
              {client_device}
              {source}
              {pasaran_name}
              {pasaran_code}
              {pasaran_periode}
              {permainan_title}
              {daylight}
              {agent_home_url}
              {portrait_mode}
              bind:balance_credit
              bind:betHistoryView
              bind:loadDisplay
            />
          {/if}
          {#if permainan == "shio"}
            <Formshio
              on:handleInvoice={handleInvoice}
              {idcomppasaran}
              {idtrxkeluaran}
              {client_token}
              {client_company}
              {client_username}
              {client_timezone}
              {client_ipaddress}
              {client_device}
              {source}
              {pasaran_name}
              {pasaran_code}
              {pasaran_periode}
              {permainan_title}
              {daylight}
              {agent_home_url}
              {portrait_mode}
              bind:balance_credit
              bind:betHistoryView
              bind:loadDisplay
            />
          {/if}
        </Col>
      </Row>
    </Container>
    <ModalRiwayat
      bind:betHistoryView
      footerActive={false}
      bind:historyViewStatus
    >
      <slot:template slot="body">
        <Riwayat
          {daylight}
          {totalbayar_invoice}
          {totalbet_invoice}
          bind:value={searchangkapasangan}
          on:searchangka={handleKeyboardAngkaPasangan_checkenter}
          on:loadmore={handleLoadMorePage}
          {filterResultInvoice}
          resultinvoice={getResInvoiceList}
          {mobile}
          {totalResInvoiceList}
          {pageResInvoiceList}
          bind:betHistoryView
        />
      </slot:template>
    </ModalRiwayat>
  {/if}
  <Modal
    open={openGame}
    modalId="game-chooser"
    toggle={toggleGame}
    bind:permainan
  >
    <slot:template slot="header">
      <h5 class="modal-title" class:dark={daylight === false}>
        Pilih Permainan
      </h5>
    </slot:template>
    <slot:template slot="body" />
  </Modal>

  <div class="clearfix" />
  <style>
    #stream::-webkit-scrollbar {
      width: 0.3em;
    }

    #stream::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    }

    #stream::-webkit-scrollbar-thumb {
      background-color: #505768;
      outline: 1px solid slategrey;
    }
    .table-dark {
      --bs-table-bg: #212529;
      --bs-table-striped-bg: #1e1e1e;
      --bs-table-striped-color: #fff;
      --bs-table-active-bg: #373b3e;
      --bs-table-active-color: #fff;
      --bs-table-hover-bg: #323539;
      --bs-table-hover-color: #fff;
      color: #fff;
      border-color: #373b3e;
    }
    .table > :not(:first-child) {
      border-top: none;
    }
  </style>
{:else if statuspasaran == "OFFLINE"}
  <div style="margin-bottom:10px;margin-left: -10px;">
    <center>
      <div style="cursor: pointer;">
        {#if agent_home_url !== ""}
          <a href={agent_home_url} title="nuketoto"> Back to Home </a>
        {:else}
          <a
            href="/?token={client_token}&agent={client_company}"
            title="nuketoto"
          >
            Back to Home
          </a>
        {/if}
      </div>
    </center>
  </div>
{/if}

<style scoped>
  .nav.top-menu > .nav-item > .nav-link.active,
  .nav.top-menu > .nav-item > .nav-link:hover {
    background: #00a86b;
    border-radius: 4px;
    color: #fff;
    cursor: pointer;
  }

  .nav.top-menu > .nav-item > .nav-link {
    color: #171717;
  }
  .nav.top-menu > .nav-item {
    font-size: 10pt;
    font-family: "Poppins";
    opacity: 1;
    color: #171717;
    width: 105px;
    text-align: center;
    margin: 0 5px;
  }

  .nav.top-menu.dark > .nav-item > .nav-link.active,
  .nav.top-menu.dark > .nav-item > .nav-link:hover {
    color: #171717;
  }

  .nav.top-menu.dark .nav-link {
    color: #fff;
  }

  .nav.top-menu > .nav-item > .nav-link,
  .nav.top-menu > .nav-item > .nav-link.active {
    text-decoration: none;
  }
</style>
