<script>
  import { createEventDispatcher } from "svelte";
  import { Button, Icon } from "sveltestrap";

  export let daylight;
  export let keranjang;
  export let mobile;
  export let pola432 = false;
  export let kei = false;

  let dispatch = createEventDispatcher();
  const handleRemoveKeranjang = (idkeranjang, bayar, nomor) => {
    dispatch("handleRemoveKeranjang", {
      idkeranjang,
      bayar,
      nomor,
    });
  };
</script>

{#if !mobile}
  <div class="table-responsive">
    <table class="table {daylight ? '' : 'table-dark table-striped '} table-sm">
      <thead>
        <tr>
          <th
            width="1%"
            class="border-bottom-0 text-center align-top fs-6"
            NOWRAP>&nbsp;</th
          >
          <th
            width="10%"
            class="border-bottom-0 text-center align-top fs-6"
            NOWRAP>NOMOR</th
          >
          {#if pola432}
            <th
              width="20%"
              class="border-bottom-0 text-center align-top fs-6"
              NOWRAP>TIPE</th
            >
          {/if}
          <th
            width="20%"
            class="border-bottom-0 text-center align-top fs-6"
            NOWRAP>PERMAINAN</th
          >
          <th width="20%" class="border-bottom-0 text-end align-top fs-6" NOWRAP
            >BET</th
          >
          <th width="20%" class="border-bottom-0 text-end align-top fs-6" NOWRAP
            >DISKON</th
          >
          {#if kei}
            <th
              width="20%"
              class="border-bottom-0 text-end align-top fs-6"
              NOWRAP>KEI</th
            >
          {/if}
          <th width="*" class="border-bottom-0 text-end align-top fs-6" NOWRAP
            >BAYAR</th
          >
        </tr>
      </thead>
      <tbody>
        {#each keranjang as rec}
          <tr>
            <td
              class="text-center align-top"
              on:click={() => {
                handleRemoveKeranjang(rec.id, rec.bayar, rec.nomor);
              }}
            >
              <Icon name="trash" style="cursor:pointer;" />
            </td>
            <td class="table-fill text-center" class:dark={daylight === false}
              >{rec.nomor}</td
            >
            {#if pola432}
              <td class="table-fill text-center" class:dark={daylight === false}
                >{rec.tipetoto}</td
              >
            {/if}
            <td class="table-fill text-center" class:dark={daylight === false}
              >{rec.permainan}</td
            >
            <td class="table-fill text-end" class:dark={daylight === false}>
              {new Intl.NumberFormat().format(rec.bet)}
            </td>
            <td class="table-fill text-end" class:dark={daylight === false}>
              {new Intl.NumberFormat().format(Math.ceil(rec.diskon))} ({(
                rec.diskonpercen * 100
              ).toFixed(1)}%)
            </td>
            {#if kei}
              <td class="table-fill text-end" class:dark={daylight === false}>
                {new Intl.NumberFormat().format(Math.ceil(rec.kei))} ({(
                  rec.kei_percen * 100
                ).toFixed(1)}%)
              </td>
            {/if}
            <td class="table-fill text-end" class:dark={daylight === false}>
              {new Intl.NumberFormat().format(rec.bayar)}
            </td>
          </tr>
        {/each}
      </tbody>
    </table>
  </div>
{:else}
  <div class="border-top">
    {#each keranjang as rec}
      <div class="card mb-2 mx-1  card-periode" class:dark={!daylight}>
        <div class="card-body {daylight ? '' : 'dark'} ">
          <div
            class="row mb-2 pb-1 border-bottom"
            class:border-success={daylight}
            class:border-warning={!daylight}
          >
            <div class="col">
              <span class="badge bg-success fs-6"
                >{rec.permainan.replace(/_/g, " ")}</span
              >
            </div>
            <div class="col text-end">
              <span class="fs-6 fw-bold history-font" class:dark={!daylight}>
                {rec.nomor.replace(/_/g, " + ")}
              </span>
            </div>
          </div>
          <div class="row py-2">
            <div class="col">
              <table class="table table-borderless mobile">
                <tr>
                  <td class:dark={!daylight}>
                    Bet : <span class="p-0 history-font" class:dark={!daylight}>
                      Rp. {new Intl.NumberFormat().format(rec.bet)}
                      {#if pola432}
                        {" (" + rec.tipetoto + ")"}
                      {/if}
                    </span>
                  </td>
                  <td class:dark={!daylight} class="text-end">
                    {#if kei}
                      Kei(%) : <span
                        class="p-0 history-font"
                        class:dark={!daylight}
                        >{new Intl.NumberFormat().format(Math.ceil(rec.kei))} ({(
                          rec.kei_percen * 100
                        ).toFixed(1)}%)</span
                      >
                    {/if}
                  </td>
                </tr>
                <tr>
                  <td class:dark={!daylight}>
                    Diskon(%) : <span
                      class="p-0 history-font"
                      class:dark={!daylight}
                      >{new Intl.NumberFormat().format(Math.ceil(rec.diskon))} ({(
                        rec.diskonpercen * 100
                      ).toFixed(1)}%)</span
                    >
                  </td>
                  <td class="text-end" class:dark={!daylight}>
                    Bayar : <span
                      class="p-0 history-font"
                      class:dark={!daylight}
                      >Rp. {new Intl.NumberFormat().format(rec.bayar)}</span
                    >
                  </td>
                </tr>
              </table>
            </div>
          </div>
          <div class="row pt-2">
            <div class="col d-grid gap-2">
              <Button
                class="rounded border-0"
                color="danger"
                size="sm"
                on:click={() => {
                  handleRemoveKeranjang(rec.id, rec.bayar, rec.nomor);
                }}
              >
                <Icon name="trash" />
                Hapus</Button
              >
            </div>
          </div>
        </div>
      </div>
    {/each}
  </div>
{/if}

<style>
  .card.card-periode {
    box-shadow: 0px 1px 1px 0px #d7d7d7;
  }

  table.mobile td.dark {
    color: #fff;
  }

  .history-font {
    color: #00a86b;
    font-weight: bold;
  }

  .history-font.dark {
    color: #ff9900;
  }
</style>
