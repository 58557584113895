<script>
  export let daylight;
  export let totalkeranjang;
</script>

<h1 style="padding:5px;margin:0px;font-size:14px;">
  TOTAL BAYAR : <span style="color:{daylight ? '#00A86B' : '#fc0'};"
    >{new Intl.NumberFormat().format(totalkeranjang)}</span
  >
</h1>
