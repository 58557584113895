<script>
  import { onMount } from "svelte";
  /** @type {string} gtaId - GTM ID 'GTM-F00BARS'. */
  export let gtaId = "";
  /** @type {(Object[]|Object)} [gtmDataPoints=[]] - Array or single object of custom data points for dataLayer.
   * @type {Object} [gtmDataPoints[]] - Custom data point Object.
   * @type {string} [gtmDataPoints[][]] - Custom data point property. */
  export let gtmDataPoints = [];
  /** @type {number} [timeout] - The number of milliseconds to timeout intiating loading the GTM script from Google */
  export let timeout = 0;
  /** @type {boolean} [dev=false] - Set to true to give errors */
  export let dev = true;
  let scriptSrc;
  /** getFunctionScriptElementFromInitGtm - Sets global dataLayer on Window Object.
   * @param {(Object[]|Object)} [customDataPoints=[]] - Array or single object of custom data points for dataLayer.
   * @param {Object} [customDataPoints[]] - Custom data point Object.
   * @param {string} [customDataPoints[][]] - Custom data point property.
   * @param {Object} [globalObject=window] – E.g. a reference to the Window Object (window).
   * @returns {getFunctionScriptElementFromInitGtm~getScriptSrcForGtm} - function. */
  const getScriptSrcFromInitGtm = (
    customDataPoints = [],
    globalObject = window
  ) => {
    const requiredDataPoint = {
      "gtm.start": new Date().getTime(),
      event: "gtm.js",
    };
    /** getScriptSrcForGtm - Returns script src.
     *  @param {string} gtaId - GTM ID 'GTM-F00BARS'.
     *  @returns {string} - Src for script element. */
    const getScriptSrcForGtm = (gtaId) => {
      if (!dev && (typeof gtaId !== "string" || !gtaId.length)) {
        return;
      } else if (typeof gtaId !== "string" || !gtaId.length) {
        console.error("Google Tag Analytics.", "Missing/wrong `gtaId`.");
      } else {
        return `https://www.googletagmanager.com/gtag/js?id=${gtaId}`;
      }
    };
    try {
      const dataLayer = [requiredDataPoint].concat(customDataPoints);
      /* Get/set global dataLayer on global Object (e.g., `window`).
       * Custom data points should be set before GTM script loads. */
      globalObject["dataLayer"] = globalObject["dataLayer"]
        ? [...globalObject["dataLayer"], ...dataLayer]
        : dataLayer;
    } catch (error) {
      if (!dev) console.error("Google Tag Manager.", error);
    } finally {
      return getScriptSrcForGtm; // …no matter what, for no error.
    }
  };
  onMount(() => {
    if (!timeout) scriptSrc = getScriptSrcFromInitGtm(gtmDataPoints)(gtaId);
    else
      setTimeout(() => {
        scriptSrc = getScriptSrcFromInitGtm(gtmDataPoints)(gtaId);
      }, timeout);
  });
</script>

<svelte:head>
  {#if scriptSrc}
    <!-- Global site tag (gtag.js) - Google Analytics -->
    <script async src={scriptSrc}></script>
  {/if}
</svelte:head>
