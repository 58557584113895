<script>
  import {
    Button,
    Card,
    CardBody,
    Offcanvas,
    Container,
    Row,
    Col,
  } from "sveltestrap";
  import Modal from "../components/Modal.svelte";
  import Tablekeranjangdasar from "../permainan/Tablekeranjangdasar.svelte";
  import { createEventDispatcher } from "svelte";
  import { excryptData } from "../lib/encrypter";
  import { useVisitorData } from "@fingerprintjs/fingerprintjs-pro-svelte";
  import PeriodePanel from "../components/PeriodePanel.svelte";
  import Swal from "sweetalert2";
  import Fa from "svelte-fa";
  import { faDice } from "@fortawesome/free-solid-svg-icons";
  import dayjs from "dayjs";
  import utc from "dayjs/plugin/utc";
  import timezone from "dayjs/plugin/timezone";
  import BtnBet from "../components/ButtonInputBet.svelte";

  export let idcomppasaran = "";
  export let idtrxkeluaran = "";
  export let client_token = "";
  export let client_company = "";
  export let client_username = "";
  export let client_ipaddress = "";
  export let client_device = "";
  export let source = "";
  export let client_timezone = "";
  export let pasaran_code = "";
  export let pasaran_name = "";
  export let pasaran_periode = 0;
  export let permainan_title = "COLOK";
  export let daylight = false;
  export let balance_credit;
  export let betHistoryView = false;
  export let loadDisplay;
  export let agent_home_url;
  export let immediate = false;
  export let portrait_mode;

  dayjs.extend(utc);
  dayjs.extend(timezone);

  var cd = new Date();
  var cnow = dayjs(cd).tz("Asia/Jakarta").unix();

  const { getData, data, isLoading, error } = useVisitorData(
    { extendedResult: true },
    { immediate }
  );

  const swalWithBootstrapButtons = Swal.mixin({
    customClass: {
      confirmButton: "mx-2 rounded-2 btn btn-success",
      cancelButton: "mx-2 rounded-2 btn btn-danger",
    },
    buttonsStyling: false,
  });

  const OptionDasar = ["", "GANJIL", "GENAP", "BESAR", "KECIL"];

  let keranjang = [];
  let nomor_global = 0;
  let totalkeranjang = 0;
  let group_btn_beli = false;
  let record = "";
  let temp_bulk_error = "";
  let flag_fulldiskon = "DISC";
  let min_bet = 0;
  let max_bet = 0;
  let kei_besar_bet = 0;
  let kei_kecil_bet = 0;
  let kei_genap_bet = 0;
  let kei_ganjil_bet = 0;
  let disc_besar_bet = 0;
  let disc_kecil_bet = 0;
  let disc_genap_bet = 0;
  let disc_ganjil_bet = 0;

  let limit_total = 0;

  let count_line_dasar = 0;
  let count_line_standart = 0;

  let db_formdasar = 0;
  let db_formdasar_standart = 0;

  let dispatch = createEventDispatcher();
  let bottomOpen = false;
  const toggleBottom = () => {
    bottomOpen = !bottomOpen;
  };
  let State = {
    idle: "idle",
    requesting: "requesting",
    success: "success",
  };
  let ctoken = "";
  let state = State.idle;
  let canvasHeigth = 70;

  function doRecaptcha() {
    let captchaKey = sessionStorage.getItem("siteKey");
    return new Promise((resolve, reject) => {
      grecaptcha.ready(function () {
        grecaptcha.execute(captchaKey, { action: "submit" }).then(function (t) {
          state = State.success;
          resolve(t);
        });
      });
    });
  }

  async function inittogel_432d(e) {
    loadDisplay = true;
    const res = await fetch("/api/inittogel_432d", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        t: excryptData(
          JSON.stringify({
            company: client_company.toUpperCase(),
            pasaran_code: pasaran_code,
            permainan: e,
          })
        ),
      }),
    });
    group_btn_beli = true;
    const json = await res.json();
    record = json.record;
    loadDisplay = false;
    for (var i = 0; i < record.length; i++) {
      min_bet = parseInt(record[i]["min_bet"]);
      max_bet = parseInt(record[i]["max_bet"]);
      kei_besar_bet = parseFloat(record[i]["kei_besar_bet"]);
      kei_kecil_bet = parseFloat(record[i]["kei_kecil_bet"]);
      kei_genap_bet = parseFloat(record[i]["kei_genap_bet"]);
      kei_ganjil_bet = parseFloat(record[i]["kei_ganjil_bet"]);
      disc_besar_bet = parseFloat(record[i]["disc_besar_bet"]);
      disc_kecil_bet = parseFloat(record[i]["disc_kecil_bet"]);
      disc_genap_bet = parseFloat(record[i]["disc_genap_bet"]);
      disc_ganjil_bet = parseFloat(record[i]["disc_ganjil_bet"]);
      limit_total = parseInt(record[i]["limit_total"]);
    }
  }
  async function savetransaksi() {
    ctoken = await doRecaptcha();
    group_btn_beli = false;

    const res = await fetch("/api/save/transaksi", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        t: excryptData(
          JSON.stringify({
            pasaran_idtransaction: idtrxkeluaran,
            pasaran_idcomp: idcomppasaran,
            pasaran_code: pasaran_code,
            pasaran_periode: pasaran_periode,
            token: client_token,
            company: client_company,
            username: client_username,
            ipaddress: client_ipaddress,
            devicemember: source,
            timezone: client_timezone,
            total: totalkeranjang,
            data: keranjang,
          })
        ),
        remoteip: client_ipaddress,
        token: ctoken,
      }),
    });
    const json = await res.json();
    if (json.status == "200") {
      loadDisplay = false;
      balance_credit = json.balance;
      Swal.fire({
        position: "center",
        icon: "success",
        title: "Tiket Anda sedang di proses",
        html:
          "<p>Mohon menunggu beberapa saat.</p> <br/>" +
          "Total taruhan : " +
          new Intl.NumberFormat().format(json.totalbet),
        showConfirmButton: false,
        timer: 5000,
        background: daylight ? "#fff" : "#171717",
        color: daylight ? "#00a86b" : "#ff9900",
        toast: true,
      });
      dispatch("handleInvoice", "call");
      reset();
    } else {
      loadDisplay = false;
      if (json.status == "500" || json.status == "404") {
        group_btn_beli = true;
        Swal.fire({
          position: "center",
          icon: "error",
          title: json.message,
          showConfirmButton: false,
          timer: 3000,
          background: daylight ? "#fff" : "#171717",
          color: daylight ? "#00a86b" : "#ff9900",
          toast: true,
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          html: json.message,
          background: daylight ? "#fff" : "#171717",
          color: daylight ? "#00a86b" : "#ff9900",
        });
      }
    }
    sessionStorage.removeItem("Dasarcaptcha");
    ctoken = "";
  }

  function reset() {
    keranjang = [];
    group_btn_beli = true;
    totalkeranjang = 0;
    count_line_dasar = 0;
    count_line_standart = 0;
    inittogel_432d("dasar");
  }
  inittogel_432d("dasar");
  function addKeranjang(
    nomor,
    game,
    bet,
    diskon_percen,
    diskon,
    bayar,
    win,
    kei_percen,
    kei,
    tipetoto
  ) {
    let total_data = keranjang.length;
    let flag_data = false;
    temp_bulk_error = "";
    for (var i = 0; i < total_data; i++) {
      switch (game) {
        case "DASAR":
          if (nomor == keranjang[i].nomor.toString()) {
            let maxtotal_bayardasar = 0;
            for (var j = 0; j < keranjang.length; j++) {
              if ("DASAR" == keranjang[j].permainan) {
                if (nomor == keranjang[j].nomor) {
                  maxtotal_bayardasar =
                    parseInt(maxtotal_bayardasar) + parseInt(keranjang[j].bet);
                }
              }
            }
            if (
              parseInt(limit_total) <
              parseInt(maxtotal_bayardasar) + parseInt(bet)
            ) {
              temp_bulk_error =
                "Nomor ini : " +
                nomor +
                " sudah melebihi LIMIT TOTAL DASAR<br />";
              flag_data = true;
            }
          }
          break;
      }
    }

    if (flag_data == false) {
      nomor_global = nomor_global + 1;
      const data = {
        id: nomor_global,
        nomor,
        permainan: game,
        bet,
        diskon,
        diskonpercen: diskon_percen,
        bayar,
        win,
        kei,
        kei_percen,
        tipetoto,
      };
      keranjang = [data, ...keranjang];
      count_keranjang();
    } else {
      totalkeranjang = totalkeranjang - bayar;
    }
  }
  const removekeranjang = (e) => {
    swalWithBootstrapButtons
      .fire({
        title:
          "Anda akan menghapus nomor pasangan : <strong>" +
          e.detail.nomor +
          "</strong>",
        html: "<h5>Apakah anda ingin melanjutkan?</h5>",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Ya, hapus sekarang!",
        cancelButtonText: "Tidak!",
        reverseButtons: true,
        background: daylight ? "#fff" : "#171717",
        color: daylight ? "#00a86b" : "#ff9900",
      })
      .then((result) => {
        if (result.isConfirmed) {
          keranjang = keranjang.filter(
            (keranjang) => keranjang.id != e.detail.idkeranjang
          );
          totalkeranjang = totalkeranjang - e.detail.bayar;
          count_keranjang();
        }
      });
  };
  const removekeranjangall = (e) => {
    if (keranjang.length > 0) {
      swalWithBootstrapButtons
        .fire({
          title: "Anda akan menghapus semua nomor pasangan!",
          html: "<h5>Apakah anda ingin melanjutkan?</h5>",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Ya, hapus sekarang!",
          cancelButtonText: "Tidak!",
          reverseButtons: true,
          background: daylight ? "#fff" : "#171717",
          color: daylight ? "#00a86b" : "#ff9900",
        })
        .then((result) => {
          if (result.isConfirmed) {
            reset();
            count_keranjang();
          }
        });
    } else {
      Swal.fire({
        position: "center",
        icon: "warning",
        title: "Tidak ada list transaksi",
        showConfirmButton: false,
        timer: 1500,
        background: daylight ? "#fff" : "#171717",
        color: daylight ? "#00a86b" : "#ff9900",
        toast: true,
      });
    }
  };
  const handleSave = (e) => {
    e.preventDefault();
    if (keranjang.length > 0) {
      let linkColor = daylight ? "#00a86b" : "#ff9900";
      swalWithBootstrapButtons
        .fire({
          title: "Apakah anda ingin melanjutkan?",
          html:
            "Total taruhan anda sebesar : <strong>IDR. " +
            new Intl.NumberFormat().format(totalkeranjang) +
            "</strong>",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Ya, pasang sekarang!",
          cancelButtonText: "Tidak!",
          reverseButtons: true,
          background: daylight ? "#fff" : "#171717",
          color: daylight ? "#00a86b" : "#ff9900",
          footer: `<small style="text-align:center">This site is protected by reCAPTCHA and the Google 
                <a style="color:${linkColor}" href="https://policies.google.com/privacy" target="_blank">Privacy Policy</a> and
                <a style="color:${linkColor}" href="https://policies.google.com/terms" target="_blank">Terms of Service</a> apply.
            </small>`,
        })
        .then((result) => {
          if (result.isConfirmed) {
            loadDisplay = true;
            savetransaksi();
          } else if (
            /* Read more about handling dismissals below */
            result.dismiss === Swal.DismissReason.cancel
          ) {
            loadDisplay = false;
            Swal.fire({
              position: "center",
              icon: "info",
              title: "Transaksi dibatalkan",
              showConfirmButton: false,
              timer: 3000,
              background: daylight ? "#fff" : "#171717",
              color: daylight ? "#00a86b" : "#ff9900",
              toast: true,
            });
          }
        });
    } else {
      loadDisplay = false;
      Swal.fire({
        position: "center",
        icon: "warning",
        title: "Tidak ada list transaksi",
        showConfirmButton: false,
        timer: 1500,
        background: daylight ? "#fff" : "#171717",
        color: daylight ? "#00a86b" : "#ff9900",
        toast: true,
      });
    }
  };
  function count_keranjang() {
    let count_umum = 0;
    let count_dasar = 0;
    for (let i = 0; i < keranjang.length; i++) {
      switch (keranjang[i].permainan.toString()) {
        case "DASAR":
          count_dasar = count_dasar + 1;
          break;
      }
    }
    count_line_dasar = count_dasar + db_formdasar;
    count_line_standart = count_umum + db_formdasar_standart;
    loadDisplay = false;
  }

  //DASAR - INIT FORM
  let select_dasar = "";
  let select_dasar_input;
  let bet_dasar = "";

  function form_clear(e) {
    switch (e) {
      case "dasar":
        select_dasar = "";
        select_dasar_input.focus();
        bet_dasar = 0;
        break;
    }
  }

  function formdasar_add() {
    let flag = true;
    let nomor = select_dasar;
    let bet = bet_dasar;
    let diskon = 0;
    let diskonpercen = 0;
    let win = 1;
    let kei = 0;
    let keipersen = 0;
    let bayar = 0;
    let nmgame = "DASAR";
    if (nomor == "") {
      select_dasar_input.focus();
      flag = false;
    }
    if (bet == "") {
      flag = false;
      Swal.fire({
        position: "center",
        icon: "error",
        title: "Amount tidak boleh kosong",
        showConfirmButton: false,
        timer: 1500,
        background: daylight ? "#fff" : "#171717",
        color: daylight ? "#00a86b" : "#ff9900",
        toast: true,
      });
    }
    if (parseInt(bet) < parseInt(min_bet)) {
      bet_dasar = min_bet;
      flag = false;
      Swal.fire({
        position: "center",
        icon: "error",
        title: "Minimal Bet : " + min_bet,
        showConfirmButton: false,
        timer: 3000,
        background: daylight ? "#fff" : "#171717",
        color: daylight ? "#00a86b" : "#ff9900",
        toast: true,
      });
    }
    if (parseInt(bet) > parseInt(max_bet)) {
      bet_dasar = max_bet;
      flag = false;
      Swal.fire({
        position: "center",
        icon: "error",
        title: "Maximal Bet : " + max_bet,
        showConfirmButton: false,
        timer: 3000,
        background: daylight ? "#fff" : "#171717",
        color: daylight ? "#00a86b" : "#ff9900",
        toast: true,
      });
    }
    if (flag == true) {
      switch (nomor) {
        case "GANJIL":
          kei = kei_ganjil_bet;
          diskon = disc_ganjil_bet;
          break;
        case "GENAP":
          kei = kei_genap_bet;
          diskon = disc_genap_bet;
          break;
        case "BESAR":
          kei = kei_besar_bet;
          diskon = disc_besar_bet;
          break;
        case "KECIL":
          kei = kei_kecil_bet;
          diskon = disc_kecil_bet;
          break;
      }
      keipersen = kei;
      diskonpercen = diskon;
      kei = parseInt(bet) * kei;
      diskon = (parseInt(bet) * diskon).toFixed(0);
      bayar = parseInt(bet) - parseInt(diskon) - parseInt(kei);
      totalkeranjang = bayar + totalkeranjang;

      addKeranjang(
        nomor,
        nmgame,
        bet_dasar,
        diskonpercen,
        diskon,
        bayar,
        win,
        keipersen,
        kei,
        flag_fulldiskon
      );
      form_clear("dasar");
      if (temp_bulk_error != "") {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          html: temp_bulk_error,
          background: daylight ? "#fff" : "#171717",
          color: daylight ? "#00a86b" : "#ff9900",
        });
      }
    }
  }

  function checkInteger(number) {
    if (!parseInt(number)) {
      return "0";
    }
    return number;
  }

  const handleTambah = (e) => {
    if (client_device !== "WEBSITE") {
      bottomOpen = !bottomOpen;
    }
    switch (e) {
      case "dasar":
        bet_dasar = checkInteger(bet_dasar);
        if (select_dasar == "" && parseInt(bet_dasar) < min_bet) {
          select_dasar_input.focus();
        } else {
          formdasar_add();
        }
        break;
    }
  };

  const handleKeyboard_number = (e) => {
    var numbers = /^\d+$/;
    let result = numbers.test(e.target.value);
    if (!result) {
      return (e.target.value = 0);
    } else {
      return (e.target.value = parseInt(e.target.value.replace(/\s/g, "")));
    }
  };

  const handleKeyboard_checkenter = (e) => {
    let keyCode = e.which || e.keyCode;
    if (keyCode === 13) {
      handleTambah("dasar");
    }
  };

  $: {
    if (betHistoryView) {
      dispatch("handleInvoice", "all");
    }
  }
</script>

<svelte:head>
  <title>SDSB4D - {pasaran_name} - DASAR</title>
</svelte:head>

<Card class={daylight ? "" : "bg-dark"} style="margin:0px;padding:0px;">
  <PeriodePanel
    {pasaran_name}
    {permainan_title}
    {pasaran_periode}
    {pasaran_code}
    {daylight}
    {client_device}
  />
  <CardBody class={daylight ? "" : "dark"}>
    {#if client_device == "WEBSITE"}
      <div style="margin:10px 0;">
        <div class="row gap-3">
          <div class="col-md">
            <div class="form-floating">
              <select
                class="form-select button-bet-default"
                class:dark={daylight === false}
                bind:value={select_dasar}
                bind:this={select_dasar_input}
                id="selectDasar1"
                aria-label="Floating label select"
              >
                {#each OptionDasar as dasar}
                  {#if dasar === ""}
                    <option value={dasar} selected>--Pilih--</option>
                  {:else}
                    <option value={dasar}>{dasar}</option>
                  {/if}
                {/each}
              </select>
              <label for="selectDasar1">Pilih</label>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-floating">
              <input
                bind:value={bet_dasar}
                on:keyup={handleKeyboard_number}
                on:keypress={handleKeyboard_checkenter}
                type="text"
                id="betDasar"
                class="form-control fs-5 text-end button-bet-default"
                class:dark={daylight === false}
                placeholder="Bet"
                minlength="3"
                maxlength={max_bet.toString().length}
                tab_index="0"
                autocomplete="off"
              />
              <span style="float:right;font-size:12px;color:#8a8a8a;"
                >{new Intl.NumberFormat().format(bet_dasar)}</span
              >
              <label for="betDasar" class="form-label"
                >Bet (min : {new Intl.NumberFormat().format(min_bet)} dan max : {new Intl.NumberFormat().format(
                  max_bet
                )})</label
              >
            </div>
          </div>
          <div class="col-md-2">
            <Button
              id="btn2"
              class="form-control mt-2"
              style="border-radius:5px"
              on:click={() => {
                handleTambah("dasar");
              }}>TAMBAH</Button
            >
          </div>
        </div>
      </div>
    {:else}
      <button
        class="btn btn-play mobile"
        class:dark={daylight === false}
        type="button"
        aria-controls="offcanvasdata"
        on:click={toggleBottom}
      >
        Mulai Bermain <Fa
          icon={faDice}
          size="1.5x"
          style="padding-left: .75rem;vertical-align: middle;"
        />
      </button>
    {/if}
  </CardBody>
</Card>

<Offcanvas
  isOpen={bottomOpen}
  placement="bottom"
  backdrop={true}
  scroll
  class="mx-auto"
  style="height: {canvasHeigth}%;background-color:{daylight
    ? '#fff'
    : '#171717'}; border-top-left-radius:20px; 
    border-top-right-radius:20px;
    box-shadow:0 0 1px 0px #888888; 
    max-width:540px;"
>
  <div slot="header">
    <Container>
      <Row>
        <Col class="col-8">
          <h5
            class="offcanvas-title fw-bold text-{daylight ? 'dark' : 'light'}"
            id="DasarCanvasMobile"
          >
            {pasaran_name} - DASAR
          </h5>
        </Col>
        <Col class="text-end">
          <button
            type="button"
            class="btn-close text-reset"
            class:btn-close-white={daylight === false}
            data-bs-dismiss="offcanvas"
            aria-label="Close"
            on:click={() => (bottomOpen = false)}
          />
        </Col>
      </Row>
    </Container>
  </div>
  <Container>
    <Row cols={1}>
      <div style="margin:10px 0;">
        <div class="row gap-3">
          <div class="col-xs">
            <div class="form-floating">
              <select
                class="form-select button-bet-default"
                class:dark={daylight === false}
                bind:value={select_dasar}
                bind:this={select_dasar_input}
                id="selectDasar1Mobile"
                aria-label="Floating label select"
              >
                {#each OptionDasar as dasar}
                  {#if dasar === ""}
                    <option value={dasar} selected>--Pilih--</option>
                  {:else}
                    <option value={dasar}>{dasar}</option>
                  {/if}
                {/each}
              </select>
              <label for="selectDasar1Mobile">Pilih</label>
            </div>
          </div>

          <div class="col-xs">
            <BtnBet
              {daylight}
              bind:betval={bet_dasar}
              maxbet={max_bet}
              elemid="betDasarMobile"
              gametype="Dasar"
              minimal_bet={min_bet}
            />
          </div>
          <div class="col-xs">
            <Button
              id="btn2"
              class="form-control mt-2"
              style="border-radius:5px"
              on:click={() => {
                handleTambah("dasar");
              }}>TAMBAH</Button
            >
          </div>
        </div>
      </div>
    </Row>
  </Container>
</Offcanvas>
<Modal modal_id={"modalError"} modal_size={"modal-dialog-centered"}>
  <slot:template slot="header">
    <div class="float-end">
      <img
        style="cursor:pointer;"
        data-bs-dismiss="modal"
        aria-label="Close"
        width="25"
        src="https://i.ibb.co/9yNF25L/outline-close-white-48dp.png"
        alt=""
      />
    </div>
    <h5 class="modal-title" id="exampleModalLabel">ERROR</h5>
  </slot:template>
  <slot:template slot="body">
    {@html temp_bulk_error}
  </slot:template>
</Modal>
<div class="clearfix" />
<br />
<Tablekeranjangdasar
  on:removekeranjang={removekeranjang}
  on:removekeranjangall={removekeranjangall}
  on:handleSave={handleSave}
  bind:betHistoryView
  {client_device}
  {count_line_dasar}
  {count_line_standart}
  {keranjang}
  {totalkeranjang}
  {min_bet}
  {max_bet}
  {kei_besar_bet}
  {kei_kecil_bet}
  {kei_genap_bet}
  {kei_ganjil_bet}
  {disc_besar_bet}
  {disc_kecil_bet}
  {disc_genap_bet}
  {disc_ganjil_bet}
  {daylight}
  {agent_home_url}
  {portrait_mode}
/>

<style>
  input,
  input::-webkit-input-placeholder {
    font-size: 13px;
    color: #8a8a8a;
  }
  input::placeholder {
    color: #8a8a8a;
  }
</style>
