<script>
  export let angka = "";
  export let maxlength = 4;
  const addNomor432 = (e) => {
    let lenAngka = angka;
    if (lenAngka.length < maxlength) {
      angka = angka + e;
    }
  };
</script>

<div class="row my-3 justify-content-center mx-auto">
  <div class="col-2">
    <button
      type="button"
      id="btnNumber"
      class="btn btn-outline-success"
      on:click={() => addNomor432(0)}>0</button
    >
  </div>
  <div class="col-2">
    <button
      type="button"
      id="btnNumber"
      class="btn btn-outline-success"
      on:click={() => addNomor432(1)}>1</button
    >
  </div>
  <div class="col-2">
    <button
      type="button"
      id="btnNumber"
      class="btn btn-outline-success"
      on:click={() => addNomor432(2)}>2</button
    >
  </div>
  <div class="col-2">
    <button
      type="button"
      id="btnNumber"
      class="btn btn-outline-success"
      on:click={() => addNomor432(3)}>3</button
    >
  </div>
  <div class="col-2">
    <button
      type="button"
      id="btnNumber"
      class="btn btn-outline-success"
      on:click={() => addNomor432(4)}>4</button
    >
  </div>
</div>
<div class="row mb-2 justify-content-center mx-auto">
  <div class="col-2">
    <button
      type="button"
      id="btnNumber"
      class="btn btn-outline-success"
      on:click={() => addNomor432(5)}>5</button
    >
  </div>
  <div class="col-2">
    <button
      type="button"
      id="btnNumber"
      class="btn btn-outline-success"
      on:click={() => addNomor432(6)}>6</button
    >
  </div>
  <div class="col-2">
    <button
      type="button"
      id="btnNumber"
      class="btn btn-outline-success"
      on:click={() => addNomor432(7)}>7</button
    >
  </div>
  <div class="col-2">
    <button
      type="button"
      id="btnNumber"
      class="btn btn-outline-success"
      on:click={() => addNomor432(8)}>8</button
    >
  </div>
  <div class="col-2">
    <button
      type="button"
      id="btnNumber"
      class="btn btn-outline-success"
      on:click={() => addNomor432(9)}>9</button
    >
  </div>
</div>
