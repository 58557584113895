<script>
  import { Button, Icon } from "sveltestrap";
  import { createEventDispatcher } from "svelte";
  import PanelFull from "../components/Panelfull.svelte";
  import Modal from "../components/Modalfull2.svelte";
  import Lineinfo from "../components/Keranjang.svelte";
  import KeranjangHeader from "../components/KeranjangHeader.svelte";
  import ButtonCart from "../components/KeranjangButton.svelte";
  import FillCart from "../components/KeranjangDetail.svelte";

  export let keranjang = [];
  export let client_device = "";
  export let totalkeranjang = 0;
  export let count_line_dasar = 0;
  export let count_line_standart = 0;
  export let min_bet = 0;
  export let max_bet = 0;
  export let kei_besar_bet = 0;
  export let kei_kecil_bet = 0;
  export let kei_genap_bet = 0;
  export let kei_ganjil_bet = 0;
  export let disc_besar_bet = 0;
  export let disc_kecil_bet = 0;
  export let disc_genap_bet = 0;
  export let disc_ganjil_bet = 0;
  export let daylight;
  export let betHistoryView;
  export let agent_home_url;
  export let portrait_mode;

  // export const group_btn_beli = false;

  let count_line = 0;
  let dispatch = createEventDispatcher();
  let mobile = false;

  function handleRemoveKeranjang(e) {
    dispatch("removekeranjang", {
      idkeranjang: e.detail.idkeranjang,
      bayar: e.detail.bayar,
      nomor: e.detail.nomor,
    });
  }
  const handleBetHistoryView = () => {
    betHistoryView = !betHistoryView;
  };
  const handleRemoveKeranjang_all = () => {
    dispatch("removekeranjangall", "all");
  };
  const handleSave = () => {
    dispatch("handleSave", "save");
  };
  let open_informasi = false;
  let fullscreen = "";
  const toggle = () => {
    fullscreen = "xl";
    open_informasi = !open_informasi;
  };
  $: count_line = count_line_dasar + count_line_standart;

  $: {
    if (client_device !== "WEBSITE") {
      mobile = true;
    }
  }
</script>

<PanelFull
  header={true}
  footer={true}
  body_style="padding:0px;background:{daylight
    ? '#fff'
    : '#181717'};height:450px;"
  {daylight}
>
  <slot:template slot="header">
    <div class="container px-0">
      <div class="row">
        <div class="col-md">
          <KeranjangHeader {daylight} {totalkeranjang} />
        </div>
        <ButtonCart
          {mobile}
          {agent_home_url}
          {portrait_mode}
          on:handleRemoveKeranjang_all={handleRemoveKeranjang_all}
          on:handleBetHistoryView={handleBetHistoryView}
          on:handleSave={handleSave}
        />
      </div>
    </div>
  </slot:template>
  <slot:template slot="body">
    <FillCart
      {mobile}
      {daylight}
      {keranjang}
      kei={true}
      on:handleRemoveKeranjang={handleRemoveKeranjang}
    />
  </slot:template>
  <slot:template slot="footer">
    <Lineinfo {daylight} {count_line} />
  </slot:template>
</PanelFull>

<Modal
  modal_id={"modalInformasi"}
  modal_footer_flag={false}
  modal_body_height={"height:500px;"}
  modal_size={"modal-dialog-centered"}
  {daylight}
>
  <slot:template slot="header">
    <h5 class="modal-title">DASAR</h5>
  </slot:template>
  <slot:template slot="body">
    <table class="table table-dark table-sm">
      <tbody>
        <tr>
          <td
            style="background:#303030;border:1px solid #282828;font-size:12px;"
            >MIN BET</td
          >
          <td
            style="border:1px solid #282828;text-align:right;font-size:12px;color:#ffd292;"
            >{new Intl.NumberFormat().format(min_bet)}</td
          >
        </tr>
        <tr>
          <td
            style="background:#303030;border:1px solid #282828;font-size:12px;"
            >MAX BET</td
          >
          <td
            style="border:1px solid #282828;text-align:right;font-size:12px;color:#ffd292;"
            >{new Intl.NumberFormat().format(max_bet)}</td
          >
        </tr>
        <tr>
          <td
            style="background:#303030;border:1px solid #282828;font-size:12px;"
            >DISKON</td
          >
          <td
            style="border:1px solid #282828;text-align:right;font-size:12px;color:#ffd292;"
          >
            BESAR : {Math.ceil(disc_besar_bet * 100)}%
            <br />
            KECIL : {Math.ceil(disc_kecil_bet * 100)}%
            <br />
            GENAP : {Math.ceil(disc_genap_bet * 100)}%
            <br />
            GANJIL : {Math.ceil(disc_ganjil_bet * 100)}%
          </td>
        </tr>
        <tr>
          <td
            style="background:#303030;border:1px solid #282828;font-size:12px;"
            >KEI</td
          >
          <td
            style="border:1px solid #282828;text-align:right;font-size:12px;color:#ffd292;"
          >
            BESAR : {Math.ceil(kei_besar_bet * 100)}% <br />
            KECIL : {Math.ceil(kei_kecil_bet * 100)}% <br />
            GENAP : {Math.ceil(kei_genap_bet * 100)}% <br />
            GANJIL : {Math.ceil(kei_ganjil_bet * 100)}%
          </td>
        </tr>
      </tbody>
    </table>
    <p
      style="font-size:13px;padding: 5px;color:{daylight
        ? '#171717'
        : 'white'};"
    >
      <b>CARA BERMAIN</b>
      <br />
      Menebak ganjil/genap dan besar/kecil dari penjumlah angka-angka 2D
      <br />
      Nilai pembelian ditentukan pasaran (kei) pada saat itu.
      <br />
      Struktur CD (2 angka terakhir)<br /><br />

      Kecil = angka 0-4<br />
      Besar = angka 5-9<br />
      Ganjil = 1,3,5,7,9<br />
      Genap = 0,2,4,6,8<br /><br />

      Analisis I :<br />
      Keluar : 1234,<br />
      3+4 = 7<br />
      Berarti keluar : Ganjil dan Besar<br /><br />

      Analisis II :<br />
      Keluar : 5678,<br />
      7+8 = 15<br />
      Karena angka 15 lebih besar dari 9, kembali dihitung 1+5=6<br />
      Berarti keluar : Genap dan Besar<br /><br />

      Analisis III :<br />
      Keluar : 1204,<br />
      0+4 = 4<br />
      Berarti keluar : Genap dan Kecil<br />
      Misal anda membeli dengan Rp.100rb untuk Genap, menang = 100rb + [indeks menang
      untuk Dasar]<br /><br />

      NB: Indeks menang dan diskon dapat dilihat di bagian Peraturan
    </p>
  </slot:template>
</Modal>
