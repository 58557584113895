<script>
  import { Container, Col, Card, CardBody, Row } from "sveltestrap";
  import dayjs from "dayjs";
  import utc from "dayjs/plugin/utc";
  import timezone from "dayjs/plugin/timezone";
  import Modal from "../components/Modalfull2.svelte";
  import {
    UiBellDay,
    UiBellNotifDay,
    UiBellNight,
    UiBellNotifNight,
  } from "../lib/icons";
  import Placeholder from "../components/Placeholder.svelte";
  import Switch from "../components/Switch.svelte";
  import Swal from "sweetalert2";
  import Marquee from "svelte-marquee-text-widget";
  import Fa from "svelte-fa";
  import {
    faReceipt,
    faBookOpen,
    faStore,
    faChartPie,
    faHistory,
  } from "@fortawesome/free-solid-svg-icons";
  import { excryptData } from "../lib/encrypter";

  dayjs.extend(utc);
  dayjs.extend(timezone);

  export let client_token = "";
  export let client_company = "";
  export let client_username = "";
  export let client_credit = 0;
  export let client_ipaddress = "";
  export let client_timezone = "";
  export let client_device = "";
  export let daylight = false;
  export let announce_note = "";
  export let checked;
  export let home;
  export let loadDisplay;

  let State = {
    idle: "idle",
    requesting: "requesting",
    success: "success",
  };
  let token = "";
  let state = State.idle;

  let mobile = false;
  let modal_table_fontsize_header = "13px";
  let modal_table_fontsize_body = "12px";
  let modal_table_fontsize_bukumimpi_header = "14px";
  if (client_device == "MOBILE") {
    modal_table_fontsize_bukumimpi_header = "13px";
    modal_table_fontsize_header = "11px";
    modal_table_fontsize_body = "11px";
    mobile = true;
  }
  // let display_credit = 0;
  let clockmachine = "";
  let record = "";
  let filterBukuMimpi = [];
  let listBukumimpi = [];
  let listhasilkeluaran = [];
  let resulttogel = [];
  let listhasilinvoice = [];
  let listhasilinvoicebet = [];
  let listpasaran = [];
  let searchbukumimpi = "";
  let tipe = "";
  let idinvoiceall = "";
  let detailslipheader = "";
  let detailslipheaderpermainan = "";
  let nmpasaran = "";
  let total4d_bayar = 0;
  let total3d_bayar = 0;
  let total2d_bayar = 0;
  let totalcolokbebas_bayar = 0;
  let totalcolokmacau_bayar = 0;
  let totalcoloknaga_bayar = 0;
  let totalcolokjitu_bayar = 0;
  let total5050umum_bayar = 0;
  let total5050special_bayar = 0;
  let total5050kombinasi_bayar = 0;
  let totalmacaukombinasi_bayar = 0;
  let totaldasar_bayar = 0;
  let totalshio_bayar = 0;
  let totalwin_4d = 0;
  let totalwin_3d = 0;
  let totalwin_2d = 0;
  let totalwin_colokbebas = 0;
  let totalwin_colokmacau = 0;
  let totalwin_coloknaga = 0;
  let totalwin_colokjitu = 0;
  let totalwin_5050umum = 0;
  let totalwin_5050special = 0;
  let totalwin_5050kombinasi = 0;
  let totalwin_macaukombinasi = 0;
  let totalwin_dasar = 0;
  let totalwin_shio = 0;
  let subtotal_bayar = 0;
  let subtotal_winner = 0;
  let total_winlose = 0;
  let paused_marquee = true;
  let hide_img_notif = true;

  var cd = new Date();
  var cnow = dayjs(cd).tz("Asia/Jakarta").unix();
  var cexpired = 0;
  function setMarqueePaused(e) {
    paused_marquee = !paused_marquee;
  }

  function setImgNotif(e) {
    hide_img_notif = !hide_img_notif;
  }
  function updateClock() {
    let endtime = dayjs().tz(client_timezone).format("DD MMM YYYY | HH:mm:ss");

    clockmachine = endtime;
  }

  function doRecaptcha(action, count = 0) {
    let captchaKey = sessionStorage.getItem("siteKey");
    return new Promise((resolve, reject) => {
      grecaptcha.ready(function () {
        grecaptcha.execute(captchaKey, { action }).then(function (t) {
          state = State.success;
          sessionStorage.setItem("Captcha" + action, String(cnow));
          resolve(t);
        });
      });
    });
  }

  async function fetch_bukumimpi() {
    loadDisplay = true;
    const res = await fetch("/api/bookdream", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        tipe: tipe,
        nama: searchbukumimpi.toLowerCase(),
      }),
    });

    const json = await res.json();
    loadDisplay = false;
    if (json.status == 200) {
      record = json.record;
      if (record != null) {
        for (var i = 0; i < record.length; i++) {
          listBukumimpi = [
            ...listBukumimpi,
            {
              bukumimpi_tipe: record[i]["bukumimpi_type"],
              bukumimpi_nama: record[i]["bukumimpi_name"],
              bukumimpi_nomor: record[i]["bukumimpi_nomor"],
            },
          ];
        }
      } else {
        Swal.fire({
          position: "center",
          icon: "warning",
          title: "Data tidak ditemukan!",
          showConfirmButton: false,
          timer: 3000,
          background: daylight ? "#fff" : "#171717",
          color: daylight ? "#00a86b" : "#ff9900",
          toast: true,
        });
      }
    } else if (json.status == 400) {
      Swal.fire({
        position: "center",
        icon: "error",
        title: json.message,
        showConfirmButton: false,
        timer: 1500,
        background: daylight ? "#fff" : "#171717",
        color: daylight ? "#00a86b" : "#ff9900",
        toast: true,
      });
    } else {
      Swal.fire({
        position: "center",
        icon: "error",
        title:
          "Terjadi kesalahan pada server, silahkan coba beberapa saat lagi!",
        showConfirmButton: false,
        timer: 3000,
        background: daylight ? "#fff" : "#171717",
        color: daylight ? "#00a86b" : "#ff9900",
        toast: true,
      });
    }
  }
  async function fetch_resultall() {
    loadDisplay = true;
    listhasilkeluaran = [];
    const res = await fetch("/api/resulttogelall", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        t: excryptData(
          JSON.stringify({
            company: client_company,
          })
        ),
      }),
    });

    const json = await res.json();
    loadDisplay = false;
    if (json.status == 200) {
      record = json.record;
      if (record != null) {
        for (var i = 0; i < record.length; i++) {
          listhasilkeluaran = [
            ...listhasilkeluaran,
            {
              keluaran_no: record[i]["no"],
              keluaran_date: record[i]["date"]
                ? dayjs(record[i]["date"])
                    .tz(client_timezone)
                    .format("DD MMM YYYY")
                : "schedule not ready",
              keluaran_pasaran: record[i]["pasaran"],
              keluaran_pasarancode: record[i]["pasaran_code"],
              keluaran_periode: record[i]["periode"],
              keluaran_result: record[i]["result"],
            },
          ];
        }
        // console.log(listhasilkeluaran);
      } else {
        Swal.fire({
          position: "center",
          icon: "warning",
          title: "Data tidak ditemukan!",
          showConfirmButton: false,
          timer: 1500,
          background: daylight ? "#fff" : "#171717",
          color: daylight ? "#00a86b" : "#ff9900",
          toast: true,
        });
      }
    } else if (json.status == 400) {
      Swal.fire({
        position: "center",
        icon: "error",
        title: json.message,
        showConfirmButton: false,
        timer: 1500,
        background: daylight ? "#fff" : "#171717",
        color: daylight ? "#00a86b" : "#ff9900",
        toast: true,
      });
    } else {
      Swal.fire({
        position: "center",
        icon: "error",
        title:
          "Terjadi kesalahan pada server, silahkan coba beberapa saat lagi!",
        showConfirmButton: false,
        timer: 1500,
        background: daylight ? "#fff" : "#171717",
        color: daylight ? "#00a86b" : "#ff9900",
        toast: true,
      });
    }
  }
  async function fetch_resultbypasaran(e, y) {
    loadDisplay = true;
    nmpasaran = y;
    const res = await fetch("/api/resulttogel", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        t: excryptData(
          JSON.stringify({
            company: client_company,
            pasaran_code: e,
          })
        ),
      }),
    });

    const json = await res.json();
    loadDisplay = false;
    record = json.record;
    if (json.status == 200) {
      record = json.record;
      if (record != null) {
        for (var i = 0; i < record.length; i++) {
          resulttogel = [
            ...resulttogel,
            {
              no: record[i]["no"],
              date: dayjs(record[i]["date"]).format("DD MMM YYYY"),
              periode: record[i]["periode"],
              result: record[i]["result"],
            },
          ];
        }
        let myModal = new bootstrap.Modal(
          document.getElementById("modalhasilkeluaranpasaran")
        );
        myModal.show();
      }
    }
  }
  async function fetch_invoicell() {
    // token = await doRecaptcha("InvoiceAll") ;
    const res = await fetch("/api/slipperiodeall", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        t: excryptData(
          JSON.stringify({
            company: client_company,
            username: client_username,
            token,
          })
        ),
      }),
    });

    const json = await res.json();
    loadDisplay = false;
    sessionStorage.removeItem("CaptchaInvoiceAll");
    if (json.status == 200) {
      record = json.record;
      if (record != null) {
        for (var i = 0; i < record.length; i++) {
          listhasilinvoice = [
            ...listhasilinvoice,
            {
              invoice_idinvoice: record[i]["idinvoice"],
              invoice_tglkeluaran: dayjs(record[i]["tglkeluaran"])
                .tz(client_timezone)
                .format("DD MMM YYYY"),
              invoice_pasaran: record[i]["pasaran"],
              invoice_periode: record[i]["periode"],
              invoice_status: record[i]["status"],
              invoice_totalbet: record[i]["totalbet"],
              invoice_totalbayar: record[i]["totalbayar"],
              invoice_totalwin: record[i]["totalwin"],
              invoice_totallose: record[i]["totallose"],
              invoice_background: record[i]["background"],
              invoice_color_lost: record[i]["color_lost"],
              invoice_color_totalloset: record[i]["color_totallose"],
            },
          ];
        }
      } else {
        Swal.fire({
          position: "center",
          icon: "warning",
          title: "Tidak menemukan data",
          showConfirmButton: false,
          timer: 1500,
          background: daylight ? "#fff" : "#171717",
          color: daylight ? "#00a86b" : "#ff9900",
          toast: true,
        });
      }
    } else if (json.status == 400) {
      Swal.fire({
        position: "center",
        icon: "error",
        title: json.message,
        showConfirmButton: false,
        timer: 1500,
        background: daylight ? "#fff" : "#171717",
        color: daylight ? "#00a86b" : "#ff9900",
        toast: true,
      });
    } else {
      Swal.fire({
        position: "center",
        icon: "error",
        title:
          "Terjadi kesalahan pada server, silahkan coba beberapa saat lagi!",
        showConfirmButton: false,
        timer: 1500,
        background: daylight ? "#fff" : "#171717",
        color: daylight ? "#00a86b" : "#ff9900",
        toast: true,
      });
    }
  }
  async function fetch_invoicelldetail(e, periode, yearperiode) {
    loadDisplay = true;
    detailslipheader = `${periode}-${yearperiode}`;
    idinvoiceall = e;
    const res = await fetch("/api/slipperiodedetail", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        t: excryptData(
          JSON.stringify({
            company: client_company,
            username: client_username,
            idinvoice: e,
          })
        ),
      }),
    });

    const json = await res.json();
    record = json.record;
    loadDisplay = false;
    if (json.status == 200) {
      record = json.record;
      total4d_bayar = record["total4d_bayar"];
      total3d_bayar = record["total3d_bayar"];
      total2d_bayar = record["total2d_bayar"];
      totalcolokbebas_bayar = record["totalcolokbebas_bayar"];
      totalcolokmacau_bayar = record["totalcolokmacau_bayar"];
      totalcoloknaga_bayar = record["totalcoloknaga_bayar"];
      totalcolokjitu_bayar = record["totalcolokjitu_bayar"];
      total5050umum_bayar = record["total5050umum_bayar"];
      total5050special_bayar = record["total5050special_bayar"];
      total5050kombinasi_bayar = record["total5050kombinasi_bayar"];
      totalmacaukombinasi_bayar = record["totalmacaukombinasi_bayar"];
      totaldasar_bayar = record["totaldasar_bayar"];
      totalshio_bayar = record["totalshio_bayar"];
      totalwin_4d = record["totalwin_4d"];
      totalwin_3d = record["totalwin_3d"];
      totalwin_2d = record["totalwin_2d"];
      totalwin_colokbebas = record["totalwin_colokbebas"];
      totalwin_colokmacau = record["totalwin_colokmacau"];
      totalwin_coloknaga = record["totalwin_coloknaga"];
      totalwin_colokjitu = record["totalwin_colokjitu"];
      totalwin_5050umum = record["totalwin_5050umum"];
      totalwin_5050special = record["totalwin_5050special"];
      totalwin_5050kombinasi = record["totalwin_5050kombinasi"];
      totalwin_macaukombinasi = record["totalwin_macaukombinasi"];
      totalwin_dasar = record["totalwin_dasar"];
      totalwin_shio = record["totalwin_shio"];
      subtotal_bayar = record["subtotal_bayar"];
      subtotal_winner = record["subtotal_winner"];
      total_winlose = record["total_winlose"];
    }

    let myModal = new bootstrap.Modal(
      document.getElementById("modalslipalldetail")
    );
    myModal.show();
  }
  async function fetch_invoicealldetailpermainan(permainan, bayar) {
    loadDisplay = true;
    if (parseInt(bayar) > 0) {
      detailslipheaderpermainan = permainan;
      listhasilinvoicebet = [];
      const res = await fetch("/api/invoicebetdetail", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          t: excryptData(
            JSON.stringify({
              invoice: parseInt(idinvoiceall),
              company: client_company,
              username: client_username,
              permainan: permainan,
            })
          ),
        }),
      });

      const json = await res.json();
      loadDisplay = false;
      if (json.status == 200) {
        record = json.record;
        if (record != null) {
          for (var i = 0; i < record.length; i++) {
            let status = record[i]["status"];
            let background = "";
            switch (status) {
              case "RUNNING":
                background = "background:#FFEB3B;font-weight:bold;color:black;";
                break;
              case "WINNER":
                background = "background:#8BC34A;font-weight:bold;color:black;";
                break;
              case "LOSE":
                background = "background:#E91E63;font-weight:bold;color:white;";
                break;
            }
            let win_estimate =
              parseInt(record[i]["bet"]) * parseInt(record[i]["multiplier"]);
            listhasilinvoicebet = [
              ...listhasilinvoicebet,
              {
                bet_no: record[i]["no"],
                bet_background: background,
                bet_status: record[i]["status"],
                bet_tipe: record[i]["tipe"],
                bet_permainan: record[i]["permainan"],
                bet_nomor: record[i]["nomor"],
                bet_bet: record[i]["bet"],
                bet_diskon: record[i]["diskon"],
                bet_kei: record[i]["kei"],
                bet_bayar: record[i]["bayar"],
                bet_win: record[i]["win"],
                bet_multiplier: record[i]["multiplier"],
                bet_winestimate: win_estimate,
              },
            ];
          }
          let myModal = new bootstrap.Modal(
            document.getElementById("modalslipalldetailbyid")
          );
          myModal.show();
        } else {
          Swal.fire({
            position: "center",
            icon: "warning",
            title:
              "Mohon tunggu beberapa saat, Sedang dalam proses penyimpanan data",
            showConfirmButton: false,
            timer: 1500,
            background: daylight ? "#fff" : "#171717",
            color: daylight ? "#00a86b" : "#ff9900",
            toast: true,
            timerProgressBar: true,
          });
        }
      } else if (json.status == 400) {
        Swal.fire({
          position: "center",
          icon: "error",
          title: json.message,
          showConfirmButton: false,
          timer: 1500,
          background: daylight ? "#fff" : "#171717",
          color: daylight ? "#00a86b" : "#ff9900",
          toast: true,
        });
      } else {
        Swal.fire({
          position: "center",
          icon: "error",
          title:
            "Terjadi kesalahan pada server, silahkan coba beberapa saat lagi",
          showConfirmButton: false,
          timer: 1500,
          background: daylight ? "#fff" : "#171717",
          color: daylight ? "#00a86b" : "#ff9900",
          toast: true,
          timerProgressBar: true,
        });
      }
    } else {
      Swal.fire({
        position: "center",
        icon: "warning",
        title:
          "Mohon tunggu beberapa saat, Sedang dalam proses penyimpanan data",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        background: daylight ? "#fff" : "#171717",
        color: daylight ? "#00a86b" : "#ff9900",
        toast: true,
      });
    }
  }

  async function fetch_jadwal_pasaran() {
    loadDisplay = true;
    const respasaran = await fetch("/api/listpasaran", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        t: excryptData(
          JSON.stringify({
            token: client_token,
            company: client_company,
            timezone: client_timezone,
          })
        ),
      }),
    });
    loadDisplay = false;
    if (!respasaran.ok) {
      const pasarMessage = `An error has occured: ${resPasar.status}`;
      throw new Error(pasarMessage);
    } else {
      const jsonpasaran = await respasaran.json();
      if (jsonpasaran.status == 200) {
        let record = jsonpasaran.record;
        if (record != null) {
          for (var i = 0; i < record.length; i++) {
            listpasaran = [
              ...listpasaran,
              {
                pasaran_id: record[i]["pasaran_id"],
                pasaran_marketclose: dayjs(record[i]["pasaran_marketclose"])
                  .tz(client_timezone)
                  .format("HH:mm"),
                pasaran_marketopen: dayjs(record[i]["pasaran_marketopen"])
                  .tz(client_timezone)
                  .format("HH:mm"),
                pasaran_marketschedule: dayjs(
                  record[i]["pasaran_marketschedule"]
                )
                  .tz(client_timezone)
                  .format("HH:mm"),
                pasaran_periode: record[i]["pasaran_periode"],
                pasaran_status: record[i]["pasaran_status"],
                pasaran_tglkeluaran: record[i]["pasaran_tglkeluaran"],
                pasaran_togel: record[i]["pasaran_alias"],
                pasaran_url: record[i]["pasaran_url"],
                pasaran_jadwal: record[i]["pasaran_jadwal"],
              },
            ];
          }
        } else {
          Swal.fire({
            position: "center",
            icon: "warning",
            title: "Tidak menemukan pasaran",
            showConfirmButton: false,
            timer: 1500,
            background: daylight ? "#fff" : "#171717",
            color: daylight ? "#00a86b" : "#ff9900",
            toast: true,
          });
        }
      } else if (jsonpasaran.status == 400) {
        Swal.fire({
          position: "center",
          icon: "error",
          title: jsonpasaran.message,
          showConfirmButton: false,
          timer: 1500,
          background: daylight ? "#fff" : "#171717",
          color: daylight ? "#00a86b" : "#ff9900",
          toast: true,
        });
      } else {
        Swal.fire({
          position: "center",
          icon: "error",
          title:
            "Terjadi kesalahan pada server, silahkan coba beberapa saat lagi",
          showConfirmButton: false,
          timer: 1500,
          background: daylight ? "#fff" : "#171717",
          color: daylight ? "#00a86b" : "#ff9900",
          toast: true,
        });
      }
    }
  }
  $: {
    setInterval(updateClock, 100);
    if (searchbukumimpi) {
      filterBukuMimpi = listBukumimpi.filter((item) =>
        item.bukumimpi_nama
          .toLowerCase()
          .includes(searchbukumimpi.toLowerCase())
      );
    } else {
      filterBukuMimpi = [...listBukumimpi];
    }
  }
  $: display_credit = new Intl.NumberFormat().format(client_credit);
  const handleClickButtonTop = (e) => {
    let idmodal = "";
    switch (e) {
      case "result":
        // getTimeout("DrawResult");
        loadDisplay = true;
        idmodal = "modalhasilkeluaran";
        listhasilkeluaran = [];
        setTimeout(function () {
          fetch_resultall();
        }, 500);
        break;
      case "invoice":
        loadDisplay = true;
        idmodal = "modalhasilinvoice";
        listhasilinvoice = [];
        fetch_invoicell();
        break;
      case "bukumimpi":
        // getTimeout("BookDream");
        loadDisplay = true;
        idmodal = "modalbukumimpi";
        setTimeout(function () {
          fetch_bukumimpi();
        }, 500);
        break;
      case "pasaran":
        // getTimeout("ListMarket");
        loadDisplay = true;
        listpasaran = [];
        idmodal = "modalpasaran";
        setTimeout(function () {
          fetch_jadwal_pasaran();
        }, 500);
        break;
    }
    let myModal = new bootstrap.Modal(document.getElementById(idmodal));
    myModal.show();
  };
  const handleClickBukuMimpi = (e) => {
    // getTimeout("BookDream");
    filterBukuMimpi = [];
    listBukumimpi = [];
    switch (e) {
      case "ALL":
        tipe = "";
        searchbukumimpi = "";
        break;
      case "4D":
        tipe = "4D";
        searchbukumimpi = "";
        break;
      case "3D":
        tipe = "3D";
        searchbukumimpi = "";
        break;
      case "2D":
        tipe = "2D";
        searchbukumimpi = "";
        break;
    }
    setTimeout(function () {
      fetch_bukumimpi();
    }, 500);
  };
  const handleKeyboardbukumimpi_checkenter = (e) => {
    // getTimeout("BookDream");
    let keyCode = e.which || e.keyCode;
    if (keyCode === 13) {
      filterBukuMimpi = [];
      listBukumimpi = [];
      setTimeout(function () {
        fetch_bukumimpi();
      }, 500);
    }
  };

  const goToUrl = (url) => {
    window.open(url, "_blank");
  };
</script>

<Container>
  {#if client_device == "WEBSITE"}
    <Row>
      <Col>
        <Container style="padding:0;">
          <Row class="mt-4 mb-2">
            <div class="w-25" />
            <div class="w-75">
              <Row>
                <div class="col text-end">
                  <span style="font-size:13px;"
                    >Timezone : <span id="style_text"
                      >{client_timezone}, {clockmachine} WIB</span
                    ></span
                  >
                </div>
                <div class="col-1 px-0 d-none d-xl-block d-xxl-block">
                  <Switch bind:checked />
                </div>
              </Row>
            </div>
          </Row>
          <Row>
            <div class="col">
              <img
                id="imglogo"
                alt="SDSB4D"
                style="margin-top:0px;"
                width="185"
                src="logo-green.svg"
              />
            </div>
            <div class="col">
              <form class="d-flex">
                <button
                  on:click={() => {
                    handleClickButtonTop("result");
                  }}
                  class="btn btn-play"
                  class:dark={daylight === false}
                  type="button">RESULT</button
                >
                &nbsp;
                <button
                  on:click={() => {
                    handleClickButtonTop("invoice");
                  }}
                  class="btn btn-play"
                  class:dark={daylight === false}
                  type="button">RIWAYAT</button
                >
                &nbsp;
                <button
                  on:click={() => {
                    handleClickButtonTop("pasaran");
                  }}
                  class="btn btn-play"
                  class:dark={daylight === false}
                  type="button">PASARAN</button
                >
                &nbsp;
                {#if home}
                  <button
                    on:click={() => {
                      handleClickButtonTop("bukumimpi");
                    }}
                    class="btn btn-play"
                    class:dark={daylight === false}
                    type="button">BUKU MIMPI</button
                  >
                {/if}
              </form>
            </div>
            <div class="col">
              <Card
                style="border:none;background-color:transparent;text-align:right;"
              >
                <CardBody style="padding:0px;">
                  <div class="container">
                    <div class="row">
                      <div class="col">
                        <span
                          class="user-detail"
                          class:dark={daylight === false}
                          >{client_username} ({client_ipaddress})</span
                        ><br />
                        <span
                          class="user-detail"
                          class:dark={daylight === false}
                          >Saldo : IDR <span id="style_text"
                            >{display_credit}</span
                          ></span
                        ><br />
                      </div>
                      <div class="col-1">
                        <div style="padding-top:10px;">
                          <a
                            href="#panelsStayOpen-collapseOne"
                            data-bs-target="#panelsStayOpen-collapseOne"
                            data-bs-toggle="collapse"
                            class="custom-icon"
                            class:dark={daylight === false}
                            title="notif"
                            on:click={setImgNotif}
                          >
                            {#if daylight}
                              {#if announce_note === ""}
                                <UiBellDay />
                              {:else}
                                <UiBellNotifDay />
                              {/if}
                            {:else if announce_note === ""}
                              <UiBellNight />
                            {:else}
                              <UiBellNotifNight />
                            {/if}
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </div>
          </Row>
        </Container>
        <div
          style=" margin:10px auto;padding-left:0; padding-right:0;"
          on:mouseover={setMarqueePaused}
          on:mouseleave={setMarqueePaused}
        >
          {#if announce_note !== ""}
            <div
              class="d-flex"
              style="background-color: {daylight
                ? '#fff'
                : '#171717'}; border-radius:40px; padding: 0 20px;"
            >
              <img
                class={hide_img_notif ? "d-flex" : "d-none"}
                src="/notification.svg"
                alt="notification"
              />
              <Marquee repeat={3} duration={50} pauseOnHover={true}>
                <div
                  id="panelsStayOpen-collapseOne"
                  class="accordion-collapse collapse {announce_note
                    ? 'show'
                    : 'hide'}"
                  aria-labelledby="panelsStayOpen-headingOne"
                >
                  <div
                    class="accordion-body"
                    style="color:{daylight ? '#171717' : '#fff'}"
                  >
                    {@html announce_note}
                  </div>
                </div>
              </Marquee>
            </div>
          {/if}
        </div>
      </Col>
    </Row>
  {:else}
    <div
      class="sticky-top"
      style=" margin:10px auto;padding-left:0; padding-right:0; display:{announce_note !==
      ''
        ? 'block'
        : 'none'}"
      on:mouseover={setMarqueePaused}
      on:mouseleave={setMarqueePaused}
    >
      {#if announce_note !== ""}
        <div
          class="d-flex"
          style="background-color: {daylight
            ? '#fff'
            : '#171717'}; border-radius:40px; padding: 0 20px;"
        >
          <img
            class={hide_img_notif ? "d-flex" : "d-none"}
            src="/notification.svg"
            alt="notification"
          />
          <Marquee repeat={3} duration={50} pauseOnHover={true}>
            <div
              id="panelsStayOpen-collapseOne"
              class="accordion-collapse collapse {announce_note
                ? 'show'
                : 'hide'}"
              aria-labelledby="panelsStayOpen-headingOne"
            >
              <div
                class="accordion-body"
                style="color:{daylight ? '#171717' : '#fff'}"
              >
                {@html announce_note}
              </div>
            </div>
          </Marquee>
        </div>
      {/if}
    </div>
    <Row>
      <Col style="padding:5px;margin:0px;">
        <div style="margin:20px 10px;">
          <img
            id="imglogo"
            alt="SDSB4D"
            style="margin-top:10px;"
            width="123"
            src="logo-green.svg"
          />
        </div>
      </Col>
      <Col style="padding:5px;margin:0px;">
        <Card
          style="border:none;background-color:transparent;text-align:right;"
        >
          <CardBody style="background-color: transparent; padding: 1rem 0;">
            <span style="font-size:10px;">Timezone : {client_timezone}</span><br
            />
            <span id="style_text">{clockmachine} WIB</span>
            <br />
            <span class="user-detail mobile" class:dark={daylight === false}
              >{client_username} ({client_ipaddress})</span
            ><br />
            <span style="font-size:1rem;color: {daylight ? '#171717' : '#fff'};"
              >Saldo : IDR <span id="style_text">{display_credit}</span></span
            ><br />
          </CardBody>
        </Card>
      </Col>
    </Row>
    <Row>
      <div class="col-xs text-center">
        <div
          class="btn-group text-center mobile"
          role="group"
          aria-label="Basic example"
        >
          <button
            on:click={() => {
              handleClickButtonTop("result");
            }}
            class="btn btn-play mobile"
            class:dark={daylight === false}
            type="button"
          >
            <Fa icon={faChartPie} size="1.5x" />
            <br />
            Result</button
          >&nbsp;
          <button
            on:click={() => {
              handleClickButtonTop("invoice");
            }}
            class="btn btn-play mobile"
            class:dark={daylight === false}
            type="button"
          >
            <Fa icon={faHistory} size="1.5x" />
            <br />
            Riwayat</button
          >&nbsp;
          <button
            on:click={() => {
              handleClickButtonTop("pasaran");
            }}
            class="btn btn-play mobile"
            class:dark={daylight === false}
            type="button"
          >
            <Fa icon={faStore} size="1.5x" />
            <br />
            Market</button
          >&nbsp;
          <button
            on:click={() => {
              handleClickButtonTop("bukumimpi");
            }}
            class="btn btn-play mobile"
            class:dark={daylight === false}
            type="button"
          >
            <Fa icon={faBookOpen} size="1.5x" />
            <br />
            Mimpi</button
          >
        </div>
      </div>
    </Row>
  {/if}
</Container>

<div class="clearfix" />
<Modal
  modal_id={"modalhasilkeluaran"}
  modal_footer_flag={false}
  modal_body_height={"height:350px;"}
  modal_size={"modal-dialog-centered"}
  {daylight}
>
  <slot:template slot="header">
    <h5 class="modal-title" class:dark={daylight === false}>RESULT</h5>
  </slot:template>
  <slot:template slot="body">
    {#if listhasilkeluaran}
      {#if mobile}
        <div class="container pt-3 px-2">
          <div class="row g-2">
            {#each listhasilkeluaran as rec}
              <div
                class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6 mb-3 px-1"
              >
                <div
                  class="card card-invoice text-center"
                  class:dark={!daylight}
                  on:click={() => {
                    // getTimeout("MarketResult");
                    loadDisplay = true;
                    resulttogel = [];
                    setTimeout(function () {
                      fetch_resultbypasaran(
                        rec.keluaran_pasarancode,
                        rec.keluaran_pasaran
                      );
                    }, 500);
                  }}
                >
                  <div class="card-header"><h4>{rec.keluaran_pasaran}</h4></div>
                  <div class="card-body">
                    <h5
                      class="card-title"
                      style="color:{daylight ? '#00A86B' : '#fc0'};"
                    >
                      {rec.keluaran_result}
                    </h5>
                    <p class="card-text">
                      Periode: {rec.keluaran_periode +
                        "-" +
                        dayjs(rec.keluaran_date).format("YYYY")}
                    </p>
                  </div>
                  <div class="card-footer">{rec.keluaran_date}</div>
                </div>
              </div>
            {/each}
          </div>
        </div>
      {:else}
        <table
          class="table"
          class:table-dark={daylight === false}
          class:table-striped={daylight === false}
        >
          <thead>
            <tr>
              <th
                width="1%"
                style="text-align:center;vertical-align:top;background:{daylight
                  ? '#fff'
                  : '#303030'};font-size:{modal_table_fontsize_header};border-bottom:none;"
                >NO</th
              >
              <th
                width="15%"
                style="text-align:center;vertical-align:top;background:{daylight
                  ? '#fff'
                  : '#303030'};font-size:{modal_table_fontsize_header};border-bottom:none;"
                >TANGGAL</th
              >
              <th
                width="*"
                style="text-align:left;vertical-align:top;background:{daylight
                  ? '#fff'
                  : '#303030'};font-size:{modal_table_fontsize_header};border-bottom:none;"
                >PASARAN</th
              >
              <th
                width="15%"
                style="text-align:center;vertical-align:top;background:{daylight
                  ? '#fff'
                  : '#303030'};font-size:{modal_table_fontsize_header};border-bottom:none;"
                >PERIODE</th
              >
              <th
                width="25%"
                style="text-align:center;vertical-align:top;background:{daylight
                  ? '#fff'
                  : '#303030'};font-size:{modal_table_fontsize_header};border-bottom:none;"
                >HASIL</th
              >
            </tr>
          </thead>
          <tbody>
            {#each listhasilkeluaran as rec}
              <tr>
                <td
                  NOWRAP
                  style="text-align: center;vertical-align: top;font-size:{modal_table_fontsize_body};"
                  >{rec.keluaran_no}</td
                >
                <td
                  NOWRAP
                  style="text-align: center;vertical-align: top;font-size:{modal_table_fontsize_body};"
                  >{rec.keluaran_date}</td
                >
                <td
                  on:click={() => {
                    // getTimeout("MarketResult");
                    loadDisplay = true;
                    resulttogel = [];
                    setTimeout(function () {
                      fetch_resultbypasaran(
                        rec.keluaran_pasarancode,
                        rec.keluaran_pasaran
                      );
                    }, 500);
                  }}
                  NOWRAP
                  style="text-decoration:underline;cursor:pointer;text-align: left;vertical-align: top;font-size:{modal_table_fontsize_body};"
                  >{rec.keluaran_pasaran}</td
                >
                <td
                  NOWRAP
                  style="text-align: center;vertical-align: top;font-size:{modal_table_fontsize_body};"
                  >{rec.keluaran_periode +
                    "-" +
                    dayjs(rec.keluaran_date).format("YYYY")}</td
                >
                <td
                  NOWRAP
                  style="text-align: center;vertical-align: top;font-size:{modal_table_fontsize_body};color:{daylight
                    ? '#00A86B'
                    : '#fc0'};">{rec.keluaran_result}</td
                >
              </tr>
            {/each}
          </tbody>
        </table>
      {/if}
    {:else}
      <Placeholder
        total_placeholder="5"
        card_style="background-color:{daylight
          ? '#f3f3f3'
          : '#2c2c2c'};border:none;margin-top:5px;"
      />
    {/if}
  </slot:template>
</Modal>
<Modal
  modal_id={"modalhasilkeluaranpasaran"}
  modal_footer_flag={false}
  modal_body_height={"height:350px;"}
  modal_size={"modal-dialog-centered"}
  {daylight}
>
  <slot:template slot="header">
    <h5 class="modal-title" class:dark={daylight === false}>
      PASARAN : {nmpasaran}
    </h5>
  </slot:template>
  <slot:template slot="body">
    {#if resulttogel != ""}
      <table
        class="table"
        class:table-dark={daylight === false}
        class:table-striped={daylight === false}
      >
        <thead>
          <tr>
            <th
              width="1%"
              style="text-align:center;vertical-align:top;background:{daylight
                ? '#fff'
                : '#303030'};font-size:{modal_table_fontsize_header};border-bottom:none;"
              >NO</th
            >
            <th
              width="15%"
              style="text-align:center;vertical-align:top;background:{daylight
                ? '#fff'
                : '#303030'};font-size:{modal_table_fontsize_header};border-bottom:none;"
              >TANGGAL</th
            >
            <th
              width="15%"
              style="text-align:center;vertical-align:top;background:{daylight
                ? '#fff'
                : '#303030'};font-size:{modal_table_fontsize_header};border-bottom:none;"
              >PERIODE</th
            >
            <th
              width="25%"
              style="text-align:center;vertical-align:top;background:{daylight
                ? '#fff'
                : '#303030'};font-size:{modal_table_fontsize_header};border-bottom:none;"
              >HASIL</th
            >
          </tr>
        </thead>
        <tbody>
          {#each resulttogel as rec}
            <tr>
              <td
                NOWRAP
                style="text-align: center;vertical-align: top;font-size:{modal_table_fontsize_body};"
                >{rec.no}</td
              >
              <td
                NOWRAP
                style="text-align: center;vertical-align: top;font-size:{modal_table_fontsize_body};"
                >{rec.date}</td
              >

              <td
                NOWRAP
                style="text-align: center;vertical-align: top;font-size:{modal_table_fontsize_body};"
                >{rec.periode + "-" + dayjs(rec.date).format("YYYY")}</td
              >
              <td
                NOWRAP
                style="text-align: center;vertical-align: top;font-size:{modal_table_fontsize_body};color:{daylight
                  ? '#00A86B'
                  : '#fc0'};">{rec.result}</td
              >
            </tr>
          {/each}
        </tbody>
      </table>
    {:else}
      <Placeholder
        total_placeholder="3"
        card_style="background-color:{daylight
          ? '#f3f3f3'
          : '#2c2c2c'};border:none;margin-top:5px;"
      />
    {/if}
  </slot:template>
</Modal>

<!-- modal invoice -->
<Modal
  modal_id={"modalhasilinvoice"}
  modal_footer_flag={false}
  modal_body_height={"height:350px;"}
  modal_size={"modal-dialog-centered"}
  {daylight}
  bind:loadDisplay
>
  <slot:template slot="header">
    <h5 class="modal-title" class:dark={!daylight}>BET HISTORY</h5>
  </slot:template>
  <slot:template slot="body">
    {#if listhasilinvoice != ""}
      {#if mobile}
        {#each listhasilinvoice as rec}
          <div
            class="card card-invoice mb-3 m-2"
            class:dark={!daylight}
            on:click={() => {
              // getTimeout("PeriodeDetail");
              loadDisplay = true;
              setTimeout(function () {
                fetch_invoicelldetail(
                  rec.invoice_idinvoice,
                  rec.invoice_periode,
                  dayjs(rec.invoice_tglkeluaran).format("YYYY")
                );
              }, 500);
            }}
          >
            <div class="card-body">
              <div class="row">
                <div class="col">
                  <h5 class="card-title fw-bold">
                    {rec.invoice_pasaran} - {rec.invoice_periode +
                      "-" +
                      dayjs(rec.invoice_tglkeluaran).format("YYYY")}
                  </h5>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <p class="card-text">
                    {rec.invoice_tglkeluaran}<br />
                    Winlose: {new Intl.NumberFormat().format(
                      rec.invoice_totallose
                    )}
                  </p>
                </div>
                <div class="col">
                  <p
                    class="float-end badge"
                    class:bg-success={rec.invoice_status === "COMPLETED"}
                    class:bg-warning={rec.invoice_status !== "COMPLETED"}
                  >
                    {rec.invoice_status}
                  </p>
                </div>
              </div>
            </div>
          </div>
        {/each}
      {:else}
        <table class="table" class:table-dark={!daylight}>
          <thead>
            <tr>
              <th
                width="1%"
                style="text-align:center;vertical-align:top;background:{daylight
                  ? '#fff'
                  : '#303030'};font-size:{modal_table_fontsize_header};"
                >STATUS</th
              >
              <th
                width="15%"
                style="text-align:center;vertical-align:top;background:{daylight
                  ? '#fff'
                  : '#303030'};font-size:{modal_table_fontsize_header};"
                >TANGGAL</th
              >
              <th
                width="*"
                style="text-align:left;vertical-align:top;background:{daylight
                  ? '#fff'
                  : '#303030'};font-size:{modal_table_fontsize_header};"
                >PASARAN</th
              >
              <th
                width="15%"
                style="text-align:center;vertical-align:top;background:{daylight
                  ? '#fff'
                  : '#303030'};font-size:{modal_table_fontsize_header};"
                >PERIODE</th
              >
              <th
                width="25%"
                style="text-align:right;vertical-align:top;background:{daylight
                  ? '#fff'
                  : '#303030'};font-size:{modal_table_fontsize_header};"
                >WINLOSE</th
              >
            </tr>
          </thead>
          <tbody>
            {#each listhasilinvoice as rec}
              <tr>
                <td
                  NOWRAP
                  style="text-align: center;vertical-align: top;{rec.invoice_background};"
                  >{rec.invoice_status}</td
                >
                <td
                  NOWRAP
                  style="text-align: center;vertical-align: top;font-size:{modal_table_fontsize_body};"
                  >{rec.invoice_tglkeluaran}
                </td>
                <td
                  NOWRAP
                  style="text-align: left;vertical-align: top;font-size:{modal_table_fontsize_body};"
                  >{rec.invoice_pasaran}</td
                >
                <td
                  on:click={() => {
                    // getTimeout("PeriodeDetail");
                    loadDisplay = true;
                    setTimeout(function () {
                      fetch_invoicelldetail(
                        rec.invoice_idinvoice,
                        rec.invoice_periode,
                        dayjs(rec.invoice_tglkeluaran).format("YYYY")
                      );
                    }, 500);
                  }}
                  NOWRAP
                  style="text-decoration:underline;cursor:pointer;text-align: center;vertical-align: top;font-size:{modal_table_fontsize_body};"
                  >{rec.invoice_periode +
                    "-" +
                    dayjs(rec.invoice_tglkeluaran).format("YYYY")}</td
                >
                <td
                  NOWRAP
                  style="text-align: right;vertical-align: top;font-size:{modal_table_fontsize_body};color:{daylight
                    ? '#00A86B'
                    : '#fc0'};"
                >
                  {new Intl.NumberFormat().format(rec.invoice_totallose)}
                </td>
              </tr>
            {/each}
          </tbody>
        </table>
      {/if}
    {:else}
      <Placeholder
        total_placeholder="5"
        card_style="background-color:{daylight
          ? '#f3f3f3'
          : '#2c2c2c'};border:none;margin-top:5px;"
      />
    {/if}
  </slot:template>
</Modal>

<!-- modal pasaran -->
<Modal
  modal_id={"modalpasaran"}
  modal_footer_flag={false}
  modal_body_height={"height:350px;"}
  modal_size={"modal modal-dialog-centered"}
  {daylight}
>
  <slot:template slot="header">
    <h5 class="modal-title" class:dark={daylight === false}>INFO PASARAN</h5>
  </slot:template>
  <slot:template slot="body">
    {#if listpasaran != ""}
      {#if mobile}
        <div class="container p-3">
          <div class="row">
            {#each listpasaran as rec}
              <div
                class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6 mb-3 px-1"
              >
                <div
                  class="card card-invoice"
                  class:dark={!daylight}
                  on:click={goToUrl(rec.pasaran_url)}
                >
                  <div class="card-header text-center">
                    <h4>{rec.pasaran_togel}</h4>
                  </div>
                  <div class="card-body">
                    <small class="card-title"
                      >{rec.pasaran_jadwal.replace(/, /g, ",")}</small
                    >
                    <table class="card-text">
                      <tr>
                        <td width="70%" class="text-start">Tutup</td>
                        <td width="1%" class="text-start">:</td>
                        <td width="*" class="text-end"
                          >{rec.pasaran_marketclose}</td
                        >
                      </tr>
                      <tr>
                        <td width="70%" class="text-start">Jadwal Undian</td>
                        <td width="1%" class="text-start">:</td>
                        <td width="*" class="text-end"
                          >{rec.pasaran_marketschedule}</td
                        >
                      </tr>
                      <tr>
                        <td width="70%" class="text-start">Buka Pasaran</td>
                        <td width="1%" class="text-start">:</td>
                        <td width="*" class="text-end"
                          >{rec.pasaran_marketopen}</td
                        >
                      </tr>
                    </table>
                  </div>
                  <div class="card-footer text-center">
                    <a
                      data-toggle="tooltip"
                      data-placement="top"
                      title="kunjungi website ini : {rec.pasaran_url}"
                      href={rec.pasaran_url}
                      target="_blank"
                      style="color:{daylight
                        ? '#00A86B'
                        : '#fc0'}; font-weight:bold;">Visit</a
                    >
                  </div>
                </div>
              </div>
            {/each}
          </div>
        </div>
      {:else}
        <table class="table" class:table-dark={daylight === false}>
          <thead>
            <tr>
              <th
                style="text-align:center;vertical-align:top;font-size:{modal_table_fontsize_header};"
                >PASARAN</th
              >
              <th
                style="text-align:center;vertical-align:top;font-size:{modal_table_fontsize_header};"
                >DIUNDI</th
              >
              <th
                style="text-align:center;vertical-align:top;font-size:{modal_table_fontsize_header};"
                >JADWAL</th
              >
              <th
                style="text-align:left;vertical-align:top;font-size:{modal_table_fontsize_header};"
                >TUTUP</th
              >
              <th
                style="text-align:center;vertical-align:top;font-size:{modal_table_fontsize_header};"
                >BUKA</th
              >
            </tr>
          </thead>
          <tbody>
            {#each listpasaran as rec}
              <tr>
                <td NOWRAP style="text-align: center;vertical-align: top;">
                  <a
                    data-toggle="tooltip"
                    data-placement="top"
                    title="kunjungi website ini : {rec.pasaran_url}"
                    href={rec.pasaran_url}
                    target="_blank"
                    style="color:{daylight
                      ? '#00A86B'
                      : '#fc0'}; font-weight:bold;">{rec.pasaran_togel}</a
                  >
                </td>
                <td
                  NOWRAP
                  style="text-align: center;vertical-align: top;font-size:{modal_table_fontsize_body};"
                  >{rec.pasaran_jadwal}
                </td>
                <td
                  NOWRAP
                  style="text-align: center;vertical-align: top;font-size:{modal_table_fontsize_body};"
                  >{rec.pasaran_marketschedule}
                </td>
                <td
                  NOWRAP
                  style="text-align: left;vertical-align: top;font-size:{modal_table_fontsize_body};"
                  >{rec.pasaran_marketclose}</td
                >
                <td
                  NOWRAP
                  style="text-align: center;vertical-align: top;font-size:{modal_table_fontsize_body};"
                  >{rec.pasaran_marketopen}</td
                >
              </tr>
            {/each}
          </tbody>
        </table>
      {/if}
    {:else}
      <Placeholder
        total_placeholder="5"
        card_style="background-color:{daylight
          ? '#f3f3f3'
          : '#2c2c2c'};border:none;margin-top:5px;"
      />
    {/if}
  </slot:template>
</Modal>

<!-- modal bukumimpi -->
<Modal
  modal_id={"modalbukumimpi"}
  modal_headerbootom_flag={true}
  modal_footer_flag={false}
  modal_body_height={"height:500px;"}
  modal_size={"modal-dialog-centered"}
  {daylight}
>
  <slot:template slot="header">
    <h5 class="modal-title" class:dark={daylight === false}>BUKU MIMPI</h5>
  </slot:template>
  <slot:template slot="headerbottom">
    <div class="navbar mb-3 mx-2">
      <ul
        class="nav nav-pills"
        id="pills-tab"
        role="tablist"
        style="background-color: transparent;"
      >
        <li
          on:click={() => {
            handleClickBukuMimpi("ALL");
          }}
          class="nav-item"
          role="presentation"
        >
          <button
            class="nav-link custom active"
            class:custom-dark={daylight === false}
            id="pills-home-tab"
            data-bs-toggle="pill"
            data-bs-target="#pills-bukumimpiall"
            type="button"
            role="tab"
            aria-controls="pills-bukumimpiall"
            aria-selected="true">ALL</button
          >
        </li>
        <li
          on:click={() => {
            handleClickBukuMimpi("4D");
          }}
          class="nav-item"
          role="presentation"
        >
          <button
            class="nav-link custom"
            class:custom-dark={daylight === false}
            id="pills-profile-tab"
            data-bs-toggle="pill"
            data-bs-target="#pills-bukumimpi4d"
            type="button"
            role="tab"
            aria-controls="pills-bukumimpi4d"
            aria-selected="false">4D</button
          >
        </li>
        <li
          on:click={() => {
            handleClickBukuMimpi("3D");
          }}
          class="nav-item"
          role="presentation"
        >
          <button
            class="nav-link custom"
            class:custom-dark={daylight === false}
            id="pills-contact-tab"
            data-bs-toggle="pill"
            data-bs-target="#pills-bukumimpi3d"
            type="button"
            role="tab"
            aria-controls="pills-bukumimpi3d"
            aria-selected="false">3D</button
          >
        </li>
        <li
          on:click={() => {
            handleClickBukuMimpi("2D");
          }}
          class="nav-item"
          role="presentation"
        >
          <button
            class="nav-link custom"
            class:custom-dark={daylight === false}
            id="pills-contact-tab"
            data-bs-toggle="pill"
            data-bs-target="#pills-bukumimpi2d"
            type="button"
            role="tab"
            aria-controls="pills-bukumimpi2d"
            aria-selected="false">2D</button
          >
        </li>
      </ul>
      <div class="d-flex {client_device !== 'WEBSITE' ? 'flex-fill mt-3' : ''}">
        <input
          bind:value={searchbukumimpi}
          on:keypress={handleKeyboardbukumimpi_checkenter}
          style="border-radius: 5px;border: none; background: {daylight
            ? '#f3f3f3'
            : '#303030'} none repeat scroll 0% 0%; color: {daylight
            ? '#171717'
            : '#fff'}; font-size: {modal_table_fontsize_bukumimpi_header}; margin-right: 5px;"
          placeholder="cari mimpi kamu"
          class="form-control"
          type="text"
          id="cariMimpiHeader"
          autocomplete="off"
        />
      </div>
    </div>
  </slot:template>
  <slot:template slot="body">
    <div class="tab-content" id="pills-tabContent">
      <div
        class="tab-pane fade show active"
        id="pills-bukumimpiall"
        role="tabpanel"
        aria-labelledby="pills-bukumimpiall-tab"
      >
        <div class="container-fluid">
          {#if filterBukuMimpi}
            {#each filterBukuMimpi as rec}
              <div class="row">
                <div class="col-2">
                  <span
                    style="color:{daylight
                      ? '#00A86B'
                      : '#fc0'};font-size:{modal_table_fontsize_bukumimpi_header};"
                    >{rec.bukumimpi_tipe}</span
                  >
                </div>
                <div class="col-10" style="border-left: 1px solid #E9E9E9;">
                  {rec.bukumimpi_nama}
                  <br />
                  <span
                    style="color:{daylight
                      ? '#00A86B'
                      : '#fc0'};font-size:{modal_table_fontsize_bukumimpi_header};"
                    >{rec.bukumimpi_nomor}</span
                  >
                </div>
              </div>
            {/each}
          {:else}
            <Placeholder
              total_placeholder="10"
              card_style="background-color:{daylight
                ? '#f3f3f3'
                : '#2c2c2c'};border:none;margin-top:5px;"
            />
          {/if}
        </div>
      </div>
      <div
        class="tab-pane fade"
        id="pills-bukumimpi4d"
        role="tabpanel"
        aria-labelledby="pills-bukumimpi4d-tab"
      >
        <div class="container-fluid">
          {#if filterBukuMimpi != ""}
            {#each filterBukuMimpi as rec}
              <div class="row">
                <div class="col-2">
                  <span
                    style="color:{daylight
                      ? '#00A86B'
                      : '#fc0'};font-size:{modal_table_fontsize_bukumimpi_header};"
                    >{rec.bukumimpi_tipe}</span
                  >
                </div>
                <div class="col-10" style="border-left: 1px solid #E9E9E9;">
                  {rec.bukumimpi_nama}
                  <br />
                  <span
                    style="color:{daylight
                      ? '#00A86B'
                      : '#fc0'};font-size:{modal_table_fontsize_bukumimpi_header};"
                    >{rec.bukumimpi_nomor}</span
                  >
                </div>
              </div>
            {/each}
          {:else}
            <Placeholder
              total_placeholder="1-"
              card_style="background-color:{daylight
                ? '#f3f3f3'
                : '#2c2c2c'};border:none;margin-top:5px;"
            />
          {/if}
        </div>
      </div>
      <div
        class="tab-pane fade"
        id="pills-bukumimpi3d"
        role="tabpanel"
        aria-labelledby="pills-bukumimpi3d-tab"
      >
        <div class="container-fluid">
          {#if filterBukuMimpi != ""}
            {#each filterBukuMimpi as rec}
              <div class="row">
                <div class="col-2">
                  <span
                    style="color:{daylight
                      ? '#00A86B'
                      : '#fc0'};font-size:{modal_table_fontsize_bukumimpi_header};"
                    >{rec.bukumimpi_tipe}</span
                  >
                </div>
                <div class="col-10" style="border-left: 1px solid #E9E9E9;">
                  {rec.bukumimpi_nama}
                  <br />
                  <span
                    style="color:{daylight
                      ? '#00A86B'
                      : '#fc0'};font-size:{modal_table_fontsize_bukumimpi_header};"
                    >{rec.bukumimpi_nomor}</span
                  >
                </div>
              </div>
            {/each}
          {:else}
            <Placeholder
              total_placeholder="1-"
              card_style="background-color:{daylight
                ? '#f3f3f3'
                : '#2c2c2c'};border:none;margin-top:5px;"
            />
          {/if}
        </div>
      </div>
      <div
        class="tab-pane fade"
        id="pills-bukumimpi2d"
        role="tabpanel"
        aria-labelledby="pills-bukumimpi2d-tab"
      >
        <div class="container-fluid">
          {#if filterBukuMimpi != ""}
            {#each filterBukuMimpi as rec}
              <div class="row">
                <div class="col-2">
                  <span
                    class="text-center"
                    style="color:{daylight
                      ? '#00A86B'
                      : '#fc0'};font-size:{modal_table_fontsize_bukumimpi_header};"
                    >{rec.bukumimpi_tipe}</span
                  >
                </div>
                <div class="col-10" style="border-left: 1px solid #E9E9E9;">
                  {rec.bukumimpi_nama}
                  <br />
                  <span
                    style="color:{daylight
                      ? '#00A86B'
                      : '#fc0'};font-size:{modal_table_fontsize_bukumimpi_header};"
                    >{rec.bukumimpi_nomor}</span
                  >
                </div>
              </div>
            {/each}
          {:else}
            <Placeholder
              total_placeholder="1-"
              card_style="background-color:{daylight
                ? '#f3f3f3'
                : '#2c2c2c'};border:none;margin-top:5px;"
            />
          {/if}
        </div>
      </div>
    </div>
  </slot:template>
</Modal>

<!-- invoice detail per pasaran -->
<Modal
  modal_id={"modalslipalldetail"}
  modal_footer_flag={true}
  modal_body_height={"height:350px;"}
  modal_size={"modal-dialog-centered"}
  {daylight}
>
  <slot:template slot="header">
    <h5 class="modal-title" class:dark={daylight === false}>
      PASARAN : {detailslipheader}
    </h5>
  </slot:template>
  <slot:template slot="body">
    <table
      class="table"
      class:table-dark={daylight === false}
      class:table-striped={daylight === false}
    >
      <thead>
        <tr>
          <th
            width="15%"
            style="text-align:left;vertical-align:top;background:{daylight
              ? '#fff'
              : '#303030'};font-size:{modal_table_fontsize_header};border-bottom:none;"
            >PERMAINAN</th
          >
          <th
            width="50%"
            style="text-align:right;vertical-align:top;background:{daylight
              ? '#fff'
              : '#303030'};font-size:{modal_table_fontsize_header};border-bottom:none;"
            >BAYAR</th
          >
          <th
            width="25%"
            style="text-align:right;vertical-align:top;background:{daylight
              ? '#fff'
              : '#303030'};font-size:{modal_table_fontsize_header};border-bottom:none;"
            >MENANG</th
          >
        </tr>
      </thead>
      <tbody>
        <tr class:hide={total4d_bayar === 0}>
          <td
            on:click={() => {
              // getTimeout("GameInvoiceDetail");
              loadDisplay = true;
              setTimeout(function () {
                fetch_invoicealldetailpermainan("4D", total4d_bayar);
              }, 500);
            }}
            NOWRAP
            style="text-decoration:underline;cursor:pointer;text-align:left;vertical-align:top;font-size:{modal_table_fontsize_body};"
            >4D</td
          >
          <td
            style="text-align:right;vertical-align:top;color:{daylight
              ? '#00A86B'
              : '#fc0'};;font-size:{modal_table_fontsize_body};"
          >
            {new Intl.NumberFormat().format(total4d_bayar)}
          </td>
          <td
            style="text-align:right;vertical-align:top;color:{daylight
              ? '#00A86B'
              : '#fc0'};;font-size:{modal_table_fontsize_body};"
          >
            {new Intl.NumberFormat().format(totalwin_4d)}
          </td>
        </tr>
        <tr class:hide={total3d_bayar === 0}>
          <td
            on:click={() => {
              // getTimeout("GameInvoiceDetail");
              loadDisplay = true;
              setTimeout(function () {
                fetch_invoicealldetailpermainan("3D", total3d_bayar);
              }, 500);
            }}
            NOWRAP
            style="text-decoration:underline;cursor:pointer;text-align:left;vertical-align:top;font-size:{modal_table_fontsize_body};"
            >3D</td
          >
          <td
            style="text-align:right;vertical-align:top;color:{daylight
              ? '#00A86B'
              : '#fc0'};;font-size:{modal_table_fontsize_body};"
          >
            {new Intl.NumberFormat().format(total3d_bayar)}
          </td>
          <td
            style="text-align:right;vertical-align:top;color:{daylight
              ? '#00A86B'
              : '#fc0'};;font-size:{modal_table_fontsize_body};"
          >
            {new Intl.NumberFormat().format(totalwin_3d)}
          </td>
        </tr>
        <tr class:hide={total2d_bayar === 0}>
          <td
            on:click={() => {
              // getTimeout("GameInvoiceDetail");
              loadDisplay = true;
              setTimeout(function () {
                fetch_invoicealldetailpermainan("2D", total2d_bayar);
              }, 500);
            }}
            NOWRAP
            style="text-decoration:underline;cursor:pointer;text-align:left;vertical-align:top;font-size:{modal_table_fontsize_body};"
            >2D</td
          >
          <td
            style="text-align:right;vertical-align:top;color:{daylight
              ? '#00A86B'
              : '#fc0'};;font-size:{modal_table_fontsize_body};"
          >
            {new Intl.NumberFormat().format(total2d_bayar)}
          </td>
          <td
            style="text-align:right;vertical-align:top;color:{daylight
              ? '#00A86B'
              : '#fc0'};;font-size:{modal_table_fontsize_body};"
          >
            {new Intl.NumberFormat().format(totalwin_2d)}
          </td>
        </tr>
        <tr class:hide={totalcolokbebas_bayar === 0}>
          <td
            on:click={() => {
              // getTimeout("GameInvoiceDetail");
              loadDisplay = true;
              setTimeout(function () {
                fetch_invoicealldetailpermainan(
                  "COLOK_BEBAS",
                  totalcolokbebas_bayar
                );
              }, 500);
            }}
            NOWRAP
            style="text-decoration:underline;cursor:pointer;text-align:left;vertical-align:top;font-size:{modal_table_fontsize_body};"
            >COLOK BEBAS</td
          >
          <td
            style="text-align:right;vertical-align:top;color:{daylight
              ? '#00A86B'
              : '#fc0'};;font-size:{modal_table_fontsize_body};"
          >
            {new Intl.NumberFormat().format(totalcolokbebas_bayar)}
          </td>
          <td
            style="text-align:right;vertical-align:top;color:{daylight
              ? '#00A86B'
              : '#fc0'};;font-size:{modal_table_fontsize_body};"
          >
            {new Intl.NumberFormat().format(totalwin_colokbebas)}
          </td>
        </tr>
        <tr class:hide={totalcolokmacau_bayar === 0}>
          <td
            on:click={() => {
              // getTimeout("GameInvoiceDetail");
              loadDisplay = true;
              setTimeout(function () {
                fetch_invoicealldetailpermainan(
                  "COLOK_MACAU",
                  totalcolokmacau_bayar
                );
              }, 500);
            }}
            NOWRAP
            style="text-decoration:underline;cursor:pointer;text-align:left;vertical-align:top;font-size:{modal_table_fontsize_body};"
            >COLOK MACAU</td
          >
          <td
            style="text-align:right;vertical-align:top;color:{daylight
              ? '#00A86B'
              : '#fc0'};;font-size:{modal_table_fontsize_body};"
          >
            {new Intl.NumberFormat().format(totalcolokmacau_bayar)}
          </td>
          <td
            style="text-align:right;vertical-align:top;color:{daylight
              ? '#00A86B'
              : '#fc0'};;font-size:{modal_table_fontsize_body};"
          >
            {new Intl.NumberFormat().format(totalwin_colokmacau)}
          </td>
        </tr>
        <tr class:hide={totalcoloknaga_bayar === 0}>
          <td
            on:click={() => {
              // getTimeout("GameInvoiceDetail");
              loadDisplay = true;
              setTimeout(function () {
                fetch_invoicealldetailpermainan(
                  "COLOK_NAGA",
                  totalcoloknaga_bayar
                );
              }, 500);
            }}
            NOWRAP
            style="text-decoration:underline;cursor:pointer;text-align:left;vertical-align:top;font-size:{modal_table_fontsize_body};"
            >COLOK NAGA</td
          >
          <td
            style="text-align:right;vertical-align:top;color:{daylight
              ? '#00A86B'
              : '#fc0'};;font-size:{modal_table_fontsize_body};"
          >
            {new Intl.NumberFormat().format(totalcoloknaga_bayar)}
          </td>
          <td
            style="text-align:right;vertical-align:top;color:{daylight
              ? '#00A86B'
              : '#fc0'};;font-size:{modal_table_fontsize_body};"
          >
            {new Intl.NumberFormat().format(totalwin_coloknaga)}
          </td>
        </tr>
        <tr class:hide={totalcolokjitu_bayar === 0}>
          <td
            on:click={() => {
              // getTimeout("GameInvoiceDetail");
              loadDisplay = true;
              setTimeout(function () {
                fetch_invoicealldetailpermainan(
                  "COLOK_JITU",
                  totalcolokjitu_bayar
                );
              }, 500);
            }}
            NOWRAP
            style="text-decoration:underline;cursor:pointer;text-align:left;vertical-align:top;font-size:{modal_table_fontsize_body};"
            >COLOK JITU</td
          >
          <td
            style="text-align:right;vertical-align:top;color:{daylight
              ? '#00A86B'
              : '#fc0'};;font-size:{modal_table_fontsize_body};"
          >
            {new Intl.NumberFormat().format(totalcolokjitu_bayar)}
          </td>
          <td
            style="text-align:right;vertical-align:top;color:{daylight
              ? '#00A86B'
              : '#fc0'};;font-size:{modal_table_fontsize_body};"
          >
            {new Intl.NumberFormat().format(totalwin_colokjitu)}
          </td>
        </tr>
        <tr class:hide={total5050umum_bayar === 0}>
          <td
            on:click={() => {
              // getTimeout("GameInvoiceDetail");
              loadDisplay = true;
              setTimeout(function () {
                fetch_invoicealldetailpermainan(
                  "50_50_UMUM",
                  total5050umum_bayar
                );
              }, 500);
            }}
            NOWRAP
            style="text-decoration:underline;cursor:pointer;text-align:left;vertical-align:top;font-size:{modal_table_fontsize_body};"
            >50 - 50 UMUM</td
          >
          <td
            style="text-align:right;vertical-align:top;color:{daylight
              ? '#00A86B'
              : '#fc0'};;font-size:{modal_table_fontsize_body};"
          >
            {new Intl.NumberFormat().format(total5050umum_bayar)}
          </td>
          <td
            style="text-align:right;vertical-align:top;color:{daylight
              ? '#00A86B'
              : '#fc0'};;font-size:{modal_table_fontsize_body};"
          >
            {new Intl.NumberFormat().format(totalwin_5050umum)}
          </td>
        </tr>
        <tr class:hide={total5050special_bayar === 0}>
          <td
            on:click={() => {
              // getTimeout("GameInvoiceDetail");
              loadDisplay = true;
              setTimeout(function () {
                fetch_invoicealldetailpermainan(
                  "50_50_SPECIAL",
                  total5050special_bayar
                );
              }, 500);
            }}
            NOWRAP
            style="text-decoration:underline;cursor:pointer;text-align:left;vertical-align:top;font-size:{modal_table_fontsize_body};"
            >50 - 50 SPECIAL</td
          >
          <td
            style="text-align:right;vertical-align:top;color:{daylight
              ? '#00A86B'
              : '#fc0'};;font-size:{modal_table_fontsize_body};"
          >
            {new Intl.NumberFormat().format(total5050special_bayar)}
          </td>
          <td
            style="text-align:right;vertical-align:top;color:{daylight
              ? '#00A86B'
              : '#fc0'};;font-size:{modal_table_fontsize_body};"
          >
            {new Intl.NumberFormat().format(totalwin_5050special)}
          </td>
        </tr>
        <tr class:hide={total5050kombinasi_bayar === 0}>
          <td
            on:click={() => {
              // getTimeout("GameInvoiceDetail");
              loadDisplay = true;
              setTimeout(function () {
                fetch_invoicealldetailpermainan(
                  "50_50_KOMBINASI",
                  total5050kombinasi_bayar
                );
              }, 500);
            }}
            NOWRAP
            style="text-decoration:underline;cursor:pointer;text-align:left;vertical-align:top;font-size:{modal_table_fontsize_body};"
            >50 - 50 KOMBINASI</td
          >
          <td
            style="text-align:right;vertical-align:top;color:{daylight
              ? '#00A86B'
              : '#fc0'};;font-size:{modal_table_fontsize_body};"
          >
            {new Intl.NumberFormat().format(total5050kombinasi_bayar)}
          </td>
          <td
            style="text-align:right;vertical-align:top;color:{daylight
              ? '#00A86B'
              : '#fc0'};;font-size:{modal_table_fontsize_body};"
          >
            {new Intl.NumberFormat().format(totalwin_5050kombinasi)}
          </td>
        </tr>
        <tr class:hide={totalmacaukombinasi_bayar === 0}>
          <td
            on:click={() => {
              // getTimeout("GameInvoiceDetail");
              loadDisplay = true;
              setTimeout(function () {
                fetch_invoicealldetailpermainan(
                  "MACAU_KOMBINASI",
                  totalmacaukombinasi_bayar
                );
              }, 500);
            }}
            NOWRAP
            style="text-decoration:underline;cursor:pointer;text-align:left;vertical-align:top;font-size:{modal_table_fontsize_body};"
            >MACAU / KOMBINASI</td
          >
          <td
            style="text-align:right;vertical-align:top;color:{daylight
              ? '#00A86B'
              : '#fc0'};;font-size:{modal_table_fontsize_body};"
          >
            {new Intl.NumberFormat().format(totalmacaukombinasi_bayar)}
          </td>
          <td
            style="text-align:right;vertical-align:top;color:{daylight
              ? '#00A86B'
              : '#fc0'};;font-size:{modal_table_fontsize_body};"
          >
            {new Intl.NumberFormat().format(totalwin_macaukombinasi)}
          </td>
        </tr>
        <tr class:hide={totaldasar_bayar === 0}>
          <td
            on:click={() => {
              // getTimeout("GameInvoiceDetail");
              loadDisplay = true;
              setTimeout(function () {
                fetch_invoicealldetailpermainan("DASAR", totaldasar_bayar);
              }, 500);
            }}
            NOWRAP
            style="text-decoration:underline;cursor:pointer;text-align:left;vertical-align:top;font-size:{modal_table_fontsize_body};"
            >DASAR</td
          >
          <td
            style="text-align:right;vertical-align:top;color:{daylight
              ? '#00A86B'
              : '#fc0'};;font-size:{modal_table_fontsize_body};"
          >
            {new Intl.NumberFormat().format(totaldasar_bayar)}
          </td>
          <td
            style="text-align:right;vertical-align:top;color:{daylight
              ? '#00A86B'
              : '#fc0'};;font-size:{modal_table_fontsize_body};"
          >
            {new Intl.NumberFormat().format(totalwin_dasar)}
          </td>
        </tr>
        <tr class:hide={totalshio_bayar === 0}>
          <td
            on:click={() => {
              // getTimeout("GameInvoiceDetail");
              loadDisplay = true;
              setTimeout(function () {
                fetch_invoicealldetailpermainan("SHIO", totalshio_bayar);
              }, 500);
            }}
            NOWRAP
            style="text-decoration:underline;cursor:pointer;text-align:left;vertical-align:top;font-size:{modal_table_fontsize_body};"
            >SHIO</td
          >
          <td
            style="text-align:right;vertical-align:top;color:{daylight
              ? '#00A86B'
              : '#fc0'};;font-size:{modal_table_fontsize_body};"
          >
            {new Intl.NumberFormat().format(totalshio_bayar)}
          </td>
          <td
            style="text-align:right;vertical-align:top;color:{daylight
              ? '#00A86B'
              : '#fc0'};;font-size:{modal_table_fontsize_body};"
          >
            {new Intl.NumberFormat().format(totalwin_shio)}
          </td>
        </tr>
      </tbody>
    </table>
  </slot:template>
  <slot:template slot="footer">
    <table class="table" width="100%">
      <tr>
        <td
          NOWRAP
          width="50%"
          style="text-align:right;vertical-align:top;color:{daylight
            ? '#171717'
            : 'white'};font-size:{modal_table_fontsize_body};">TOTAL BAYAR</td
        >
        <td
          NOWRAP
          width="1%"
          style="text-align:center;vertical-align:top;color:{daylight
            ? '#171717'
            : 'white'};font-size:{modal_table_fontsize_body};">:</td
        >
        <td
          NOWRAP
          width="*"
          style="text-align:right;vertical-align:top;color:{daylight
            ? '#171717'
            : 'white'};font-size:{modal_table_fontsize_body};color:{daylight
            ? '#00A86B'
            : '#fc0'};">{new Intl.NumberFormat().format(subtotal_bayar)}</td
        >
      </tr>
      <tr>
        <td
          NOWRAP
          style="text-align:right;vertical-align:top;color:{daylight
            ? '#171717'
            : 'white'};font-size:12px;">TOTAL WINNER</td
        >
        <td
          NOWRAP
          style="text-align:center;vertical-align:top;color:{daylight
            ? '#171717'
            : 'white'};font-size:12px;">:</td
        >
        <td
          NOWRAP
          style="text-align:right;vertical-align:top;color:{daylight
            ? '#171717'
            : 'white'};font-size:12px;color:{daylight ? '#00A86B' : '#fc0'};"
          >{new Intl.NumberFormat().format(subtotal_winner)}</td
        >
      </tr>
      <tr>
        <td
          NOWRAP
          style="text-align:right;vertical-align:top;color:{daylight
            ? '#171717'
            : 'white'};font-size:12px;">WINLOSE</td
        >
        <td
          NOWRAP
          style="text-align:center;vertical-align:top;color:{daylight
            ? '#171717'
            : 'white'};font-size:12px;">:</td
        >
        <td
          NOWRAP
          style="text-align:right;vertical-align:top;color:{daylight
            ? '#171717'
            : 'white'};font-size:12px;color:{daylight ? '#00A86B' : '#fc0'};"
          >{new Intl.NumberFormat().format(total_winlose)}</td
        >
      </tr>
    </table>
  </slot:template>
</Modal>

<!-- modal detail invoice by ID -->
<Modal
  modal_id={"modalslipalldetailbyid"}
  modal_footer_flag={true}
  modal_body_height={"height:450px;"}
  modal_size={"modal-dialog-centered"}
  {daylight}
>
  <slot:template slot="header">
    <h5 class="modal-title" class:dark={daylight === false}>
      PERMAINAN : {detailslipheaderpermainan.replace(/_/g, " / ")}
    </h5>
  </slot:template>
  <slot:template slot="body">
    <!-- {#if mobile} -->
    {#each listhasilinvoicebet as rec}
      <div class="card card-invoice m-2" class:dark={!daylight}>
        <div class="card-body">
          <div class="row">
            <div
              class="col border-bottom"
              class:border-success={daylight}
              class:border-warning={!daylight}
            >
              <h5 class="card-title fw-bold position-relative">
                {rec.bet_nomor.replace(/_/g, " + ")}
                <span
                  class="position-absolute top-50 end-0 translate-middle-y badge"
                  class:bg-danger={rec.bet_status === "LOSE" ||
                    rec.bet_status === "CANCEL"}
                  class:bg-warning={rec.bet_status === "RUNNING"}
                  class:bg-success={rec.bet_status === "WINNER"}
                  >{rec.bet_status}</span
                >
              </h5>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <table class="table">
                <tr class>
                  <td width="*" class="text-start pb-2">
                    <small>Permainan</small><br />
                    <span style="color:{daylight ? '#00A86B' : '#fc0'}"
                      >{rec.bet_permainan}
                    </span>
                  </td>
                  <td width="50%" class="text-end pb-2">
                    <small>Bet</small><br />
                    <span style="color:{daylight ? '#00A86B' : '#fc0'}"
                      >{new Intl.NumberFormat().format(rec.bet_bet)}</span
                    >
                  </td>
                </tr>
                <tr>
                  <td width="*" class="text-start pb-2">
                    <small>Diskon (%)</small><br />
                    <span style="color:{daylight ? '#00A86B' : '#fc0'}"
                      >{rec.bet_diskon.toFixed(2)}</span
                    >
                  </td>
                  <td width="50%" class="text-end pb-2">
                    <small>KEI (%)</small><br />
                    <span style="color:{daylight ? '#00A86B' : '#fc0'}"
                      >{rec.bet_kei.toFixed(2)}</span
                    >
                  </td>
                </tr>
                <tr>
                  <td width="*" class="text-start pb-2">
                    <small>Bayar</small><br />
                    <span style="color:{daylight ? '#00A86B' : '#fc0'}"
                      >{new Intl.NumberFormat().format(rec.bet_bayar)}</span
                    >
                  </td>
                  <td width="50%" class="text-end pb-2">
                    <small>Hadiah</small><br />
                    <span style="color:{daylight ? '#00A86B' : '#fc0'}"
                      >{new Intl.NumberFormat().format(rec.bet_multiplier)}x
                    </span>
                  </td>
                </tr>
                <tr>
                  {#if rec.bet_status === "RUNNING"}
                    <td width="*" class="text-start">
                      <small>Estimasi Kemenangan</small><br />
                      <span style="color:{daylight ? '#00A86B' : '#fc0'}"
                        >{new Intl.NumberFormat().format(
                          rec.bet_winestimate
                        )}</span
                      >
                    </td>
                  {:else}
                    <td width="50%" class="text-start">
                      <small>Hasil</small><br />
                      <span style="color:{daylight ? '#00A86B' : '#fc0'}"
                        >{new Intl.NumberFormat().format(rec.bet_win)}</span
                      ></td
                    >
                  {/if}
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>
    {/each}
    <!-- {:else}
      <table class="table" class:table-dark={daylight === false}>
        <thead>
          <tr>
            <th
              width="1%"
              style="text-align:center;vertical-align:top;background:{daylight
                ? '#fff'
                : '#303030'};font-size:{modal_table_fontsize_header};border-bottom:none;"
              >NO</th
            >
            <th
              width="1%"
              style="text-align:center;vertical-align:top;background:{daylight
                ? '#fff'
                : '#303030'};font-size:{modal_table_fontsize_header};border-bottom:none;"
              >STATUS</th
            >
            <th
              width="20%"
              style="text-align:center;vertical-align:top;background:{daylight
                ? '#fff'
                : '#303030'};font-size:{modal_table_fontsize_header};border-bottom:none;"
              >TIPE</th
            >
            <th
              width="20%"
              style="text-align:center;vertical-align:top;background:{daylight
                ? '#fff'
                : '#303030'};font-size:{modal_table_fontsize_header};border-bottom:none;"
              >PERMAINAN</th
            >
            <th
              width="*"
              style="text-align:center;vertical-align:top;background:{daylight
                ? '#fff'
                : '#303030'};font-size:{modal_table_fontsize_header};border-bottom:none;"
              >NOMOR</th
            >
            <th
              width="10%"
              style="text-align:right;vertical-align:top;background:{daylight
                ? '#fff'
                : '#303030'};font-size:{modal_table_fontsize_header};border-bottom:none;"
              >BET</th
            >
            <th
              width="10%"
              style="text-align:right;vertical-align:top;background:{daylight
                ? '#fff'
                : '#303030'};font-size:{modal_table_fontsize_header};border-bottom:none;"
              >DISC(%)</th
            >
            <th
              width="10%"
              style="text-align:right;vertical-align:top;background:{daylight
                ? '#fff'
                : '#303030'};font-size:{modal_table_fontsize_header};border-bottom:none;"
              >KEI(%)</th
            >
            <th
              width="10%"
              style="text-align:right;vertical-align:top;background:{daylight
                ? '#fff'
                : '#303030'};font-size:{modal_table_fontsize_header};border-bottom:none;"
              >BAYAR</th
            >
            <th
              width="10%"
              style="text-align:right;vertical-align:top;background:{daylight
                ? '#fff'
                : '#303030'};font-size:{modal_table_fontsize_header};border-bottom:none;"
              >HADIAH</th
            >
            <th
              width="10%"
              style="text-align:right;vertical-align:top;background:{daylight
                ? '#fff'
                : '#303030'};font-size:{modal_table_fontsize_header};border-bottom:none;"
              >WIN</th
            >
          </tr>
        </thead>
        <tbody>
          {#each listhasilinvoicebet as rec}
            <tr>
              <td
                NOWRAP
                style="text-align:center;vertical-align:top;font-size:{modal_table_fontsize_body};"
                >{rec.bet_no}</td
              >
              <td
                NOWRAP
                style="text-align:center;vertical-align:top;font-size:{modal_table_fontsize_body};{rec.bet_background}"
                >{rec.bet_status}</td
              >
              <td
                NOWRAP
                style="text-align:center;vertical-align:top;font-size:{modal_table_fontsize_body};"
                >{rec.bet_tipe}</td
              >
              <td
                NOWRAP
                style="text-align:center;vertical-align:top;font-size:{modal_table_fontsize_body};"
                >{rec.bet_permainan}</td
              >
              <td
                NOWRAP
                style="text-align:center;vertical-align:top;font-size:{modal_table_fontsize_body};"
                >{rec.bet_nomor}</td
              >
              <td
                NOWRAP
                style="text-align:right;vertical-align:top;color:{daylight
                  ? '#00A86B'
                  : '#fc0'};font-size:{modal_table_fontsize_body};"
              >
                {new Intl.NumberFormat().format(rec.bet_bet)}
              </td>
              <td
                NOWRAP
                style="text-align:right;vertical-align:top;color:{daylight
                  ? '#00A86B'
                  : '#fc0'};font-size:{modal_table_fontsize_body};"
              >
                {rec.bet_diskon.toFixed(2)}
              </td>
              <td
                NOWRAP
                style="text-align:right;vertical-align:top;color:{daylight
                  ? '#00A86B'
                  : '#fc0'};font-size:{modal_table_fontsize_body};"
              >
                {rec.bet_kei.toFixed(2)}
              </td>
              <td
                NOWRAP
                style="text-align:right;vertical-align:top;color:{daylight
                  ? '#00A86B'
                  : '#fc0'};font-size:{modal_table_fontsize_body};"
              >
                {new Intl.NumberFormat().format(rec.bet_bayar)}
              </td>
              <td
                NOWRAP
                style="text-align:right;vertical-align:top;color:{daylight
                  ? '#00A86B'
                  : '#fc0'};font-size:{modal_table_fontsize_body};"
              >
                {new Intl.NumberFormat().format(rec.bet_multiplier)}x
              </td>
              <td
                NOWRAP
                style="text-align:right;vertical-align:top;color:{daylight
                  ? '#00A86B'
                  : '#fc0'};font-size:{modal_table_fontsize_body};"
              >
                {new Intl.NumberFormat().format(rec.bet_win)}
              </td>
            </tr>
          {/each}
        </tbody>
      </table>
    {/if} -->
  </slot:template>
</Modal>

<style>
  .btn.btn-play {
    color: #fff;
    border-color: transparent;
    border-radius: 15px;
    margin: 0 0.5rem 0;
    width: 122.19px;
    background: linear-gradient(180deg, #007741, #00a86b);
  }

  .btn.btn-play.mobile {
    font-size: 11px;
    padding: 1rem;
  }
  .btn.btn-play.dark {
    color: #fff;
    border-color: transparent;
  }

  .btn.btn-play:hover {
    color: #fff;
    background: #00a86b;
    border-color: #00a86b;
  }
  .mobile .btn.btn-play {
    width: 100% !important;
  }

  button.nav-link {
    border: 1px solid #8d8d8d;
    margin: 0 5px;
  }

  .user-detail {
    font-size: 15px;
    color: #171717;
  }
  .user-detail.mobile {
    font-size: 1rem;
  }
  .user-detail.dark {
    color: #fff;
  }

  .modal-title {
    font-size: 16px;
    line-height: 12px;
    font-family: "Poppins";
    font-weight: 700;
  }
  .accordion-body {
    padding: 0.5em;
    font-size: 1.2em;
    font-weight: bold;
    color: #171717;
  }
  @media (min-width: 768px) {
  }
</style>
