<script>
  import { Button, Icon } from "sveltestrap";
  import { createEventDispatcher } from "svelte";
  import PanelFull from "../components/Panelfull.svelte";
  import Modal from "../components/Modalfull2.svelte";
  import Lineinfo from "../components/Keranjang.svelte";
  import KeranjangHeader from "../components/KeranjangHeader.svelte";
  import ButtonCart from "../components/KeranjangButton.svelte";
  import FillCart from "../components/KeranjangDetail.svelte";

  export let keranjang = [];
  export let client_device = "";
  export let totalkeranjang = 0;
  export let count_line_dasar = 0;
  export let count_line_standart = 0;
  export let min_bet = 0;
  export let max_bet = 0;
  export let win_bet = 0;
  export let diskon_bet = 0;
  export let daylight;
  export let count_line_shio = 0;
  export let betHistoryView;
  export let agent_home_url;
  export let portrait_mode;

  let dispatch = createEventDispatcher();
  let mobile = false;

  function handleRemoveKeranjang(e) {
    dispatch("removekeranjang", {
      idkeranjang: e.detail.idkeranjang,
      bayar: e.detail.bayar,
      nomor: e.detail.nomor,
    });
  }
  const handleRemoveKeranjang_all = () => {
    dispatch("removekeranjangall", "all");
  };
  const handleSave = () => {
    dispatch("handleSave", "save");
  };
  const handleBetHistoryView = () => {
    betHistoryView = !betHistoryView;
  };
  let open_informasi = false;
  let fullscreen = "";
  const toggle = () => {
    fullscreen = "xl";
    open_informasi = !open_informasi;
  };
  $: count_line_shio = count_line_dasar + count_line_standart;

  $: {
    if (client_device !== "WEBSITE") {
      mobile = true;
    }
  }
</script>

<PanelFull
  header={true}
  footer={true}
  body_style="padding:0px;background:{daylight
    ? '#fff'
    : '#181717'};height:450px;"
  {daylight}
>
  <slot:template slot="header">
    <div class="container px-0">
      <div class="row">
        <div class="col-md">
          <KeranjangHeader {daylight} {totalkeranjang} />
        </div>
        <ButtonCart
          {mobile}
          {agent_home_url}
          {portrait_mode}
          on:handleRemoveKeranjang_all={handleRemoveKeranjang_all}
          on:handleBetHistoryView={handleBetHistoryView}
          on:handleSave={handleSave}
        />
      </div>
    </div>
  </slot:template>
  <slot:template slot="body">
    <FillCart
      {mobile}
      {daylight}
      {keranjang}
      on:handleRemoveKeranjang={handleRemoveKeranjang}
    />
  </slot:template>
  <slot:template slot="footer">
    <Lineinfo {daylight} count_line={count_line_shio} />
  </slot:template>
</PanelFull>

<Modal
  modal_id={"modalInformasi"}
  modal_footer_flag={false}
  modal_body_height={"height:500px;"}
  modal_size={"modal-dialog-centered"}
  {daylight}
>
  <slot:template slot="header">
    <h5 class="modal-title">SHIO</h5>
  </slot:template>
  <slot:template slot="body">
    <table class="table table-dark table-sm">
      <tbody>
        <tr>
          <td
            style="background:#303030;border:1px solid #282828;font-size:12px;"
            >MIN BET</td
          >
          <td
            style="border:1px solid #282828;text-align:right;font-size:12px;color:#ffd292;"
            >{new Intl.NumberFormat().format(min_bet)}</td
          >
        </tr>
        <tr>
          <td
            style="background:#303030;border:1px solid #282828;font-size:12px;"
            >MAX BET</td
          >
          <td
            style="border:1px solid #282828;text-align:right;font-size:12px;color:#ffd292;"
            >{new Intl.NumberFormat().format(max_bet)}</td
          >
        </tr>
        <tr>
          <td
            style="background:#303030;border:1px solid #282828;font-size:12px;"
            >DISKON</td
          >
          <td
            style="border:1px solid #282828;text-align:right;font-size:12px;color:#ffd292;"
          >
            {(diskon_bet * 100).toFixed(1)}%
          </td>
        </tr>
        <tr>
          <td
            style="background:#303030;border:1px solid #282828;font-size:12px;"
            >HADIAH</td
          >
          <td
            style="border:1px solid #282828;text-align:right;font-size:12px;color:#ffd292;"
          >
            {win_bet.toFixed(1)}X
          </td>
        </tr>
      </tbody>
    </table>
    <p
      style="font-size:13px;padding: 5px;color:{daylight
        ? '#171717'
        : 'white'};"
    >
      <b>CARA BERMAIN</b>
      <br />
      Menebak SHIO dari posisi 2D, SHIO merupakan 12 lambang kelahiran dalam penanggalan
      China. Dalam permainan ini, setiap lambang diwakili dengan satu nomor.
      <br />
      Struktur ABCD<br /><br />

      Analisis I :<br />
      Keluar : 4321<br />
      Permainan ini hanya memperhatikan posisi 2D, berarti yang dipedomanin = 21<br
      />
      Hasil = 21-12 = 9 (shio disesuaikan dengan tabel diatas)<br />
      catatan: nilai yang dikurangi merupakan kelipatan 12.<br /><br />

      Jika dilakukan pembelian dengan 100rb dan menang maka:<br />
      Menang = 100rb + [Indeks kemenangan untuk SHIO]<br /><br />

      NB: Indeks menang dan diskon dapat dilihat di bagian Peraturan
    </p>
  </slot:template>
</Modal>
