<script lang="ts">
  import { Row, Container } from "sveltestrap";
  import { writable } from "svelte/store";
  import { excryptData } from "./lib/encrypter";
  import Home from "./pages/Home.svelte";
  import Permainan from "./pages/Permainan.svelte";
  import Preloader from "./components/Preloader.svelte";
  import Landscape from "./components/LandscapeWarning.svelte";
  import Loader from "./components/Loader.svelte";
  import dayjs from "dayjs";
  import utc from "dayjs/plugin/utc";
  import timezone from "dayjs/plugin/timezone";
  import Swal from "sweetalert2";
  import Footer from "./components/Footer.svelte";
  import GTM from "./components/GTM.svelte";
  import GTA from "./components/GTA.svelte";
  import CAPTCHA from "./components/CAPTCHA.svelte";

  dayjs.extend(utc);
  dayjs.extend(timezone);

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const accesskey = urlParams.get("poipetbeb");
  const testuser = urlParams.get("playername");
  const testagent = urlParams.get("testagent");
  var d = new Date();
  var now = dayjs(d).tz("Asia/Jakarta").unix();
  let bundletimer = `build/bundle.js?time=${now}`;

  const referrer = document.referrer;
  // if (!referrer) {
  //   if (!accesskey) {
  //     let message_err = "Please login before continue!";
  //     Swal.fire({
  //       icon: "error",
  //       title: "Oops...",
  //       text: message_err,
  //       heightAuto: false,
  //       allowOutsideClick: false,
  //       allowEscapeKey: false,
  //       allowEnterKey: false,
  //     });
  //     throw new Error(message_err);
  //   }
  // }
  const token_key = sessionStorage.getItem("tokenkey");
  var reloded = sessionStorage.getItem("generated");

  const clientToken = urlParams.get("token");
  if (token_key != clientToken) {
    if (clientToken) {
      sessionStorage.removeItem("generated");
      reloded = "false";
    }
  }
  if ((token_key === null || token_key != clientToken) && reloded != "true") {
    const clientAgentCode = urlParams.get("agent");
    const clientMarketCode = urlParams.get("market");
    const clientAgentHome = urlParams.get("homeUrl");
    const encryptdata = {
      clientToken,
      clientAgentCode,
      clientMarketCode,
      clientAgentHome,
    };
    const token_name = writable(encryptdata);
    token_name.subscribe((val) => {
      if (clientToken) {
        sessionStorage.setItem("tokenkey", val.clientToken);
      }
      if (clientAgentCode) {
        sessionStorage.setItem("agent_code", val.clientAgentCode);
      }
      if (clientMarketCode) {
        sessionStorage.setItem("market_code", val.clientMarketCode);
      }
      if (clientAgentHome) {
        sessionStorage.setItem("agent_home", val.clientAgentHome);
      }
      sessionStorage.setItem("timestamp", String(now));
      if (testuser) {
        sessionStorage.setItem("username", testuser);
      }
    });
    sessionStorage.setItem("generated", "true");
    const encrpty = excryptData(JSON.stringify(encryptdata));
    location.href = `/?session=${encrpty}`;
  }
  const token_browser = token_key;
  const agentCode = sessionStorage.getItem("agent_code");
  const marketCode = sessionStorage.getItem("market_code");
  const agent_home = sessionStorage.getItem("agent_home");
  const time = Number(sessionStorage.getItem("timestamp"));

  var expired = dayjs(time * 1000)
    .tz("Asia/Jakarta")
    .add(1, "hour")
    .unix();

  if (now > expired) {
    if (!accesskey) {
      let message_err = "your session has expired!";
      let home_location = agent_home;
      if (agent_home == null) {
        home_location = "/";
      }
      Swal.fire({
        title: message_err,
        icon: "error",
        confirmButtonText: "Go back",
        confirmButtonColor: "#00a86b",
        heightAuto: false,
        allowOutsideClick: false,
      }).then((result) => {
        if (result.isConfirmed) {
          location.href = home_location;
        }
      });
    }
  }
  let client_device = "MOBILE";
  let mobile = false;
  let preloader = false;
  let loadDisplay = false;
  let listkeluaran = [];
  let fpOptions = {};
  let pasaran_name = "";
  let pasaran_code = "";
  let pasaran_periode = 0;
  let permainan = "4-3-2";
  let client_token = "";
  let client_company = "";
  let client_username = "";
  let client_ipaddress = "";
  let client_timezone = "";
  let client_website_status = "";
  let client_website_message = "";
  let agent_home_url = "";
  let gtmId = "";
  let gtaId = "";
  let apiKey = "";
  let captchaSiteKey = "";
  let source = "WEBSITE";

  if (
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent,
    )
  ) {
    // true for mobile device
    source = "MOBILE";
    mobile = true;
    permainan = "";
  }

  if (token_key === null && clientToken === null) {
    let message_err = "Agent not found or Token expired, Please contact admin";
    Swal.fire({
      icon: "error",
      title: "Oops...",
      text: message_err,
      heightAuto: false,
    });
  } else {
    initTimezone();
  }

  $: if (marketCode) {
    pasaran_code = marketCode;
  }

  $: if (agent_home) {
    agent_home_url = agent_home;
  }

  const pasaran = (e) => {
    pasaran_code = e.detail.code;
    pasaran_name = e.detail.name;
    pasaran_periode = e.detail.periode;
  };

  let record = "";
  let message_err = "";
  let checked = false;
  let announce_note = "";
  let balance_credit = 0;
  let portrait_mode = true;
  // let daylight = false;
  $: daylight = checked;
  $: client_credit = balance_credit;

  $: {
    if (mobile) {
      if (screen.availHeight < screen.availWidth) {
        portrait_mode = false;
      }

      window
        .matchMedia("(orientation: portrait)")
        .addEventListener("change", (e) => {
          const portrait = e.matches;

          if (portrait) {
            portrait_mode = true;
          } else {
            portrait_mode = false;
          }
        });
    }
  }
  const clickBody = () => {
    if (mobile) {
      openFullscreen();
    }
  };
  function openFullscreen() {
    let elem = document.documentElement;
    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.webkitRequestFullscreen) {
      /* Safari */
      elem.webkitRequestFullscreen();
    } else if (elem.msRequestFullscreen) {
      /* IE11 */
      elem.msRequestFullscreen();
    }
  }

  const swalWithBootstrapButtons = Swal.mixin({
    customClass: {
      confirmButton: "mx-2 rounded-2 btn btn-success",
      cancelButton: "mx-2 rounded-2 btn btn-danger",
    },
    buttonsStyling: false,
  });
  // $: callday = daylights();
  function daylights() {
    const time = new Date().getHours();
    if (time >= 6 && time < 18) {
      return true;
    }
    window.document.body.classList.toggle("dark-mode");
    return false;
  }

  async function initTimezone() {
    preloader = true;
    const res = await fetch("/api/healthz");
    if (!res.ok) {
      const message = `An error has occured: ${res.status}`;
      throw new Error(message);
    } else {
      const json = await res.json();
      client_ipaddress = json.real_ip;
      client_timezone = "Asia/Jakarta";
    }
    let callday = daylights();
    if (callday) {
      checked = true;
    }
    initapp(token_browser, agentCode);
  }
  async function initapp(token, agent_code) {
    const resInit = await fetch("/api/init", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-forwarder-for": client_ipaddress,
      },
      body: JSON.stringify({
        t: excryptData(
          JSON.stringify({
            token,
            agent_code,
          }),
        ),
      }),
    });
    if (!resInit.ok) {
      const initMessage = `An error has occured: ${resInit.status}`;
      const initJson = await resInit.json();
      const record = initJson.record;
      let validationMsg = "";
      let i = 0;
      record.forEach((el) => {
        validationMsg += `${el.Field} is ${el.Tag}, `;
      });

      validationMsg = validationMsg.substring(0, validationMsg.length - 2);

      swalWithBootstrapButtons
        .fire({
          title: "Please Correct this required field(s)",
          html: validationMsg,
          icon: "error",
          showCancelButton: false,
          confirmButtonText: "Back",
          reverseButtons: true,
          background: daylight ? "#fff" : "#171717",
          color: daylight ? "#00a86b" : "#ff9900",
        })
        .then((result) => {
          if (result.isConfirmed) {
            location.href = `${agent_home_url}`;
          } else result.dismiss === Swal.DismissReason.cancel;
          /* Read more about handling dismissals below */
        });

      // throw new Error(initMessage);
    } else {
      const initJson = await resInit.json();
      if (initJson.status === 200) {
        switch (initJson.company) {
          case "":
            // loadDisplay = true;
            message_err =
              "Agent not found or Token expired, Please contact admin";
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: message_err,
              heightAuto: false,
            });
            // notifications.push(message_err, "white", "middle");
            // setTimeout(function () {
            //   loadDisplay = false;
            // }, 5000);
            break;
          default:
            client_token = initJson.token;
            client_company = initJson.company;
            if (testagent !== null) {
              client_company = testagent;
            }
            client_username = initJson.developer;
            balance_credit = initJson.credit;
            if (testuser !== null) {
              client_username = testuser;
              balance_credit = 0;
            }
            client_website_status = initJson.website_status;
            client_website_message = initJson.website_message;
            gtmId = initJson.gtm_id;
            gtaId = initJson.gta_id;
            captchaSiteKey = initJson.site_key;
            if (apiKey == "") {
              sessionStorage.setItem("publicKey", initJson.public_key);
              sessionStorage.setItem("siteKey", initJson.site_key);
            }
            apiKey = sessionStorage.getItem("publicKey");
            fpOptions = {
              loadOptions: {
                apiKey,
              },
            };
            if (client_website_status == "OFFLINE") {
              client_token = "";
              // message_err = client_website_message;
              // css_err = "display:inline-block";
              Swal.fire({
                icon: "warning",
                title: "Sorry we are offline now",
                html: client_website_message.replace(",", "<br/>"),
                heightAuto: false,
              });
            } else {
              initPasaran();
            }
            break;
        }
        return initJson;
      }
    }
  }
  async function initPasaran() {
    const resPasar = await fetch("/api/listpasaran", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        t: excryptData(
          JSON.stringify({
            token: client_token,
            company: client_company,
            timezone: client_timezone,
          }),
        ),
      }),
    });
    if (!resPasar.ok) {
      const pasarMessage = `An error has occured: ${resPasar.status}`;
      throw new Error(pasarMessage);
    } else {
      const jsonPasar = await resPasar.json();
      if (jsonPasar.status == 200) {
        record = jsonPasar.record;
        if (record != null) {
          for (var i = 0; i < record.length; i++) {
            listkeluaran = [
              ...listkeluaran,
              {
                id: record[i]["pasaran_id"],
                pasaran_code: record[i]["pasaran_id"],
                pasaran: record[i]["pasaran_togel"],
                pasaran_periode: record[i]["pasaran_periode"],
                pasaran_tgl: dayjs(record[i]["pasaran_marketclose"])
                  .tz(client_timezone)
                  .format("DD MMM YYYY | HH:mm:ss"),
                pasaran_status: record[i]["pasaran_status"],
              },
            ];
          }
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "An error has occured, Please Contact Administrator",
            heightAuto: false,
          });
        }
      } else {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "An error has occured, Please Contact Administrator",
          heightAuto: false,
        });
      }
    }
  }
</script>

<svelte:head>
  <title>SDSB4D</title>
</svelte:head>
<Landscape {portrait_mode} />
<Preloader bind:preloader {daylight} {mobile} />
<!-- {#if apiKey != ""} -->
{#if gtmId}
  <GTM {gtmId} />
{/if}
{#if gtaId}
  <GTA {gtaId} />
{/if}
{#if captchaSiteKey}
  <CAPTCHA {captchaSiteKey} />
{/if}
<Loader {loadDisplay} {mobile} />
<div class="screen-safe-area" class:dark={daylight === false} />
{#if client_device == "WEBSITE"}
  <div class="content" class:dark={daylight === false}>
    {#if client_token != ""}
      <Container>
        {#if token_browser != ""}
          {#if pasaran_code != ""}
            <Row align-items-start>
              <Permainan
                {client_token}
                {client_company}
                {client_username}
                {client_credit}
                {client_ipaddress}
                {client_timezone}
                {client_device}
                {pasaran_code}
                {pasaran_name}
                {pasaran_periode}
                {permainan}
                bind:checked
                bind:balance_credit
                bind:announce_note
                bind:loadDisplay
                bind:preloader
                {daylight}
                {agent_home_url}
              />
            </Row>
          {:else}
            <Row align-items-start>
              <Home
                {client_token}
                {client_company}
                {client_username}
                {client_credit}
                {client_ipaddress}
                {client_timezone}
                {client_device}
                {listkeluaran}
                bind:checked
                bind:loadDisplay
                {daylight}
                on:pasaran={pasaran}
              />
            </Row>
          {/if}
        {/if}
        <Footer />
      </Container>
    {/if}
  </div>
{:else}
  <div
    class="content mobile mx-auto"
    class:dark={daylight === false}
    on:click={clickBody}
  >
    {#if client_token != ""}
      <Container>
        {#if token_browser != ""}
          <Row align-items-start>
            {#if pasaran_code != ""}
              <Permainan
                {client_token}
                {client_company}
                {client_username}
                {client_credit}
                {client_ipaddress}
                {client_timezone}
                {client_device}
                {source}
                {pasaran_code}
                {pasaran_name}
                {pasaran_periode}
                {permainan}
                bind:checked
                bind:balance_credit
                bind:loadDisplay
                bind:preloader
                {daylight}
                {agent_home_url}
                {portrait_mode}
              />
            {:else}
              <Home
                {client_token}
                {client_company}
                {client_username}
                {client_credit}
                {client_ipaddress}
                {client_timezone}
                {client_device}
                {listkeluaran}
                bind:checked
                bind:loadDisplay
                {daylight}
                on:pasaran={pasaran}
              />
            {/if}
          </Row>
        {/if}
        <Footer />
      </Container>
    {/if}
  </div>
{/if}

<!-- <FpjsProvider options={fpOptions}>
  </FpjsProvider> -->
<!-- {/if} -->

<style>
  .screen-safe-area {
    background-image: url("/bg-light.svg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    filter: blur(8px);
    -webkit-filter: blur(8px);
    height: 100%;

    position: fixed;
    display: block;
    left: 0;
    right: 0;
  }
  .screen-safe-area.dark {
    background-image: url("/bg-dark.svg");
  }
  .content {
    background-image: url("/bg-light.svg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    background-attachment: scroll;
    min-height: 100%;
    max-width: 540px;
    position: relative;
  }

  .content.dark {
    background-image: url("/bg-dark.svg");
  }

  .content.mobile,
  .content.dark.mobile {
    height: unset;
  }
</style>
