<script>
  export let portrait_mode;
</script>

<div class="rotate-sign" class:hide={portrait_mode} />

<style>
  .rotate-sign {
    background-image: url("/rotate.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    background-attachment: scroll;
    position: fixed;
    z-index: 9999;
    display: block;
    margin: auto;
    right: 0;
    left: 0;
    height: 100%;
  }

  .hide {
    display: none;
  }
</style>
