<script>
  import PanelFull from "./Panelfull.svelte";
  import { createEventDispatcher } from "svelte";
  export let totalbayar_invoice;
  export let totalbet_invoice;
  export let filterResultInvoice = [];
  export let resultinvoice = [];
  export let daylight;
  export let value;
  export let mobile;
  export let betHistoryView = false;
  export let pageResInvoiceList;
  export let totalResInvoiceList;

  let pola432 = ["4D", "3D", "3DD", "2D", "2DD", "2DT"];
  const dispatch = createEventDispatcher();
  const handleKeyboardAngkaPasangan_checkenter = (e) => {
    let keyCode = e.which || e.keyCode;
    if (keyCode === 13) {
      filterResultInvoice = [];
      resultinvoice = [];
      dispatch("searchangka", { angka: e });
    }
  };

  const handleLoadMorePageListBet = (e, f) => {
    const selectPage = {
      pageSize: e,
      pageNumber: f,
    };
    dispatch("loadmore", selectPage);
  };

  const toggle = () => (betHistoryView = !betHistoryView);
  $: {
    if (value) {
      filterResultInvoice = resultinvoice.filter((item) =>
        item.nomor.toLowerCase().includes(value.toLowerCase())
      );
    } else {
      filterResultInvoice = [...resultinvoice];
    }
  }
</script>

<PanelFull
  header={true}
  footer={true}
  header_style="padding:0px;margin:0px;"
  body_style="padding:0px;margin:0px;background: url(/bg-invoice.svg);
background-position: bottom right;
background-repeat: no-repeat;
background-size: 400px;
border:1px solid {daylight ? '#fff' : '#0e0c13'};
height:calc(100vh - 100px);
max-height:612px;"
  {daylight}
>
  <slot:template slot="header">
    <div
      class="row"
      style="padding: 20px;font-size: 14px;color: {daylight
        ? '#171717'
        : '#fff'};"
    >
      {#if mobile}
        <div class="col">
          <span
            >TOTAL BAYAR : <span class="history-font " class:dark={!daylight}>
              {new Intl.NumberFormat().format(totalbayar_invoice)}</span
            ></span
          >
        </div>
        <div class="col-2 text-end">
          <button
            type="button"
            class="btn-close custom"
            class:btn-close-white={daylight === false}
            data-bs-dismiss="modal"
            aria-label="Close"
            on:click={toggle}
          />
        </div>
      {:else}
        <div class="col">
          <span
            >BET SAYA : <span class="history-font " class:dark={!daylight}>
              {new Intl.NumberFormat().format(totalbet_invoice)}</span
            ></span
          >
        </div>
        <div class="col text-end">
          <span
            >TOTAL BAYAR : <span class="history-font " class:dark={!daylight}>
              {new Intl.NumberFormat().format(totalbayar_invoice)}</span
            ></span
          >
        </div>
      {/if}
    </div>
    <div class="row">
      <div class="col">
        <div class="form-floating p-2">
          <input
            bind:value
            on:keypress={() => {
              handleKeyboardAngkaPasangan_checkenter(value);
            }}
            style="border-radius: none;border: none; background: {daylight
              ? '#fff'
              : '#303030'} none repeat scroll 0% 0%; color: {daylight
              ? '#171717'
              : '#fff'}; font-size: 15px; "
            class="form-control button-bet-default"
            placeholder="Ketik angka pasangan kamu"
            maxlength="4"
            type="text"
            id="cariAngka"
            autocomplete="off"
          />
          <label
            for="cariAngka"
            class="form-label"
            style={daylight ? "" : "color:#fff;"}
            >Ketik angka pasangan kamu</label
          >
        </div>
      </div>
    </div>
  </slot:template>
  <slot:template slot="body">
    <div id="container">
      {#each filterResultInvoice as rec}
        <div class="card mb-2 mx-1 card-periode" class:dark={!daylight}>
          <div class="card-body {daylight ? '' : 'dark'} ">
            <div
              class="row mb-2 pb-1 border-bottom"
              class:border-success={daylight}
              class:border-warning={!daylight}
            >
              <div class="col">
                <span class="badge bg-success fs-6"
                  >{rec.permainan.replace(/_/g, " ")}</span
                >
              </div>
              <div class="col text-end">
                <span class="fs-6 fw-bold history-font" class:dark={!daylight}
                  >Rp. {new Intl.NumberFormat().format(rec.bayar)}</span
                >
              </div>
            </div>
            <div class="row">
              <div class="col">
                <table class="table table-borderless mobile">
                  <tr>
                    <td class:dark={!daylight}>
                      BET: <span class="history-font" class:dark={!daylight}>
                        Rp. {new Intl.NumberFormat().format(rec.bet)}</span
                      >
                    </td>
                    <td class="text-end" class:dark={!daylight}>
                      <span class="p-0 history-font" class:dark={!daylight}>
                        {rec.nomor.replace(/_/g, " + ")}
                        {#if pola432.includes(rec.permainan)}
                          {" (" + rec.tipemain + ")"}
                        {/if}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td class:dark={!daylight}>
                      KEI(%): <span class="history-font" class:dark={!daylight}
                        >{rec.kei.toFixed(1)}</span
                      >
                    </td>
                    <td class="text-end" class:dark={!daylight}>
                      DISC(%): <span
                        class="p-0 history-font"
                        class:dark={!daylight}>{rec.diskon.toFixed(1)}</span
                      >
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </div>
      {/each}
      {#if pageResInvoiceList < totalResInvoiceList}
        <div class="text-center">
          <!-- svelte-ignore a11y-invalid-attribute -->
          <a
            class="pe-auto"
            href="#"
            on:click={() => {
              if (pageResInvoiceList < totalResInvoiceList) {
                pageResInvoiceList++;
              }
              handleLoadMorePageListBet(100, pageResInvoiceList);
            }}>load more</a
          >
        </div>
      {/if}
    </div>
  </slot:template>
  <slot:template slot="footer" />
</PanelFull>

<style>
  .card.card-periode {
    box-shadow: 0px 1px 1px 0px #d7d7d7;
  }

  table.mobile td.dark {
    color: #fff;
  }

  .history-font {
    color: #00a86b;
    font-weight: bold;
  }

  .history-font.dark {
    color: #ff9900;
  }
</style>
