<script>
  import {
    Modal,
    ModalBody,
    ModalHeader,
    ModalFooter,
    Button,
    Row,
    Col,
    Container,
  } from "sveltestrap";
  export let open = false;
  export let toggle;
  // export let daylight = false;
  export let modalId = "defModal";
  export let loadDisplay = false;
  export let permainan;

  export const checkClick = () => {
    loadDisplay = false;
  };
</script>

<Modal
  modalClassName={modalId}
  isOpen={open}
  centered={true}
  fade={false}
  {toggle}
  backdrop="static"
>
  <ModalHeader {toggle}>Pilih Permainan</ModalHeader>
  <ModalBody>
    <Container class="text-center">
      <Row class="mb-3">
        <Col>
          <Button
            outline
            color={"success"}
            class="border-success"
            on:click={() => {
              permainan = "4-3-2";
            }}
            on:click={toggle}
            block>4D / 3D / 2D</Button
          >
        </Col>
        <Col>
          <Button
            outline
            color={"success"}
            class="border-success"
            on:click={() => {
              permainan = "colok";
            }}
            on:click={toggle}
            block>Colok</Button
          >
        </Col>
      </Row>
      <Row class="mb-3">
        <Col>
          <Button
            outline
            color={"success"}
            class="border-success"
            on:click={() => {
              permainan = "5050";
            }}
            on:click={toggle}
            block>50 - 50</Button
          >
        </Col>
        <Col>
          <Button
            outline
            color={"success"}
            class="border-success"
            on:click={() => {
              permainan = "kombinasi";
            }}
            on:click={toggle}
            block>Macau / Kombinasi</Button
          >
        </Col>
      </Row>
      <Row class="mb-3">
        <Col>
          <Button
            outline
            color={"success"}
            class="border-success"
            on:click={() => {
              permainan = "dasar";
            }}
            on:click={toggle}
            block>Dasar</Button
          >
        </Col>
        <Col>
          <Button
            outline
            color={"success"}
            class="border-success"
            on:click={() => {
              permainan = "shio";
            }}
            on:click={toggle}
            block>Shio</Button
          >
        </Col>
      </Row>
    </Container>
  </ModalBody>
</Modal>
