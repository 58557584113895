<script>
  import {
    Button,
    Card,
    CardBody,
    CardHeader,
    TabContent,
    TabPane,
    Offcanvas,
    Container,
    Row,
    Col,
  } from "sveltestrap";
  import Modal from "../components/Modal.svelte";
  import Tablekeranjang5050 from "../permainan/Tablekeranjang5050.svelte";
  import { createEventDispatcher } from "svelte";
  import { excryptData } from "../lib/encrypter";
  import { useVisitorData } from "@fingerprintjs/fingerprintjs-pro-svelte";
  import PeriodePanel from "../components/PeriodePanel.svelte";
  import Swal from "sweetalert2";
  import Fa from "svelte-fa";
  import { faDice } from "@fortawesome/free-solid-svg-icons";
  import dayjs from "dayjs";
  import utc from "dayjs/plugin/utc";
  import timezone from "dayjs/plugin/timezone";
  import BtnBet from "../components/ButtonInputBet.svelte";

  export let idcomppasaran = "";
  export let idtrxkeluaran = "";
  export let client_token = "";
  export let client_company = "";
  export let client_username = "";
  export let client_ipaddress = "";
  export let client_device = "";
  export let source = "";
  export let client_timezone = "";
  export let pasaran_code = "";
  export let pasaran_name = "";
  export let pasaran_periode = 0;
  export let permainan_title = "COLOK";
  export let daylight = false;
  export let balance_credit;
  export let betHistoryView = false;
  export let loadDisplay;
  export let agent_home_url;
  export let immediate = false;
  export let portrait_mode;

  dayjs.extend(utc);
  dayjs.extend(timezone);

  var cd = new Date();
  var cnow = dayjs(cd).tz("Asia/Jakarta").unix();

  const { getData, data, isLoading, error } = useVisitorData(
    { extendedResult: true },
    { immediate }
  );

  const swalWithBootstrapButtons = Swal.mixin({
    customClass: {
      confirmButton: "mx-2 rounded-2 btn btn-success",
      cancelButton: "mx-2 rounded-2 btn btn-danger",
    },
    buttonsStyling: false,
  });

  const Option5050Umum = [
    "",
    "BESAR",
    "KECIL",
    "GENAP",
    "GANJIL",
    "TENGAH",
    "TEPI",
  ];

  const OptionSpecial1 = ["", "AS", "KOP", "KEPALA", "EKOR"];
  const OptionSpecial2 = ["", "BESAR", "KECIL", "GENAP", "GANJIL"];
  const OptionKombinasi1 = ["", "BELAKANG", "TENGAH", "DEPAN"];
  const OptionKombinasi2 = [
    "",
    "MONO",
    "STEREO",
    "KEMBANG",
    "KEMPIS",
    "KEMBAR",
  ];

  let State = {
    idle: "idle",
    requesting: "requesting",
    success: "success",
  };
  let ctoken = "";
  let state = State.idle;

  let gametype = "";
  let keranjang = [];
  let nomor_global = 0;
  let totalkeranjang = 0;
  let group_btn_beli = false;
  let record = "";
  let temp_bulk_error = "";

  let minimal_bet = 0;
  let maxbet = 0;
  let min_bet_5050umum = 0;
  let max_bet_5050umum = 0;
  let keibesar_bet_5050umum = 0;
  let keikecil_bet_5050umum = 0;
  let keigenap_bet_5050umum = 0;
  let keiganjil_bet_5050umum = 0;
  let keitengah_bet_5050umum = 0;
  let keitepi_bet_5050umum = 0;
  let discbesar_bet_5050umum = 0;
  let disckecil_bet_5050umum = 0;
  let discgenap_bet_5050umum = 0;
  let discganjil_bet_5050umum = 0;
  let disctengah_bet_5050umum = 0;
  let disctepi_bet_5050umum = 0;
  let limittotal_bet_5050umum = 0;
  let min_bet_5050special = 0;
  let max_bet_5050special = 0;
  let keiasganjil_bet_5050special = 0;
  let keiasgenap_bet_5050special = 0;
  let keiasbesar_bet_5050special = 0;
  let keiaskecil_bet_5050special = 0;
  let keikopganjil_bet_5050special = 0;
  let keikopgenap_bet_5050special = 0;
  let keikopbesar_bet_5050special = 0;
  let keikopkecil_bet_5050special = 0;
  let keikepalaganjil_bet_5050special = 0;
  let keikepalagenap_bet_5050special = 0;
  let keikepalabesar_bet_5050special = 0;
  let keikepalakecil_bet_5050special = 0;
  let keiekorganjil_bet_5050special = 0;
  let keiekorgenap_bet_5050special = 0;
  let keiekorbesar_bet_5050special = 0;
  let keiekorkecil_bet_5050special = 0;
  let discasganjil_bet_5050special = 0;
  let discasgenap_bet_5050special = 0;
  let discasbesar_bet_5050special = 0;
  let discaskecil_bet_5050special = 0;
  let disckopganjil_bet_5050special = 0;
  let disckopgenap_bet_5050special = 0;
  let disckopbesar_bet_5050special = 0;
  let disckopkecil_bet_5050special = 0;
  let disckepalaganjil_bet_5050special = 0;
  let disckepalagenap_bet_5050special = 0;
  let disckepalabesar_bet_5050special = 0;
  let disckepalakecil_bet_5050special = 0;
  let discekorganjil_bet_5050special = 0;
  let discekorgenap_bet_5050special = 0;
  let discekorbesar_bet_5050special = 0;
  let discekorkecil_bet_5050special = 0;
  let limittotal_bet_5050special = 0;
  let min_bet_5050kombinasi = 0;
  let max_bet_5050kombinasi = 0;
  let kei_belakangmono_bet_5050kombinasi = 0;
  let kei_belakangstereo_bet_5050kombinasi = 0;
  let kei_belakangkembang_bet_5050kombinasi = 0;
  let kei_belakangkempis_bet_5050kombinasi = 0;
  let kei_belakangkembar_bet_5050kombinasi = 0;
  let kei_tengahmono_bet_5050kombinasi = 0;
  let kei_tengahstereo_bet_5050kombinasi = 0;
  let kei_tengahkembang_bet_5050kombinasi = 0;
  let kei_tengahkempis_bet_5050kombinasi = 0;
  let kei_tengahkembar_bet_5050kombinasi = 0;
  let kei_depanmono_bet_5050kombinasi = 0;
  let kei_depanstereo_bet_5050kombinasi = 0;
  let kei_depankembang_bet_5050kombinasi = 0;
  let kei_depankempis_bet_5050kombinasi = 0;
  let kei_depankembar_bet_5050kombinasi = 0;
  let disc_belakangmono_bet_5050kombinasi = 0;
  let disc_belakangstereo_bet_5050kombinasi = 0;
  let disc_belakangkembang_bet_5050kombinasi = 0;
  let disc_belakangkempis_bet_5050kombinasi = 0;
  let disc_belakangkembar_bet_5050kombinasi = 0;
  let disc_tengahmono_bet_5050kombinasi = 0;
  let disc_tengahstereo_bet_5050kombinasi = 0;
  let disc_tengahkembang_bet_5050kombinasi = 0;
  let disc_tengahkempis_bet_5050kombinasi = 0;
  let disc_tengahkembar_bet_5050kombinasi = 0;
  let disc_depanmono_bet_5050kombinasi = 0;
  let disc_depanstereo_bet_5050kombinasi = 0;
  let disc_depankembang_bet_5050kombinasi = 0;
  let disc_depankempis_bet_5050kombinasi = 0;
  let disc_depankembar_bet_5050kombinasi = 0;
  let limittotal_bet_5050kombinasi = 0;

  let count_line_5050umum = 0;
  let count_line_5050special = 0;
  let count_line_5050kombinasi = 0;

  let db_form5050_umum_count_temp = 0;
  let db_form5050_special_count_temp = 0;
  let db_form5050_kombinasi_count_temp = 0;
  let flag_fulldiskon = "DISC";
  let dispatch = createEventDispatcher();
  let bottomOpen = false;
  const toggleBottom = () => {
    bottomOpen = !bottomOpen;
  };
  let canvasHeigth = 70;

  function doRecaptcha() {
    let captchaKey = sessionStorage.getItem("siteKey");
    return new Promise((resolve, reject) => {
      grecaptcha.ready(function () {
        grecaptcha.execute(captchaKey, { action: "submit" }).then(function (t) {
          state = State.success;
          resolve(t);
        });
      });
    });
  }

  async function inittogel_432d(e) {
    loadDisplay = true;
    const res = await fetch("/api/inittogel_432d", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        t: excryptData(
          JSON.stringify({
            company: client_company.toUpperCase(),
            pasaran_code: pasaran_code,
            permainan: e,
          })
        ),
      }),
    });
    group_btn_beli = true;
    const json = await res.json();
    record = json.record;
    loadDisplay = false;
    for (var i = 0; i < record.length; i++) {
      min_bet_5050umum = parseInt(record[i]["min_bet_5050umum"]);
      max_bet_5050umum = parseInt(record[i]["max_bet_5050umum"]);
      keibesar_bet_5050umum = parseFloat(record[i]["keibesar_bet_5050umum"]);
      keikecil_bet_5050umum = parseFloat(record[i]["keikecil_bet_5050umum"]);
      keigenap_bet_5050umum = parseFloat(record[i]["keigenap_bet_5050umum"]);
      keiganjil_bet_5050umum = parseFloat(record[i]["keiganjil_bet_5050umum"]);
      keitengah_bet_5050umum = parseFloat(record[i]["keitengah_bet_5050umum"]);
      keitepi_bet_5050umum = parseFloat(record[i]["keitepi_bet_5050umum"]);
      discbesar_bet_5050umum = parseFloat(record[i]["discbesar_bet_5050umum"]);
      disckecil_bet_5050umum = parseFloat(record[i]["disckecil_bet_5050umum"]);
      discgenap_bet_5050umum = parseFloat(record[i]["discgenap_bet_5050umum"]);
      discganjil_bet_5050umum = parseFloat(
        record[i]["discganjil_bet_5050umum"]
      );
      disctengah_bet_5050umum = parseFloat(
        record[i]["disctengah_bet_5050umum"]
      );
      disctepi_bet_5050umum = parseFloat(record[i]["disctepi_bet_5050umum"]);
      limittotal_bet_5050umum = parseFloat(
        record[i]["limittotal_bet_5050umum"]
      );
      min_bet_5050special = parseFloat(record[i]["min_bet_5050special"]);
      max_bet_5050special = parseFloat(record[i]["max_bet_5050special"]);
      keiasganjil_bet_5050special = parseFloat(
        record[i]["keiasganjil_bet_5050special"]
      );
      keiasgenap_bet_5050special = parseFloat(
        record[i]["keiasgenap_bet_5050special"]
      );
      keiasbesar_bet_5050special = parseFloat(
        record[i]["keiasbesar_bet_5050special"]
      );
      keiaskecil_bet_5050special = parseFloat(
        record[i]["keiaskecil_bet_5050special"]
      );
      keikopganjil_bet_5050special = parseFloat(
        record[i]["keikopganjil_bet_5050special"]
      );
      keikopgenap_bet_5050special = parseFloat(
        record[i]["keikopgenap_bet_5050special"]
      );
      keikopbesar_bet_5050special = parseFloat(
        record[i]["keikopbesar_bet_5050special"]
      );
      keikopkecil_bet_5050special = parseFloat(
        record[i]["keikopkecil_bet_5050special"]
      );
      keikepalaganjil_bet_5050special = parseFloat(
        record[i]["keikepalaganjil_bet_5050special"]
      );
      keikepalagenap_bet_5050special = parseFloat(
        record[i]["keikepalagenap_bet_5050special"]
      );
      keikepalabesar_bet_5050special = parseFloat(
        record[i]["keikepalabesar_bet_5050special"]
      );
      keikepalakecil_bet_5050special = parseFloat(
        record[i]["keikepalakecil_bet_5050special"]
      );
      keiekorganjil_bet_5050special = parseFloat(
        record[i]["keiekorganjil_bet_5050special"]
      );
      keiekorgenap_bet_5050special = parseFloat(
        record[i]["keiekorgenap_bet_5050special"]
      );
      keiekorbesar_bet_5050special = parseFloat(
        record[i]["keiekorbesar_bet_5050special"]
      );
      keiekorkecil_bet_5050special = parseFloat(
        record[i]["keiekorkecil_bet_5050special"]
      );
      discasganjil_bet_5050special = parseFloat(
        record[i]["discasganjil_bet_5050special"]
      );
      discasgenap_bet_5050special = parseFloat(
        record[i]["discasgenap_bet_5050special"]
      );
      discasbesar_bet_5050special = parseFloat(
        record[i]["discasbesar_bet_5050special"]
      );
      discaskecil_bet_5050special = parseFloat(
        record[i]["discaskecil_bet_5050special"]
      );
      disckopganjil_bet_5050special = parseFloat(
        record[i]["disckopganjil_bet_5050special"]
      );
      disckopgenap_bet_5050special = parseFloat(
        record[i]["disckopgenap_bet_5050special"]
      );
      disckopbesar_bet_5050special = parseFloat(
        record[i]["disckopbesar_bet_5050special"]
      );
      disckopkecil_bet_5050special = parseFloat(
        record[i]["disckopkecil_bet_5050special"]
      );
      disckepalaganjil_bet_5050special = parseFloat(
        record[i]["disckepalaganjil_bet_5050special"]
      );
      disckepalagenap_bet_5050special = parseFloat(
        record[i]["disckepalagenap_bet_5050special"]
      );
      disckepalabesar_bet_5050special = parseFloat(
        record[i]["disckepalabesar_bet_5050special"]
      );
      disckepalakecil_bet_5050special = parseFloat(
        record[i]["disckepalakecil_bet_5050special"]
      );
      discekorganjil_bet_5050special = parseFloat(
        record[i]["discekorganjil_bet_5050special"]
      );
      discekorgenap_bet_5050special = parseFloat(
        record[i]["discekorgenap_bet_5050special"]
      );
      discekorbesar_bet_5050special = parseFloat(
        record[i]["discekorbesar_bet_5050special"]
      );
      discekorkecil_bet_5050special = parseFloat(
        record[i]["discekorkecil_bet_5050special"]
      );
      limittotal_bet_5050special = parseFloat(
        record[i]["limittotal_bet_5050special"]
      );
      min_bet_5050kombinasi = parseFloat(record[i]["min_bet_5050kombinasi"]);
      max_bet_5050kombinasi = parseFloat(record[i]["max_bet_5050kombinasi"]);
      kei_belakangmono_bet_5050kombinasi = parseFloat(
        record[i]["kei_belakangmono_bet_5050kombinasi"]
      );
      kei_belakangstereo_bet_5050kombinasi = parseFloat(
        record[i]["kei_belakangstereo_bet_5050kombinasi"]
      );
      kei_belakangkembang_bet_5050kombinasi = parseFloat(
        record[i]["kei_belakangkembang_bet_5050kombinasi"]
      );
      kei_belakangkempis_bet_5050kombinasi = parseFloat(
        record[i]["kei_belakangkempis_bet_5050kombinasi"]
      );
      kei_belakangkembar_bet_5050kombinasi = parseFloat(
        record[i]["kei_belakangkembar_bet_5050kombinasi"]
      );
      kei_tengahmono_bet_5050kombinasi = parseFloat(
        record[i]["kei_tengahmono_bet_5050kombinasi"]
      );
      kei_tengahstereo_bet_5050kombinasi = parseFloat(
        record[i]["kei_tengahstereo_bet_5050kombinasi"]
      );
      kei_tengahkembang_bet_5050kombinasi = parseFloat(
        record[i]["kei_tengahkembang_bet_5050kombinasi"]
      );
      kei_tengahkempis_bet_5050kombinasi = parseFloat(
        record[i]["kei_tengahkempis_bet_5050kombinasi"]
      );
      kei_tengahkembar_bet_5050kombinasi = parseFloat(
        record[i]["kei_tengahkembar_bet_5050kombinasi"]
      );
      kei_depanmono_bet_5050kombinasi = parseFloat(
        record[i]["kei_depanmono_bet_5050kombinasi"]
      );
      kei_depanstereo_bet_5050kombinasi = parseFloat(
        record[i]["kei_depanstereo_bet_5050kombinasi"]
      );
      kei_depankembang_bet_5050kombinasi = parseFloat(
        record[i]["kei_depankembang_bet_5050kombinasi"]
      );
      kei_depankempis_bet_5050kombinasi = parseFloat(
        record[i]["kei_depankempis_bet_5050kombinasi"]
      );
      kei_depankembar_bet_5050kombinasi = parseFloat(
        record[i]["kei_depankembar_bet_5050kombinasi"]
      );
      disc_belakangmono_bet_5050kombinasi = parseFloat(
        record[i]["disc_belakangmono_bet_5050kombinasi"]
      );
      disc_belakangstereo_bet_5050kombinasi = parseFloat(
        record[i]["disc_belakangstereo_bet_5050kombinasi"]
      );
      disc_belakangkembang_bet_5050kombinasi = parseFloat(
        record[i]["disc_belakangkembang_bet_5050kombinasi"]
      );
      disc_belakangkempis_bet_5050kombinasi = parseFloat(
        record[i]["disc_belakangkempis_bet_5050kombinasi"]
      );
      disc_belakangkembar_bet_5050kombinasi = parseFloat(
        record[i]["disc_belakangkembar_bet_5050kombinasi"]
      );
      disc_tengahmono_bet_5050kombinasi = parseFloat(
        record[i]["disc_tengahmono_bet_5050kombinasi"]
      );
      disc_tengahstereo_bet_5050kombinasi = parseFloat(
        record[i]["disc_tengahstereo_bet_5050kombinasi"]
      );
      disc_tengahkembang_bet_5050kombinasi = parseFloat(
        record[i]["disc_tengahkembang_bet_5050kombinasi"]
      );
      disc_tengahkempis_bet_5050kombinasi = parseFloat(
        record[i]["disc_tengahkempis_bet_5050kombinasi"]
      );
      disc_tengahkembar_bet_5050kombinasi = parseFloat(
        record[i]["disc_tengahkembar_bet_5050kombinasi"]
      );
      disc_depanmono_bet_5050kombinasi = parseFloat(
        record[i]["disc_depanmono_bet_5050kombinasi"]
      );
      disc_depanstereo_bet_5050kombinasi = parseFloat(
        record[i]["disc_depanstereo_bet_5050kombinasi"]
      );
      disc_depankembang_bet_5050kombinasi = parseFloat(
        record[i]["disc_depankembang_bet_5050kombinasi"]
      );
      disc_depankempis_bet_5050kombinasi = parseFloat(
        record[i]["disc_depankempis_bet_5050kombinasi"]
      );
      disc_depankembar_bet_5050kombinasi = parseFloat(
        record[i]["disc_depankembar_bet_5050kombinasi"]
      );
      limittotal_bet_5050kombinasi = parseFloat(
        record[i]["limittotal_bet_5050kombinasi"]
      );
    }
  }
  async function savetransaksi() {
    ctoken = await doRecaptcha();
    group_btn_beli = false;
    const res = await fetch("/api/save/transaksi", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        t: excryptData(
          JSON.stringify({
            pasaran_idtransaction: idtrxkeluaran,
            pasaran_idcomp: idcomppasaran,
            pasaran_code: pasaran_code,
            pasaran_periode: pasaran_periode,
            token: client_token,
            company: client_company,
            username: client_username,
            ipaddress: client_ipaddress,
            devicemember: source,
            timezone: client_timezone,
            total: totalkeranjang,
            data: keranjang,
          })
        ),
        remoteip: client_ipaddress,
        token: ctoken,
      }),
    });
    const json = await res.json();
    if (json.status == "200") {
      loadDisplay = false;
      balance_credit = json.balance;
      Swal.fire({
        position: "center",
        icon: "success",
        title: "Tiket Anda sedang di proses",
        html:
          "<p>Mohon menunggu beberapa saat.</p> <br/>" +
          "Total taruhan : " +
          new Intl.NumberFormat().format(json.totalbet),
        showConfirmButton: false,
        timer: 5000,
        background: daylight ? "#fff" : "#171717",
        color: daylight ? "#00a86b" : "#ff9900",
        toast: true,
      });
      dispatch("handleInvoice", "call");
      reset();
    } else {
      loadDisplay = false;
      if (json.status == "500" || json.status == "404") {
        group_btn_beli = true;
        Swal.fire({
          position: "center",
          icon: "error",
          title: json.message,
          showConfirmButton: false,
          timer: 3000,
          background: daylight ? "#fff" : "#171717",
          color: daylight ? "#00a86b" : "#ff9900",
          toast: true,
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          html: json.message,
          background: daylight ? "#fff" : "#171717",
          color: daylight ? "#00a86b" : "#ff9900",
        });
      }
    }
    loadDisplay = false;
    sessionStorage.removeItem("5050captcha");
    ctoken = "";
  }

  function reset() {
    keranjang = [];
    group_btn_beli = true;
    totalkeranjang = 0;
    count_line_5050umum = 0;
    count_line_5050special = 0;
    count_line_5050kombinasi = 0;
    inittogel_432d("5050");
  }
  inittogel_432d("5050");
  function addKeranjang(
    nomor,
    game,
    bet,
    diskon_percen,
    diskon,
    bayar,
    win,
    kei_percen,
    kei,
    tipetoto
  ) {
    let total_data = keranjang.length;
    let flag_data = false;
    temp_bulk_error = "";
    for (var i = 0; i < total_data; i++) {
      switch (game) {
        case "50_50_UMUM":
          if (nomor == keranjang[i].nomor.toString()) {
            let maxtotal_bayar5050umum = 0;
            for (var j = 0; j < keranjang.length; j++) {
              if ("50_50_UMUM" == keranjang[j].permainan) {
                if (nomor == keranjang[j].nomor) {
                  maxtotal_bayar5050umum =
                    parseInt(maxtotal_bayar5050umum) +
                    parseInt(keranjang[j].bet);
                }
              }
            }
            if (
              parseInt(limittotal_bet_5050umum) <
              parseInt(maxtotal_bayar5050umum) + parseInt(bet)
            ) {
              temp_bulk_error =
                "Nomor ini : " +
                nomor +
                " sudah melebihi LIMIT TOTAL 50-50 UMUM<br />";
              flag_data = true;
            }
          }
          break;
        case "50_50_SPECIAL":
          if (nomor == keranjang[i].nomor.toString()) {
            let maxtotal_bayar5050special = 0;
            for (var j = 0; j < keranjang.length; j++) {
              if ("50_50_SPECIAL" == keranjang[j].permainan) {
                if (nomor == keranjang[j].nomor) {
                  maxtotal_bayar5050special =
                    parseInt(maxtotal_bayar5050special) +
                    parseInt(keranjang[j].bet);
                }
              }
            }
            if (
              parseInt(limittotal_bet_5050special) <
              parseInt(maxtotal_bayar5050special) + parseInt(bet)
            ) {
              temp_bulk_error =
                "Nomor ini : " +
                nomor +
                " sudah melebihi LIMIT TOTAL 50-50 SPECIAL<br />";
              flag_data = true;
            }
          }
          break;
        case "50_50_KOMBINASI":
          if (nomor == keranjang[i].nomor.toString()) {
            let maxtotal_bayar5050kombinasi = 0;
            for (var j = 0; j < keranjang.length; j++) {
              if ("50_50_KOMBINASI" == keranjang[j].permainan) {
                if (nomor == keranjang[j].nomor) {
                  maxtotal_bayar5050kombinasi =
                    parseInt(maxtotal_bayar5050kombinasi) +
                    parseInt(keranjang[j].bet);
                }
              }
            }
            if (
              parseInt(limittotal_bet_5050kombinasi) <
              parseInt(maxtotal_bayar5050kombinasi) + parseInt(bet)
            ) {
              temp_bulk_error =
                "Nomor ini : " +
                nomor +
                " sudah melebihi LIMIT TOTAL 50-50 KOMBINASI<br />";
              flag_data = true;
            }
          }
          break;
      }
    }

    if (flag_data == false) {
      nomor_global = nomor_global + 1;
      const data = {
        id: nomor_global,
        nomor,
        permainan: game,
        bet,
        diskon,
        diskonpercen: diskon_percen,
        bayar,
        win,
        kei,
        kei_percen,
        tipetoto,
      };
      keranjang = [data, ...keranjang];
      count_keranjang();
    } else {
      totalkeranjang = totalkeranjang - bayar;
    }
  }
  const removekeranjang = (e) => {
    swalWithBootstrapButtons
      .fire({
        title:
          "Anda akan menghapus nomor pasangan : <strong>" +
          e.detail.nomor +
          "</strong>",
        html: "<h5>Apakah anda ingin melanjutkan?</h5>",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Ya, hapus sekarang!",
        cancelButtonText: "Tidak!",
        reverseButtons: true,
        background: daylight ? "#fff" : "#171717",
        color: daylight ? "#00a86b" : "#ff9900",
      })
      .then((result) => {
        if (result.isConfirmed) {
          keranjang = keranjang.filter(
            (keranjang) => keranjang.id != e.detail.idkeranjang
          );
          totalkeranjang = totalkeranjang - e.detail.bayar;
          count_keranjang();
        }
      });
  };
  const removekeranjangall = (e) => {
    if (keranjang.length > 0) {
      swalWithBootstrapButtons
        .fire({
          title: "Anda akan menghapus semua nomor pasangan!",
          html: "<h5>Apakah anda ingin melanjutkan?</h5>",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Ya, hapus sekarang!",
          cancelButtonText: "Tidak!",
          reverseButtons: true,
          background: daylight ? "#fff" : "#171717",
          color: daylight ? "#00a86b" : "#ff9900",
        })
        .then((result) => {
          if (result.isConfirmed) {
            reset();
            count_keranjang();
          }
        });
    } else {
      Swal.fire({
        position: "center",
        icon: "warning",
        title: "Tidak ada list transaksi",
        showConfirmButton: false,
        timer: 1500,
        background: daylight ? "#fff" : "#171717",
        color: daylight ? "#00a86b" : "#ff9900",
        toast: true,
      });
    }
  };
  const handleSave = (e) => {
    e.preventDefault();
    if (keranjang.length > 0) {
      let linkColor = daylight ? "#00a86b" : "#ff9900";
      swalWithBootstrapButtons
        .fire({
          title: "Apakah anda ingin melanjutkan?",
          html:
            "Total taruhan anda sebesar : <strong>IDR. " +
            new Intl.NumberFormat().format(totalkeranjang) +
            "</strong>",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Ya, pasang sekarang!",
          cancelButtonText: "Tidak!",
          reverseButtons: true,
          background: daylight ? "#fff" : "#171717",
          color: daylight ? "#00a86b" : "#ff9900",
          footer: `<small style="text-align:center">This site is protected by reCAPTCHA and the Google 
                <a style="color:${linkColor}"  href="https://policies.google.com/privacy" target="_blank">Privacy Policy</a> and
                <a style="color:${linkColor}"  href="https://policies.google.com/terms" target="_blank">Terms of Service</a> apply.
            </small>`,
        })
        .then((result) => {
          if (result.isConfirmed) {
            loadDisplay = true;
            savetransaksi();
          } else if (
            /* Read more about handling dismissals below */
            result.dismiss === Swal.DismissReason.cancel
          ) {
            Swal.fire({
              position: "center",
              icon: "info",
              title: "Transaksi dibatalkan",
              showConfirmButton: false,
              timer: 3000,
              background: daylight ? "#fff" : "#171717",
              color: daylight ? "#00a86b" : "#ff9900",
              toast: true,
            });
          }
        });
    } else {
      Swal.fire({
        position: "center",
        icon: "warning",
        title: "Tidak ada list transaksi",
        showConfirmButton: false,
        timer: 1500,
        background: daylight ? "#fff" : "#171717",
        color: daylight ? "#00a86b" : "#ff9900",
        toast: true,
      });
    }
  };
  function count_keranjang() {
    let count_umum = 0;
    let count_special = 0;
    let count_kombinasi = 0;
    for (let i = 0; i < keranjang.length; i++) {
      switch (keranjang[i].permainan.toString()) {
        case "50_50_UMUM":
          count_umum = count_umum + 1;
          break;
        case "50_50_SPECIAL":
          count_special = count_special + 1;
          break;
        case "50_50_KOMBINASI":
          count_kombinasi = count_kombinasi + 1;
          break;
      }
    }
    count_line_5050umum = count_umum + db_form5050_umum_count_temp;
    count_line_5050special = count_special + db_form5050_special_count_temp;
    count_line_5050kombinasi =
      count_kombinasi + db_form5050_kombinasi_count_temp;
    loadDisplay = false;
  }

  //5050 UMUM - INIT FORM
  let select_5050umum = "";
  let select_5050umum_input;
  let bet_5050umum = "";

  //5050 SPECIAL - INIT FORM
  let select_5050special_1 = "";
  let select_5050special_1_input;
  let select_5050special_2 = "";
  let select_5050special_2_input;
  let bet_5050special = "";

  //5050 KOMBINASI - INIT FORM
  let select_5050kombinasi_1 = "";
  let select_5050kombinasi_1_input;
  let select_5050kombinasi_2 = "";
  let select_5050kombinasi_2_input;
  let bet_5050kombinasi = "";

  function form_clear(e) {
    switch (e) {
      case "5050umum":
        select_5050umum = "";
        select_5050umum_input.focus();
        bet_5050umum = 0;
        break;
      case "5050special":
        select_5050special_1 = "";
        select_5050special_1_input.focus();
        select_5050special_2 = "";
        bet_5050special = 0;
        break;
      case "5050kombinasi":
        select_5050kombinasi_1 = "";
        select_5050kombinasi_1_input.focus();
        select_5050kombinasi_2 = "";
        bet_5050kombinasi = 0;
        break;
    }
  }
  function form5050_add() {
    let flag = true;
    let nomor = select_5050umum;
    let bet = bet_5050umum;
    let diskon = 0;
    let diskonpercen = 0;
    let kei = 0;
    let keipersen = 0;
    let bayar = 0;
    let win = 1;
    let nmgame = "50_50_UMUM";

    if (nomor == "") {
      select_5050umum_input.focus();
      flag = false;
    }

    if (bet == "") {
      flag = false;
      Swal.fire({
        position: "center",
        icon: "error",
        title: "Amount tidak boleh kosong",
        showConfirmButton: false,
        timer: 1500,
        background: daylight ? "#fff" : "#171717",
        color: daylight ? "#00a86b" : "#ff9900",
        toast: true,
      });
    }
    if (
      parseInt(bet) < parseInt(min_bet_5050umum) ||
      parseInt(bet) > parseInt(max_bet_5050umum)
    ) {
      bet_5050umum = min_bet_5050umum;
      flag = false;
      Swal.fire({
        position: "center",
        icon: "error",
        title:
          "Minimal Bet : " +
          min_bet_5050umum +
          " Maximal Bet : " +
          max_bet_5050umum,
        showConfirmButton: false,
        timer: 1500,
        background: daylight ? "#fff" : "#171717",
        color: daylight ? "#00a86b" : "#ff9900",
        toast: true,
      });
    }

    if (flag == true) {
      switch (nomor) {
        case "BESAR":
          kei = keibesar_bet_5050umum;
          diskon = discbesar_bet_5050umum;
          break;
        case "KECIL":
          kei = keikecil_bet_5050umum;
          diskon = disckecil_bet_5050umum;
          break;
        case "GENAP":
          kei = keigenap_bet_5050umum;
          diskon = discgenap_bet_5050umum;
          break;
        case "GANJIL":
          kei = keiganjil_bet_5050umum;
          diskon = discganjil_bet_5050umum;
          break;
        case "TENGAH":
          kei = keitengah_bet_5050umum;
          diskon = disctengah_bet_5050umum;
          break;
        case "TEPI":
          kei = keitepi_bet_5050umum;
          diskon = disctepi_bet_5050umum;
          break;
      }
      keipersen = kei;
      diskonpercen = diskon;
      kei = parseInt(bet) * kei;
      diskon = (parseInt(bet) * diskon).toFixed(0);
      bayar = parseInt(bet) - parseInt(diskon) - parseInt(kei);
      totalkeranjang = bayar + totalkeranjang;

      addKeranjang(
        nomor,
        nmgame,
        bet_5050umum,
        diskonpercen,
        diskon,
        bayar,
        win,
        keipersen,
        kei,
        flag_fulldiskon
      );
      form_clear("5050umum");
      if (temp_bulk_error != "") {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          html: temp_bulk_error,
          background: daylight ? "#fff" : "#171717",
          color: daylight ? "#00a86b" : "#ff9900",
        });
      }
    }
  }
  function form5050special_add() {
    var flag = true;
    var nomor = select_5050special_1;
    var nomor2 = select_5050special_2;
    var bet = bet_5050special;
    var diskon = 0;
    var diskonpercen = 0;
    var win = 1;
    var bayar = 0;
    var kei = 0;
    var keipersen = 0;
    var nmgame = "50_50_SPECIAL";
    var msg = "";
    if (nomor == "") {
      select_5050umum_input.focus();
      flag = false;
    }
    if (nomor2 == "") {
      flag = false;
    }

    if (bet == "") {
      flag = false;
      Swal.fire({
        position: "center",
        icon: "error",
        title: "Amount tidak boleh kosong",
        showConfirmButton: false,
        timer: 1500,
        background: daylight ? "#fff" : "#171717",
        color: daylight ? "#00a86b" : "#ff9900",
        toast: true,
      });
    }
    if (parseInt(bet) < parseInt(min_bet_5050special)) {
      bet_5050special = min_bet_5050special;
      flag = false;
      msg += "Minimal Bet : " + min_bet_5050special;
    }
    if (parseInt(bet) > parseInt(max_bet_5050special)) {
      bet_5050special = max_bet_5050special;
      flag = false;
      msg += " Maximal Bet : " + max_bet_5050special;
    }
    if (flag == true) {
      switch (nomor + nomor2) {
        case "ASGANJIL":
          kei = keiasganjil_bet_5050special;
          diskon = discasganjil_bet_5050special;
          break;
        case "ASGENAP":
          kei = keiasgenap_bet_5050special;
          diskon = discasgenap_bet_5050special;
          break;
        case "ASBESAR":
          kei = keiasbesar_bet_5050special;
          diskon = discasbesar_bet_5050special;
          break;
        case "ASKECIL":
          kei = keiaskecil_bet_5050special;
          diskon = discaskecil_bet_5050special;
          break;
        case "KOPGANJIL":
          kei = keikopganjil_bet_5050special;
          diskon = disckopganjil_bet_5050special;
          break;
        case "KOPGENAP":
          kei = keikopgenap_bet_5050special;
          diskon = disckopgenap_bet_5050special;
          break;
        case "KOPBESAR":
          kei = keikopbesar_bet_5050special;
          diskon = disckopbesar_bet_5050special;
          break;
        case "KOPKECIL":
          kei = keikopkecil_bet_5050special;
          diskon = disckopkecil_bet_5050special;
          break;
        case "KEPALAGANJIL":
          kei = keikepalaganjil_bet_5050special;
          diskon = disckepalaganjil_bet_5050special;
          break;
        case "KEPALAGENAP":
          kei = keikepalagenap_bet_5050special;
          diskon = disckepalagenap_bet_5050special;
          break;
        case "KEPALABESAR":
          kei = keikepalabesar_bet_5050special;
          diskon = disckepalabesar_bet_5050special;
          break;
        case "KEPALAKECIL":
          kei = keikepalakecil_bet_5050special;
          diskon = disckepalakecil_bet_5050special;
          break;
        case "EKORGANJIL":
          kei = keiekorganjil_bet_5050special;
          diskon = discekorganjil_bet_5050special;
          break;
        case "EKORGENAP":
          kei = keiekorgenap_bet_5050special;
          diskon = discekorgenap_bet_5050special;
          break;
        case "EKORBESAR":
          kei = keiekorbesar_bet_5050special;
          diskon = discekorbesar_bet_5050special;
          break;
        case "EKORKECIL":
          kei = keiekorkecil_bet_5050special;
          diskon = discekorkecil_bet_5050special;
          break;
      }
      keipersen = kei;
      diskonpercen = diskon;
      kei = parseInt(bet) * kei;
      diskon = parseInt(bet) * diskon;
      bayar = parseInt(bet) - parseInt(diskon) - parseInt(kei);
      totalkeranjang = bayar + totalkeranjang;

      addKeranjang(
        nomor + "_" + nomor2,
        nmgame,
        bet_5050special,
        diskonpercen,
        diskon,
        bayar,
        win,
        keipersen,
        kei,
        flag_fulldiskon
      );
      form_clear("5050special");
      if (temp_bulk_error != "") {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          html: temp_bulk_error,
          background: daylight ? "#fff" : "#171717",
          color: daylight ? "#00a86b" : "#ff9900",
        });
      }
    }

    if (msg != "") {
      Swal.fire({
        position: "center",
        icon: "warning",
        title: msg,
        showConfirmButton: false,
        timer: 1500,
        background: daylight ? "#fff" : "#171717",
        color: daylight ? "#00a86b" : "#ff9900",
        toast: true,
      });
    }
  }
  function form5050kombinasi_add() {
    var flag = true;
    var nomor = select_5050kombinasi_1;
    var nomor2 = select_5050kombinasi_2;
    var bet = bet_5050kombinasi;
    var diskon = 0;
    var diskonpercen = 0;
    var win = 1;
    var bayar = 0;
    var kei = 0;
    var keipersen = 0;
    var nmgame = "50_50_KOMBINASI";

    if (nomor == "") {
      select_5050special_1_input.focus();
      flag = false;
    }
    if (nomor2 == "") {
      flag = false;
    }

    if (bet == "") {
      flag = false;
      Swal.fire({
        position: "center",
        icon: "error",
        title: "Amount tidak boleh kosong",
        showConfirmButton: false,
        timer: 1500,
        background: daylight ? "#fff" : "#171717",
        color: daylight ? "#00a86b" : "#ff9900",
        toast: true,
      });
    }
    if (parseInt(bet) < parseInt(min_bet_5050kombinasi)) {
      bet_5050kombinasi = min_bet_5050kombinasi;
      flag = false;
      Swal.fire({
        position: "center",
        icon: "error",
        title: "Minimal Bet : " + min_bet_5050kombinasi,
        showConfirmButton: false,
        timer: 1500,
        background: daylight ? "#fff" : "#171717",
        color: daylight ? "#00a86b" : "#ff9900",
        toast: true,
      });
    }
    if (parseInt(bet) > parseInt(max_bet_5050kombinasi)) {
      bet_5050kombinasi = max_bet_5050kombinasi;
      flag = false;
      Swal.fire({
        position: "center",
        icon: "error",
        title: " Maximal Bet : " + max_bet_5050kombinasi,
        showConfirmButton: false,
        timer: 1500,
        background: daylight ? "#fff" : "#171717",
        color: daylight ? "#00a86b" : "#ff9900",
        toast: true,
      });
    }
    if (flag == true) {
      switch (nomor + nomor2) {
        case "BELAKANGMONO":
          kei = kei_belakangmono_bet_5050kombinasi;
          diskon = disc_belakangmono_bet_5050kombinasi;
          break;
        case "BELAKANGSTEREO":
          kei = kei_belakangstereo_bet_5050kombinasi;
          diskon = disc_belakangstereo_bet_5050kombinasi;
          break;
        case "BELAKANGKEMBANG":
          kei = kei_belakangkembang_bet_5050kombinasi;
          diskon = disc_belakangkembang_bet_5050kombinasi;
          break;
        case "BELAKANGKEMPIS":
          kei = kei_belakangkempis_bet_5050kombinasi;
          diskon = disc_belakangkempis_bet_5050kombinasi;
          break;
        case "BELAKANGKEMBAR":
          kei = kei_belakangkembar_bet_5050kombinasi;
          diskon = disc_belakangkembar_bet_5050kombinasi;
          break;
        case "TENGAHMONO":
          kei = kei_tengahmono_bet_5050kombinasi;
          diskon = disc_tengahmono_bet_5050kombinasi;
          break;
        case "TENGAHSTEREO":
          kei = kei_tengahstereo_bet_5050kombinasi;
          diskon = disc_tengahstereo_bet_5050kombinasi;
          break;
        case "TENGAHKEMBANG":
          kei = kei_tengahkembang_bet_5050kombinasi;
          diskon = disc_tengahkembang_bet_5050kombinasi;
          break;
        case "TENGAHKEMPIS":
          kei = kei_tengahkempis_bet_5050kombinasi;
          diskon = disc_tengahkempis_bet_5050kombinasi;
          break;
        case "TENGAHKEMBAR":
          kei = kei_tengahkembar_bet_5050kombinasi;
          diskon = disc_tengahkembar_bet_5050kombinasi;
          break;
        case "DEPANMONO":
          kei = kei_depanmono_bet_5050kombinasi;
          diskon = disc_depanmono_bet_5050kombinasi;
          break;
        case "DEPANSTEREO":
          kei = kei_depanstereo_bet_5050kombinasi;
          diskon = disc_depanstereo_bet_5050kombinasi;
          break;
        case "DEPANKEMBANG":
          kei = kei_depankembang_bet_5050kombinasi;
          diskon = disc_depankembang_bet_5050kombinasi;
          break;
        case "DEPANKEMPIS":
          kei = kei_depankempis_bet_5050kombinasi;
          diskon = disc_depankempis_bet_5050kombinasi;
          break;
        case "DEPANKEMBAR":
          kei = kei_depankembar_bet_5050kombinasi;
          diskon = disc_depankembar_bet_5050kombinasi;
          break;
      }
      keipersen = kei;
      diskonpercen = diskon;
      kei = parseInt(bet) * kei;
      diskon = parseInt(bet) * diskon;
      bayar = parseInt(bet) - parseInt(diskon) - parseInt(kei);

      totalkeranjang = bayar + totalkeranjang;

      addKeranjang(
        nomor + "_" + nomor2,
        nmgame,
        bet_5050kombinasi,
        diskonpercen,
        diskon,
        bayar,
        win,
        keipersen,
        kei,
        flag_fulldiskon
      );
      form_clear("5050kombinasi");
      if (temp_bulk_error != "") {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          html: temp_bulk_error,
          background: daylight ? "#fff" : "#171717",
          color: daylight ? "#00a86b" : "#ff9900",
        });
      }
    }
  }

  function checkInteger(number) {
    if (!parseInt(number)) {
      return "0";
    }
    return number;
  }

  const handleTambah = (e) => {
    if (client_device !== "WEBSITE") {
      bottomOpen = !bottomOpen;
    }
    switch (e) {
      case "5050umum":
        bet_5050umum = checkInteger(bet_5050umum);
        if (
          select_5050umum == "" &&
          parseInt(bet_5050umum) < min_bet_5050umum
        ) {
          select_5050special_1_input.focus();
        } else {
          form5050_add();
        }
        break;
      case "5050special":
        bet_5050special = checkInteger(bet_5050special);

        if (
          select_5050special_1 == "" &&
          select_5050special_2 == "" &&
          parseInt(bet_5050special) < min_bet_5050special
        ) {
          select_5050special_1_input.focus();
        } else {
          form5050special_add();
        }
        break;
      case "5050kombinasi":
        bet_5050kombinasi = checkInteger(bet_5050kombinasi);

        if (
          select_5050kombinasi_1 == "" &&
          select_5050kombinasi_2 == "" &&
          parseInt(bet_5050kombinasi) < min_bet_5050kombinasi
        ) {
          select_5050kombinasi_1_input.focus();
        } else {
          form5050kombinasi_add();
        }
        break;
    }
  };
  const handleKeyboard_number = (e) => {
    var numbers = /^\d+$/;
    let result = numbers.test(e.target.value);
    if (!result) {
      return (e.target.value = 0);
    } else {
      return (e.target.value = parseInt(e.target.value.replace(/\s/g, "")));
    }
  };

  const handleKeyboard_checkenter = (e) => {
    let keyCode = e.which || e.keyCode;
    if (keyCode === 13) {
      handleTambah("5050umum");
      // form5050_add();
    }
  };
  const handleKeyboardspecial_checkenter = (e) => {
    let keyCode = e.which || e.keyCode;
    if (keyCode === 13) {
      handleTambah("5050special");
      // form5050special_add();
    }
  };
  const handleKeyboardkombinasi_checkenter = (e) => {
    let keyCode = e.which || e.keyCode;
    if (keyCode === 13) {
      handleTambah("5050kombinasi");
      // form5050kombinasi_add();
    }
  };
  $: {
    if (betHistoryView) {
      dispatch("handleInvoice", "all");
    }
    switch (gametype) {
      case "5050 UMUM":
        minimal_bet = min_bet_5050umum;
        maxbet = max_bet_5050umum;
        break;
      case "5050 SPECIAL":
        minimal_bet = min_bet_5050special;
        maxbet = max_bet_5050special;
        break;
      case "5050 KOMBINASI":
        minimal_bet = min_bet_5050kombinasi;
        maxbet = max_bet_5050kombinasi;
        break;
    }
  }
</script>

<svelte:head>
  <title>SDSB4D - {pasaran_name} - {gametype}</title>
</svelte:head>

<Card class={daylight ? "" : "bg-dark"}>
  <PeriodePanel
    {pasaran_name}
    {permainan_title}
    {pasaran_periode}
    {pasaran_code}
    {daylight}
    {client_device}
  />
  <CardBody class={daylight ? "" : "dark"}>
    {#if client_device == "WEBSITE"}
      <TabContent
        pills
        class="periode-menu {daylight ? '' : 'dark'}"
        on:tab={(e) => (gametype = e.detail)}
      >
        <!-- Umum -->
        <TabPane tabId="5050 UMUM" tab="UMUM" active>
          <div style="margin:10px 0;">
            <div class="row gap-3">
              <div class="col-md">
                <div class="form-floating">
                  <select
                    class="form-select button-bet-default"
                    class:dark={daylight === false}
                    bind:value={select_5050umum}
                    bind:this={select_5050umum_input}
                    id="selectOpt1"
                    aria-label="Floating label select"
                  >
                    {#each Option5050Umum as umum}
                      {#if umum === ""}
                        <option value={umum} selected>--Pilih--</option>
                      {:else}
                        <option value={umum}>{umum}</option>
                      {/if}
                    {/each}
                  </select>
                  <label for="selectOpt1">Pilih</label>
                </div>
              </div>
              <div class="col-md">
                <div class="form-floating">
                  <input
                    bind:value={bet_5050umum}
                    on:keyup={handleKeyboard_number}
                    on:keypress={handleKeyboard_checkenter}
                    type="text"
                    id="betQuick2D"
                    class="form-control fs-5 text-end button-bet-default"
                    class:dark={daylight === false}
                    placeholder="Bet"
                    minlength="3"
                    maxlength="7"
                    tab_index="0"
                    autocomplete="off"
                  />
                  <span style="float:right;font-size:12px;color:#8a8a8a;"
                    >{new Intl.NumberFormat().format(bet_5050umum)}</span
                  >
                  <label for="betQuick2D" class="form-label"
                    >Bet (min : {new Intl.NumberFormat().format(
                      min_bet_5050umum
                    )}
                    dan max : {new Intl.NumberFormat().format(
                      max_bet_5050umum
                    )})</label
                  >
                </div>
              </div>
              <div class="col-md">
                <Button
                  id="btn2"
                  class="form-control mt-2"
                  style="border-radius:5px"
                  on:click={() => {
                    handleTambah("5050umum");
                  }}>TAMBAH</Button
                >
              </div>
            </div>
          </div>
        </TabPane>

        <!-- Special -->
        <TabPane tabId="5050 SPECIAL" tab="SPECIAL">
          <div style="margin:10px 0;">
            <div class="row gap-3">
              <div class="col-md">
                <div class="form-floating">
                  <select
                    class="form-select button-bet-default"
                    class:dark={daylight === false}
                    bind:value={select_5050special_1}
                    bind:this={select_5050special_1_input}
                    id="selectSpc1"
                    aria-label="Floating label select"
                    required
                  >
                    {#each OptionSpecial1 as spc1}
                      {#if spc1 === ""}
                        <option value={spc1} selected>--Pilih--</option>
                      {:else}
                        <option value={spc1}>{spc1}</option>
                      {/if}
                    {/each}
                  </select>
                  <label for="selectSpc1">Pilih</label>
                </div>
              </div>
              <div class="col-md">
                <div class="form-floating">
                  <select
                    class="form-select button-bet-default"
                    class:dark={daylight === false}
                    bind:value={select_5050special_2}
                    bind:this={select_5050special_2_input}
                    id="selectSpc2"
                    aria-label="Floating label select"
                    required
                  >
                    {#each OptionSpecial2 as spc2}
                      {#if spc2 === ""}
                        <option value={spc2} selected>--Pilih--</option>
                      {:else}
                        <option value={spc2}>{spc2}</option>
                      {/if}
                    {/each}
                  </select>
                  <label for="selectSpc2">Pilih</label>
                </div>
              </div>
              <div class="col-md">
                <div class="form-floating">
                  <input
                    bind:value={bet_5050special}
                    on:keyup={handleKeyboard_number}
                    on:keypress={handleKeyboardspecial_checkenter}
                    type="text"
                    id="inptSpc"
                    class="form-control fs-5 text-end button-bet-default"
                    class:dark={daylight === false}
                    placeholder="Bet"
                    minlength="3"
                    maxlength="7"
                    tab_index="0"
                    autocomplete="off"
                  />
                  <span style="text-align:right;font-size:12px;color:#8a8a8a;"
                    >{new Intl.NumberFormat().format(bet_5050special)}</span
                  >
                  <label for="inptSpc" class="form-label"
                    >Bet (min : {new Intl.NumberFormat().format(
                      min_bet_5050special
                    )} dan max : {new Intl.NumberFormat().format(
                      max_bet_5050special
                    )})</label
                  >
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <Button
                  id="btn2"
                  class="form-control mt-2"
                  style="border-radius:5px"
                  on:click={() => {
                    handleTambah("5050special");
                  }}>TAMBAH</Button
                >
              </div>
            </div>
          </div>
        </TabPane>

        <!-- Kombinasi -->
        <TabPane tabId="5050 KOMBINASI" tab="KOMBINASI">
          <div style="margin:10px 0;">
            <div class="row gap-3">
              <div class="col-md">
                <div class="form-floating">
                  <select
                    class="form-select button-bet-default"
                    class:dark={daylight === false}
                    bind:value={select_5050kombinasi_1}
                    bind:this={select_5050kombinasi_1_input}
                    id="selectKmbn1"
                    aria-label="Floating label select"
                  >
                    {#each OptionKombinasi1 as kbn1}
                      {#if kbn1 === ""}
                        <option value={kbn1} selected>--Pilih--</option>
                      {:else}
                        <option value={kbn1}>{kbn1}</option>
                      {/if}
                    {/each}
                  </select>
                  <label for="selectKmbn1">Pilih</label>
                </div>
              </div>
              <div class="col-md">
                <div class="form-floating">
                  <select
                    class="form-select button-bet-default"
                    class:dark={daylight === false}
                    bind:value={select_5050kombinasi_2}
                    bind:this={select_5050kombinasi_2_input}
                    id="selectKmbn2"
                    aria-label="Floating label select"
                  >
                    {#each OptionKombinasi2 as kbn2}
                      {#if kbn2 === ""}
                        <option value={kbn2} selected>--Pilih--</option>
                      {:else}
                        <option value={kbn2}>{kbn2}</option>
                      {/if}
                    {/each}
                  </select>
                  <label for="selectKmbn2">Pilih</label>
                </div>
              </div>
              <div class="col-md">
                <div class="form-floating">
                  <input
                    bind:value={bet_5050kombinasi}
                    on:keyup={handleKeyboard_number}
                    on:keypress={handleKeyboardkombinasi_checkenter}
                    type="text"
                    id="inptKmbn"
                    class="form-control fs-5 text-end button-bet-default"
                    class:dark={daylight === false}
                    placeholder="Bet"
                    minlength="3"
                    maxlength="7"
                    tab_index="0"
                    autocomplete="off"
                  />
                  <span style="text-align:right;font-size:12px;color:#8a8a8a;"
                    >{new Intl.NumberFormat().format(bet_5050kombinasi)}</span
                  >
                  <label for="inptKmbn" class="form-label text-truncate"
                    >Bet (min : {new Intl.NumberFormat().format(
                      min_bet_5050special
                    )} dan max : {new Intl.NumberFormat().format(
                      max_bet_5050special
                    )})</label
                  >
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <Button
                  id="btn2"
                  class="form-control mt-2"
                  style="border-radius:5px"
                  on:click={() => {
                    handleTambah("5050kombinasi");
                  }}>TAMBAH</Button
                >
              </div>
            </div>
          </div>
        </TabPane>
      </TabContent>
    {:else}
      <button
        class="btn btn-play mobile"
        class:dark={daylight === false}
        type="button"
        aria-controls="offcanvasdata"
        on:click={toggleBottom}
      >
        Mulai Bermain <Fa
          icon={faDice}
          size="1.5x"
          style="padding-left: .75rem;vertical-align: middle;"
        />
      </button>
    {/if}
    <Offcanvas
      isOpen={bottomOpen}
      placement="bottom"
      backdrop={true}
      scroll
      class="mx-auto"
      style="height: {canvasHeigth}%;background-color:{daylight
        ? '#fff'
        : '#171717'}; border-top-left-radius:20px; 
        border-top-right-radius:20px;
        box-shadow:0 0 1px 0px #888888; 
        max-width:540px;"
    >
      <div slot="header">
        <Container>
          <Row>
            <Col class="col-8">
              <h5
                class="offcanvas-title fw-bold text-{daylight
                  ? 'dark'
                  : 'light'}"
                id="5050CanvasLabel"
              >
                {pasaran_name} - 50-50
              </h5>
            </Col>
            <Col class="text-end">
              <button
                type="button"
                class="btn-close text-reset"
                class:btn-close-white={daylight === false}
                data-bs-dismiss="offcanvas"
                aria-label="Close"
                on:click={() => (bottomOpen = false)}
              />
            </Col>
          </Row>
        </Container>
      </div>
      <Container>
        <Row cols={1}>
          <TabContent
            pills
            class="periode-menu {daylight ? '' : 'dark'}"
            on:tab={(e) => {
              gametype = e.detail;
            }}
          >
            <!-- Umum -->
            <TabPane tabId="5050 UMUM" tab="UMUM" active>
              <div style="margin:10px 0;">
                <div class="row gap-3">
                  <div class="col-xs">
                    <div class="form-floating">
                      <select
                        class="form-select button-bet-default"
                        class:dark={daylight === false}
                        bind:value={select_5050umum}
                        bind:this={select_5050umum_input}
                        id="selectOpt1Mobile"
                        aria-label="Floating label select"
                      >
                        {#each Option5050Umum as umum}
                          {#if umum === ""}
                            <option value={umum} selected>--Pilih--</option>
                          {:else}
                            <option value={umum}>{umum}</option>
                          {/if}
                        {/each}
                      </select>
                      <label for="selectOpt1Mobile">Pilih</label>
                    </div>
                  </div>
                  <!-- <div class="col-md">
                    <div class="form-floating">
                      <input
                        bind:value={bet_5050umum}
                        on:keyup={handleKeyboard_number}
                        on:keypress={handleKeyboard_checkenter}
                        type="text"
                        id="betQuick2DMobile"
                        class="form-control fs-5 text-end button-bet-default"
                        class:dark={daylight === false}
                        placeholder="Bet"
                        minlength="3"
                        maxlength="7"
                        tab_index="0"
                        autocomplete="off"
                      />
                      <span style="float:right;font-size:12px;color:#8a8a8a;"
                        >{new Intl.NumberFormat().format(bet_5050umum)}</span
                      >
                      <label for="betQuick2DMobile" class="form-label"
                        >Bet (min : {new Intl.NumberFormat().format(
                          min_bet_5050umum
                        )}
                        dan max : {new Intl.NumberFormat().format(
                          max_bet_5050umum
                        )})</label
                      >
                    </div>
                  </div> -->
                  <div class="col-xs">
                    <BtnBet
                      {daylight}
                      bind:betval={bet_5050umum}
                      {maxbet}
                      elemid="betQuick2DMobile"
                      gametype="Umum"
                      {minimal_bet}
                    />
                  </div>
                  <div class="col-xs">
                    <Button
                      id="btn2"
                      class="form-control mt-2"
                      style="border-radius:5px"
                      on:click={() => {
                        handleTambah("5050umum");
                      }}>TAMBAH</Button
                    >
                  </div>
                </div>
              </div>
            </TabPane>

            <!-- Special -->
            <TabPane tabId="5050 SPECIAL" tab="SPECIAL">
              <div style="margin:10px 0;">
                <div class="row gap-3">
                  <div class="col-xs">
                    <div class="form-floating">
                      <select
                        class="form-select button-bet-default"
                        class:dark={daylight === false}
                        bind:value={select_5050special_1}
                        bind:this={select_5050special_1_input}
                        id="selectSpc1Mobile"
                        aria-label="Floating label select"
                        required
                      >
                        {#each OptionSpecial1 as spc1}
                          {#if spc1 === ""}
                            <option value={spc1} selected>--Pilih--</option>
                          {:else}
                            <option value={spc1}>{spc1}</option>
                          {/if}
                        {/each}
                      </select>
                      <label for="selectSpc1Mobile">Pilih</label>
                    </div>
                  </div>
                  <div class="col-xs">
                    <div class="form-floating">
                      <select
                        class="form-select button-bet-default"
                        class:dark={daylight === false}
                        bind:value={select_5050special_2}
                        bind:this={select_5050special_2_input}
                        id="selectSpc2Mobile"
                        aria-label="Floating label select"
                        required
                      >
                        {#each OptionSpecial2 as spc2}
                          {#if spc2 === ""}
                            <option value={spc2} selected>--Pilih--</option>
                          {:else}
                            <option value={spc2}>{spc2}</option>
                          {/if}
                        {/each}
                      </select>
                      <label for="selectSpc2Mobile">Pilih</label>
                    </div>
                  </div>
                  <!-- <div class="col-md">
                    <div class="form-floating">
                      <input
                        bind:value={bet_5050special}
                        on:keyup={handleKeyboard_number}
                        on:keypress={handleKeyboardspecial_checkenter}
                        type="text"
                        id="inptSpcMobile"
                        class="form-control fs-5 text-end button-bet-default"
                        class:dark={daylight === false}
                        placeholder="Bet"
                        minlength="3"
                        maxlength="7"
                        tab_index="0"
                        autocomplete="off"
                      />
                      <span
                        style="text-align:right;font-size:12px;color:#8a8a8a;"
                        >{new Intl.NumberFormat().format(bet_5050special)}</span
                      >
                      <label for="inptSpcMobile" class="form-label"
                        >Bet (min : {new Intl.NumberFormat().format(
                          min_bet_5050special
                        )} dan max : {new Intl.NumberFormat().format(
                          max_bet_5050special
                        )})</label
                      >
                    </div>
                  </div> -->
                  <div class="col-xs">
                    <BtnBet
                      {daylight}
                      bind:betval={bet_5050special}
                      {maxbet}
                      elemid="inptSpcMobile"
                      gametype="Umum"
                      {minimal_bet}
                    />
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <Button
                      id="btn2"
                      class="form-control mt-2"
                      style="border-radius:5px"
                      on:click={() => {
                        handleTambah("5050special");
                      }}>TAMBAH</Button
                    >
                  </div>
                </div>
              </div>
            </TabPane>

            <!-- Kombinasi -->
            <TabPane tabId="5050 KOMBINASI" tab="KOMBINASI">
              <div style="margin:10px 0;">
                <div class="row gap-3">
                  <div class="col-xs">
                    <div class="form-floating">
                      <select
                        class="form-select button-bet-default"
                        class:dark={daylight === false}
                        bind:value={select_5050kombinasi_1}
                        bind:this={select_5050kombinasi_1_input}
                        id="selectKmbn1Mobile"
                        aria-label="Floating label select"
                      >
                        {#each OptionKombinasi1 as kbn1}
                          {#if kbn1 === ""}
                            <option value={kbn1} selected>--Pilih--</option>
                          {:else}
                            <option value={kbn1}>{kbn1}</option>
                          {/if}
                        {/each}
                      </select>
                      <label for="selectKmbn1Mobile">Pilih</label>
                    </div>
                  </div>
                  <div class="col-xs">
                    <div class="form-floating">
                      <select
                        class="form-select button-bet-default"
                        class:dark={daylight === false}
                        bind:value={select_5050kombinasi_2}
                        bind:this={select_5050kombinasi_2_input}
                        id="selectKmbn2Mobile"
                        aria-label="Floating label select"
                      >
                        {#each OptionKombinasi2 as kbn2}
                          {#if kbn2 === ""}
                            <option value={kbn2} selected>--Pilih--</option>
                          {:else}
                            <option value={kbn2}>{kbn2}</option>
                          {/if}
                        {/each}
                      </select>
                      <label for="selectKmbn2Mobile">Pilih</label>
                    </div>
                  </div>
                  <!-- <div class="col-md">
                    <div class="form-floating">
                      <input
                        bind:value={bet_5050kombinasi}
                        on:keyup={handleKeyboard_number}
                        on:keypress={handleKeyboardkombinasi_checkenter}
                        type="text"
                        id="inptKmbnMobile"
                        class="form-control fs-5 text-end button-bet-default"
                        class:dark={daylight === false}
                        placeholder="Bet"
                        minlength="3"
                        maxlength="7"
                        tab_index="0"
                        autocomplete="off"
                      />
                      <span
                        style="text-align:right;font-size:12px;color:#8a8a8a;"
                        >{new Intl.NumberFormat().format(
                          bet_5050kombinasi
                        )}</span
                      >
                      <label
                        for="inptKmbnMobile"
                        class="form-label text-truncate"
                        >Bet (min : {new Intl.NumberFormat().format(
                          min_bet_5050special
                        )} dan max : {new Intl.NumberFormat().format(
                          max_bet_5050special
                        )})</label
                      >
                    </div>
                  </div> -->
                  <div class="col-xs-">
                    <BtnBet
                      {daylight}
                      bind:betval={bet_5050kombinasi}
                      {maxbet}
                      elemid="inptKmbnMobile"
                      gametype="Umum"
                      {minimal_bet}
                    />
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <Button
                      id="btn2"
                      class="form-control mt-2"
                      style="border-radius:5px"
                      on:click={() => {
                        handleTambah("5050kombinasi");
                      }}>TAMBAH</Button
                    >
                  </div>
                </div>
              </div>
            </TabPane>
          </TabContent>
        </Row>
      </Container>
    </Offcanvas>
  </CardBody>
</Card>

<Modal modal_id={"modalError"} modal_size={"modal-dialog-centered"}>
  <slot:template slot="header">
    <div class="float-end">
      <img
        style="cursor:pointer;"
        data-bs-dismiss="modal"
        aria-label="Close"
        width="25"
        src="https://i.ibb.co/9yNF25L/outline-close-white-48dp.png"
        alt=""
      />
    </div>
    <h5 class="modal-title" id="exampleModalLabel">ERROR</h5>
  </slot:template>
  <slot:template slot="body">
    {@html temp_bulk_error}
  </slot:template>
</Modal>
<div class="clearfix" />
<br />
<Tablekeranjang5050
  on:removekeranjang={removekeranjang}
  on:removekeranjangall={removekeranjangall}
  on:handleSave={handleSave}
  bind:betHistoryView
  {client_device}
  {count_line_5050umum}
  {count_line_5050special}
  {count_line_5050kombinasi}
  {keranjang}
  {totalkeranjang}
  {min_bet_5050umum}
  {max_bet_5050umum}
  {keibesar_bet_5050umum}
  {keikecil_bet_5050umum}
  {keigenap_bet_5050umum}
  {keiganjil_bet_5050umum}
  {keitengah_bet_5050umum}
  {keitepi_bet_5050umum}
  {discbesar_bet_5050umum}
  {disckecil_bet_5050umum}
  {discgenap_bet_5050umum}
  {discganjil_bet_5050umum}
  {disctengah_bet_5050umum}
  {disctepi_bet_5050umum}
  {min_bet_5050special}
  {max_bet_5050special}
  {keiasganjil_bet_5050special}
  {keiasgenap_bet_5050special}
  {keiasbesar_bet_5050special}
  {keiaskecil_bet_5050special}
  {keikopganjil_bet_5050special}
  {keikopgenap_bet_5050special}
  {keikopbesar_bet_5050special}
  {keikopkecil_bet_5050special}
  {keikepalaganjil_bet_5050special}
  {keikepalagenap_bet_5050special}
  {keikepalabesar_bet_5050special}
  {keikepalakecil_bet_5050special}
  {keiekorganjil_bet_5050special}
  {keiekorgenap_bet_5050special}
  {keiekorbesar_bet_5050special}
  {keiekorkecil_bet_5050special}
  {discasganjil_bet_5050special}
  {discasgenap_bet_5050special}
  {discasbesar_bet_5050special}
  {discaskecil_bet_5050special}
  {disckopganjil_bet_5050special}
  {disckopgenap_bet_5050special}
  {disckopbesar_bet_5050special}
  {disckopkecil_bet_5050special}
  {disckepalaganjil_bet_5050special}
  {disckepalagenap_bet_5050special}
  {disckepalabesar_bet_5050special}
  {disckepalakecil_bet_5050special}
  {discekorganjil_bet_5050special}
  {discekorgenap_bet_5050special}
  {discekorbesar_bet_5050special}
  {discekorkecil_bet_5050special}
  {min_bet_5050kombinasi}
  {max_bet_5050kombinasi}
  {kei_belakangmono_bet_5050kombinasi}
  {kei_belakangstereo_bet_5050kombinasi}
  {kei_belakangkembang_bet_5050kombinasi}
  {kei_belakangkempis_bet_5050kombinasi}
  {kei_belakangkembar_bet_5050kombinasi}
  {kei_tengahmono_bet_5050kombinasi}
  {kei_tengahstereo_bet_5050kombinasi}
  {kei_tengahkembang_bet_5050kombinasi}
  {kei_tengahkempis_bet_5050kombinasi}
  {kei_tengahkembar_bet_5050kombinasi}
  {kei_depanmono_bet_5050kombinasi}
  {kei_depanstereo_bet_5050kombinasi}
  {kei_depankembang_bet_5050kombinasi}
  {kei_depankempis_bet_5050kombinasi}
  {kei_depankembar_bet_5050kombinasi}
  {disc_belakangmono_bet_5050kombinasi}
  {disc_belakangstereo_bet_5050kombinasi}
  {disc_belakangkembang_bet_5050kombinasi}
  {disc_belakangkempis_bet_5050kombinasi}
  {disc_belakangkembar_bet_5050kombinasi}
  {disc_tengahmono_bet_5050kombinasi}
  {disc_tengahstereo_bet_5050kombinasi}
  {disc_tengahkembang_bet_5050kombinasi}
  {disc_tengahkempis_bet_5050kombinasi}
  {disc_tengahkembar_bet_5050kombinasi}
  {disc_depanmono_bet_5050kombinasi}
  {disc_depanstereo_bet_5050kombinasi}
  {disc_depankembang_bet_5050kombinasi}
  {disc_depankempis_bet_5050kombinasi}
  {disc_depankembar_bet_5050kombinasi}
  {daylight}
  {agent_home_url}
  {portrait_mode}
/>

<style>
  input,
  input::-webkit-input-placeholder {
    font-size: 13px;
    color: #8a8a8a;
  }
  input::placeholder {
    color: #8a8a8a;
  }
</style>
