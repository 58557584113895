function reverseString(str) {
    return str.split("").reverse().join("");
}
export function intToString(value) {
    let suffixes = ["", "K", "M", "B", "T"];
    let suffixNum = Math.floor(("" + value).length / 5);
    let shortValue = parseFloat((suffixNum != 0 ? value / Math.pow(1000, suffixNum) : value).toPrecision(2));
    if (shortValue % 1 != 0) {
        shortValue = Number(shortValue.toFixed(1));
    }
    let joinstring = String(shortValue + suffixes[suffixNum]);
    return String(joinstring);
}
export function excryptData(str) {
    return btoa(reverseString(btoa(str)));
}
