<script>
  import { Button, Icon } from "sveltestrap";
  import { createEventDispatcher } from "svelte";
  import Fa from "svelte-fa";
  import {
    faDollarSign,
    faHome,
    faInfoCircle,
    faSlidersH,
    faTrash,
  } from "@fortawesome/free-solid-svg-icons";

  export let mobile;
  export let agent_home_url = "/";
  export let mode432 = false;
  export let portrait_mode;
  // export let daylight = false;

  let dispatch = createEventDispatcher();
  const handleBetHistoryView = () => {
    dispatch("handleBetHistoryView", "all");
  };
  const handleRemoveKeranjang_all = () => {
    dispatch("handleRemoveKeranjang_all", "all");
  };
  const handleSave = () => {
    dispatch("handleSave", "save");
  };

  const handleAgentHomeUrl = () => {
    sessionStorage.clear();
    window.location.replace(agent_home_url);
  };

  const handlePaymentType = (e) => {
    dispatch("handlePaymentType");
  };
</script>

<div
  class="col-xs mx-auto"
  class:fixed-bottom={mobile}
  class:bg-transparent={mobile}
  class:py-3={mobile}
  class:hide={!portrait_mode}
  style="max-width:540px;"
>
  <div
    class:float-end={!mobile}
    class:btn-group={mobile}
    class:w-100={mobile}
    class:bg-dark={mobile}
    class:bg-gradient={mobile}
    class:rounded-pill={mobile}
    id="btnbelitogel"
    style={mobile
      ? "box-shadow: 0 3px 1px -2px rgb(0 0 0 / 20%), 0 2px 2px 0 rgb(0 0 0 / 14%), 0 1px 5px 0 rgb(0 0 0 / 12%)"
      : ""}
  >
    {#if mobile}
      <Button
        id="btn1"
        style="background:transparent !important; border:0px !important; border-radius: 0px !important; box-shadow:unset !important"
        class="fs-6 fw-normal text-capitalize"
        on:click={handleAgentHomeUrl}
        ><Fa icon={faHome} size="1.2x" /> <br />
        <span style="font-size:8pt;">Beranda</span></Button
      >
    {/if}
    {#if mode432}
      <Button
        id="btn1"
        on:click={handlePaymentType}
        style={mobile
          ? "background:transparent !important; border:0px !important; border-radius: 0px !important; box-shadow:unset !important"
          : ""}
        class="fs-6 fw-normal text-capitalize"
        ><Fa icon={faSlidersH} size="1.2x" />
        <br class:d-none={!mobile} />
        <span style="font-size:8pt;">Setting</span></Button
      >
    {/if}
    <Button
      id="btn1"
      data-bs-toggle="modal"
      data-bs-target="#modalInformasi"
      style={mobile
        ? "background:transparent !important; border:0px !important; border-radius: 0px !important; box-shadow:unset !important"
        : ""}
      class="fs-6 fw-normal text-capitalize"
    >
      <Fa icon={faInfoCircle} size="1.2x" />
      <br class:d-none={!mobile} />
      <span style="font-size:8pt;">Info</span></Button
    >
    <Button
      id="btn1"
      on:click={handleRemoveKeranjang_all}
      style={mobile
        ? "background:transparent !important; border:0px !important; border-radius: 0px !important; box-shadow:unset !important"
        : ""}
      class="fs-6 fw-normal text-capitalize"
      ><Fa icon={faTrash} size="1.2x" />
      <br class:d-none={!mobile} />
      <span style="font-size:8pt;">Hapus</span></Button
    >
    <Button
      id="btn2"
      class="fs-6 fw-normal text-capitalize"
      on:click={handleSave}
      style={mobile
        ? "border-radius: 50px !important; box-shadow:0px 0px 0px 1px rgb(108 108 108 / 20%), 0px 2px 2px 0px rgb(255 255 255 / 14%), 0px 1px 5px 0px rgb(255 255 255 / 12%) !important; border-size:10px !important"
        : ""}
      ><Fa icon={faDollarSign} size="1.2x" />
      <br class:d-none={!mobile} />
      <span>Bayar</span></Button
    >
  </div>
</div>
