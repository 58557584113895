<script>
  import Fa from "svelte-fa";
  import { faPlus, faMinus } from "@fortawesome/free-solid-svg-icons";

  export let minimal_bet;
  export let maxbet;
  export let betval;
  export let elemid;
  export let daylight;
  export let gametype;
  export const type4d = false;
  export const type3d = false;
  export const type2d = false;

  let betIdx = 0;
  let currentBet = betval;

  let betvals = [
    0, 100, 200, 300, 600, 1000, 1500, 2000, 2400, 12000, 55000, 72000, 100000,
    500000, 1000000, 3000000, 5000000, 10000000,
  ];

  $: {
    if (betval === "") {
      betIdx = 0;
      betval = betvals[betIdx];
      currentBet = betval;
    }
    // if (parseInt(betval) === 0 || betval === "") {
    //   let idx = betvals.indexOf(minimal_bet);
    //   if (idx < 0) {
    //     idx = 3;
    //     minimal_bet = betvals[idx];
    //   }
    //   if (maxbet > betvals[15]) {
    //     maxbet = betvals[13];
    //   }
    //   betval = minimal_bet;
    //   betIdx = idx;
    //   currentBet = betvals[betIdx];
    // }
  }

  const addbets = () => {
    if (betval >= maxbet) {
      betval = maxbet;
      currentBet = betval;
      return;
    }
    betIdx++;

    betval = betvals[betIdx];

    currentBet = betval === maxbet ? maxbet : betval;
  };

  const subsbets = () => {
    if (betval <= 0) {
      betval = 0;
      currentBet = betval;
      return;
    }
    betIdx--;
    betval = betvals[betIdx];

    currentBet = betval;
  };
</script>

<div class="row my-2 justify-content-center mx-4">
  <div class="col-2 py-2">
    <button
      class:disabled={currentBet === 0}
      on:click={subsbets}
      type="button"
      id="btnNumber"
      class="btn"
      ><Fa icon={faMinus} size="0.5x" style="vertical-align: middle;" /></button
    >
  </div>
  <div class="col">
    <div class="form-floating h-100">
      <input
        bind:value={betval}
        type="number"
        class="form-control text-center button-bet-default h-100"
        class:dark={daylight === false}
        placeholder="Bet"
        id={elemid}
        style=""
        minlength="3"
        maxlength="7"
        tab_index="0"
        name={gametype}
        autocomplete="off"
        disabled
      />
      <label for={elemid} class="form-label"
        >{gametype} bet (min: {minimal_bet})</label
      >
    </div>
  </div>
  <div class="col-2 py-2">
    <button
      class:disabled={currentBet === maxbet}
      on:click={addbets}
      type="button"
      id="btnNumber"
      class="btn"
      ><Fa icon={faPlus} size="0.5x" style="vertical-align: middle;" /></button
    >
  </div>
</div>
