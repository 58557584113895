<script>
  import {
    Button,
    Card,
    CardBody,
    CardHeader,
    TabContent,
    TabPane,
    Offcanvas,
    Container,
    Row,
    Col,
  } from "sveltestrap";
  import Modal from "../components/Modal.svelte";
  import Tablekeranjang from "../permainan/Tablekeranjang.svelte";
  import { createEventDispatcher, onMount } from "svelte";
  import { excryptData } from "../lib/encrypter";
  import { useVisitorData } from "@fingerprintjs/fingerprintjs-pro-svelte";
  import PeriodePanel from "../components/PeriodePanel.svelte";
  import Swal from "sweetalert2";
  import Fa from "svelte-fa";
  import { faDice } from "@fortawesome/free-solid-svg-icons";
  import dayjs from "dayjs";
  import utc from "dayjs/plugin/utc";
  import timezone from "dayjs/plugin/timezone";
  import { UiDeleteLeft } from "../lib/icons";
  import BtnAngka from "../components/ButtonAngka.svelte";
  import BtnBet from "../components/ButtonInputBet.svelte";

  export let idcomppasaran = "";
  export let idtrxkeluaran = "";
  export let client_token = "";
  export let client_company = "";
  export let client_username = "";
  export let client_ipaddress = "";
  export let client_device = "";
  export let source = "";
  export let client_timezone = "";
  export let pasaran_code = "";
  export let pasaran_name = "";
  export let pasaran_periode = 0;
  export let permainan_title = "4D / 3D / 2D";
  export let daylight = false;
  export let betHistoryView = false;
  export let balance_credit;
  export let css_loader = "display:none;";
  export let loadDisplay;
  export let agent_home_url;
  export let portrait_mode;

  // Set to true fo fetch data when component is mounted
  export let immediate = false;
  dayjs.extend(utc);
  dayjs.extend(timezone);

  const { getData, data, isLoading, error } = useVisitorData(
    { extendedResult: true },
    { immediate },
  );
  let State = {
    idle: "idle",
    requesting: "requesting",
    success: "success",
  };
  let ctoken = "";
  let state = State.idle;

  let gametype = "";
  let keranjang = [];
  let nomor_global = 0;
  let totalkeranjang = 0;
  let group_btn_beli = false;
  let record = "";
  let minimal_bet = 0;
  let maxbet = 0;
  let max4d_bet = 0;
  let max3d_bet = 0;
  let max3dd_bet = 0;
  let max2d_bet = 0;
  let max2dd_bet = 0;
  let max2dt_bet = 0;
  let max4d_bet_bbdisc = 0;
  let max3d_bet_bbdisc = 0;
  let max3dd_bet_bbdisc = 0;
  let max2d_bet_bbdisc = 0;
  let max2dd_bet_bbdisc = 0;
  let max2dt_bet_bbdisc = 0;
  let disc4d_bet = 0;
  let disc3d_bet = 0;
  let disc3dd_bet = 0;
  let disc2d_bet = 0;
  let disc2dd_bet = 0;
  let disc2dt_bet = 0;
  let win4d_bet = 0;
  let win3d_bet = 0;
  let win3dd_bet = 0;
  let win2d_bet = 0;
  let win2dd_bet = 0;
  let win2dt_bet = 0;
  let win4dnodiskon_bet = 0;
  let win3dnodiskon_bet = 0;
  let win3ddnodiskon_bet = 0;
  let win2dnodiskon_bet = 0;
  let win2ddnodiskon_bet = 0;
  let win2dtnodiskon_bet = 0;
  let win4dbb_kena_bet = 0;
  let win3dbb_kena_bet = 0;
  let win3ddbb_kena_bet = 0;
  let win2dbb_kena_bet = 0;
  let win2ddbb_kena_bet = 0;
  let win2dtbb_kena_bet = 0;
  let win4dbb_bet = 0;
  let win3dbb_bet = 0;
  let win3ddbb_bet = 0;
  let win2dbb_bet = 0;
  let win2ddbb_bet = 0;
  let win2dtbb_bet = 0;
  let limittotal4d_bet = 0;
  let limittotal3d_bet = 0;
  let limittotal3dd_bet = 0;
  let limittotal2d_bet = 0;
  let limittotal2dd_bet = 0;
  let limittotal2dt_bet = 0;
  let limitline_4d = 0;
  let limitline_3d = 0;
  let limitline_3dd = 0;
  let limitline_2d = 0;
  let limitline_2dd = 0;
  let limitline_2dt = 0;
  let bbfs = 0;

  let count_line_4d = 0;
  let count_line_3d = 0;
  let count_line_3dd = 0;
  let count_line_2d = 0;
  let count_line_2dd = 0;
  let count_line_2dt = 0;

  let db_form4d_4d_count_temp = 0;
  let db_form4d_3d_count_temp = 0;
  let db_form4d_3dd_count_temp = 0;
  let db_form4d_2d_count_temp = 0;
  let db_form4d_2dd_count_temp = 0;
  let db_form4d_2dt_count_temp = 0;

  let canvasHeigth = 50;
  let lastval = "";

  let flag_fulldiskon = "DISC";
  let game_title = "";
  let path_432 = "";
  let dispatch = createEventDispatcher();

  let max4d = 600;
  let max3d = 5500;
  let max3dd = 5500;
  let max2d = 55000;
  let max2dd = 55000;
  let max2dt = 55000;

  var cd = new Date();
  var cnow = dayjs(cd).tz("Asia/Jakarta").unix();

  let bottomOpen = false;
  const toggleBottom = () => {
    bottomOpen = !bottomOpen;
    maxbet = max2d;
  };

  const swalWithBootstrapButtons = Swal.mixin({
    customClass: {
      confirmButton: "mx-2 rounded-2 btn btn-success",
      cancelButton: "mx-2 rounded-2 btn btn-danger",
    },
    buttonsStyling: false,
  });

  onMount(() => {
    inittogel_432d("4-3-2");
    limittogel("4-3-2");
    Swal.fire({
      html: "<h3>Pilih tipe pembayaran dibawah ini :</h3>",
      showDenyButton: true,
      showCancelButton: true,
      confirmButtonText: "Diskon",
      denyButtonText: `Non Diskon / Full`,
      cancelButtonText: `Bolak Balik / BB`,
      background: daylight ? "#fff" : "#171717",
      color: daylight ? "#00a86b" : "#ff9900",
      allowOutsideClick: false,
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        flag_fulldiskon = "DISC";
        max4d_bet = max4d_bet_bbdisc;
        max3d_bet = max3d_bet_bbdisc;
        max3dd_bet = max3dd_bet_bbdisc;
        max2d_bet = max2d_bet_bbdisc;
        max2dd_bet = max2dd_bet_bbdisc;
        max2dt_bet = max2dt_bet_bbdisc;
      } else if (result.isDenied) {
        flag_fulldiskon = "FULL";
        max4d_bet = max4d;
        max3d_bet = max3d;
        max3dd_bet = max3dd;
        max2d_bet = max2d;
        max2dd_bet = max2dd;
        max2dt_bet = max2dt;
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        flag_fulldiskon = "BB";
        max4d_bet = max4d_bet_bbdisc;
        max3d_bet = max3d_bet_bbdisc;
        max3dd_bet = max3dd_bet_bbdisc;
        max2d_bet = max2d_bet_bbdisc;
        max2dd_bet = max2dd_bet_bbdisc;
        max2dt_bet = max2dt_bet_bbdisc;
      }
      game_title = permainan_title + ") \n (" + flag_fulldiskon;
    });
  });

  function doRecaptcha() {
    let captchaKey = sessionStorage.getItem("siteKey");
    return new Promise((resolve, reject) => {
      grecaptcha.ready(function () {
        grecaptcha.execute(captchaKey, { action: "submit" }).then(function (t) {
          state = State.success;
          resolve(t);
        });
      });
    });
  }

  async function inittogel_432d(e) {
    loadDisplay = true;
    const res = await fetch("/api/inittogel_432d", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        t: excryptData(
          JSON.stringify({
            company: client_company.toUpperCase(),
            pasaran_code: pasaran_code,
            permainan: e,
          }),
        ),
      }),
    });

    group_btn_beli = true;
    loadDisplay = false;
    const json = await res.json();
    record = json.record;
    minimal_bet = parseInt(record[0]["min_bet"]);
    for (var i = 0; i < record.length; i++) {
      minimal_bet = parseInt(record[i]["min_bet"]);
      max4d = parseInt(record[i]["max4d_bet"]);
      max3d = parseInt(record[i]["max3d_bet"]);
      max3dd = parseInt(record[i]["max3dd_bet"]);
      max2d = parseInt(record[i]["max2d_bet"]);
      max2dd = parseInt(record[i]["max2dd_bet"]);
      max2dt = parseInt(record[i]["max2dt_bet"]);
      max4d_bet = parseInt(record[i]["max4d_bet"]);
      max3d_bet = parseInt(record[i]["max3d_bet"]);
      max3dd_bet = parseInt(record[i]["max3dd_bet"]);
      max2d_bet = parseInt(record[i]["max2d_bet"]);
      max2dd_bet = parseInt(record[i]["max2dd_bet"]);
      max2dt_bet = parseInt(record[i]["max2dt_bet"]);
      max4d_bet_bbdisc = parseInt(record[i]["max4d_bet_bbdisc"]);
      max3d_bet_bbdisc = parseInt(record[i]["max3d_bet_bbdisc"]);
      max3dd_bet_bbdisc = parseInt(record[i]["max3dd_bet_bbdisc"]);
      max2d_bet_bbdisc = parseInt(record[i]["max2d_bet_bbdisc"]);
      max2dd_bet_bbdisc = parseInt(record[i]["max2dd_bet_bbdisc"]);
      max2dt_bet_bbdisc = parseInt(record[i]["max2dt_bet_bbdisc"]);
      disc4d_bet = parseFloat(record[i]["disc4d_bet"]);
      disc3d_bet = parseFloat(record[i]["disc3d_bet"]);
      disc3dd_bet = parseFloat(record[i]["disc3dd_bet"]);
      disc2d_bet = parseFloat(record[i]["disc2d_bet"]);
      disc2dd_bet = parseFloat(record[i]["disc2dd_bet"]);
      disc2dt_bet = parseFloat(record[i]["disc2dt_bet"]);
      win4d_bet = parseInt(record[i]["win4d_bet"]);
      win3d_bet = parseInt(record[i]["win3d_bet"]);
      win3dd_bet = parseInt(record[i]["win3dd_bet"]);
      win2d_bet = parseInt(record[i]["win2d_bet"]);
      win2dd_bet = parseInt(record[i]["win2dd_bet"]);
      win2dt_bet = parseInt(record[i]["win2dt_bet"]);
      win4dnodiskon_bet = parseInt(record[i]["win4dnodiskon_bet"]);
      win3dnodiskon_bet = parseInt(record[i]["win3dnodiskon_bet"]);
      win3ddnodiskon_bet = parseInt(record[i]["win3ddnodiskon_bet"]);
      win2dnodiskon_bet = parseInt(record[i]["win2dnodiskon_bet"]);
      win2ddnodiskon_bet = parseInt(record[i]["win2ddnodiskon_bet"]);
      win2dtnodiskon_bet = parseInt(record[i]["win2dtnodiskon_bet"]);
      win4dbb_kena_bet = parseInt(record[i]["win4dbb_kena_bet"]);
      win3dbb_kena_bet = parseInt(record[i]["win3dbb_kena_bet"]);
      win3ddbb_kena_bet = parseInt(record[i]["win3ddbb_kena_bet"]);
      win2dbb_kena_bet = parseInt(record[i]["win2dbb_kena_bet"]);
      win2ddbb_kena_bet = parseInt(record[i]["win2ddbb_bet"]);
      win2dtbb_kena_bet = parseInt(record[i]["win2dtbb_kena_bet"]);
      win4dbb_bet = parseInt(record[i]["win4dbb_bet"]);
      win3dbb_bet = parseInt(record[i]["win3dbb_bet"]);
      win3ddbb_bet = parseInt(record[i]["win3ddbb_bet"]);
      win2dbb_bet = parseInt(record[i]["win2dbb_bet"]);
      win2ddbb_bet = parseInt(record[i]["win2ddbb_bet"]);
      win2dtbb_bet = parseInt(record[i]["win2dtbb_bet"]);
      limittotal4d_bet = parseInt(record[i]["limittotal4d_bet"]);
      limittotal3d_bet = parseInt(record[i]["limittotal3d_bet"]);
      limittotal3dd_bet = parseInt(record[i]["limittotal3dd_bet"]);
      limittotal2d_bet = parseInt(record[i]["limittotal2d_bet"]);
      limittotal2dd_bet = parseInt(record[i]["limittotal2dd_bet"]);
      limittotal2dt_bet = parseInt(record[i]["limittotal2dt_bet"]);
      limitline_4d = parseInt(record[i]["limitline_4d"]);
      limitline_3d = parseInt(record[i]["limitline_3d"]);
      limitline_3dd = parseInt(record[i]["limitline_3dd"]);
      limitline_2d = parseInt(record[i]["limitline_2d"]);
      limitline_2dd = parseInt(record[i]["limitline_2dd"]);
      limitline_2dt = parseInt(record[i]["limitline_2dt"]);
      bbfs = parseInt(record[i]["bbfs"]);
    }
  }
  async function limittogel(e) {
    loadDisplay = true;
    db_form4d_4d_count_temp = 0;
    db_form4d_3d_count_temp = 0;
    db_form4d_3dd_count_temp = 0;
    db_form4d_2d_count_temp = 0;
    db_form4d_2dd_count_temp = 0;
    db_form4d_2dt_count_temp = 0;
    const res = await fetch("/api/limittogel", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        t: excryptData(
          JSON.stringify({
            pasaran_idtransaction: parseInt(idtrxkeluaran),
            company: client_company,
            username: client_username,
            pasaran_code: pasaran_code,
            pasaran_periode: pasaran_periode,
            permainan: e,
          }),
        ),
      }),
    });
    const json = await res.json();
    loadDisplay = false;
    record = json.record;

    db_form4d_4d_count_temp = record.total_4d;
    db_form4d_3d_count_temp = record.total_3d;
    db_form4d_3dd_count_temp = record.total_3dd;
    db_form4d_2d_count_temp = record.total_2d;
    db_form4d_2dd_count_temp = record.total_2dd;
    db_form4d_2dt_count_temp = record.total_2dt;

    count_line_4d = count_line_4d + db_form4d_4d_count_temp;
    count_line_3d = count_line_3d + db_form4d_3d_count_temp;
    count_line_3dd = count_line_3dd + db_form4d_3dd_count_temp;
    count_line_2d = count_line_2d + db_form4d_2d_count_temp;
    count_line_2dd = count_line_2dd + db_form4d_2dd_count_temp;
    count_line_2dt = count_line_2dt + db_form4d_2dt_count_temp;
  }

  async function savetransaksi() {
    ctoken = await doRecaptcha();
    group_btn_beli = false;
    let chunkSize = 300;
    if (keranjang.length > 1500) {
      chunkSize = keranjang.length / 5;
    }
    let totalPost = 0;
    let totalBuy = 0;
    let round = 0;
    for (let i = 0; i < keranjang.length; i += chunkSize) {
      let total = totalkeranjang - totalBuy;
      const chunk = keranjang.slice(i, i + chunkSize);
      const res = await fetch("/api/save/transaksi", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          t: excryptData(
            JSON.stringify({
              pasaran_idtransaction: idtrxkeluaran,
              pasaran_idcomp: idcomppasaran,
              pasaran_code: pasaran_code,
              pasaran_periode: pasaran_periode,
              token: client_token,
              company: client_company,
              username: client_username,
              ipaddress: client_ipaddress,
              devicemember: source,
              timezone: client_timezone,
              data: chunk,
              total,
            }),
          ),
          y: round,
          remoteip: client_ipaddress,
          token: ctoken,
        }),
      });
      round++;
      const json = await res.json();
      state = json.status === "200" ? State.idle : State.requesting;
      totalPost = keranjang.length - i - chunkSize;
      totalBuy = totalBuy + json.totalbet;
      if (totalPost <= 0) {
        loadDisplay = false;
        if (json.status == "200") {
          css_loader = "display:none;";
          balance_credit = json.balance;
          Swal.fire({
            position: "center",
            icon: "success",
            title: "Tiket Anda sedang di proses",
            html:
              "<p>Mohon menunggu beberapa saat.</p> <br/>" +
              "<p>Total taruhan : " +
              new Intl.NumberFormat().format(totalBuy) +
              "</p>" +
              (json.error
                ? "<p class='text-danger'>Beberapa nomor tidak dapat dipasang dengan keterangan:<br><br>" +
                  json.error +
                  "</p>"
                : ""),
            showConfirmButton: false,
            timer: 5000,
            timerProgressBar: true,
            background: daylight ? "#fff" : "#171717",
            color: daylight ? "#00a86b" : "#ff9900",
            toast: true,
            width: json.error ? "42rem" : "32rem",
          });
          dispatch("handleInvoice", "call");
          reset();
        } else if (res.status === 524) {
          Swal.fire({
            position: "center",
            icon: "success",
            title: "Tiket Anda sedang di proses",
            html: "<p>Mohon menunggu beberapa saat.</p> <br/>",
            showConfirmButton: false,
            timer: 5000,
            timerProgressBar: true,
            background: daylight ? "#fff" : "#171717",
            color: daylight ? "#00a86b" : "#ff9900",
            toast: true,
          });
          dispatch("handleInvoice", "call");
          reset();
        } else {
          css_loader = "display:none;";
          if (json.status == "500" || json.status == "404") {
            group_btn_beli = true;
            Swal.fire({
              position: "center",
              icon: "error",
              html: json.message,
              showConfirmButton: false,
              timer: 3000,
              background: daylight ? "#fff" : "#171717",
              color: daylight ? "#00a86b" : "#ff9900",
              toast: true,
            });
          } else {
            group_btn_beli = true;
            Swal.fire({
              icon: "error",
              title: "Oops...",
              html: json.message,
              background: daylight ? "#fff" : "#171717",
              color: daylight ? "#00a86b" : "#ff9900",
              width: "42rem",
            });
          }
        }
        ctoken = "";
        sessionStorage.removeItem("432captcha");
        setTimeout(() => {
          limittogel("4-3-2");
        }, 10000);
      }
    }
  }

  function reset() {
    keranjang = [];
    group_btn_beli = true;
    totalkeranjang = 0;
    count_line_4d = 0;
    count_line_3d = 0;
    count_line_3dd = 0;
    count_line_2d = 0;
    count_line_2dd = 0;
    count_line_2dt = 0;
    temp_bulk_error = "";
    inittogel_432d("4-3-2");
  }

  function addKeranjang(
    nomor,
    game,
    bet,
    diskon_percen,
    diskon,
    bayar,
    win,
    kei,
    kei_percen,
    tipetoto,
  ) {
    let total_data = keranjang.length;
    let flag_data = false;
    temp_bulk_error = "";

    for (var i = 0; i < total_data; i++) {
      switch (game) {
        case "4D":
          if (nomor == keranjang[i].nomor.toString()) {
            let maxtotal_bayar4d = 0;
            for (var j = 0; j < keranjang.length; j++) {
              if ("4D" == keranjang[j].permainan) {
                if (nomor == keranjang[j].nomor) {
                  maxtotal_bayar4d =
                    parseInt(maxtotal_bayar4d) + parseInt(keranjang[j].bet);
                }
              }
            }
            if (
              parseInt(limittotal4d_bet) <
              parseInt(maxtotal_bayar4d) + parseInt(bet)
            ) {
              temp_bulk_error =
                "Nomor ini : " + nomor + " sudah melebihi LIMIT TOTAL 4D<br />";
              flag_data = true;
            }
          }
          break;
        case "3D":
          if (nomor == keranjang[i].nomor.toString()) {
            let maxtotal_bayar3d = 0;
            for (var j = 0; j < keranjang.length; j++) {
              if ("3D" == keranjang[j].permainan) {
                if (nomor == keranjang[j].nomor) {
                  maxtotal_bayar3d =
                    parseInt(maxtotal_bayar3d) + parseInt(keranjang[j].bet);
                }
              }
            }
            if (
              parseInt(limittotal3d_bet) <
              parseInt(maxtotal_bayar3d) + parseInt(bet)
            ) {
              temp_bulk_error =
                "Nomor ini : " + nomor + " sudah melebihi LIMIT TOTAL 3D<br />";
              flag_data = true;
            }
          }
          break;
        case "3DD":
          if (nomor == keranjang[i].nomor.toString()) {
            let maxtotal_bayar3dd = 0;
            for (var j = 0; j < keranjang.length; j++) {
              if ("3DD" == keranjang[j].permainan) {
                if (nomor == keranjang[j].nomor) {
                  maxtotal_bayar3dd =
                    parseInt(maxtotal_bayar3dd) + parseInt(keranjang[j].bet);
                }
              }
            }
            if (
              parseInt(limittotal3dd_bet) <
              parseInt(maxtotal_bayar3dd) + parseInt(bet)
            ) {
              temp_bulk_error =
                "Nomor ini : " +
                nomor +
                " sudah melebihi LIMIT TOTAL 3DD<br />";
              flag_data = true;
            }
          }
          break;
        case "2D":
          if (nomor == keranjang[i].nomor.toString()) {
            let maxtotal_bayar2d = 0;
            for (var j = 0; j < keranjang.length; j++) {
              if ("2D" == keranjang[j].permainan) {
                if (nomor == keranjang[j].nomor) {
                  maxtotal_bayar2d =
                    parseInt(maxtotal_bayar2d) + parseInt(keranjang[j].bet);
                }
              }
            }
            if (
              parseInt(limittotal2d_bet) <
              parseInt(maxtotal_bayar2d) + parseInt(bet)
            ) {
              temp_bulk_error =
                "Nomor ini : " + nomor + " sudah melebihi LIMIT TOTAL 2D<br />";
              flag_data = true;
            }
          }
          break;
        case "2DD":
          if (nomor == keranjang[i].nomor.toString()) {
            let maxtotal_bayar2dd = 0;
            for (var j = 0; j < keranjang.length; j++) {
              if ("2DD" == keranjang[j].permainan) {
                if (nomor == keranjang[j].nomor) {
                  maxtotal_bayar2dd =
                    parseInt(maxtotal_bayar2dd) + parseInt(keranjang[j].bet);
                }
              }
            }
            if (
              parseInt(limittotal2dd_bet) <
              parseInt(maxtotal_bayar2dd) + parseInt(bet)
            ) {
              temp_bulk_error =
                "Nomor ini : " +
                nomor +
                " sudah melebihi LIMIT TOTAL 2DD<br />";
              flag_data = true;
            }
          }
          break;
        case "2DT":
          if (nomor == keranjang[i].nomor.toString()) {
            let maxtotal_bayar2dt = 0;
            for (var j = 0; j < keranjang.length; j++) {
              if ("2DT" == keranjang[j].permainan) {
                if (nomor == keranjang[j].nomor) {
                  maxtotal_bayar2dt =
                    parseInt(maxtotal_bayar2dt) + parseInt(keranjang[j].bet);
                }
              }
            }
            if (
              parseInt(limittotal2dt_bet) <
              parseInt(maxtotal_bayar2dt) + parseInt(bet)
            ) {
              temp_bulk_error =
                "Nomor ini : " +
                nomor +
                " sudah melebihi LIMIT TOTAL 2DT<br />";
              flag_data = true;
            }
          }
          break;
      }
    }

    if (flag_data == false) {
      nomor_global = nomor_global + 1;
      const data = {
        id: nomor_global,
        nomor,
        permainan: game,
        bet,
        diskon,
        diskonpercen: diskon_percen,
        bayar,
        win,
        kei,
        kei_percen,
        tipetoto,
      };
      keranjang = [data, ...keranjang];
      count_keranjang();
    } else {
      totalkeranjang = totalkeranjang - bayar;
    }
  }

  const setCssLoader = () => {
    loadDisplay = true;
  };

  const addNomor432 = (e) => {
    let lenAngka = nomor_432;
    if (lenAngka.length < 4) {
      nomor_432 = nomor_432 + e;
    }
  };

  const clearNomor423 = (e) => {
    switch (e) {
      case "432":
        nomor_432 = "";
        break;
      case "set":
        nomorset = "";
        break;
      case "bbfs":
        nomorbbfs = "";
        break;
      case "ptas":
        nomoras = "";
        break;
      case "ptkop":
        nomorkop = "";
        break;
      case "ptkep":
        nomorkepala = "";
        break;
      case "ptekr":
        nomorekor = "";
        break;
      case "3dds":
        nomor3dd = "";
        break;
      case "2dds":
        nomor2dd = "";
        break;
      case "2dts":
        nomor2dt = "";
        break;
    }
  };
  const removekeranjang = (e) => {
    swalWithBootstrapButtons
      .fire({
        title:
          "Anda akan menghapus nomor pasangan : <strong>" +
          e.detail.nomor +
          "</strong>",
        html: "<h5>Apakah anda ingin melanjutkan?</h5>",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Ya, hapus sekarang!",
        cancelButtonText: "Tidak!",
        reverseButtons: true,
        background: daylight ? "#fff" : "#171717",
        color: daylight ? "#00a86b" : "#ff9900",
        allowOutsideClick: false,
      })
      .then((result) => {
        if (result.isConfirmed) {
          setCssLoader();
          keranjang = keranjang.filter(
            (keranjang) => keranjang.id != e.detail.idkeranjang,
          );
          totalkeranjang = totalkeranjang - e.detail.bayar;
          count_keranjang();
        }
      });
  };
  const removekeranjangall = (e) => {
    if (keranjang.length > 0) {
      swalWithBootstrapButtons
        .fire({
          title: "Anda akan menghapus semua nomor pasangan!",
          html: "<h5>Apakah anda ingin melanjutkan?</h5>",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Ya, hapus sekarang!",
          cancelButtonText: "Tidak!",
          reverseButtons: true,
          background: daylight ? "#fff" : "#171717",
          color: daylight ? "#00a86b" : "#ff9900",
          allowOutsideClick: false,
        })
        .then((result) => {
          if (result.isConfirmed) {
            setCssLoader();
            reset();
            count_keranjang();
          }
        });
    } else {
      loadDisplay = false;
      Swal.fire({
        position: "center",
        icon: "warning",
        title: "Tidak ada list transaksi",
        showConfirmButton: false,
        timer: 1500,
        background: daylight ? "#fff" : "#171717",
        color: daylight ? "#00a86b" : "#ff9900",
        toast: true,
      });
    }
    loadDisplay = false;
  };
  const handleSave = (e) => {
    e.preventDefault();
    let minKeranjang = parseInt(minimal_bet) * 2;
    if (keranjang.length > 0) {
      if (parseInt(totalkeranjang) < minKeranjang) {
        loadDisplay = false;
        Swal.fire({
          position: "center",
          icon: "warning",
          title: "Minimal Bayar sebesar Rp." + minKeranjang,
          showConfirmButton: false,
          timer: 2000,
          background: daylight ? "#fff" : "#171717",
          color: daylight ? "#00a86b" : "#ff9900",
          toast: true,
        });
      } else {
        let linkColor = daylight ? "#00a86b" : "#ff9900";
        swalWithBootstrapButtons
          .fire({
            title: "Apakah anda ingin melanjutkan?",
            html:
              "Total taruhan anda sebesar : <strong>IDR. " +
              new Intl.NumberFormat().format(totalkeranjang) +
              "</strong>",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Ya, pasang sekarang!",
            cancelButtonText: "Tidak!",
            reverseButtons: true,
            background: daylight ? "#fff" : "#171717",
            color: daylight ? "#00a86b" : "#ff9900",
            allowOutsideClick: false,
            footer: `<small style="text-align:center">This site is protected by reCAPTCHA and the Google 
                <a style="color:${linkColor}" href="https://policies.google.com/privacy" target="_blank">Privacy Policy</a> and
                <a style="color:${linkColor}" href="https://policies.google.com/terms" target="_blank">Terms of Service</a> apply.
            </small>`,
          })
          .then((result) => {
            if (result.isConfirmed) {
              loadDisplay = true;
              savetransaksi();
            } else if (
              /* Read more about handling dismissals below */
              result.dismiss === Swal.DismissReason.cancel
            ) {
              loadDisplay = false;
              Swal.fire({
                position: "center",
                icon: "info",
                title: "Transaksi dibatalkan",
                showConfirmButton: false,
                timer: 3000,
                background: daylight ? "#fff" : "#171717",
                color: daylight ? "#00a86b" : "#ff9900",
                toast: true,
              });
            }
          });
      }
    } else {
      loadDisplay = false;
      Swal.fire({
        position: "center",
        icon: "warning",
        title: "Tidak ada list transaksi",
        showConfirmButton: false,
        timer: 1500,
        background: daylight ? "#fff" : "#171717",
        color: daylight ? "#00a86b" : "#ff9900",
        toast: true,
      });
    }
    loadDisplay = false;
  };

  const handlePaymentType = () => {
    Swal.fire({
      html: "<h3>Pilih tipe pembayaran dibawah ini :</h3>",
      showDenyButton: true,
      showCancelButton: true,
      confirmButtonText: "Diskon",
      denyButtonText: `Non Diskon / Full`,
      cancelButtonText: `Bolak Balik / BB`,
      background: daylight ? "#fff" : "#171717",
      color: daylight ? "#00a86b" : "#ff9900",
      allowOutsideClick: false,
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        flag_fulldiskon = "DISC";
        max4d_bet = max4d_bet_bbdisc;
        max3d_bet = max3d_bet_bbdisc;
        max3dd_bet = max3dd_bet_bbdisc;
        max2d_bet = max2d_bet_bbdisc;
        max2dd_bet = max2dd_bet_bbdisc;
        max2dt_bet = max2dt_bet_bbdisc;
      } else if (result.isDenied) {
        flag_fulldiskon = "FULL";
        max4d_bet = max4d;
        max3d_bet = max3d;
        max3dd_bet = max3dd;
        max2d_bet = max2d;
        max2dd_bet = max2dd;
        max2dt_bet = max2dt;
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        flag_fulldiskon = "BB";
        max4d_bet = max4d_bet_bbdisc;
        max3d_bet = max3d_bet_bbdisc;
        max3dd_bet = max3dd_bet_bbdisc;
        max2d_bet = max2d_bet_bbdisc;
        max2dd_bet = max2dd_bet_bbdisc;
        max2dt_bet = max2dt_bet_bbdisc;
      }
      game_title = permainan_title + ") \n (" + flag_fulldiskon;
    });
  };
  function count_keranjang() {
    let count_4d = 0;
    let count_3d = 0;
    let count_3dd = 0;
    let count_2d = 0;
    let count_2dd = 0;
    let count_2dt = 0;
    for (let i = 0; i < keranjang.length; i++) {
      switch (keranjang[i].permainan.toString()) {
        case "4D":
          count_4d = count_4d + 1;
          break;
        case "3D":
          count_3d = count_3d + 1;
          break;
        case "3DD":
          count_3dd = count_3dd + 1;
          break;
        case "2D":
          count_2d = count_2d + 1;
          break;
        case "2DD":
          count_2dd = count_2dd + 1;
          break;
        case "2DT":
          count_2dt = count_2dt + 1;
          break;
      }
    }
    count_line_4d = count_4d + db_form4d_4d_count_temp;
    count_line_3d = count_3d + db_form4d_3d_count_temp;
    count_line_3dd = count_3dd + db_form4d_3dd_count_temp;
    count_line_2d = count_2d + db_form4d_2d_count_temp;
    count_line_2dd = count_2dd + db_form4d_2dd_count_temp;
    count_line_2dt = count_2dt + db_form4d_2dt_count_temp;

    css_loader = "display:none;";
    loadDisplay = false;
  }

  //432 - INIT FORM
  let nomor_432 = "";
  let nomor_432_input;
  let bet_432 = "";
  //432SET - INIT FORM
  let nomorset = "";
  let nomorset_input;
  let betset_1 = "";
  let betset_2 = "";
  let betset_3 = "";
  let betset_4 = "";
  let betset_5 = "";
  let betset_6 = "";

  //BBFS - INIT FORM
  let nomorbbfs = "";
  let nomorbbfs_input;
  let bet_1 = "";
  let bet_2 = "";
  let bet_3 = "";
  let bet_4 = "";
  let bet_5 = "";
  let bet_6 = "";

  //WAP
  let nomorwap = "";
  let nomorwap_input;
  //pola tarung
  let nomoras = "";
  let nomoras_input;
  let nomorkop = "";
  let nomorkop_input;
  let nomorkepala = "";
  let nomorekor = "";
  let bet_tarung = "";
  let bet_tarung_input;
  //3DD - INIT FORM
  let nomor3dd = "";
  let nomor3dd_input;
  let bet_3dd = "";
  //2DD - INIT FORM
  let nomor2dd = "";
  let nomor2dd_input;
  let bet_2dd = "";
  //2DT - INIT FORM
  let nomor2dt = "";
  let nomor2dt_input;
  let bet_2dt = "";
  //QUICK 2D
  let quick_pilihan1;
  let quick_pilihan2;
  let quick_bet = "";
  let quick_pilihan1_input;
  let quick_pilihan2_input;
  let quick_bet_input;

  let generate2D = [];
  let generate2DD = [];
  let generate2DT = [];
  let generate3D = [];
  let generate3DD = [];
  let generate4D = [];
  let data_bbfs = [];
  let nol = 0;
  let satu = 0;
  let dua = 0;
  let tiga = 0;
  let empat = 0;
  let lima = 0;
  let enam = 0;
  let tujuh = 0;
  let delapan = 0;
  let sembilan = 0;
  let temp_bulk_error = "";
  let quick2dmax = "";
  function form_clear(e) {
    switch (e) {
      case "4-3-2":
        nomor_432 = "";
        nomor_432_input.focus();
        // bet_432 = "";
        break;
      case "432SET":
        nomorset = "";
        nomorset_input.focus();
        betset_1 = "";
        betset_2 = "";
        betset_3 = "";
        betset_4 = "";
        betset_5 = "";
        betset_6 = "";
        break;
      case "bbfs":
        nomorbbfs = "";
        nomorbbfs_input.focus();
        // bet_432 = "";
        bet_1 = "";
        bet_2 = "";
        bet_3 = "";
        bet_4 = "";
        bet_5 = "";
        bet_6 = "";

        break;
      case "wap":
        nomorwap = "";
        nomorwap_input.focus();
        break;
      case "polatarung":
        nomoras = "";
        nomoras_input.focus();
        nomorkop = "";
        nomorkepala = "";
        nomorekor = "";
        bet_tarung = "";
        break;
      case "3DD":
        nomor3dd = "";
        nomor3dd_input.focus();
        bet_3dd = "";
        break;
      case "2DD":
        nomor2dd = "";
        nomor2dd_input.focus();
        bet_2dd = "";
        break;
      case "2DT":
        nomor2dt = "";
        nomor2dt_input.focus();
        bet_2dt = "";
        break;
      case "quick2D":
        quick_pilihan1;
        quick_pilihan2;
        quick_bet = "";
        quick_pilihan1_input;
        quick_pilihan2_input;
        // quick_bet_input.focus();
        break;
    }
  }
  function form4d_add() {
    let flag = true;
    let game = nomor_432.length;
    let diskon = 0;
    let diskonpercen = 0;
    let win = 0;
    let bayar = 0;
    let nmgame = "";
    let msg = "";
    if (nomor_432 == "") {
      nomor_432_input.focus();
      flag = false;
    }
    let tempbintang = 0;
    let tempnumber = 0;
    for (let i = 0; i <= 3; i++) {
      if (nomor_432[i] == "*") {
        tempbintang = tempbintang + 1;
      }
      let numbera = parseInt(nomor_432[i]);
      if (!isNaN(numbera)) {
        tempnumber = tempnumber + 1;
      }
    }

    if (tempbintang > 2) {
      flag = false;
      form_clear("4-3-2");
      nomor_input.focus();
      msg +=
        "Format Salah\nContoh:\n4D: 1234\n3D: 123\n3DD: 123*\n2D: 12 atau **10\n2DD: 10**\n2DT: *10*\n";
    }

    if (nomor_432[3] == "*" && parseInt(tempnumber) == 3) {
      nomor_432 =
        String(nomor_432[0]) + String(nomor_432[1]) + String(nomor_432[2]);
      game = "3DD";
    }

    if (nomor_432[0] == "*" && parseInt(tempnumber) == 3) {
      nomor_432 =
        String(nomor_432[1]) + String(nomor_432[2]) + String(nomor_432[3]);
      game = "3";
    }

    if (
      nomor_432[0] == "*" &&
      nomor_432[1] == "*" &&
      parseInt(tempnumber) == 2
    ) {
      nomor_432 = String(nomor_432[2]) + String(nomor_432[3]);
      game = "2";
    }

    if (
      nomor_432[2] == "*" &&
      nomor_432[3] == "*" &&
      parseInt(tempnumber) == 2
    ) {
      nomor_432 = String(nomor_432[0]) + String(nomor_432[1]);
      game = "2DD";
    }

    if (
      nomor_432[0] == "*" &&
      nomor_432[3] == "*" &&
      parseInt(tempnumber) == 2
    ) {
      nomor_432 = String(nomor_432[1]) + String(nomor_432[2]);
      game = "2DT";
    }

    if (nomor_432.length < 2) {
      flag = false;
      nomor_432_input.focus();
      nomor_432 = "";
      msg += "Minimal Nomor 2 Digit" + "\n";
    }

    if (bet_432 == "") {
      flag = false;
      msg += "Bet tidak boleh kosong" + "\n";
    }

    if (parseInt(bet_432) < parseInt(minimal_bet)) {
      bet_432 = minimal_bet;
      flag = false;
      msg +=
        "Minimal Bet : " + new Intl.NumberFormat().format(minimal_bet) + "\n";
    }

    if (game.toString() == "4") {
      if (parseInt(bet_432) > parseInt(max4d_bet)) {
        // bet_432 = minimal_bet;
        flag = false;
        msg +=
          `Maximal Bet 4D (${flag_fulldiskon}) : ` +
          new Intl.NumberFormat().format(max4d_bet) +
          "\n";
      }
      if (checkLimitLine("4D") == false) {
        flag = false;
        msg += "Maximal Line 4D : " + limitline_4d + "\n";
        form_clear("4-3-2");
      }
    }

    if (game.toString() == "3") {
      if (parseInt(bet_432) > parseInt(max3d_bet)) {
        // bet_432 = minimal_bet;
        flag = false;
        msg +=
          `Maximal Bet 3D (${flag_fulldiskon}): ` +
          new Intl.NumberFormat().format(max3d_bet) +
          "\n";
      }
      if (checkLimitLine("3D") == false) {
        flag = false;
        msg += "Maximal Line 3D : " + limitline_3d + "\n";
        form_clear("4-3-2");
      }
      if (parseInt(tempnumber) != 3) {
        flag = false;
        msg += "Format Nomor 3D Salah \n";
        form_clear("4-3-2");
      }
    }

    if (game.toString() == "3DD") {
      if (parseInt(bet_432) > parseInt(max3dd_bet)) {
        // bet_432 = minimal_bet;
        flag = false;
        msg +=
          `Maximal Bet 3DD (${flag_fulldiskon}): ` +
          new Intl.NumberFormat().format(max3dd_bet) +
          "\n";
      }
      if (checkLimitLine("3DD") == false) {
        flag = false;
        msg += "Maximal Line 3DD : " + limitline_3dd + "\n";
        form_clear("4-3-2");
      }
      if (parseInt(tempnumber) != 3) {
        flag = false;
        msg += "Format Nomor 3D Salah \n";
        form_clear("4-3-2");
      }
    }
    if (game.toString() == "2") {
      if (parseInt(bet_432) > parseInt(max2d_bet)) {
        // bet_432 = minimal_bet;
        flag = false;
        msg +=
          `Maximal Bet 2D (${flag_fulldiskon}): ` +
          new Intl.NumberFormat().format(max2d_bet) +
          "\n";
      }
      if (checkLimitLine("2D") == false) {
        flag = false;
        msg += "Maximal Line 2D : " + limitline_2d + "\n";
        form_clear("4-3-2");
      }
      if (parseInt(tempnumber) != 2) {
        flag = false;
        msg += "Format Nomor 2D Salah \n";
        form_clear("4-3-2");
      }
    }
    if (game.toString() == "2DD") {
      if (parseInt(bet_432) > parseInt(max2dd_bet)) {
        // bet_432 = minimal_bet;
        flag = false;
        msg +=
          `Maximal Bet 2D (${flag_fulldiskon}): ` +
          new Intl.NumberFormat().format(max2dd_bet) +
          "\n";
      }
      if (checkLimitLine("2DD") == false) {
        flag = false;
        msg += "Maximal Line 2DD : " + limitline_2dd;
        form_clear("4-3-2");
      }
      if (parseInt(tempnumber) != 2) {
        flag = false;
        msg += "Format Nomor 2D Salah \n";
        form_clear("4-3-2");
      }
    }
    if (game.toString() == "2DT") {
      if (parseInt(bet_432) > parseInt(max2dt_bet)) {
        // bet = minimal_bet;
        flag = false;
        msg +=
          `Maximal Bet 2DT (${flag_fulldiskon}): ` +
          new Intl.NumberFormat().format(max2dt_bet) +
          "\n";
      }
      if (checkLimitLine("2DT") == false) {
        flag = false;
        msg += "Maximal Line 2DT : " + limitline_2dt + "\n";
        form_clear("4-3-2");
      }
    }
    if (flag) {
      switch (game.toString()) {
        case "4":
          nmgame = "4D";
          switch (flag_fulldiskon) {
            case "FULL":
              diskon = 0;
              diskonpercen = 0;
              win = win4dnodiskon_bet;
              break;
            case "BB":
              diskon = 0;
              diskonpercen = 0;
              win = win4dbb_kena_bet;
              break;
            default:
              diskon = (parseFloat(bet_432) * parseFloat(disc4d_bet)).toFixed(
                0,
              );
              diskonpercen = disc4d_bet;
              win = win4d_bet;
              break;
          }
          break;
        case "3":
          nmgame = "3D";
          switch (flag_fulldiskon) {
            case "FULL":
              diskon = 0;
              diskonpercen = 0;
              win = win3dnodiskon_bet;
              break;
            case "BB":
              diskon = 0;
              diskonpercen = 0;
              win = win3dbb_kena_bet;
              break;
            default:
              diskon = (parseFloat(bet_432) * parseFloat(disc3d_bet)).toFixed(
                0,
              );

              diskonpercen = disc3d_bet;
              win = win3d_bet;
              break;
          }
          break;
        case "3DD":
          nmgame = "3DD";
          switch (flag_fulldiskon) {
            case "FULL":
              diskon = 0;
              diskonpercen = 0;
              win = win3ddnodiskon_bet;
              break;
            case "BB":
              diskon = 0;
              diskonpercen = 0;
              win = win3ddbb_kena_bet;
              break;
            default:
              diskon = (parseFloat(bet_432) * parseFloat(disc3dd_bet)).toFixed(
                0,
              );
              diskonpercen = disc3dd_bet;
              win = win3dd_bet;
              break;
          }
          break;
        case "2":
          nmgame = "2D";
          switch (flag_fulldiskon) {
            case "FULL":
              diskon = 0;
              diskonpercen = 0;
              win = win2dnodiskon_bet;
              break;
            case "BB":
              diskon = 0;
              diskonpercen = 0;
              win = win2dbb_kena_bet;
              break;
            default:
              diskon = (parseFloat(bet_432) * parseFloat(disc2d_bet)).toFixed(
                0,
              );
              diskonpercen = disc2d_bet;
              win = win2d_bet;
              break;
          }
          break;
        case "2DD":
          nmgame = "2DD";
          switch (flag_fulldiskon) {
            case "FULL":
              diskon = 0;
              diskonpercen = 0;
              win = win2ddnodiskon_bet;
              break;
            case "BB":
              diskon = 0;
              diskonpercen = 0;
              win = win2ddbb_kena_bet;
              break;
            default:
              diskon = (parseFloat(bet_432) * parseFloat(disc2dd_bet)).toFixed(
                0,
              );
              diskonpercen = disc2dd_bet;
              win = win2dd_bet;
              break;
          }
          break;
        case "2DT":
          nmgame = "2DT";
          switch (flag_fulldiskon) {
            case "FULL":
              diskon = 0;
              diskonpercen = 0;
              win = win2dtnodiskon_bet;
              break;
            case "BB":
              diskon = 0;
              diskonpercen = 0;
              win = win2dtbb_kena_bet;
              break;
            default:
              diskon = (parseFloat(bet_432) * parseFloat(disc2dt_bet)).toFixed(
                0,
              );
              diskonpercen = disc2dt_bet;
              win = win2dt_bet;
              break;
          }
          break;
      }
      bayar = parseInt(bet_432) - parseInt(Math.ceil(diskon));
      totalkeranjang = bayar + totalkeranjang;

      addKeranjang(
        nomor_432,
        nmgame,
        bet_432,
        diskonpercen,
        diskon,
        bayar,
        win,
        0,
        0,
        flag_fulldiskon,
      );
      form_clear("4-3-2");
      if (temp_bulk_error != "") {
        // let myModal = new bootstrap.Modal(
        //   document.getElementById("modalError")
        // );
        // myModal.show();
        Swal.fire({
          icon: "error",
          title: "Oops...",
          html: temp_bulk_error,
          background: daylight ? "#fff" : "#171717",
          color: daylight ? "#00a86b" : "#ff9900",
        });
      }
    }
    if (msg != "") {
      Swal.fire({
        position: "center",
        icon: "warning",
        title: msg,
        showConfirmButton: false,
        timer: 1500,
        background: daylight ? "#fff" : "#171717",
        color: daylight ? "#00a86b" : "#ff9900",
        toast: true,
      });
    }
  }

  function form4dset_add() {
    let flag = true;
    let flagfinish = false;
    let game = nomorset.length;
    let bet = 0;
    let diskon = 0;
    let diskonpercen = 0;
    let win = 0;
    let bayar = 0;
    let nomor = "";
    let msg = "";

    if (nomorset == "") {
      nomorset_input.focus();
      flag = false;
    }

    if (flag) {
      if (parseInt(betset_1) > 0) {
        let flag_4D = true;
        if (parseInt(betset_1) < parseInt(minimal_bet)) {
          betset_1 = minimal_bet;
          flag_4D = false;
          msg +=
            "Minimal Bet 4D : " +
            new Intl.NumberFormat().format(minimal_bet) +
            "\n";
        }
        if (parseInt(betset_1) > parseInt(max4d_bet)) {
          betset_1 = max4d_bet;
          flag_4D = false;
          msg +=
            `Maximal Bet 4D (${flag_fulldiskon}): ` +
            new Intl.NumberFormat().format(max4d_bet) +
            "\n";
        }
        if (flag_4D) {
          if (game.toString() == "4") {
            switch (flag_fulldiskon) {
              case "FULL":
                diskon = 0;
                diskonpercen = 0;
                win = win4dnodiskon_bet;
                break;
              case "BB":
                diskon = 0;
                diskonpercen = 0;
                win = win4dbb_kena_bet;
                break;
              default:
                diskon = Math.round(
                  parseFloat(betset_1) * parseFloat(disc4d_bet),
                ).toFixed(0);
                diskonpercen = disc4d_bet;
                win = win4d_bet;
                break;
            }
            bayar = parseInt(betset_1) - parseInt(diskon);
            if (checkLimitLine("4D") == true) {
              nomor = nomorset;
              totalkeranjang = bayar + totalkeranjang;
              bet = betset_1;
              addKeranjang(
                nomor,
                "4D",
                bet,
                diskonpercen,
                diskon,
                bayar,
                win,
                0,
                0,
                flag_fulldiskon,
              );
              flagfinish = true;
            } else {
              msg = "Line 4D sudah melebihi limit";
              Swal.fire({
                position: "center",
                icon: "danger",
                title: msg,
                showConfirmButton: false,
                timer: 1500,
                background: daylight ? "#fff" : "#171717",
                color: daylight ? "#00a86b" : "#ff9900",
                toast: true,
              });
            }
          }
        }
      }
      if (parseInt(betset_2) > 0) {
        let flag_3D = true;
        if (parseInt(betset_2) < parseInt(minimal_bet)) {
          betset_2 = minimal_bet;
          flag_3D = false;
          msg +=
            "Minimal Bet 3D : " +
            new Intl.NumberFormat().format(minimal_bet) +
            "\n";
        }
        if (parseInt(betset_2) > parseInt(max3d_bet)) {
          betset_2 = max3d_bet;
          flag_3D = false;
          msg +=
            `Maximal Bet 3D (${flag_fulldiskon}): ` +
            new Intl.NumberFormat().format(max3d_bet) +
            "\n";
        }
        if (flag_3D) {
          if (game.toString() == "4" || game.toString() == "3") {
            switch (flag_fulldiskon) {
              case "FULL":
                diskon = 0;
                diskonpercen = 0;
                win = win3dnodiskon_bet;
                break;
              case "BB":
                diskon = 0;
                diskonpercen = 0;
                win = win3dbb_kena_bet;
                break;
              default:
                diskon = Math.round(
                  parseFloat(betset_2) * parseFloat(disc3d_bet),
                ).toFixed(0);
                diskonpercen = disc3d_bet;
                win = win3d_bet;
                break;
            }
            bayar = parseInt(betset_2) - parseInt(diskon);
            if (checkLimitLine("3D") == true) {
              switch (game.toString()) {
                case "4":
                  nomor = nomorset[1] + nomorset[2] + nomorset[3];
                  break;
                case "3":
                  nomor = nomorset[0] + nomorset[1] + nomorset[2];
                  break;
              }
              totalkeranjang = bayar + totalkeranjang;
              bet = betset_2;
              addKeranjang(
                nomor,
                "3D",
                bet,
                diskonpercen,
                diskon,
                bayar,
                win,
                0,
                0,
                flag_fulldiskon,
              );
              flagfinish = true;
            } else {
              msg = "Line 3D sudah melebihi limit";
              Swal.fire({
                position: "center",
                icon: "danger",
                title: msg,
                showConfirmButton: false,
                timer: 1500,
                background: daylight ? "#fff" : "#171717",
                color: daylight ? "#00a86b" : "#ff9900",
                toast: true,
              });
            }
          }
        }
      }
      if (parseInt(betset_3) > 0) {
        let flag_2D = true;
        if (parseInt(betset_3) < parseInt(minimal_bet)) {
          betset_3 = minimal_bet;
          flag_2D = false;
          msg +=
            "Minimal Bet 2D : " +
            new Intl.NumberFormat().format(minimal_bet) +
            "\n";
        }
        if (parseInt(betset_3) > parseInt(max2d_bet)) {
          betset_3 = max2d_bet;
          flag_2D = false;
          msg +=
            `Maximal Bet 2D (${flag_fulldiskon}): ` +
            new Intl.NumberFormat().format(max2d_bet) +
            "\n";
        }
        if (flag_2D) {
          if (
            game.toString() == "4" ||
            game.toString() == "3" ||
            game.toString() == "2"
          ) {
            switch (flag_fulldiskon) {
              case "FULL":
                diskon = 0;
                diskonpercen = 0;
                win = win2dnodiskon_bet;
                break;
              case "BB":
                diskon = 0;
                diskonpercen = 0;
                win = win2dbb_kena_bet;
                break;
              default:
                diskon = Math.round(
                  parseFloat(betset_3) * parseFloat(disc2d_bet),
                ).toFixed(0);
                diskonpercen = disc2d_bet;
                win = win2d_bet;
                break;
            }

            bayar = parseInt(betset_3) - parseInt(diskon);
            if (checkLimitLine("2D") == true) {
              switch (game.toString()) {
                case "4":
                  nomor = nomorset[2] + nomorset[3];
                  break;
                case "3":
                  nomor = nomorset[1] + nomorset[2];
                  break;
                case "2":
                  nomor = nomorset[0] + nomorset[1];
                  break;
              }
              totalkeranjang = bayar + totalkeranjang;
              bet = betset_3;
              addKeranjang(
                nomor,
                "2D",
                bet,
                diskonpercen,
                diskon,
                bayar,
                win,
                0,
                0,
                flag_fulldiskon,
              );
              flagfinish = true;
            } else {
              msg = "Line 2D sudah melebihi limit";
              Swal.fire({
                position: "center",
                icon: "danger",
                title: msg,
                showConfirmButton: false,
                timer: 1500,
                background: daylight ? "#fff" : "#171717",
                color: daylight ? "#00a86b" : "#ff9900",
                toast: true,
              });
            }
          }
        }
      }
      if (parseInt(betset_4) > 0) {
        let flag_2DD = true;
        if (parseInt(betset_4) < parseInt(minimal_bet)) {
          betset_4 = minimal_bet;
          flag_2DD = false;
          msg +=
            "Minimal Bet 2DD : " +
            new Intl.NumberFormat().format(minimal_bet) +
            "\n";
        }
        if (parseInt(betset_4) > parseInt(max2dd_bet)) {
          betset_4 = max2dd_bet;
          flag_2DD = false;
          msg +=
            `Maximal Bet 2DD (${flag_fulldiskon}): ` +
            new Intl.NumberFormat().format(max2dd_bet) +
            "\n";
        }
        if (flag_2DD) {
          if (
            game.toString() == "4" ||
            game.toString() == "3" ||
            game.toString() == "2"
          ) {
            switch (flag_fulldiskon) {
              case "FULL":
                diskon = 0;
                diskonpercen = 0;
                win = win2ddnodiskon_bet;
                break;
              case "BB":
                diskon = 0;
                diskonpercen = 0;
                win = win2ddbb_kena_bet;
                break;
              default:
                diskon = Math.round(
                  parseFloat(betset_4) * parseFloat(disc2dd_bet),
                ).toFixed(0);
                diskonpercen = disc2dd_bet;
                win = win2dd_bet;
                break;
            }

            bayar = parseInt(betset_4) - parseInt(diskon);
            if (checkLimitLine("2DD") == true) {
              switch (game.toString()) {
                case "4":
                  nomor = nomorset[0] + nomorset[1];
                  break;
                case "3":
                  nomor = nomorset[0] + nomorset[1];
                  break;
                case "2":
                  nomor = nomorset[0] + nomorset[1];
                  break;
              }
              totalkeranjang = bayar + totalkeranjang;
              bet = betset_4;
              addKeranjang(
                nomor,
                "2DD",
                bet,
                diskonpercen,
                diskon,
                bayar,
                win,
                0,
                0,
                flag_fulldiskon,
              );
              flagfinish = true;
            } else {
              msg = "Line 2DD sudah melebihi limit";
              Swal.fire({
                position: "center",
                icon: "danger",
                title: msg,
                showConfirmButton: false,
                timer: 1500,
                background: daylight ? "#fff" : "#171717",
                color: daylight ? "#00a86b" : "#ff9900",
                toast: true,
              });
            }
          }
        }
      }
      if (parseInt(betset_5) > 0) {
        let flag_2DT = true;
        if (parseInt(betset_5) < parseInt(minimal_bet)) {
          betset_5 = minimal_bet;
          flag_2DT = false;
          msg +=
            "Minimal Bet 2DT : " +
            new Intl.NumberFormat().format(minimal_bet) +
            "\n";
        }
        if (parseInt(betset_5) > parseInt(max2dt_bet)) {
          betset_5 = max2dt_bet;
          flag_2DT = false;
          msg +=
            `Maximal Bet 2DT (${flag_fulldiskon}): ` +
            new Intl.NumberFormat().format(max2dt_bet) +
            "\n";
        }
        if (flag_2DT) {
          if (
            game.toString() == "4" ||
            game.toString() == "3" ||
            game.toString() == "2"
          ) {
            switch (flag_fulldiskon) {
              case "FULL":
                diskon = 0;
                diskonpercen = 0;
                win = win2dtnodiskon_bet;
                break;
              case "BB":
                diskon = 0;
                diskonpercen = 0;
                win = win2dtbb_kena_bet;
                break;
              default:
                diskon = Math.round(
                  parseFloat(betset_5) * parseFloat(disc2dt_bet),
                ).toFixed(0);
                diskonpercen = disc2dt_bet;
                win = win2dt_bet;
                break;
            }

            bayar = parseInt(betset_5) - parseInt(diskon);
            if (checkLimitLine("2DT") == true) {
              switch (game.toString()) {
                case "4":
                  nomor = nomorset[1] + nomorset[2];
                  break;
                case "3":
                  nomor = nomorset[1] + nomorset[2];
                  break;
                case "2":
                  nomor = nomorset[0] + nomorset[1];
                  break;
              }
              totalkeranjang = bayar + totalkeranjang;
              bet = betset_5;
              addKeranjang(
                nomor,
                "2DT",
                bet,
                diskonpercen,
                diskon,
                bayar,
                win,
                0,
                0,
                flag_fulldiskon,
              );
              flagfinish = true;
            } else {
              msg = "Line 2DT sudah melebihi limit";
              Swal.fire({
                position: "center",
                icon: "danger",
                title: msg,
                showConfirmButton: false,
                timer: 1500,
                background: daylight ? "#fff" : "#171717",
                color: daylight ? "#00a86b" : "#ff9900",
                toast: true,
              });
            }
          }
        }
      }
      if (parseInt(betset_6) > 0) {
        let flag_3DD = true;
        if (parseInt(betset_6) < parseInt(minimal_bet)) {
          betset_6 = minimal_bet;
          flag_3DD = false;
          msg +=
            "Minimal Bet 3DD : " +
            new Intl.NumberFormat().format(minimal_bet) +
            "\n";
        }
        if (parseInt(betset_6) > parseInt(max3dd_bet)) {
          betset_6 = max3dd_bet;
          flag_3DD = false;
          msg +=
            `Maximal Bet 3DD (${flag_fulldiskon}): ` +
            new Intl.NumberFormat().format(max3dd_bet) +
            "\n";
        }
        if (flag_3DD) {
          if (game.toString() == "4" || game.toString() == "3") {
            switch (flag_fulldiskon) {
              case "FULL":
                diskon = 0;
                diskonpercen = 0;
                win = win3ddnodiskon_bet;
                break;
              case "BB":
                diskon = 0;
                diskonpercen = 0;
                win = win3ddbb_kena_bet;
                break;
              default:
                diskon = Math.round(
                  parseFloat(betset_6) * parseFloat(disc3dd_bet),
                ).toFixed(0);
                diskonpercen = disc3dd_bet;
                win = win3dd_bet;
                break;
            }
            bayar = parseInt(betset_6) - parseInt(diskon);
            if (checkLimitLine("3DD") == true) {
              switch (game.toString()) {
                case "4":
                  nomor = nomorset[0] + nomorset[1] + nomorset[2];
                  break;
                case "3":
                  nomor = nomorset[0] + nomorset[1] + nomorset[2];
                  break;
              }
              totalkeranjang = bayar + totalkeranjang;
              bet = betset_6;
              addKeranjang(
                nomor,
                "3DD",
                bet,
                diskonpercen,
                diskon,
                bayar,
                win,
                0,
                0,
                flag_fulldiskon,
              );
              flagfinish = true;
            } else {
              msg = "Line 3DD sudah melebihi limit";
              Swal.fire({
                position: "center",
                icon: "danger",
                title: msg,
                showConfirmButton: false,
                timer: 1500,
                background: daylight ? "#fff" : "#171717",
                color: daylight ? "#00a86b" : "#ff9900",
                toast: true,
              });
            }
          }
        }
      }
      if (msg != "") {
        Swal.fire({
          position: "center",
          icon: "danger",
          title: msg,
          showConfirmButton: false,
          timer: 1500,
          background: daylight ? "#fff" : "#171717",
          color: daylight ? "#00a86b" : "#ff9900",
          toast: true,
        });
      }
      if (flagfinish) {
        form_clear("432SET");
      }
    }
  }

  function formbbfs_add() {
    generate4D = [];
    generate3D = [];
    generate3DD = [];
    generate2D = [];
    generate2DD = [];
    generate2DT = [];
    data_bbfs = [];
    nol = 0;
    satu = 0;
    dua = 0;
    tiga = 0;
    empat = 0;
    lima = 0;
    enam = 0;
    tujuh = 0;
    delapan = 0;
    sembilan = 0;
    let found = false;
    let flag = true;
    let bet = 0;
    let game = nomorbbfs.length;
    let nmgame = "";
    let nomor = "";
    let diskon = 0;
    let diskonpercen = 0;
    let win = 0;
    let kei = 0;
    let kei_percen = 0;
    let bayar = 0;
    let msg_error = "";
    data_bbfs = [];
    if (nomorbbfs == "") {
      nomorbbfs_input.focus();
      flag = false;
      msg_error += "Nomor Tidak Boleh Kosong<br>";
    }
    if (nomorbbfs.length < 2 || nomorbbfs.length > bbfs) {
      flag = false;
      nomorbbfs_input.focus();
      msg_error += "Nomor 2 - " + bbfs + " Digit<br>";
    } else {
      countangkabbfs(nomorbbfs);
    }

    //VALIDASI BET 4D/3D/3DD/2D/2DD/2DT
    if (flag) {
      //
      if (parseInt(bet_1) > 0) {
        let flag_4D = true;
        if (parseInt(bet_1) < parseInt(minimal_bet)) {
          bet_1 = minimal_bet;
          flag_4D = false;
          msg_error += "Minimal Bet 4D : " + minimal_bet + "<br>";
        }
        if (parseInt(bet_1) > parseInt(max4d_bet)) {
          bet_1 = max4d_bet;
          flag_4D = false;
          msg_error +=
            `Maximal Bet 4D (${flag_fulldiskon}): ` +
            new Intl.NumberFormat().format(max4d_bet) +
            "<br>";
        }
        if (flag_4D) {
          switch (flag_fulldiskon) {
            case "FULL":
              diskon = 0;
              diskonpercen = 0;
              win = win4dnodiskon_bet;
              break;
            case "BB":
              diskon = 0;
              diskonpercen = 0;
              win = win4dbb_kena_bet;
              break;
            default:
              diskon = Math.round(
                parseFloat(bet_1) * parseFloat(disc4d_bet),
              ).toFixed(0);
              diskonpercen = disc4d_bet;
              win = win4d_bet;
              break;
          }
          bayar = parseInt(bet_1) - parseInt(diskon);
          for (let a = 0; a < data_bbfs.length; a++) {
            for (let b = 0; b < data_bbfs.length; b++) {
              for (let c = 0; c < data_bbfs.length; c++) {
                for (let d = 0; d < data_bbfs.length; d++) {
                  let dat =
                    data_bbfs[a] + data_bbfs[b] + data_bbfs[c] + data_bbfs[d];
                  if (generate4D.length > 0) {
                    for (let x = 0; x < generate4D.length; x++) {
                      if (dat == generate4D[x]) {
                        found = true;
                      }
                    }
                    if (found == false) {
                      generate4D.push(dat);
                    }
                  } else {
                    generate4D.push(dat);
                  }
                  found = false;
                  dat = "";
                }
              }
            }
          }
          for (let x = 0; x < generate4D.length; x++) {
            if (checkcountangka(generate4D[x]) == true) {
              if (checkLimitLine("4D") == true) {
                nomor = generate4D[x];
                totalkeranjang = bayar + totalkeranjang;
                bet = bet_1;
                addKeranjang(
                  nomor,
                  "4D",
                  bet,
                  diskonpercen,
                  diskon,
                  bayar,
                  win,
                  0,
                  0,
                  flag_fulldiskon,
                );
              } else {
                msg_error += "Line 4D sudah melebihi limit<br>";
                break;
              }
            }
          }
        }
      }
      if (parseInt(bet_2) > 0) {
        let flag_3D = true;
        if (parseInt(bet_2) < parseInt(minimal_bet)) {
          bet_2 = minimal_bet;
          flag_3D = false;
          msg_error += "Minimal Bet 3D : " + minimal_bet + "<br>";
        }
        if (parseInt(bet_2) > parseInt(max3d_bet)) {
          bet_2 = max3d_bet;
          flag_3D = false;
          msg_error +=
            `Maximal Bet 3D (${flag_fulldiskon}): ` +
            new Intl.NumberFormat().format(max3d_bet) +
            "<br>";
        }
        if (flag_3D) {
          switch (flag_fulldiskon) {
            case "FULL":
              diskon = 0;
              diskonpercen = 0;
              win = win3dnodiskon_bet;
              break;
            case "BB":
              diskon = 0;
              diskonpercen = 0;
              win = win3dbb_kena_bet;
              break;
            default:
              diskon = Math.round(
                parseFloat(bet_2) * parseFloat(disc3d_bet),
              ).toFixed(0);
              diskonpercen = disc3d_bet;
              win = win3d_bet;
              break;
          }
          bayar = parseInt(bet_2) - parseInt(diskon);
          for (let a = 0; a < data_bbfs.length; a++) {
            for (let b = 0; b < data_bbfs.length; b++) {
              for (let c = 0; c < data_bbfs.length; c++) {
                let dat = data_bbfs[a] + data_bbfs[b] + data_bbfs[c];
                if (generate3D.length > 0) {
                  for (let x = 0; x < generate3D.length; x++) {
                    if (dat == generate3D[x]) {
                      found = true;
                    }
                  }
                  if (found == false) {
                    generate3D.push(dat);
                  }
                } else {
                  generate3D.push(dat);
                }
                found = false;
                dat = "";
              }
            }
          }
          for (let x = 0; x < generate3D.length; x++) {
            if (checkcountangka(generate3D[x]) == true) {
              if (checkLimitLine("3D") == true) {
                nomor = generate3D[x];
                totalkeranjang = bayar + totalkeranjang;
                bet = bet_2;
                addKeranjang(
                  nomor,
                  "3D",
                  bet,
                  diskonpercen,
                  diskon,
                  bayar,
                  win,
                  0,
                  0,
                  flag_fulldiskon,
                );
              } else {
                msg_error += "Line 3D sudah melebihi limit<br>";
                break;
              }
            }
          }
        }
      }
      if (parseInt(bet_3) > 0) {
        let flag_2D = true;
        if (parseInt(bet_3) < parseInt(minimal_bet)) {
          bet_3 = minimal_bet;
          flag_2D = false;
          msg_error += "Minimal Bet 2D : " + minimal_bet + "<br>";
        }
        if (parseInt(bet_3) > parseInt(max2d_bet)) {
          bet_3 = max2d_bet;
          flag_2D = false;
          msg_error +=
            `Maximal Bet 2D (${flag_fulldiskon}): ` +
            new Intl.NumberFormat().format(max2d_bet) +
            "<br>";
        }
        if (flag_2D) {
          switch (flag_fulldiskon) {
            case "FULL":
              diskon = 0;
              diskonpercen = 0;
              win = win2dnodiskon_bet;
              break;
            case "BB":
              diskon = 0;
              diskonpercen = 0;
              win = win2dbb_kena_bet;
              break;
            default:
              diskon = Math.round(
                parseFloat(bet_3) * parseFloat(disc2d_bet),
              ).toFixed(0);
              diskonpercen = disc2d_bet;
              win = win2d_bet;
              break;
          }
          bayar = parseInt(bet_3) - parseInt(diskon);
          for (let a = 0; a < data_bbfs.length; a++) {
            for (let b = 0; b < data_bbfs.length; b++) {
              let dat = data_bbfs[a] + data_bbfs[b];
              if (generate2D.length > 0) {
                for (let x = 0; x < generate2D.length; x++) {
                  if (dat == generate2D[x]) {
                    found = true;
                  }
                }
                if (found == false) {
                  generate2D.push(dat);
                }
              } else {
                generate2D.push(dat);
              }
              found = false;
              dat = "";
            }
          }
          for (let x = 0; x < generate2D.length; x++) {
            if (checkcountangka(generate2D[x]) == true) {
              if (checkLimitLine("2D") == true) {
                nomor = generate2D[x];
                totalkeranjang = bayar + totalkeranjang;
                bet = bet_3;
                addKeranjang(
                  nomor,
                  "2D",
                  bet,
                  diskonpercen,
                  diskon,
                  bayar,
                  win,
                  0,
                  0,
                  flag_fulldiskon,
                );
              } else {
                msg_error = "Line 2D sudah melebihi limit";
                break;
              }
            }
          }
        }
      }
      if (parseInt(bet_4) > 0) {
        let flag_2DD = true;
        if (parseInt(bet_4) < parseInt(minimal_bet)) {
          bet_4 = minimal_bet;
          flag_2DD = false;
          msg_error += "Minimal Bet 2DD : " + minimal_bet + "<br>";
        }
        if (parseInt(bet_4) > parseInt(max2dd_bet)) {
          bet_4 = max2dd_bet;
          flag_2DD = false;
          msg_error +=
            `Maximal Bet 2DD (${flag_fulldiskon}): ` +
            new Intl.NumberFormat().format(max2dd_bet) +
            "<br>";
        }
        if (flag_2DD) {
          switch (flag_fulldiskon) {
            case "FULL":
              diskon = 0;
              diskonpercen = 0;
              win = win2ddnodiskon_bet;
              break;
            case "BB":
              diskon = 0;
              diskonpercen = 0;
              win = win2ddbb_kena_bet;
              break;
            default:
              diskon = Math.round(
                parseFloat(bet_4) * parseFloat(disc2dd_bet),
              ).toFixed(0);
              diskonpercen = disc2dd_bet;
              win = win2dd_bet;
              break;
          }
          bayar = parseInt(bet_4) - parseInt(diskon);
          for (let a = 0; a < data_bbfs.length; a++) {
            for (let b = 0; b < data_bbfs.length; b++) {
              let dat = data_bbfs[a] + data_bbfs[b];
              if (generate2DD.length > 0) {
                for (let x = 0; x < generate2DD.length; x++) {
                  if (dat == generate2DD[x]) {
                    found = true;
                  }
                }
                if (found == false) {
                  generate2DD.push(dat);
                }
              } else {
                generate2DD.push(dat);
              }
              found = false;
              dat = "";
            }
          }
          for (let x = 0; x < generate2DD.length; x++) {
            if (checkcountangka(generate2DD[x]) == true) {
              if (checkLimitLine("2DD") == true) {
                nomor = generate2DD[x];
                totalkeranjang = bayar + totalkeranjang;
                bet = bet_4;
                addKeranjang(
                  nomor,
                  "2DD",
                  bet,
                  diskonpercen,
                  diskon,
                  bayar,
                  win,
                  0,
                  0,
                  flag_fulldiskon,
                );
              } else {
                msg_error += "Line 2DD sudah melebihi limit<br>";
                break;
              }
            }
          }
        }
      }
      if (parseInt(bet_5) > 0) {
        let flag_2DT = true;
        if (parseInt(bet_5) < parseInt(minimal_bet)) {
          bet_5 = minimal_bet;
          flag_2DT = false;
          msg_error += "Minimal Bet 2DT : " + minimal_bet + "<br>";
        }
        if (parseInt(bet_5) > parseInt(max2dt_bet)) {
          bet_5 = max2dt_bet;
          flag_2DT = false;
          msg_error +=
            `Maximal Bet 2DT (${flag_fulldiskon}): ` +
            new Intl.NumberFormat().format(max2dt_bet) +
            "<br>";
        }
        if (flag_2DT) {
          switch (flag_fulldiskon) {
            case "FULL":
              diskon = 0;
              diskonpercen = 0;
              win = win2dtnodiskon_bet;
              break;
            case "BB":
              diskon = 0;
              diskonpercen = 0;
              win = win2dtbb_kena_bet;
              break;
            default:
              diskon = Math.round(
                parseFloat(bet_5) * parseFloat(disc2dt_bet),
              ).toFixed(0);
              diskonpercen = disc2dt_bet;
              win = win2dd_bet;
              break;
          }
          bayar = parseInt(bet_5) - parseInt(diskon);
          for (let a = 0; a < data_bbfs.length; a++) {
            for (let b = 0; b < data_bbfs.length; b++) {
              let dat = data_bbfs[a] + data_bbfs[b];
              if (generate2DT.length > 0) {
                for (let x = 0; x < generate2DT.length; x++) {
                  if (dat == generate2DT[x]) {
                    found = true;
                  }
                }
                if (found == false) {
                  generate2DT.push(dat);
                }
              } else {
                generate2DT.push(dat);
              }
              found = false;
              dat = "";
            }
          }
          for (let x = 0; x < generate2DT.length; x++) {
            if (checkcountangka(generate2DT[x]) == true) {
              if (checkLimitLine("2DT") == true) {
                nomor = generate2DT[x];
                totalkeranjang = bayar + totalkeranjang;
                bet = bet_5;
                addKeranjang(
                  nomor,
                  "2DT",
                  bet,
                  diskonpercen,
                  diskon,
                  bayar,
                  win,
                  0,
                  0,
                  flag_fulldiskon,
                );
              } else {
                msg_error += "Line 2DT sudah melebihi limit";
                break;
              }
            }
          }
        }
      }
      if (parseInt(bet_6) > 0) {
        let flag_3DD = true;
        if (parseInt(bet_6) < parseInt(minimal_bet)) {
          bet_6 = minimal_bet;
          flag_3DD = false;
          msg_error += "Minimal Bet 3DD : " + minimal_bet + "<br>";
        }
        if (parseInt(bet_6) > parseInt(max3dd_bet)) {
          bet_6 = max3dd_bet;
          flag_3DD = false;
          msg_error +=
            `Maximal Bet 3DD (${flag_fulldiskon}): ` +
            new Intl.NumberFormat().format(max3dd_bet) +
            "<br>";
        }
        if (flag_3DD) {
          switch (flag_fulldiskon) {
            case "FULL":
              diskon = 0;
              diskonpercen = 0;
              win = win3ddnodiskon_bet;
              break;
            case "BB":
              diskon = 0;
              diskonpercen = 0;
              win = win3ddbb_kena_bet;
              break;
            default:
              diskon = Math.round(
                parseFloat(bet_6) * parseFloat(disc3dd_bet),
              ).toFixed(0);
              diskonpercen = disc3dd_bet;
              win = win3dd_bet;
              break;
          }
          bayar = parseInt(bet_6) - parseInt(diskon);

          for (let a = 0; a < data_bbfs.length; a++) {
            for (let b = 0; b < data_bbfs.length; b++) {
              for (let c = 0; c < data_bbfs.length; c++) {
                let dat = data_bbfs[a] + data_bbfs[b] + data_bbfs[c];
                if (generate3DD.length > 0) {
                  for (let x = 0; x < generate3DD.length; x++) {
                    if (dat == generate3DD[x]) {
                      found = true;
                    }
                  }
                  if (found == false) {
                    generate3DD.push(dat);
                  }
                } else {
                  generate3DD.push(dat);
                }
                found = false;
                dat = "";
              }
            }
          }
          for (let x = 0; x < generate3DD.length; x++) {
            if (checkcountangka(generate3DD[x]) == true) {
              if (checkLimitLine("3DD") == true) {
                nomor = generate3DD[x];
                totalkeranjang = bayar + totalkeranjang;
                bet = bet_6;
                addKeranjang(
                  nomor,
                  "3DD",
                  bet,
                  diskonpercen,
                  diskon,
                  bayar,
                  win,
                  0,
                  0,
                  flag_fulldiskon,
                );
              } else {
                msg_error += "Line 3DD sudah melebihi limit<br>";
                break;
              }
            }
          }
        }
      }
      if (msg_error != "") {
        if (client_device !== "WEBSITE") {
          bottomOpen = !bottomOpen;
        }
        Swal.fire({
          position: "center",
          icon: "warning",
          html: msg_error,
          showConfirmButton: false,
          timer: 2000,
          background: daylight ? "#fff" : "#171717",
          color: daylight ? "#00a86b" : "#ff9900",
          toast: true,
        });
      }
      form_clear("bbfs");
    }
  }

  function form3dd_add() {
    let flag = true;
    let game = nomor3dd.length;
    let bet = 0;
    let diskon = 0;
    let diskonpercen = 0;
    let win = 0;
    let bayar = 0;
    let nomor = "";
    let nmgame = "";
    let msg_error = "";
    if (nomor3dd == "") {
      nomor3dd_input.focus();
      flag = false;
    }
    if (bet_3dd == "") {
      flag = false;
      msg_error += "Bet tidak boleh kosong";
    }
    if (parseInt(bet_3dd) < parseInt(minimal_bet)) {
      bet_3dd = minimal_bet;
      flag = false;
      msg_error += "Minimal Bet : " + minimal_bet;
    }

    if (game.toString() == "3") {
      if (parseInt(bet_3dd) > parseInt(max3dd_bet)) {
        bet_3dd = minimal_bet;
        flag = false;
        msg_error += `Maximal Bet 3D Depan (${flag_fulldiskon}): ` + max3dd_bet;
      }
      if (checkLimitLine("3DD") == false) {
        flag = false;
        msg_error += "Maximal Line 3D Depan : " + limitline_3dd;
        form_clear("3DD");
      }
    } else {
      flag = false;
      msg_error += "Minimal 3 Digit";
      form_clear("3DD");
    }

    for (var i = 0; i < nomor3dd.length; i++) {
      let numbera = parseInt(nomor3dd[i]);
      if (isNaN(numbera)) {
        flag = false;
        msg_error += "Nomor harus angka";
        form_clear("3DD");
      }
    }

    if (flag == true) {
      nmgame = "3DD";
      switch (flag_fulldiskon) {
        case "FULL":
          diskon = 0;
          diskonpercen = 0;
          win = win3ddnodiskon_bet;
          break;
        case "BB":
          diskon = 0;
          diskonpercen = 0;
          win = win3ddbb_kena_bet;
          break;
        default:
          diskon = (bet_3dd * disc3dd_bet).toFixed(0);
          diskonpercen = disc3dd_bet;
          win = win3dd_bet;
          break;
      }

      nomor = nomor3dd;
      bet = bet_3dd;
      bayar = parseInt(bet_3dd) - parseInt(Math.ceil(diskon));
      totalkeranjang = bayar + totalkeranjang;
      addKeranjang(
        nomor,
        nmgame,
        bet,
        diskonpercen,
        diskon,
        bayar,
        win,
        0,
        0,
        flag_fulldiskon,
      );
      form_clear("3DD");
    }
    if (msg_error != "") {
      Swal.fire({
        position: "center",
        icon: "info",
        title: msg_error,
        showConfirmButton: false,
        timer: 3000,
        background: daylight ? "#fff" : "#171717",
        color: daylight ? "#00a86b" : "#ff9900",
        toast: true,
      });
    }
  }

  function form2dd_add() {
    let flag = true;
    let game = nomor2dd.length;
    let bet = 0;
    let diskon = 0;
    let diskonpercen = 0;
    let win = 0;
    let kei = 0;
    let kei_percen = 0;
    let bayar = 0;
    let nmgame = "";
    let nomor = "";
    if (nomor2dd == "") {
      nomor2dd_input.focus();
      flag = false;
    }

    if (bet_2dd == "") {
      flag = false;
      Swal.fire({
        position: "center",
        icon: "info",
        title: "Bet tidak boleh kosong",
        showConfirmButton: false,
        timer: 3000,
        background: daylight ? "#fff" : "#171717",
        color: daylight ? "#00a86b" : "#ff9900",
        toast: true,
      });
    }
    if (parseInt(bet_2dd) < parseInt(minimal_bet)) {
      bet_2dd = minimal_bet;
      flag = false;
      Swal.fire({
        position: "center",
        icon: "info",
        title: "Minimal Bet : " + minimal_bet,
        showConfirmButton: false,
        timer: 3000,
        background: daylight ? "#fff" : "#171717",
        color: daylight ? "#00a86b" : "#ff9900",
        toast: true,
      });
    }

    if (game.toString() == "2") {
      if (parseInt(bet_2dd) > parseInt(max2dd_bet)) {
        bet_2dd = minimal_bet;
        flag = false;
        Swal.fire({
          position: "center",
          icon: "info",
          title: `Maximal Bet 2D Depan (${flag_fulldiskon}): ` + max2dd_bet,
          showConfirmButton: false,
          timer: 3000,
          background: daylight ? "#fff" : "#171717",
          color: daylight ? "#00a86b" : "#ff9900",
          toast: true,
        });
      }
      if (checkLimitLine("2DD") == false) {
        flag = false;
        Swal.fire({
          position: "center",
          icon: "info",
          title: "Maximal Line 2D Depan : " + limitline_2dd,
          showConfirmButton: false,
          timer: 3000,
          background: daylight ? "#fff" : "#171717",
          color: daylight ? "#00a86b" : "#ff9900",
          toast: true,
        });
        form_clear("2DD");
      }
    } else {
      flag = false;
      Swal.fire({
        position: "center",
        icon: "info",
        title: "Minimal 2 Digit",
        showConfirmButton: false,
        timer: 3000,
        background: daylight ? "#fff" : "#171717",
        color: daylight ? "#00a86b" : "#ff9900",
        toast: true,
      });
      form_clear("2DD");
    }

    for (var i = 0; i < nomor2dd.length; i++) {
      let numbera = parseInt(nomor2dd[i]);
      if (isNaN(numbera)) {
        flag = false;
        Swal.fire({
          position: "center",
          icon: "info",
          title: "Error",
          showConfirmButton: false,
          timer: 3000,
          background: daylight ? "#fff" : "#171717",
          color: daylight ? "#00a86b" : "#ff9900",
          toast: true,
        });
        form_clear("2DD");
      }
    }
    if (flag == true) {
      nmgame = "2DD";
      switch (flag_fulldiskon) {
        case "FULL":
          diskon = 0;
          diskonpercen = 0;
          win = win2ddnodiskon_bet;
          break;
        case "BB":
          diskon = 0;
          diskonpercen = 0;
          win = win2ddbb_kena_bet;
          break;
        default:
          diskon = (bet_2dd * disc2dd_bet).toFixed(0);
          diskonpercen = disc2dd_bet;
          win = win2dd_bet;
          break;
      }

      nomor = nomor2dd;
      bet = bet_2dd;
      bayar = parseInt(bet_2dd) - parseInt(Math.ceil(diskon));
      totalkeranjang = bayar + totalkeranjang;
      addKeranjang(
        nomor,
        nmgame,
        bet,
        diskonpercen,
        diskon,
        bayar,
        win,
        0,
        0,
        flag_fulldiskon,
      );
      form_clear("2DD");
    }
    if (temp_bulk_error != "") {
      // let myModal = new bootstrap.Modal(document.getElementById("modalError"));
      // myModal.show();
      Swal.fire({
        icon: "error",
        title: "Oops...",
        html: temp_bulk_error,
        background: daylight ? "#fff" : "#171717",
        color: daylight ? "#00a86b" : "#ff9900",
      });
    }
  }

  function form2dt_add() {
    let flag = true;
    let game = nomor2dt.length;
    let bet = 0;
    let diskon = 0;
    let diskonpercen = 0;
    let win = 0;
    let kei = 0;
    let kei_percen = 0;
    let bayar = 0;
    let nmgame = "";
    let nomor = "";
    if (nomor2dt == "") {
      nomor2dt_input.focus();
      flag = false;
    }

    if (bet_2dt == "") {
      flag = false;
      Swal.fire({
        position: "center",
        icon: "info",
        title: "Bet tidak boleh kosong",
        showConfirmButton: false,
        timer: 3000,
        background: daylight ? "#fff" : "#171717",
        color: daylight ? "#00a86b" : "#ff9900",
        toast: true,
      });
    }
    if (parseInt(bet_2dt) < parseInt(minimal_bet)) {
      bet_2dt = minimal_bet;
      flag = false;
      Swal.fire({
        position: "center",
        icon: "info",
        title: "Minimal Bet : " + minimal_bet,
        showConfirmButton: false,
        timer: 3000,
        background: daylight ? "#fff" : "#171717",
        color: daylight ? "#00a86b" : "#ff9900",
        toast: true,
      });
    }

    if (game.toString() == "2") {
      if (parseInt(bet_2dt) > parseInt(max2dt_bet)) {
        bet_2dt = minimal_bet;
        flag = false;
        Swal.fire({
          position: "center",
          icon: "info",
          title: `Maximal Bet 2D Tengah (${flag_fulldiskon}): ` + max2dt_bet,
          showConfirmButton: false,
          timer: 3000,
          background: daylight ? "#fff" : "#171717",
          color: daylight ? "#00a86b" : "#ff9900",
          toast: true,
        });
      }
      if (checkLimitLine("2DT") == false) {
        flag = false;
        Swal.fire({
          position: "center",
          icon: "info",
          title: "Maximal Line 2T Tengah : " + limitline_2dt,
          showConfirmButton: false,
          timer: 3000,
          background: daylight ? "#fff" : "#171717",
          color: daylight ? "#00a86b" : "#ff9900",
          toast: true,
        });
        form_clear("2DT");
      }
    } else {
      flag = false;
      Swal.fire({
        position: "center",
        icon: "info",
        title: "Minimal 2 Digit",
        showConfirmButton: false,
        timer: 3000,
        background: daylight ? "#fff" : "#171717",
        color: daylight ? "#00a86b" : "#ff9900",
        toast: true,
      });
      form_clear("2DD");
    }

    for (var i = 0; i < nomor2dt.length; i++) {
      let numbera = parseInt(nomor2dt[i]);
      if (isNaN(numbera)) {
        flag = false;
        Swal.fire({
          position: "center",
          icon: "info",
          title: "Error",
          showConfirmButton: false,
          timer: 3000,
          background: daylight ? "#fff" : "#171717",
          color: daylight ? "#00a86b" : "#ff9900",
          toast: true,
        });
        form_clear("2DT");
      }
    }
    if (flag == true) {
      nmgame = "2DT";
      switch (flag_fulldiskon) {
        case "FULL":
          diskon = 0;
          diskonpercen = 0;
          win = win2dtnodiskon_bet;
          break;
        case "BB":
          diskon = 0;
          diskonpercen = 0;
          win = win2dtbb_kena_bet;
          break;
        default:
          diskon = (bet_2dt * disc2dt_bet).toFixed(0);
          diskonpercen = disc2dt_bet;
          win = win2dt_bet;
          break;
      }

      nomor = nomor2dt;
      bet = bet_2dt;
      bayar = parseInt(bet_2dt) - parseInt(Math.ceil(diskon));
      totalkeranjang = bayar + totalkeranjang;
      addKeranjang(
        nomor,
        nmgame,
        bet,
        diskonpercen,
        diskon,
        bayar,
        win,
        0,
        0,
        flag_fulldiskon,
      );
      form_clear("2DT");
    }
    if (temp_bulk_error != "") {
      // let myModal = new bootstrap.Modal(document.getElementById("modalError"));
      // myModal.show();
      Swal.fire({
        icon: "error",
        title: "Oops...",
        html: temp_bulk_error,
        background: daylight ? "#fff" : "#171717",
        color: daylight ? "#00a86b" : "#ff9900",
      });
    }
  }
  function formwap_add() {
    loadDisplay = true;

    let pemisah = nomorwap.split(",");
    let res_money = nomorwap.split("#");
    let totalpemisah = pemisah.length;
    let totalres_money = res_money.length;
    let flag_checkdata = true;
    for (let i = 0; i < nomorwap.length; i++) {
      let numbera = parseInt(nomorwap[i]);
      if (isNaN(numbera)) {
        if (nomorwap[i] != "*" && nomorwap[i] != "#" && nomorwap[i] != ",") {
          // form_clear("wap");
          flag_checkdata = false;
        }
      }
    }
    if (flag_checkdata == false) {
      loadDisplay = false;
      Swal.fire({
        position: "center",
        icon: "info",
        title: "Format Salah, hanya boleh karakter angka * # ,",
        showConfirmButton: false,
        timer: 3000,
        background: daylight ? "#fff" : "#171717",
        color: daylight ? "#00a86b" : "#ff9900",
        toast: true,
      });
    } else {
      if (totalpemisah < 2) {
        //jika tidak ada pemisah
        if (totalres_money < 2) {
          loadDisplay = false;
          Swal.fire({
            position: "center",
            icon: "info",
            title: "Format Salah",
            showConfirmButton: false,
            timer: 3000,
            background: daylight ? "#fff" : "#171717",
            color: daylight ? "#00a86b" : "#ff9900",
            toast: true,
          });
        } else {
          checkbulkdata(nomorwap);
        }
      } else {
        for (var i = 0; i < totalpemisah; i++) {
          checkbulkdata(pemisah[i]);
        }
      }
    }
    loadDisplay = false;
    css_loader = "display:none;";
    if (temp_bulk_error != "") {
      // let myModal = new bootstrap.Modal(document.getElementById("modalError"));
      // myModal.show();
      Swal.fire({
        icon: "error",
        title: "Oops...",
        html: temp_bulk_error,
        background: daylight ? "#fff" : "#171717",
        color: daylight ? "#00a86b" : "#ff9900",
      });
    }
  }

  function formpolatarung_add() {
    loadDisplay = true;
    let flag = true;
    let nomoras_game = nomoras.length;
    let nomorkop_game = nomorkop.length;
    let nomorkepala_game = nomorkepala.length;
    let nomorekor_game = nomorekor.length;
    let pola = "";
    let count = 0;
    let diskon = 0;
    let diskonpercen = 0;
    let win = 0;
    let bayar = 0;
    let msg = "";
    if (nomoras == "") {
      nomoras_input.focus();
      flag = false;
      pola += "-";
    } else {
      pola += "1";
    }
    if (nomorkop == "") {
      nomorkop_input.focus();
      flag = false;
      pola += "-";
    } else {
      pola += "1";
    }
    if (nomorkepala != "") {
      pola += "1";
    } else {
      pola += "-";
    }
    if (nomorekor != "") {
      pola += "1";
    } else {
      pola += "-";
    }
    if (bet_tarung == "") {
      bet_tarung_input.focus();
      msg += "Bet Wajib diisi";
      flag = false;
    }
    if (parseInt(bet_tarung) < parseInt(minimal_bet)) {
      bet_tarung = minimal_bet;
      flag = false;
      msg += "Minimal Bet : " + minimal_bet;
    }

    if (flag) {
      let nomor = "";
      switch (pola) {
        case "11--":
          let flag_2D = true;
          if (parseInt(bet_tarung) > parseInt(max2d_bet)) {
            flag_2D = false;
            msg +=
              `Maximal Bet Pola Tarung Bet 2D (${flag_fulldiskon}): ` +
              new Intl.NumberFormat().format(max2d_bet) +
              "<br>";
          }
          if (flag_2D) {
            for (let i = 0; i < nomoras_game; i++) {
              for (let j = 0; j < nomorkop_game; j++) {
                if (checkLimitLine("2D") == true) {
                  nomor = nomoras[i] + nomorkop[j];
                  count = count + 1;
                  switch (flag_fulldiskon) {
                    case "FULL":
                      diskon = 0;
                      diskonpercen = 0;
                      win = win2dnodiskon_bet;
                      break;
                    case "BB":
                      diskon = 0;
                      diskonpercen = 0;
                      win = win2dbb_kena_bet;
                      break;
                    default:
                      diskon = (bet_tarung * disc2d_bet).toFixed(0);
                      diskonpercen = disc2d_bet;
                      win = win2d_bet;
                      break;
                  }

                  bayar = parseInt(bet_tarung) - parseInt(Math.ceil(diskon));
                  totalkeranjang = bayar + totalkeranjang;
                  addKeranjang(
                    nomor,
                    "2D",
                    bet_tarung,
                    diskonpercen,
                    diskon,
                    bayar,
                    win,
                    0,
                    0,
                    flag_fulldiskon,
                  );
                } else {
                  msg = "Maximal Line 2D : " + limitline_2d + "\n";
                }
              }
            }
            form_clear("polatarung");
          }
          break;
        case "111-":
          let flag_3D = true;
          if (parseInt(bet_tarung) > parseInt(max3d_bet)) {
            flag_3D = false;
            msg +=
              `Maximal Bet Pola Tarung Bet 3D (${flag_fulldiskon}): ` +
              new Intl.NumberFormat().format(max3d_bet) +
              "<br>";
          }
          if (flag_3D) {
            for (let i = 0; i < nomoras_game; i++) {
              for (let j = 0; j < nomorkop_game; j++) {
                for (let x = 0; x < nomorkepala_game; x++) {
                  if (checkLimitLine("3D") == true) {
                    nomor = nomoras[i] + nomorkop[j] + nomorkepala[x];
                    count = count + 1;
                    switch (flag_fulldiskon) {
                      case "FULL":
                        diskon = 0;
                        diskonpercen = 0;
                        win = win3dnodiskon_bet;
                        break;
                      case "BB":
                        diskon = 0;
                        diskonpercen = 0;
                        win = win3dbb_kena_bet;
                        break;
                      default:
                        diskon = (bet_tarung * disc3d_bet).toFixed(0);
                        diskonpercen = disc3d_bet;
                        win = win3d_bet;
                        break;
                    }

                    bayar = parseInt(bet_tarung) - parseInt(Math.ceil(diskon));
                    totalkeranjang = bayar + totalkeranjang;
                    addKeranjang(
                      nomor,
                      "3D",
                      bet_tarung,
                      diskonpercen,
                      diskon,
                      bayar,
                      win,
                      0,
                      0,
                      flag_fulldiskon,
                    );
                  } else {
                    msg = "Maximal Line 3D : " + limitline_3d + "\n";
                  }
                }
              }
            }
            form_clear("polatarung");
          }
          break;
        case "1111":
          let flag_4D = true;
          if (parseInt(bet_tarung) > parseInt(max4d_bet)) {
            flag_4D = false;
            msg +=
              `Maximal Bet Pola Tarung Bet 4D (${flag_fulldiskon}): ` +
              new Intl.NumberFormat().format(max4d_bet) +
              "<br>";
          }
          if (flag_4D) {
            for (let i = 0; i < nomoras_game; i++) {
              for (let j = 0; j < nomorkop_game; j++) {
                for (let x = 0; x < nomorkepala_game; x++) {
                  for (let y = 0; y < nomorekor_game; y++) {
                    if (checkLimitLine("4D") == true) {
                      nomor =
                        nomoras[i] +
                        nomorkop[j] +
                        nomorkepala[x] +
                        nomorekor[y];
                      count = count + 1;
                      switch (flag_fulldiskon) {
                        case "FULL":
                          diskon = 0;
                          diskonpercen = 0;
                          win = win4dnodiskon_bet;
                          break;
                        case "BB":
                          diskon = 0;
                          diskonpercen = 0;
                          win = win4dbb_kena_bet;
                          break;
                        default:
                          diskon = (bet_tarung * disc4d_bet).toFixed(0);
                          diskonpercen = disc4d_bet;
                          win = win4d_bet;
                          break;
                      }

                      bayar =
                        parseInt(bet_tarung) - parseInt(Math.ceil(diskon));
                      totalkeranjang = bayar + totalkeranjang;
                      addKeranjang(
                        nomor,
                        "4D",
                        bet_tarung,
                        diskonpercen,
                        diskon,
                        bayar,
                        win,
                        0,
                        0,
                        flag_fulldiskon,
                      );
                    } else {
                      msg = "Maximal Line 4D : " + limitline_4d + "\n";
                    }
                  }
                }
              }
            }
            form_clear("polatarung");
          }
          break;
        default:
          msg = "Format Pola Tarung Salah";
          break;
      }
    }
    loadDisplay = false;
    if (msg != "") {
      Swal.fire({
        position: "center",
        icon: "info",
        title: msg,
        showConfirmButton: false,
        timer: 3000,
        background: daylight ? "#fff" : "#171717",
        color: daylight ? "#00a86b" : "#ff9900",
        toast: true,
      });
      form_clear("polatarung");
    }
  }

  function formquick2d_add() {
    let flag = true;
    let diskon = 0;
    let diskonpercen = 0;
    let bet = 0;
    let win = 0;
    let kei = 0;
    let kei_percen = 0;
    let bayar = 0;
    let nmgame = "";
    let data_temp = [];
    let data_quick = [];
    let code_alert = 0;
    let note_alert = "";
    if (quick_pilihan1 == "") {
      quick_pilihan1_input.focus();
      flag = false;
      Swal.fire({
        position: "center",
        icon: "info",
        title: "Besar/Kecil/Genap/Ganjil tidak boleh kosong",
        showConfirmButton: false,
        timer: 3000,
        background: daylight ? "#fff" : "#171717",
        color: daylight ? "#00a86b" : "#ff9900",
        toast: true,
      });
    }
    if (quick_pilihan2 == "") {
      quick_pilihan2_input.focus();
      flag = false;
      Swal.fire({
        position: "center",
        icon: "info",
        title: "2D/2D Depan/2D Tengah tidak boleh kosong",
        showConfirmButton: false,
        timer: 3000,
        background: daylight ? "#fff" : "#171717",
        color: daylight ? "#00a86b" : "#ff9900",
        toast: true,
      });
    }
    if (quick_bet == "") {
      quick_bet_input.focus();
      flag = false;
      Swal.fire({
        position: "center",
        icon: "info",
        title: "Bet tidak boleh kosong",
        showConfirmButton: false,
        timer: 3000,
        background: daylight ? "#fff" : "#171717",
        color: daylight ? "#00a86b" : "#ff9900",
        toast: true,
      });
    }
    if (parseInt(quick_bet) < parseInt(minimal_bet)) {
      quick_bet = minimal_bet;
      flag = false;
      Swal.fire({
        position: "center",
        icon: "info",
        title: "Minimal Bet : " + minimal_bet,
        showConfirmButton: false,
        timer: 3000,
        background: daylight ? "#fff" : "#171717",
        color: daylight ? "#00a86b" : "#ff9900",
        toast: true,
      });
    }
    if (quick_pilihan2 != "") {
      switch (quick_pilihan2) {
        case "2D":
          if (parseInt(quick_bet) > parseInt(max2d_bet)) {
            quick_bet = minimal_bet;
            flag = false;
            Swal.fire({
              position: "center",
              icon: "info",
              title: `Maximal Bet 2D (${flag_fulldiskon}): ` + max2d_bet,
              showConfirmButton: false,
              timer: 3000,
              background: daylight ? "#fff" : "#171717",
              color: daylight ? "#00a86b" : "#ff9900",
              toast: true,
            });
          }
          break;
        case "2DD":
          if (parseInt(quick_bet) > parseInt(max2dd_bet)) {
            quick_bet = minimal_bet;
            flag = false;
            Swal.fire({
              position: "center",
              icon: "info",
              title: `Maximal Bet 2D Depan (${flag_fulldiskon}): ` + max2dd_bet,
              showConfirmButton: false,
              timer: 3000,
              background: daylight ? "#fff" : "#171717",
              color: daylight ? "#00a86b" : "#ff9900",
              toast: true,
            });
          }
          break;
        case "2DT":
          if (parseInt(quick_bet) > parseInt(max2dt_bet)) {
            quick_bet = minimal_bet;
            flag = false;
            Swal.fire({
              position: "center",
              icon: "info",
              title:
                `Maximal Bet 2D Tengah (${flag_fulldiskon}): ` + max2dt_bet,
              showConfirmButton: false,
              timer: 3000,
              background: daylight ? "#fff" : "#171717",
              color: daylight ? "#00a86b" : "#ff9900",
              toast: true,
            });
          }
          break;
      }
    }

    if (flag == true) {
      for (let x = 0; x < 10; x++) {
        for (let y = 0; y < 10; y++) {
          data_temp.push(x.toString() + y.toString());
        }
      }
      if (data_temp.length > 0) {
        switch (quick_pilihan1) {
          case "BESAR":
            for (let i = 0; i <= data_temp.length; i++) {
              if (parseInt(data_temp[i]) > 49) {
                data_quick.push(data_temp[i]);
              }
            }
            break;
          case "KECIL":
            for (let i = 0; i <= data_temp.length; i++) {
              if (parseInt(data_temp[i]) < 50) {
                data_quick.push(data_temp[i]);
              }
            }
            break;
          case "GANJIL":
            for (let i = 0; i < data_temp.length; i++) {
              if (parseInt(data_temp[i]) % 2 !== 0) {
                data_quick.push(data_temp[i]);
              }
            }
            break;
          case "GENAP":
            for (let i = 0; i < data_temp.length; i++) {
              if (parseInt(data_temp[i]) % 2 == 0) {
                data_quick.push(data_temp[i]);
              }
            }
            break;
        }
      }
      if (data_quick.length > 0) {
        switch (quick_pilihan2) {
          case "2D":
            bet = quick_bet;
            nmgame = "2D";
            switch (flag_fulldiskon) {
              case "FULL":
                diskon = 0;
                diskonpercen = 0;
                win = win2dnodiskon_bet;
                break;
              case "BB":
                diskon = 0;
                diskonpercen = 0;
                win = win2dbb_kena_bet;
                break;
              default:
                diskon = (quick_bet * disc2d_bet).toFixed(0);
                diskonpercen = disc2d_bet;
                win = win2d_bet;
                break;
            }

            bayar = parseInt(quick_bet) - parseInt(Math.ceil(diskon));
            for (let i = 0; i < data_quick.length; i++) {
              if (checkLimitLine("2D") == false) {
                code_alert = 1;
                note_alert = "Line 2D sudah melebihi limit";
                break;
              } else {
                totalkeranjang = bayar + totalkeranjang;
                addKeranjang(
                  data_quick[i],
                  nmgame,
                  bet,
                  diskonpercen,
                  diskon,
                  bayar,
                  win,
                  0,
                  0,
                  flag_fulldiskon,
                );
                form_clear("quick2D");
              }
            }
            if (code_alert == 1) {
              Swal.fire({
                position: "center",
                icon: "info",
                title: note_alert,
                showConfirmButton: false,
                timer: 3000,
                background: daylight ? "#fff" : "#171717",
                color: daylight ? "#00a86b" : "#ff9900",
                toast: true,
              });
              code_alert = 0;
            }
            if (temp_bulk_error != "") {
              Swal.fire({
                icon: "error",
                title: "Oops...",
                html: temp_bulk_error,
                background: daylight ? "#fff" : "#171717",
                color: daylight ? "#00a86b" : "#ff9900",
              });
            }
            break;
          case "2DD":
            bet = quick_bet;
            nmgame = "2DD";
            switch (flag_fulldiskon) {
              case "FULL":
                diskon = 0;
                diskonpercen = 0;
                win = win2ddnodiskon_bet;
                break;
              case "BB":
                diskon = 0;
                diskonpercen = 0;
                win = win2ddbb_kena_bet;
                break;
              default:
                diskon = (quick_bet * disc2dd_bet).toFixed(0);
                diskonpercen = disc2dd_bet;
                win = win2dd_bet;
                break;
            }

            bayar = parseInt(quick_bet) - parseInt(Math.ceil(diskon));
            for (let i = 0; i < data_quick.length; i++) {
              if (checkLimitLine("2DD") == false) {
                code_alert = 1;
                note_alert = "Line 2DD sudah melebihi limit";
                break;
              } else {
                totalkeranjang = bayar + totalkeranjang;
                const data = {
                  id: Math.floor(Math.random() * 5000) + 3,
                  nomor: data_quick[i],
                  permainan: nmgame,
                  bet,
                  diskon,
                  diskonpercen,
                  bayar,
                  win,
                  kei,
                  kei_percen,
                };
                addKeranjang(
                  data_quick[i],
                  nmgame,
                  bet,
                  diskonpercen,
                  diskon,
                  bayar,
                  win,
                  0,
                  0,
                  flag_fulldiskon,
                );
                form_clear("quick2D");
              }
            }
            if (code_alert == 1) {
              Swal.fire({
                position: "center",
                icon: "info",
                title: note_alert,
                showConfirmButton: false,
                timer: 3000,
                background: daylight ? "#fff" : "#171717",
                color: daylight ? "#00a86b" : "#ff9900",
                toast: true,
              });
              code_alert = 0;
            }
            if (temp_bulk_error != "") {
              // let myModal = new bootstrap.Modal(
              //   document.getElementById("modalError")
              // );
              // myModal.show();
              Swal.fire({
                icon: "error",
                title: "Oops...",
                html: temp_bulk_error,
                background: daylight ? "#fff" : "#171717",
                color: daylight ? "#00a86b" : "#ff9900",
              });
            }
            break;
          case "2DT":
            bet = quick_bet;
            nmgame = "2DT";
            switch (flag_fulldiskon) {
              case "FULL":
                diskon = 0;
                diskonpercen = 0;
                win = win2dtnodiskon_bet;
                break;
              case "BB":
                diskon = 0;
                diskonpercen = 0;
                win = win2dtbb_kena_bet;
                break;
              default:
                diskon = (quick_bet * disc2dt_bet).toFixed(0);
                diskonpercen = disc2dt_bet;
                win = win2dt_bet;
                break;
            }

            bayar = parseInt(quick_bet) - parseInt(Math.ceil(diskon));
            for (let i = 0; i < data_quick.length; i++) {
              if (checkLimitLine("2DT") == false) {
                code_alert = 1;
                note_alert = "Line 2DT sudah melebihi limit";
                break;
              } else {
                totalkeranjang = bayar + totalkeranjang;
                addKeranjang(
                  data_quick[i],
                  nmgame,
                  bet,
                  diskonpercen,
                  diskon,
                  bayar,
                  win,
                  0,
                  0,
                  flag_fulldiskon,
                );
                form_clear("quick2D");
              }
            }
            if (code_alert == 1) {
              Swal.fire({
                position: "center",
                icon: "info",
                title: note_alert,
                showConfirmButton: false,
                timer: 3000,
                background: daylight ? "#fff" : "#171717",
                color: daylight ? "#00a86b" : "#ff9900",
                toast: true,
              });
              code_alert = 0;
            }
            if (temp_bulk_error != "") {
              // let myModal = new bootstrap.Modal(
              //   document.getElementById("modalError")
              // );
              // myModal.show();
              Swal.fire({
                icon: "error",
                title: "Oops...",
                html: temp_bulk_error,
                background: daylight ? "#fff" : "#171717",
                color: daylight ? "#00a86b" : "#ff9900",
              });
            }
            break;
        }
        form_clear("quick2D");
      }
    }
  }
  function checkbulkdata(datatot) {
    let res_money = datatot.split("#");
    let flag_checkcharacter = false;
    let flag_running = false;
    let count_checkcharacter = 0;
    let money = res_money[1];
    let bet = 0;
    let datanomor = res_money[0];
    let resnomor = datanomor.split("*");
    let total_resnomor = resnomor.length;
    let diskon = 0;
    let diskonpercen = 0;
    let win = 0;
    let kei = 0;
    let kei_percen = 0;
    let bayar = 0;
    let nmgame = "";
    for (let i = 0; i < datanomor.length; i++) {
      if (datanomor[i] == "*") {
        flag_checkcharacter = true;
        count_checkcharacter = count_checkcharacter + 1;
      }
    }
    if (flag_checkcharacter == true && count_checkcharacter > 0) {
      flag_running = true;
    }

    if (flag_running == false) {
      let flag_checkdata = true;
      flag_checkdata = checkdata_432d(datanomor, datanomor.length, money);
      if (flag_checkdata == true) {
        let flag_push = false;
        let game = datanomor.length;
        switch (game.toString()) {
          case "4":
            if (checkLimitLine("4D") == true) {
              nmgame = "4D";
              switch (flag_fulldiskon) {
                case "FULL":
                  diskon = 0;
                  diskonpercen = 0;
                  win = win4dnodiskon_bet;
                  break;
                case "BB":
                  diskon = 0;
                  diskonpercen = 0;
                  win = win4dbb_kena_bet;
                  break;
                default:
                  diskon = (money * disc4d_bet).toFixed(0);
                  diskonpercen = disc4d_bet;
                  win = win4d_bet;
                  break;
              }

              flag_push = true;
            }
            break;
          case "3":
            if (checkLimitLine("3D") == true) {
              nmgame = "3D";
              switch (flag_fulldiskon) {
                case "FULL":
                  diskon = 0;
                  diskonpercen = 0;
                  win = win3dnodiskon_bet;
                  break;
                case "BB":
                  diskon = 0;
                  diskonpercen = 0;
                  win = win3dbb_kena_bet;
                  break;
                default:
                  diskon = (money * disc3d_bet).toFixed(0);
                  diskonpercen = disc3d_bet;
                  win = win3d_bet;
                  break;
              }

              flag_push = true;
            }
            break;
          case "2":
            if (checkLimitLine("2D") == true) {
              nmgame = "2D";
              switch (flag_fulldiskon) {
                case "FULL":
                  diskon = 0;
                  diskonpercen = 0;
                  win = win2dnodiskon_bet;
                  break;
                case "BB":
                  diskon = 0;
                  diskonpercen = 0;
                  win = win2dbb_kena_bet;
                  break;
                default:
                  diskon = (money * disc2d_bet).toFixed(0);
                  diskonpercen = disc2d_bet;
                  win = win2d_bet;
                  break;
              }
              flag_push = true;
            }
            break;
        }
        if (flag_push == true) {
          bet = money;
          bayar = parseInt(bet) - parseInt(Math.ceil(diskon));
          totalkeranjang = bayar + totalkeranjang;
          addKeranjang(
            datanomor,
            nmgame,
            bet,
            diskonpercen,
            diskon,
            bayar,
            win,
            0,
            0,
            flag_fulldiskon,
          );
          form_clear("wap");
        }
      }
    } else {
      for (let i = 0; i < total_resnomor; i++) {
        let nomor = resnomor[i];
        let game = resnomor[i].length;
        let flag = true;

        flag = checkdata_432d(nomor, game, money);
        if (flag == true) {
          let flag_push = false;
          switch (game.toString()) {
            case "4":
              if (checkLimitLine("4D") == true) {
                nmgame = "4D";
                switch (flag_fulldiskon) {
                  case "FULL":
                    diskon = 0;
                    diskonpercen = 0;
                    win = win4dnodiskon_bet;
                    break;
                  case "BB":
                    diskon = 0;
                    diskonpercen = 0;
                    win = win4dbb_kena_bet;
                    break;
                  default:
                    diskon = (money * disc4d_bet).toFixed(0);
                    diskonpercen = disc4d_bet;
                    win = win4d_bet;
                    break;
                }
                flag_push = true;
              }
              break;
            case "3":
              if (checkLimitLine("3D") == true) {
                nmgame = "3D";
                switch (flag_fulldiskon) {
                  case "FULL":
                    diskon = 0;
                    diskonpercen = 0;
                    win = win3dnodiskon_bet;
                    break;
                  case "BB":
                    diskon = 0;
                    diskonpercen = 0;
                    win = win3dbb_kena_bet;
                    break;
                  default:
                    diskon = (money * disc3d_bet).toFixed(0);
                    diskonpercen = disc3d_bet;
                    win = win3d_bet;
                    break;
                }
                flag_push = true;
              }
              break;
            case "2":
              if (checkLimitLine("2D") == true) {
                nmgame = "2D";
                switch (flag_fulldiskon) {
                  case "FULL":
                    diskon = 0;
                    diskonpercen = 0;
                    win = win2dnodiskon_bet;
                    break;
                  case "BB":
                    diskon = 0;
                    diskonpercen = 0;
                    win = win2dbb_kena_bet;
                    break;
                  default:
                    diskon = (money * disc2d_bet).toFixed(0);
                    diskonpercen = disc2d_bet;
                    win = win2d_bet;
                    break;
                }

                flag_push = true;
              }
              break;
          }
          if (flag_push == true) {
            bet = money;
            bayar = parseInt(bet) - parseInt(Math.ceil(diskon));
            totalkeranjang = bayar + totalkeranjang;

            addKeranjang(
              nomor,
              nmgame,
              bet,
              diskonpercen,
              diskon,
              bayar,
              win,
              0,
              0,
              flag_fulldiskon,
            );

            form_clear("wap");
          }
        }
      }
    }
  }
  function checkdata_432d(nomor, game, money) {
    let flag = true;
    if (money == undefined || money == "") {
      flag = false;
    } else {
      if (parseInt(money) < parseInt(minimal_bet)) {
        // form_clear("wap");
        flag = false;
        temp_bulk_error +=
          "Data Tidak Valid: " +
          nomor +
          "-" +
          game +
          "D-" +
          money +
          " | Minimal Bet : " +
          new Intl.NumberFormat().format(minimal_bet) +
          "<br />";
      }
      if (parseInt(nomor.length) < 2 || parseInt(nomor.length) > 4) {
        flag = false;
        // form_clear("wap");
        temp_bulk_error +=
          "Data Tidak Valid: " +
          nomor +
          "-" +
          game +
          "D-" +
          money +
          " | Nomor harus 2 - 4 Digit<br />";
      }

      if (game.toString() == "4") {
        if (parseInt(money) > parseInt(max4d_bet)) {
          // form_clear("wap");
          flag = false;
          temp_bulk_error +=
            "Data Tidak Valid: " +
            nomor +
            "-" +
            game +
            "D-" +
            money +
            ` | Maximal Bet 4D (${flag_fulldiskon}): ` +
            max4d_bet +
            "<br />";
        }

        if (
          parseInt(count_line_4d) + parseInt(db_form4d_4d_count_temp) >=
          parseInt(limitline_4d)
        ) {
          flag = false;
        }
      }
      if (game.toString() == "3") {
        if (parseInt(money) > parseInt(max3d_bet)) {
          // form_clear("wap");
          flag = false;
        }
        if (
          parseInt(count_line_3d) + parseInt(db_form4d_3d_count_temp) >
          parseInt(limitline_3d)
        ) {
          flag = false;
        }
      }
      if (game.toString() == "2") {
        if (parseInt(money) > parseInt(max2d_bet)) {
          // form_clear("wap");
          flag = false;
          temp_bulk_error +=
            "Data Tidak Valid: " +
            nomor +
            "-" +
            game +
            "D-" +
            money +
            ` | Maximal Bet 2D (${flag_fulldiskon}): ` +
            new Intl.NumberFormat().format(max2d_bet) +
            "<br />";
        }
        if (
          parseInt(count_line_2d) + parseInt(db_form4d_2d_count_temp) >=
          parseInt(limitline_2d)
        ) {
          flag = false;
        }
      }
    }

    return flag;
  }
  function checkLimitLine(game) {
    let flag = false;
    let limit4d = 0;
    let limit3d = 0;
    let limit3dd = 0;
    let limit2d = 0;
    let limit2dd = 0;
    let limit2dt = 0;
    switch (game.toString()) {
      case "4D":
        limit4d = parseInt(count_line_4d);
        if (parseInt(limit4d) < parseInt(limitline_4d)) {
          flag = true;
        }
        break;
      case "3D":
        limit3d = parseInt(count_line_3d);
        if (parseInt(limit3d) < parseInt(limitline_3d)) {
          flag = true;
        }
        break;
      case "3DD":
        limit3dd = parseInt(count_line_3dd);
        if (parseInt(limit3dd) < parseInt(limitline_3dd)) {
          flag = true;
        }
        break;
      case "2D":
        limit2d = parseInt(count_line_2d);
        if (parseInt(limit2d) < parseInt(limitline_2d)) {
          flag = true;
        }
        break;
      case "2DD":
        limit2dd = parseInt(count_line_2dd);
        if (parseInt(limit2dd) < parseInt(limitline_2dd)) {
          flag = true;
        }
        break;
      case "2DT":
        limit2dt = parseInt(count_line_2dt);
        if (parseInt(limit2dt) < parseInt(limitline_2dt)) {
          flag = true;
        }
        break;
    }

    return flag;
  }
  function countangkabbfs(x) {
    for (let i = 0; i < x.length; i++) {
      data_bbfs.push(x[i]);
      switch (x[i]) {
        case "0":
          nol = nol + 1;
          break;
        case "1":
          satu = satu + 1;
          break;
        case "2":
          dua = dua + 1;
          break;
        case "3":
          tiga = tiga + 1;
          break;
        case "4":
          empat = empat + 1;
          break;
        case "5":
          lima = lima + 1;
          break;
        case "6":
          enam = enam + 1;
          break;
        case "7":
          tujuh = tujuh + 1;
          break;
        case "8":
          delapan = delapan + 1;
          break;
        case "9":
          sembilan = sembilan + 1;
          break;
      }
    }
  }
  function checkcountangka(x) {
    //TEMP
    let nol_temp = 0;
    let satu_temp = 0;
    let dua_temp = 0;
    let tiga_temp = 0;
    let empat_temp = 0;
    let lima_temp = 0;
    let enam_temp = 0;
    let tujuh_temp = 0;
    let delapan_temp = 0;
    let sembilan_temp = 0;
    let flag = true;
    for (let i = 0; i < x.length; i++) {
      switch (x[i]) {
        case "0":
          nol_temp = nol_temp + 1;
          break;
        case "1":
          satu_temp = satu_temp + 1;
          break;
        case "2":
          dua_temp = dua_temp + 1;
          break;
        case "3":
          tiga_temp = tiga_temp + 1;
          break;
        case "4":
          empat_temp = empat_temp + 1;
          break;
        case "5":
          lima_temp = lima_temp + 1;
          break;
        case "6":
          enam_temp = enam_temp + 1;
          break;
        case "7":
          tujuh_temp = tujuh_temp + 1;
          break;
        case "8":
          delapan_temp = delapan_temp + 1;
          break;
        case "9":
          sembilan_temp = sembilan_temp + 1;
          break;
      }
    }
    if (nol_temp > nol) {
      flag = false;
    }
    if (satu_temp > satu) {
      flag = false;
    }
    if (dua_temp > dua) {
      flag = false;
    }
    if (tiga_temp > tiga) {
      flag = false;
    }
    if (empat_temp > empat) {
      flag = false;
    }
    if (lima_temp > lima) {
      flag = false;
    }
    if (enam_temp > enam) {
      flag = false;
    }
    if (tujuh_temp > tujuh) {
      flag = false;
    }
    if (delapan_temp > delapan) {
      flag = false;
    }
    if (sembilan_temp > sembilan) {
      flag = false;
    }
    return flag;
  }
  let idmodal = "";
  let myModal;
  const handleTambah = (e, path) => {
    let flag = true;
    switch (e) {
      case "4-3-2":
        if (nomor_432 == "" && parseInt(bet_432) < minimal_bet) {
          nomor_432_input.focus();
        } else {
          form4d_add();
        }
        break;
      case "432SET":
        if (nomorset == "") {
          nomorset_input.focus();
        } else {
          if (isNaN(nomorset)) {
            nomorset = "";
            flag = false;
          }
          if (isNaN(betset_1)) {
            betset_1 = 0;
            flag = false;
          }
          if (isNaN(betset_2)) {
            betset_2 = 0;
            flag = false;
          }
          if (isNaN(betset_3)) {
            betset_3 = 0;
            flag = false;
          }
          if (isNaN(betset_4)) {
            betset_4 = 0;
            flag = false;
          }
          if (isNaN(betset_5)) {
            betset_5 = 0;
            flag = false;
          }
          if (flag) {
            form4dset_add();
          }
        }
        break;
      case "BBFS":
        if (nomorbbfs == "") {
          nomorbbfs_input.focus();
        } else {
          if (isNaN(nomorbbfs)) {
            nomorbbfs = "";
            flag = false;
          }
          if (isNaN(bet_1)) {
            bet_1 = 0;
            flag = false;
          }
          if (isNaN(bet_2)) {
            bet_2 = 0;
            flag = false;
          }
          if (isNaN(bet_3)) {
            bet_3 = 0;
            flag = false;
          }
          if (isNaN(bet_4)) {
            bet_4 = 0;
            flag = false;
          }
          if (isNaN(bet_5)) {
            bet_5 = 0;
            flag = false;
          }
          if (flag) {
            formbbfs_add();
          }
        }
        break;
      case "wap":
        if (nomorwap == "") {
          nomorwap_input.focus();
        } else {
          formwap_add();
        }
        break;
      case "polatarung":
        if (nomoras == "") {
          nomoras_input.focus();
          flag = false;
        }
        if (nomorkop == "") {
          nomorkop_input.focus();
          flag = false;
        }
        if (flag) {
          formpolatarung_add();
        }
        break;
      case "3DD":
        if (nomor3dd == "" && parseInt(bet_3dd) < minimal_bet) {
          nomor3dd_input.focus();
        } else {
          if (nomor3dd.length < 3) {
            nomor3dd = "";
            bet_3dd = 0;
            flag = false;
          }
          if (isNaN(nomor3dd)) {
            nomor3dd = "";
            flag = false;
          }
          if (isNaN(bet_3dd)) {
            bet_3dd = 0;
            flag = false;
          }
          if (flag) {
            form3dd_add();
          }
        }
        break;
      case "2DD":
        if (nomor2dd == "" && parseInt(bet_2dd) < minimal_bet) {
          nomor2dd_input.focus();
        } else {
          if (nomor2dd.length < 2) {
            nomor2dd = "";
            bet_2dd = 0;
            flag = false;
          }
          if (isNaN(nomor2dd)) {
            nomor2dd = "";
            flag = false;
          }
          if (isNaN(bet_2dd)) {
            bet_2dd = 0;
            flag = false;
          }
          if (flag) {
            form2dd_add();
          }
        }
        break;
      case "2DT":
        if (nomor2dt == "" && parseInt(bet_2dt) < minimal_bet) {
          nomor2dt_input.focus();
        } else {
          if (nomor2dt.length < 2) {
            nomor2dt = "";
            bet_2dt = 0;
            flag = false;
          }
          if (isNaN(nomor2dt)) {
            nomor2dt = "";
            flag = false;
          }
          if (isNaN(bet_2dt)) {
            bet_2dt = 0;
            flag = false;
          }
          if (flag) {
            form2dt_add();
          }
        }
        break;
      case "quick2D":
        if (quick_bet == "" && parseInt(quick_bet) < minimal_bet) {
          quick_bet_input.focus();
        } else {
          if (isNaN(quick_bet)) {
            quick_bet = 0;
            flag = false;
          }
          if (flag) {
            formquick2d_add();
          }
        }
        break;
      case "pilihan":
        path_432 = path;
        handlePilihan(flag_fulldiskon);
        if (client_device !== "WEBSITE") {
          bottomOpen = !bottomOpen;
        }
        // myModal = new bootstrap.Modal(document.getElementById(idmodal));
        // myModal.show();
        break;
    }
  };
  const handlePilihan = (e) => {
    switch (path_432) {
      case "4-3-2":
        handleTambah("4-3-2");
        break;
      case "432SET":
        handleTambah("432SET");
        break;
      case "BBFS":
        handleTambah("BBFS");
        break;
      case "wap":
        handleTambah("wap");
        break;
      case "polatarung":
        handleTambah("polatarung");
        break;
      case "3DD":
        handleTambah("3DD");
        break;
      case "2DD":
        handleTambah("2DD");
        break;
      case "2DT":
        handleTambah("2DT");
        break;
      case "quick2D":
        handleTambah("quick2D");
        break;
    }
  };
  const handleKeyboard_format = (e) => {
    let key = e.target.value.replace(/\s/g, "");
    const numbers = /^\**\d+\**$/;
    let result = numbers.test(e.target.value);
    let maxLen = 0;
    if (result) {
      var lenVal = e.target.value.length;
      var typename = e.target.name;
      switch (typename) {
        case "BBFS":
          maxLen = bbfs + 1;
          break;
        case "3DD":
          maxLen = 4;
          break;
        case "2DD":
          maxLen = 3;
          break;
        case "2DT":
          maxLen = 3;
          break;
        default:
          maxLen = 5;
          break;
      }

      if (lenVal >= maxLen) {
        return (e.target.value = lastval);
      } else {
        lastval = +key;
        return (e.target.value = key);
      }
    } else {
      return (e.target.value = "");
    }
  };
  const handleKeyboard_number = (e) => {
    const numbers = /^\**\d+\**$/;
    let result = numbers.test(e.target.value);
    if (!result) {
      return (e.target.value = 0);
    } else {
      return (e.target.value = parseInt(e.target.value.replace(/\s/g, "")));
    }
  };

  const handleKeyboard_checkenter = (e) => {
    let keyCode = e.which || e.keyCode;
    if (keyCode === 13) {
      handleTambah("pilihan", e.target.name);
    }
  };

  $: {
    if (betHistoryView) {
      dispatch("handleInvoice", "all");
    }
  }
</script>

<svelte:head>
  <title>SDSB4D - {pasaran_name} - {gametype}</title>
</svelte:head>

<Card class={daylight ? "" : "bg-dark"} style="margin:0px;padding:0px;">
  <PeriodePanel
    {pasaran_name}
    permainan_title={game_title}
    {pasaran_periode}
    {pasaran_code}
    {daylight}
    {client_device}
  />
  <CardBody class={daylight ? "" : "dark"}>
    {#if client_device == "WEBSITE"}
      <TabContent
        pills
        class="periode-menu {daylight ? '' : 'dark'}"
        on:tab={(e) => (gametype = e.detail)}
      >
        <!-- 432D -->
        <TabPane tabId="4D/3D/2D" tab="4D/3D/2D" class="smart" active>
          <div style="margin:10px 0;">
            <div class="row gap-3">
              <div class="col-md-3">
                <div class="form-floating">
                  <!-- svelte-ignore a11y-autofocus -->
                  <input
                    autofocus
                    bind:this={nomor_432_input}
                    bind:value={nomor_432}
                    on:keyup={handleKeyboard_format}
                    on:keypress={handleKeyboard_checkenter}
                    type="text"
                    class="form-control fs-5 text-center button-bet-default"
                    class:dark={daylight === false}
                    placeholder="Input 4D/3D/2D Digit"
                    id="inputNomor432d"
                    min="1"
                    minlength="4"
                    maxlength="4"
                    tab_index="-1"
                    autocomplete="off"
                    name="4-3-2"
                  />
                  <label for="inputNomor432d" class="form-label"
                    >Nomor (2 - 4 Digits)</label
                  >
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-floating">
                  <input
                    bind:value={bet_432}
                    on:keyup={handleKeyboard_number}
                    on:keypress={handleKeyboard_checkenter}
                    type="text"
                    class="form-control fs-5 text-end button-bet-default"
                    class:dark={daylight === false}
                    placeholder="Bet"
                    id="inputBetMin432d"
                    style=""
                    minlength="3"
                    maxlength="7"
                    tab_index="0"
                    name="4-3-2"
                    autocomplete="off"
                  />
                  <label for="inputBetMin432d" class="form-label"
                    >Bet (min : {minimal_bet})</label
                  >
                  <span style="float:right;font-size:12px;color:#8a8a8a;"
                    >{new Intl.NumberFormat().format(bet_432)}</span
                  >
                </div>
              </div>
              <div class="col">
                <div class="mb-3">
                  <Button
                    id="btn2"
                    class="form-control mt-2"
                    style="border-radius:5px"
                    on:click={() => {
                      handleTambah("pilihan", "4-3-2");
                    }}>TAMBAH</Button
                  >
                </div>
              </div>
            </div>
          </div>
        </TabPane>

        <!-- 432d SET -->
        <TabPane tabId="4D/3D/2D SET" tab="4D/3D/2D SET">
          <div style="margin:10px 0;">
            <div class="row gap-1">
              <div class="col-md mb-3">
                <div class="form-floating">
                  <input
                    bind:this={nomorset_input}
                    bind:value={nomorset}
                    on:keyup={handleKeyboard_format}
                    on:keypress={handleKeyboard_checkenter}
                    id="inputNomor432dSET"
                    type="text"
                    class="form-control fs-5 text-center form-control-sm button-bet-default"
                    class:dark={daylight === false}
                    placeholder="Input 4D/3D/2D Digit"
                    minlength="4"
                    maxlength="4"
                    tab_index="-1"
                    name="432SET"
                    autocomplete="off"
                  />

                  <label for="inputNomor432dSET" class="form-label"
                    >Angka Pasangan (2 - 4 Digits)</label
                  >
                </div>
              </div>
            </div>
            <div class="row gap-1">
              <div class="col-md">
                <div class="form-floating">
                  <input
                    bind:value={betset_1}
                    on:keyup={handleKeyboard_number}
                    on:keypress={handleKeyboard_checkenter}
                    type="text"
                    id="inputBetMinSET4D"
                    class="form-control fs-5 text-end form-control-sm button-bet-default"
                    class:dark={daylight === false}
                    placeholder="Bet"
                    minlength="3"
                    maxlength={max4d_bet.toString().length}
                    tab_index="0"
                    name="432SET"
                    autocomplete="off"
                  />
                  <label for="inputBetMinSET4D" class="form-label"
                    >4D - Bet (min : {minimal_bet})</label
                  >
                  <span style="float:right;font-size:12px;color:#8a8a8a;"
                    >{new Intl.NumberFormat().format(betset_1)}</span
                  >
                </div>
              </div>
              <div class="col-md">
                <div class="form-floating">
                  <input
                    bind:value={betset_2}
                    on:keyup={handleKeyboard_number}
                    on:keypress={handleKeyboard_checkenter}
                    type="text"
                    id="inputBetMinSet3d"
                    class="form-control fs-5 text-end form-control-sm button-bet-default"
                    class:dark={daylight === false}
                    placeholder="Bet"
                    minlength="3"
                    maxlength={max3d_bet.toString().length}
                    tab_index="1"
                    name="432SET"
                    autocomplete="off"
                  />
                  <label for="inputBetMinSet3d" class="form-label"
                    >3D - Bet (min : {minimal_bet})</label
                  >
                  <span style="float:right;font-size:12px;color:#8a8a8a;"
                    >{new Intl.NumberFormat().format(betset_2)}</span
                  >
                </div>
              </div>
              <div class="col-md mb-3">
                <div class="form-floating">
                  <input
                    bind:value={betset_6}
                    on:keyup={handleKeyboard_number}
                    on:keypress={handleKeyboard_checkenter}
                    id="inputBetMinSet3dd"
                    type="text"
                    class="form-control fs-5 text-end form-control-sm button-bet-default"
                    class:dark={daylight === false}
                    placeholder="Input 4D/3D/2D Digit"
                    minlength="3"
                    maxlength={max3dd_bet.toString().length}
                    tab_index="2"
                    name="432SET"
                    autocomplete="off"
                  />

                  <label for="inputBetMinSet3dd" class="form-label"
                    >3DD (3D Depan) - Bet (min : {minimal_bet})</label
                  >
                  <span style="float:right;font-size:12px;color:#8a8a8a;"
                    >{new Intl.NumberFormat().format(betset_6)}</span
                  >
                </div>
              </div>
            </div>
            <div class="row gap-1">
              <div class="col-md">
                <div class="form-floating">
                  <input
                    bind:value={betset_3}
                    on:keyup={handleKeyboard_number}
                    on:keypress={handleKeyboard_checkenter}
                    type="text"
                    id="inputBetMinSet2d"
                    class="form-control fs-5 text-end form-control-sm button-bet-default"
                    class:dark={daylight === false}
                    placeholder="Bet"
                    minlength="3"
                    maxlength={max2d_bet.toString().length}
                    tab_index="3"
                    name="432SET"
                    autocomplete="off"
                  />
                  <span style="float:right;font-size:12px;color:#8a8a8a;"
                    >{new Intl.NumberFormat().format(betset_3)}</span
                  >
                  <label for="inputBetMinSet2d" class="form-label"
                    >2D - Bet (min : {minimal_bet})</label
                  >
                </div>
              </div>
              <div class="col-md">
                <div class="form-floating">
                  <input
                    bind:value={betset_4}
                    on:keyup={handleKeyboard_number}
                    on:keypress={handleKeyboard_checkenter}
                    type="text"
                    id="inputBetMinSet2ds"
                    class="form-control fs-5 text-end form-control-sm button-bet-default"
                    class:dark={daylight === false}
                    placeholder="Bet"
                    minlength="3"
                    maxlength={max2dd_bet.toString().length}
                    tab_index="4"
                    name="432SET"
                    autocomplete="off"
                  />
                  <span style="float:right;font-size:12px;color:#8a8a8a;"
                    >{new Intl.NumberFormat().format(betset_4)}</span
                  >
                  <label for="inputBetMinSet2ds" class="form-label"
                    >2DD (2D Depan) - Bet (min : {minimal_bet})</label
                  >
                </div>
              </div>
              <div class="col-md mb-3">
                <div class="form-floating">
                  <input
                    bind:value={betset_5}
                    on:keyup={handleKeyboard_number}
                    on:keypress={handleKeyboard_checkenter}
                    type="text"
                    id="inputBetMinSet2dts"
                    class="form-control fs-5 text-end form-control-sm button-bet-default"
                    class:dark={daylight === false}
                    placeholder="Bet"
                    minlength="3"
                    maxlength={max2dt_bet.toString().length}
                    tab_index="5"
                    name="432SET"
                    autocomplete="off"
                  />
                  <span style="float:right;font-size:12px;color:#8a8a8a;"
                    >{new Intl.NumberFormat().format(betset_5)}</span
                  >
                  <label for="inputBetMinSet2dts" class="form-label"
                    >2DT (2D Tengah) - Bet (min : {minimal_bet})</label
                  >
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <Button
                  id="btn2"
                  class="form-control mt-2"
                  style="border-radius:5px"
                  on:click={() => {
                    handleTambah("pilihan", "432SET");
                  }}>TAMBAH</Button
                >
              </div>
            </div>
          </div>
        </TabPane>

        <!-- Bolak Balik -->
        <TabPane tabId="BBFS" tab="BOLAK BALIK">
          <div style="margin:10px 0;">
            <div class="row gap-1">
              <div class="col-md mb-3">
                <div class="form-floating">
                  <input
                    bind:this={nomorbbfs_input}
                    bind:value={nomorbbfs}
                    on:keyup={handleKeyboard_format}
                    on:keypress={handleKeyboard_checkenter}
                    type="text"
                    id="inputNomorBbfs"
                    class="form-control fs-5 text-center form-control-sm button-bet-default"
                    class:dark={daylight === false}
                    placeholder="Input 4D/3D/2D Digit"
                    minlength="4"
                    maxlength={bbfs}
                    tab_index="-1"
                    name="BBFS"
                    autocomplete="off"
                  />
                  <span style="float:right;font-size:12px;color:#8a8a8a;" />
                  <label for="inputNomorBbfs" class="form-label"
                    >Angka Pasangan (2 - {bbfs} Digits)</label
                  >
                </div>
              </div>
            </div>
            <div class="row gap-1">
              <div class="col-md">
                <div class="form-floating">
                  <input
                    bind:value={bet_1}
                    on:keyup={handleKeyboard_number}
                    on:keypress={handleKeyboard_checkenter}
                    type="text"
                    id="inputBetMinbbfs4d"
                    class="form-control fs-5 text-end form-control-sm button-bet-default"
                    class:dark={daylight === false}
                    placeholder="Bet"
                    minlength="3"
                    maxlength={max4d_bet.toString().length}
                    tab_index="0"
                    name="BBFS"
                    autocomplete="off"
                  />
                  <span style="float:right;font-size:12px;color:#8a8a8a;"
                    >{new Intl.NumberFormat().format(bet_1)}</span
                  >
                  <label for="inputBetMinbbfs4d" class="form-label"
                    >4D - Bet (min : {minimal_bet})</label
                  >
                </div>
              </div>
              <div class="col-md">
                <div class="form-floating">
                  <input
                    bind:value={bet_2}
                    on:keyup={handleKeyboard_number}
                    on:keypress={handleKeyboard_checkenter}
                    type="text"
                    id="inputBetMinbbfs3d"
                    class="form-control fs-5 text-end form-control-sm button-bet-default"
                    class:dark={daylight === false}
                    placeholder="Bet"
                    minlength="3"
                    maxlength={max3d_bet.toString().length}
                    tab_index="1"
                    name="BBFS"
                    autocomplete="off"
                  />
                  <span style="float:right;font-size:12px;color:#8a8a8a;"
                    >{new Intl.NumberFormat().format(bet_2)}</span
                  >
                  <label for="inputBetMinbbfs3d" class="form-label"
                    >3D - Bet (min : {minimal_bet})</label
                  >
                </div>
              </div>
              <div class="col-md mb-3">
                <div class="form-floating">
                  <input
                    bind:value={bet_6}
                    on:keyup={handleKeyboard_number}
                    on:keypress={handleKeyboard_checkenter}
                    type="text"
                    id="inputBetMinbbfs3dd"
                    class="form-control fs-5 text-end form-control-sm button-bet-default"
                    class:dark={daylight === false}
                    placeholder="Input 4D/3D/2D Digit"
                    minlength="3"
                    maxlength={max3dd_bet.toString().length}
                    tab_index="2"
                    name="BBFS"
                    autocomplete="off"
                  />
                  <span style="float:right;font-size:12px;color:#8a8a8a;"
                    >{new Intl.NumberFormat().format(bet_6)}</span
                  >
                  <label for="inputBetMinbbfs3d" class="form-label"
                    >3DD (3D Depan) - Bet (min : {minimal_bet})</label
                  >
                </div>
              </div>
            </div>
            <div class="row gap-1">
              <div class="col-md">
                <div class="form-floating">
                  <input
                    bind:value={bet_3}
                    on:keyup={handleKeyboard_number}
                    on:keypress={handleKeyboard_checkenter}
                    type="text"
                    id="inputBetMinBbfs2d"
                    class="form-control fs-5 text-end form-control-sm button-bet-default"
                    class:dark={daylight === false}
                    placeholder="Bet"
                    minlength="3"
                    maxlength={max2d_bet.toString().length}
                    tab_index="3"
                    name="BBFS"
                    autocomplete="off"
                  />
                  <span style="float:right;font-size:12px;color:#8a8a8a;"
                    >{new Intl.NumberFormat().format(bet_3)}</span
                  >
                  <label for="inputBetMinBbfs2d" class="form-label"
                    >2D - Bet (min : {minimal_bet})</label
                  >
                </div>
              </div>
              <div class="col-md">
                <div class="form-floating">
                  <input
                    bind:value={bet_4}
                    on:keyup={handleKeyboard_number}
                    on:keypress={handleKeyboard_checkenter}
                    type="text"
                    id="inputBetMinBbfs2dd"
                    class="form-control fs-5 text-end form-control-sm button-bet-default"
                    class:dark={daylight === false}
                    placeholder="Bet"
                    minlength="3"
                    maxlength={max2dd_bet.toString().length}
                    tab_index="4"
                    name="BBFS"
                    autocomplete="off"
                  />
                  <span style="float:right;font-size:12px;color:#8a8a8a;"
                    >{new Intl.NumberFormat().format(bet_4)}</span
                  >
                  <label for="inputBetMinBbfs2dd" class="form-label"
                    >2DD (2D Depan) - Bet (min : {minimal_bet})</label
                  >
                </div>
              </div>
              <div class="col-md mb-3">
                <div class="form-floating">
                  <input
                    bind:value={bet_5}
                    on:keyup={handleKeyboard_number}
                    on:keypress={handleKeyboard_checkenter}
                    type="text"
                    id="inputBetMinBbfs2dt"
                    class="form-control fs-5 text-end form-control-sm button-bet-default"
                    class:dark={daylight === false}
                    placeholder="Bet"
                    minlength="3"
                    maxlength={max2dt_bet.toString().length}
                    tab_index="5"
                    name="BBFS"
                    autocomplete="off"
                  />
                  <span style="float:right;font-size:12px;color:#8a8a8a;"
                    >{new Intl.NumberFormat().format(bet_5)}</span
                  >
                  <label for="inputBetMinBbfs2dt" class="form-label"
                    >2DT (2D Tengah) - Bet (min : {minimal_bet})</label
                  >
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <Button
                  id="btn2"
                  class="form-control mt-2"
                  style="border-radius:5px"
                  on:click={() => {
                    handleTambah("pilihan", "BBFS");
                  }}>TAMBAH</Button
                >
              </div>
            </div>
          </div>
        </TabPane>

        <!-- WAP -->
        <TabPane tabId="WAP" tab="WAP">
          <div style="margin:10px 0;">
            <div class="form-floating">
              <textarea
                class="form-control fs-5 button-bet-default"
                class:dark={daylight === false}
                placeholder="Input WAP Format"
                style="height:95px;resize:none;"
                bind:this={nomorwap_input}
                bind:value={nomorwap}
                rows="100"
                cols="100"
                maxlength="1000"
                id="floatingTextarea"
              />
              <label for="floatingTextarea">Input WAP Format</label>
            </div>
            <div class="d-grid gap-1 mt-3">
              <Button
                id="btn2"
                on:click={() => {
                  handleTambah("pilihan", "wap");
                }}>TAMBAH</Button
              >
            </div>
            <p class="p-3" style="font-size:12px;color:#8a8a8a;">
              <b>Contoh (WAP) :</b><br />
              1234*234*34#1000,34*235*35#5000<br />
            </p>
          </div>
        </TabPane>

        <!-- Quick 2D -->
        <TabPane tabId="QUICK 2D" tab="QUICK 2D">
          <div style="margin:10px 0;">
            <div class="row gap-3">
              <div class="col-md">
                <div class="form-floating">
                  <select
                    class="form-select button-bet-default"
                    class:dark={daylight === false}
                    bind:value={quick_pilihan1}
                    bind:this={quick_pilihan1_input}
                    id="selectOpt1"
                    aria-label="Floating label select"
                  >
                    <option selected>Pilih Salah Satu</option>
                    <option value="BESAR">BESAR</option>
                    <option value="KECIL">KECIL</option>
                    <option value="GENAP">GENAP</option>
                    <option value="GANJIL">GANJIL</option>
                  </select>
                  <label for="selectOpt1">Besar/Kecil/Genap/Ganjil</label>
                </div>
              </div>
              <div class="col-md">
                <div class="form-floating">
                  <select
                    class="form-select button-bet-default"
                    class:dark={daylight === false}
                    bind:value={quick_pilihan2}
                    bind:this={quick_pilihan2_input}
                    id="selectOpt2"
                    aria-label="Floating label select"
                  >
                    <option selected>Pilih Posisi</option>
                    <option value="2D">2D</option>
                    <option value="2DD">2D DEPAN</option>
                    <option value="2DT">2D TENGAH</option>
                  </select>
                  <label for="selectOpt2">Posisi</label>
                </div>
              </div>
              <div class="col-md">
                <div class="form-floating">
                  <input
                    bind:value={quick_bet}
                    bind:this={quick_bet_input}
                    on:keyup={handleKeyboard_number}
                    on:keypress={handleKeyboard_checkenter}
                    type="text"
                    id="betQuick2D"
                    class="form-control fs-5 text-end button-bet-default"
                    class:dark={daylight === false}
                    placeholder="Bet"
                    minlength="3"
                    maxlength={quick_pilihan2 === "2D"
                      ? max2d_bet.toString().length
                      : quick_pilihan2 === "2DD"
                        ? max2dd_bet.toString().length
                        : max2dt_bet.toString().length}
                    tab_index="0"
                    name="quick2D"
                    autocomplete="off"
                  />
                  <span style="float:right;font-size:12px;color:#8a8a8a;"
                    >{new Intl.NumberFormat().format(quick_bet)}</span
                  >
                  <label for="betQuick2D" class="form-label"
                    >Bet (min : {minimal_bet})</label
                  >
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <Button
                  id="btn2"
                  class="form-control mt-2"
                  style="border-radius:5px"
                  on:click={() => {
                    handleTambah("pilihan", "quick2D");
                  }}>TAMBAH</Button
                >
              </div>
            </div>
          </div>
        </TabPane>

        <!-- Pola Tarung -->
        <TabPane tabId="POLA TARUNG" tab="POLA TARUNG">
          <div style="margin:10px 0;">
            <div class="row gap-3">
              <div class="col-md">
                <div class="form-floating">
                  <input
                    bind:this={nomoras_input}
                    bind:value={nomoras}
                    on:keyup={handleKeyboard_format}
                    type="text"
                    id="poltarAS"
                    class="form-control fs-5 text-center form-control-sm button-bet-default"
                    class:dark={daylight === false}
                    placeholder="AS"
                    minlength="4"
                    maxlength="4"
                    tab_index="-1"
                    autocomplete="off"
                  />
                  <label for="poltarAS" class="form-label">AS</label>
                </div>
              </div>
              <div class="col-md">
                <div class="form-floating">
                  <input
                    bind:this={nomorkop_input}
                    bind:value={nomorkop}
                    on:keyup={handleKeyboard_format}
                    type="text"
                    id="poltarKOP"
                    class="form-control fs-5 text-center form-control-sm button-bet-default"
                    class:dark={daylight === false}
                    placeholder="KOP"
                    minlength="4"
                    maxlength="4"
                    tab_index="-1"
                    autocomplete="off"
                  />
                  <label for="poltarKOP" class="form-label">KOP</label>
                </div>
              </div>
              <div class="col-md">
                <div class="form-floating">
                  <input
                    bind:value={nomorkepala}
                    on:keyup={handleKeyboard_format}
                    type="text"
                    id="poltarKPL"
                    class="form-control fs-5 text-center form-control-sm button-bet-default"
                    class:dark={daylight === false}
                    placeholder="KEPALA"
                    minlength="4"
                    maxlength="4"
                    tab_index="-1"
                    autocomplete="off"
                  />
                  <label for="poltarKPL" class="form-label">KEPALA</label>
                </div>
              </div>
              <div class="col-md">
                <div class="form-floating">
                  <input
                    bind:value={nomorekor}
                    on:keyup={handleKeyboard_format}
                    type="text"
                    id="poltarEKR"
                    class="form-control fs-5 text-center form-control-sm button-bet-default"
                    class:dark={daylight === false}
                    placeholder="EKOR"
                    minlength="4"
                    maxlength="4"
                    tab_index="-1"
                    autocomplete="off"
                  />
                  <label for="poltarEKR" class="form-label">EKOR</label>
                </div>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-md">
                <div class="form-floating">
                  <input
                    bind:this={bet_tarung_input}
                    bind:value={bet_tarung}
                    on:keyup={handleKeyboard_number}
                    on:keypress={handleKeyboard_checkenter}
                    type="text"
                    id="poltarBetMin"
                    class="form-control fs-5 text-end button-bet-default"
                    class:dark={daylight === false}
                    placeholder="Bet"
                    minlength="3"
                    maxlength="7"
                    tab_index="0"
                    name="polatarung"
                    autocomplete="off"
                  />
                  <span style="float:right;font-size:12px;color:#8a8a8a;"
                    >{new Intl.NumberFormat().format(bet_tarung)}</span
                  >
                  <label for="poltarBetMin" class="form-label"
                    >Bet (min : {minimal_bet})</label
                  >
                </div>
              </div>
              <div class="col-md">
                <Button
                  id="btn2"
                  class="form-control mt-1"
                  style="border-radius:5px"
                  on:click={() => {
                    handleTambah("pilihan", "polatarung");
                  }}>TAMBAH</Button
                >
              </div>
            </div>
            <p class="p-3" style="font-size:12px;color:#8a8a8a;">
              POLA TARUNG : AS KOP KEPALA EKOR <br />
              <b>Contoh (POLA TARUNG) :</b><br />
              - 123 456 789 012 => 4D<br />
              - 12 45 78 => 3D<br />
              - 12 45 => 2D<br />
            </p>
          </div>
        </TabPane>

        <!-- 3D Depan -->
        <TabPane tabId="3D DEPAN" tab="3D DEPAN">
          <div style="margin:10px 0;">
            <div class="row gap-3">
              <div class="col-md">
                <div class="form-floating">
                  <input
                    bind:this={nomor3dd_input}
                    bind:value={nomor3dd}
                    on:keyup={handleKeyboard_format}
                    on:keypress={handleKeyboard_checkenter}
                    type="text"
                    id="inputNomor3dd"
                    class="form-control fs-5 text-end form-control-sm button-bet-default"
                    class:dark={daylight === false}
                    placeholder="Input 3DD Digit"
                    minlength="3"
                    maxlength="3"
                    tab_index="-1"
                    name="3DD"
                    autocomplete="off"
                  />
                  <span
                    class="help-block"
                    style="float:right;font-size:12px;"
                  />
                  <label for="inputNomor3dd" class="form-label"
                    >Nomor (3 digit)</label
                  >
                </div>
              </div>
              <div class="col-md">
                <div class="form-floating">
                  <input
                    bind:value={bet_3dd}
                    on:keyup={handleKeyboard_number}
                    on:keypress={handleKeyboard_checkenter}
                    type="text"
                    id="inputBetMin3dd"
                    class="form-control fs-5 text-end button-bet-default"
                    class:dark={daylight === false}
                    placeholder="Bet"
                    minlength="3"
                    maxlength={max3dd_bet.toString().length}
                    tab_index="0"
                    name="3DD"
                    autocomplete="off"
                  />
                  <label for="inputBetMin3dd" class="form-label"
                    >Bet (min : {minimal_bet})</label
                  >
                  <span style="float:right;font-size:12px;color:#8a8a8a;"
                    >{new Intl.NumberFormat().format(bet_3dd)}</span
                  >
                </div>
              </div>
              <div class="col-md">
                <div class="mb-3">
                  <Button
                    id="btn2"
                    class="form-control mt-2"
                    style="border-radius:5px"
                    on:click={() => {
                      handleTambah("pilihan", "3DD");
                    }}>TAMBAH</Button
                  >
                </div>
              </div>
            </div>
          </div>
        </TabPane>

        <!-- 2D Depan -->
        <TabPane tabId="2D DEPAN" tab="2D DEPAN">
          <div style="margin:10px 0;">
            <div class="row gap-3">
              <div class="col-md">
                <div class="form-floating">
                  <input
                    bind:this={nomor2dd_input}
                    bind:value={nomor2dd}
                    on:keyup={handleKeyboard_format}
                    on:keypress={handleKeyboard_checkenter}
                    type="text"
                    id="inputNomor2dd"
                    class="form-control fs-5 text-center form-control-sm button-bet-default"
                    class:dark={daylight === false}
                    placeholder="Input 2DD Digit"
                    minlength="2"
                    maxlength="2"
                    tab_index="-1"
                    name="2DD"
                    autocomplete="off"
                  />
                  <span
                    class="help-block"
                    style="float:right;font-size:12px;"
                  />
                  <label for="inputNomor2dd" class="form-label"
                    >Nomor (2 digit)</label
                  >
                </div>
              </div>
              <div class="col-md">
                <div class="form-floating">
                  <input
                    bind:value={bet_2dd}
                    on:keyup={handleKeyboard_number}
                    on:keypress={handleKeyboard_checkenter}
                    type="text"
                    id="inputBetMin2dd"
                    class="form-control fs-5 text-end button-bet-default"
                    class:dark={daylight === false}
                    placeholder="Bet"
                    minlength="3"
                    name="2DD"
                    maxlength={max2dd_bet.toString().length}
                    tab_index="0"
                    autocomplete="off"
                  />
                  <span style="float:right;font-size:12px;color:#8a8a8a;"
                    >{new Intl.NumberFormat().format(bet_2dd)}</span
                  >
                  <label for="inputBetMin2dd" class="form-label"
                    >Bet (min : {minimal_bet})</label
                  >
                </div>
              </div>
              <div class="col">
                <div class="mb-3">
                  <Button
                    id="btn2"
                    class="form-control mt-2"
                    style="border-radius:5px"
                    on:click={() => {
                      handleTambah("pilihan", "2DD");
                    }}>TAMBAH</Button
                  >
                </div>
              </div>
            </div>
          </div>
        </TabPane>

        <!-- 2D Tengah -->
        <TabPane tabId="2D TENGAH" tab="2D TENGAH">
          <div style="margin:10px 0;">
            <div class="row gap-3">
              <div class="col-md">
                <div class="form-floating">
                  <input
                    bind:this={nomor2dt_input}
                    bind:value={nomor2dt}
                    on:keyup={handleKeyboard_format}
                    on:keypress={handleKeyboard_checkenter}
                    type="text"
                    id="inputNomor2dt"
                    class="form-control form-control-sm fs-5 text-center button-bet-default"
                    class:dark={daylight === false}
                    placeholder="Input 2DT Digit"
                    minlength="2"
                    maxlength="2"
                    tab_index="-1"
                    name="2DT"
                    autocomplete="off"
                  />
                  <span
                    class="help-block"
                    style="float:right;font-size:12px;"
                  />
                  <label for="inputNomor2dt" class="form-label"
                    >Nomor (2 digit)</label
                  >
                </div>
              </div>
              <div class="col-md">
                <div class="form-floating">
                  <input
                    bind:value={bet_2dt}
                    on:keyup={handleKeyboard_number}
                    on:keypress={handleKeyboard_checkenter}
                    type="text"
                    id="inputBetMin2dt"
                    class="form-control fs-5 text-end button-bet-default"
                    class:dark={daylight === false}
                    placeholder="Bet"
                    minlength="3"
                    maxlength={max2dt_bet.toString().length}
                    tab_index="0"
                    name="2DT"
                    autocomplete="off"
                  />
                  <span style="float:right;font-size:12px;color:#8a8a8a;"
                    >{new Intl.NumberFormat().format(bet_2dt)}</span
                  >
                  <label for="inputBetMin2dt" class="form-label"
                    >Bet (min : {minimal_bet})</label
                  >
                </div>
              </div>
              <div class="col-md">
                <div class="mb-3">
                  <Button
                    id="btn2"
                    class="form-control mt-2"
                    style="border-radius:5px"
                    on:click={() => {
                      handleTambah("pilihan", "2DT");
                    }}>TAMBAH</Button
                  >
                </div>
              </div>
            </div>
          </div>
        </TabPane>
      </TabContent>
    {:else}
      <button
        class="btn btn-play mobile"
        class:dark={daylight === false}
        type="button"
        aria-controls="offcanvasdata"
        on:click={toggleBottom}
      >
        Mulai Bermain <Fa
          icon={faDice}
          size="1.5x"
          style="padding-left: .75rem;vertical-align: middle;"
        />
      </button>
    {/if}
    <Offcanvas
      isOpen={bottomOpen}
      placement="bottom"
      backdrop={true}
      scroll
      class="mx-auto"
      style="height: {canvasHeigth}%;background-color:{daylight
        ? '#fff'
        : '#171717'}; border-top-left-radius:20px; 
        border-top-right-radius:20px;
        box-shadow:0 0 1px 0px #888888; 
        max-width:540px;"
    >
      <div slot="header">
        <Container>
          <Row>
            <Col class="col-8">
              <h5
                class="offcanvas-title fw-bold text-{daylight
                  ? 'dark'
                  : 'light'}"
                id="offcanvasExampleLabel"
              >
                {pasaran_name} - 4D/3D/2D
              </h5>
            </Col>
            <Col class="text-end">
              <button
                type="button"
                class="btn-close text-reset"
                class:btn-close-white={daylight === false}
                data-bs-dismiss="offcanvas"
                aria-label="Close"
                on:click={() => (bottomOpen = false)}
              />
            </Col>
          </Row>
        </Container>
      </div>
      <Container>
        <Row>
          <Col>
            <TabContent
              pills
              class="periode-menu {daylight ? '' : 'dark'}"
              on:tab={(e) => {
                gametype = e.detail;
                switch (gametype) {
                  case "4D/3D/2D SET":
                    canvasHeigth = 88;
                    break;
                  case "POLA TARUNG":
                    canvasHeigth = 75;
                    break;
                  case "BBFS":
                    canvasHeigth = 88;
                    break;
                  default:
                    canvasHeigth = 70;
                    break;
                }
              }}
            >
              <!-- 432D -->
              <TabPane tabId="4D/3D/2D" tab="4D/3D/2D" class="smart" active>
                <div style="margin:10px 0;">
                  <div class="row gap-3">
                    <div class="col-md-12">
                      <div class="form-floating">
                        <!-- svelte-ignore a11y-autofocus -->
                        <input
                          autofocus
                          bind:this={nomor_432_input}
                          bind:value={nomor_432}
                          on:keyup={handleKeyboard_format}
                          on:keypress={handleKeyboard_checkenter}
                          type="text"
                          class="form-control fs-5 text-center button-bet-default"
                          class:dark={daylight === false}
                          placeholder="Input 4D/3D/2D Digit"
                          id="inputNomor432dMobile"
                          min="1"
                          minlength="4"
                          maxlength="4"
                          tab_index="-1"
                          autocomplete="off"
                          name="4-3-2"
                          disabled
                        />
                        <label for="inputNomor432dMobile" class="form-label"
                          >Nomor (2 - 4 Digits)</label
                        >
                        <span
                          class="field-icon"
                          on:click={() => clearNomor423("432")}
                          ><UiDeleteLeft /></span
                        >
                      </div>

                      <BtnAngka bind:angka={nomor_432} />
                    </div>
                    <div class="col-md-12">
                      <BtnBet
                        {daylight}
                        bind:betval={bet_432}
                        {maxbet}
                        elemid="inputBetMin432dMobile"
                        gametype="4-3-2"
                        {minimal_bet}
                      />
                    </div>
                    <div class="col">
                      <div class="mb-3">
                        <Button
                          id="btn2"
                          class="form-control mt-2"
                          style="border-radius:5px"
                          on:click={() => {
                            handleTambah("pilihan", "4-3-2");
                          }}>TAMBAH</Button
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </TabPane>

              <!-- 432d SET -->
              <TabPane tabId="4D/3D/2D SET" tab="4D/3D/2D SET">
                <div style="margin:10px 0;">
                  <div class="row gap-1">
                    <div class="col-md mb-3">
                      <div class="form-floating">
                        <input
                          bind:this={nomorset_input}
                          bind:value={nomorset}
                          on:keyup={handleKeyboard_format}
                          on:keypress={handleKeyboard_checkenter}
                          id="inputNomor432dSETMobile"
                          type="text"
                          class="form-control fs-5 text-center form-control-sm button-bet-default"
                          class:dark={daylight === false}
                          placeholder="Input 4D/3D/2D Digit"
                          minlength="4"
                          maxlength="4"
                          tab_index="-1"
                          name="432SET"
                          autocomplete="off"
                          disabled
                        />

                        <label for="inputNomor432dSETMobile" class="form-label"
                          >Angka Pasangan (2 - 4 Digits)</label
                        >
                        <span
                          class="field-icon"
                          on:click={() => clearNomor423("set")}
                          ><UiDeleteLeft /></span
                        >
                      </div>

                      <BtnAngka bind:angka={nomorset} />
                    </div>
                  </div>
                  <div class="row gap-1">
                    <div class="col-md-12">
                      <BtnBet
                        {daylight}
                        bind:betval={betset_1}
                        {maxbet}
                        elemid="inputBetMinSET4DMobile"
                        gametype="4D"
                        {minimal_bet}
                      />
                    </div>
                    <div class="col-md-12">
                      <BtnBet
                        {daylight}
                        bind:betval={betset_2}
                        {maxbet}
                        elemid="inputBetMinSet3dMobile"
                        gametype="3D"
                        {minimal_bet}
                      />
                    </div>
                    <div class="col-md-12">
                      <BtnBet
                        {daylight}
                        bind:betval={betset_6}
                        {maxbet}
                        elemid="inputBetMinSet3ddMobile"
                        gametype="3DD"
                        {minimal_bet}
                      />
                    </div>
                  </div>
                  <div class="row gap-1">
                    <div class="col-md-12">
                      <BtnBet
                        {daylight}
                        bind:betval={betset_3}
                        {maxbet}
                        elemid="inputBetMinSet2dMobile"
                        gametype="2D"
                        {minimal_bet}
                      />
                    </div>
                    <div class="col-md-12">
                      <BtnBet
                        {daylight}
                        bind:betval={betset_4}
                        {maxbet}
                        elemid="inputBetMinSet2ddMobile"
                        gametype="2DD"
                        {minimal_bet}
                      />
                    </div>
                    <div class="col-md-12 mb-3">
                      <BtnBet
                        {daylight}
                        bind:betval={betset_5}
                        {maxbet}
                        elemid="inputBetMinSet2dtMobile"
                        gametype="2DT"
                        {minimal_bet}
                      />
                    </div>
                  </div>
                  <div class="row">
                    <div class="col">
                      <Button
                        id="btn2"
                        class="form-control mt-2"
                        style="border-radius:5px"
                        on:click={() => {
                          handleTambah("pilihan", "432SET");
                        }}>TAMBAH</Button
                      >
                    </div>
                  </div>
                </div>
              </TabPane>

              <!-- Bolak Balik -->
              <TabPane tabId="BBFS" tab="BOLAK BALIK">
                <div style="margin:10px 0;">
                  <div class="row gap-1">
                    <div class="col-md mb-3">
                      <div class="form-floating">
                        <input
                          bind:this={nomorbbfs_input}
                          bind:value={nomorbbfs}
                          on:keyup={handleKeyboard_format}
                          on:keypress={handleKeyboard_checkenter}
                          type="text"
                          id="inputNomorBbfsMobile"
                          class="form-control fs-5 text-center form-control-sm button-bet-default"
                          class:dark={daylight === false}
                          placeholder="Input 4D/3D/2D Digit"
                          minlength="4"
                          maxlength={bbfs}
                          tab_index="-1"
                          name="BBFS"
                          autocomplete="off"
                          disabled
                        />
                        <span
                          style="float:right;font-size:12px;color:#8a8a8a;"
                        />
                        <label for="inputNomorBbfsMobile" class="form-label"
                          >Angka Pasangan (2 - {bbfs} Digits)</label
                        >
                        <span
                          class="field-icon"
                          on:click={() => clearNomor423("bbfs")}
                          ><UiDeleteLeft /></span
                        >
                      </div>

                      <BtnAngka bind:angka={nomorbbfs} maxlength={bbfs} />
                    </div>
                  </div>
                  <div class="row gap-1">
                    <div class="col-md-12">
                      <BtnBet
                        {daylight}
                        bind:betval={bet_1}
                        {maxbet}
                        elemid="inputBetMinbbfs4dMobile"
                        gametype="4D"
                        {minimal_bet}
                      />
                    </div>
                    <div class="col-md-12">
                      <BtnBet
                        {daylight}
                        bind:betval={bet_2}
                        {maxbet}
                        elemid="inputBetMinbbfs3dMobile"
                        gametype="3D"
                        {minimal_bet}
                      />
                    </div>
                    <div class="col-md-12">
                      <BtnBet
                        {daylight}
                        bind:betval={bet_6}
                        {maxbet}
                        elemid="inputBetMinbbfs3ddMobile"
                        gametype="3DD"
                        {minimal_bet}
                      />
                    </div>
                  </div>
                  <div class="row gap-1">
                    <div class="col-md-12">
                      <BtnBet
                        {daylight}
                        bind:betval={bet_3}
                        {maxbet}
                        elemid="inputBetMinBbfs2dMobile"
                        gametype="2D"
                        {minimal_bet}
                      />
                    </div>
                    <div class="col-md-12">
                      <BtnBet
                        {daylight}
                        bind:betval={bet_4}
                        {maxbet}
                        elemid="inputBetMinBbfs2ddMobile"
                        gametype="2DD"
                        {minimal_bet}
                      />
                    </div>
                    <div class="col-md-12 mb-3">
                      <BtnBet
                        {daylight}
                        bind:betval={bet_5}
                        {maxbet}
                        elemid="inputBetMinBbfs2dtMobile"
                        gametype="2DT"
                        {minimal_bet}
                      />
                    </div>
                  </div>
                  <div class="row">
                    <div class="col">
                      <Button
                        id="btn2"
                        class="form-control mt-2"
                        style="border-radius:5px"
                        on:click={() => {
                          handleTambah("pilihan", "BBFS");
                        }}>TAMBAH</Button
                      >
                    </div>
                  </div>
                </div>
              </TabPane>

              <!-- WAP -->
              <TabPane tabId="WAP" tab="WAP">
                <div style="margin:10px 0;">
                  <div class="form-floating">
                    <textarea
                      class="form-control fs-5 button-bet-default"
                      class:dark={daylight === false}
                      placeholder="Input WAP Format"
                      style="height:95px;resize:none;"
                      bind:this={nomorwap_input}
                      bind:value={nomorwap}
                      rows="100"
                      cols="100"
                      maxlength="1000"
                      id="floatingTextareaMobile"
                    />
                    <label for="floatingTextareaMobile">Input WAP Format</label>
                  </div>
                  <div class="d-grid gap-1 mt-3">
                    <Button
                      id="btn2"
                      on:click={() => {
                        handleTambah("pilihan", "wap");
                      }}>TAMBAH</Button
                    >
                  </div>
                  <p class="p-3" style="font-size:12px;color:#8a8a8a;">
                    <b>Contoh (WAP) :</b><br />
                    1234*234*34#1000,34*235*35#5000<br />
                  </p>
                </div>
              </TabPane>

              <!-- Quick 2D -->
              <TabPane tabId="QUICK 2D" tab="QUICK 2D">
                <div style="margin:10px 0;">
                  <div class="row gap-3">
                    <div class="col-md">
                      <div class="form-floating">
                        <select
                          class="form-select button-bet-default"
                          class:dark={daylight === false}
                          bind:value={quick_pilihan1}
                          bind:this={quick_pilihan1_input}
                          id="selectOpt1Mobile"
                          aria-label="Floating label select"
                        >
                          <option selected>Pilih Salah Satu</option>
                          <option value="BESAR">BESAR</option>
                          <option value="KECIL">KECIL</option>
                          <option value="GENAP">GENAP</option>
                          <option value="GANJIL">GANJIL</option>
                        </select>
                        <label for="selectOpt1Mobile"
                          >Besar/Kecil/Genap/Ganjil</label
                        >
                      </div>
                    </div>
                    <div class="col-md">
                      <div class="form-floating">
                        <select
                          class="form-select button-bet-default"
                          class:dark={daylight === false}
                          bind:value={quick_pilihan2}
                          bind:this={quick_pilihan2_input}
                          id="selectOpt2Mobile"
                          aria-label="Floating label select"
                        >
                          <option selected>Pilih Posisi</option>
                          <option value="2D">2D</option>
                          <option value="2DD">2D DEPAN</option>
                          <option value="2DT">2D TENGAH</option>
                        </select>
                        <label for="selectOpt2Mobile">Posisi</label>
                      </div>
                    </div>
                    <div class="col-md-12 mb-3">
                      <BtnBet
                        {daylight}
                        bind:betval={quick_bet}
                        {maxbet}
                        elemid="betQuick2DMobile"
                        gametype="2D"
                        {minimal_bet}
                      />
                    </div>
                  </div>
                  <div class="row">
                    <div class="col">
                      <Button
                        id="btn2"
                        class="form-control mt-2"
                        style="border-radius:5px"
                        on:click={() => {
                          handleTambah("pilihan", "quick2D");
                        }}>TAMBAH</Button
                      >
                    </div>
                  </div>
                </div>
              </TabPane>

              <!-- Pola Tarung -->
              <TabPane tabId="POLA TARUNG" tab="POLA TARUNG">
                <div style="margin:10px 0;">
                  <div class="row gap-3">
                    <div class="col-md-12">
                      <div class="form-floating">
                        <input
                          bind:this={nomoras_input}
                          bind:value={nomoras}
                          on:keyup={handleKeyboard_format}
                          type="text"
                          id="poltarASMobile"
                          class="form-control fs-5 text-center form-control-sm button-bet-default"
                          class:dark={daylight === false}
                          placeholder="AS"
                          minlength="4"
                          maxlength="4"
                          tab_index="-1"
                          autocomplete="off"
                          disabled
                        />
                        <label for="poltarASMobile" class="form-label">AS</label
                        >
                        <span
                          class="field-icon"
                          on:click={() => clearNomor423("ptas")}
                          ><UiDeleteLeft /></span
                        >
                      </div>
                      <BtnAngka bind:angka={nomoras} />
                    </div>
                    <div class="col-md-12">
                      <div class="form-floating">
                        <input
                          bind:this={nomorkop_input}
                          bind:value={nomorkop}
                          on:keyup={handleKeyboard_format}
                          type="text"
                          id="poltarKOPMobile"
                          class="form-control fs-5 text-center form-control-sm button-bet-default"
                          class:dark={daylight === false}
                          placeholder="KOP"
                          minlength="4"
                          maxlength="4"
                          tab_index="-1"
                          autocomplete="off"
                          disabled
                        />
                        <label for="poltarKOPMobile" class="form-label"
                          >KOP</label
                        >
                        <span
                          class="field-icon"
                          on:click={() => clearNomor423("ptkop")}
                          ><UiDeleteLeft /></span
                        >
                      </div>
                      <BtnAngka bind:angka={nomorkop} />
                    </div>
                    <div class="col-md-12">
                      <div class="form-floating">
                        <input
                          bind:value={nomorkepala}
                          on:keyup={handleKeyboard_format}
                          type="text"
                          id="poltarKPLMobile"
                          class="form-control fs-5 text-center form-control-sm button-bet-default"
                          class:dark={daylight === false}
                          placeholder="KEPALA"
                          minlength="4"
                          maxlength="4"
                          tab_index="-1"
                          autocomplete="off"
                          disabled
                        />
                        <label for="poltarKPLMobile" class="form-label"
                          >KEPALA</label
                        >
                        <span
                          class="field-icon"
                          on:click={() => clearNomor423("ptkep")}
                          ><UiDeleteLeft /></span
                        >
                      </div>
                      <BtnAngka bind:angka={nomorkepala} />
                    </div>
                    <div class="col-md-12">
                      <div class="form-floating">
                        <input
                          bind:value={nomorekor}
                          on:keyup={handleKeyboard_format}
                          type="text"
                          id="poltarEKRMobile"
                          class="form-control fs-5 text-center form-control-sm button-bet-default"
                          class:dark={daylight === false}
                          placeholder="EKOR"
                          minlength="4"
                          maxlength="4"
                          tab_index="-1"
                          autocomplete="off"
                          disabled
                        />
                        <label for="poltarEKRMobile" class="form-label"
                          >EKOR</label
                        >
                        <span
                          class="field-icon"
                          on:click={() => clearNomor423("ptekr")}
                          ><UiDeleteLeft /></span
                        >
                      </div>
                      <BtnAngka bind:angka={nomorekor} />
                    </div>
                  </div>
                  <div class="row mt-3">
                    <div class="col-md-12 mb-3">
                      <BtnBet
                        {daylight}
                        bind:betval={bet_tarung}
                        {maxbet}
                        elemid="poltarBetMinMobile"
                        gametype="polatarung"
                        {minimal_bet}
                      />
                    </div>

                    <div class="col-md">
                      <Button
                        id="btn2"
                        class="form-control mt-1"
                        style="border-radius:5px"
                        on:click={() => {
                          handleTambah("pilihan", "polatarung");
                        }}>TAMBAH</Button
                      >
                    </div>
                  </div>
                  <p class="p-3" style="font-size:12px;color:#8a8a8a;">
                    POLA TARUNG : AS KOP KEPALA EKOR <br />
                    <b>Contoh (POLA TARUNG) :</b><br />
                    - 123 456 789 012 => 4D<br />
                    - 12 45 78 => 3D<br />
                    - 12 45 => 2D<br />
                  </p>
                </div>
              </TabPane>

              <!-- 3D Depan -->
              <TabPane tabId="3D DEPAN" tab="3D DEPAN">
                <div style="margin:10px 0;">
                  <div class="row gap-3">
                    <div class="col-md-12">
                      <div class="form-floating">
                        <input
                          bind:this={nomor3dd_input}
                          bind:value={nomor3dd}
                          on:keyup={handleKeyboard_format}
                          on:keypress={handleKeyboard_checkenter}
                          type="text"
                          id="inputNomor3ddMobile"
                          class="form-control fs-5 text-center form-control-sm button-bet-default"
                          class:dark={daylight === false}
                          placeholder="Input 3DD Digit"
                          minlength="3"
                          maxlength="3"
                          tab_index="-1"
                          name="3DD"
                          autocomplete="off"
                          disabled
                        />
                        <span
                          class="help-block"
                          style="float:right;font-size:12px;"
                        />
                        <label for="inputNomor3ddMobile" class="form-label"
                          >Nomor (3 digit)</label
                        >
                        <span
                          class="field-icon"
                          on:click={() => clearNomor423("3dds")}
                          ><UiDeleteLeft /></span
                        >
                      </div>
                      <BtnAngka bind:angka={nomor3dd} maxlength={3} />
                    </div>
                    <div class="col-md-12 mb-3">
                      <BtnBet
                        {daylight}
                        bind:betval={bet_3dd}
                        {maxbet}
                        elemid="inputBetMin3ddsMobile"
                        gametype="3DD"
                        {minimal_bet}
                      />
                    </div>
                    <div class="col-md-12">
                      <div class="mb-3">
                        <Button
                          id="btn2"
                          class="form-control mt-2"
                          style="border-radius:5px"
                          on:click={() => {
                            handleTambah("pilihan", "3DD");
                          }}>TAMBAH</Button
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </TabPane>

              <!-- 2D Depan -->
              <TabPane tabId="2D DEPAN" tab="2D DEPAN">
                <div style="margin:10px 0;">
                  <div class="row gap-3">
                    <div class="col-md-12">
                      <div class="form-floating">
                        <input
                          bind:this={nomor2dd_input}
                          bind:value={nomor2dd}
                          on:keyup={handleKeyboard_format}
                          on:keypress={handleKeyboard_checkenter}
                          type="text"
                          id="inputNomor2ddMobile"
                          class="form-control fs-5 text-center form-control-sm button-bet-default"
                          class:dark={daylight === false}
                          placeholder="Input 2DD Digit"
                          minlength="2"
                          maxlength="2"
                          tab_index="-1"
                          name="2DD"
                          autocomplete="off"
                          disabled
                        />
                        <span
                          class="help-block"
                          style="float:right;font-size:12px;"
                        />
                        <label for="inputNomor2ddMobile" class="form-label"
                          >Nomor (2 digit)</label
                        >
                        <span
                          class="field-icon"
                          on:click={() => clearNomor423("2dds")}
                          ><UiDeleteLeft /></span
                        >
                      </div>
                      <BtnAngka bind:angka={nomor2dd} maxlength={2} />
                    </div>
                    <div class="col-md-12 mb-3">
                      <BtnBet
                        {daylight}
                        bind:betval={bet_2dd}
                        {maxbet}
                        elemid="inputBetMin2ddsMobile"
                        gametype="2DD"
                        {minimal_bet}
                      />
                    </div>
                    <div class="col">
                      <div class="mb-3">
                        <Button
                          id="btn2"
                          class="form-control mt-2"
                          style="border-radius:5px"
                          on:click={() => {
                            handleTambah("pilihan", "2DD");
                          }}>TAMBAH</Button
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </TabPane>

              <!-- 2D Tengah -->
              <TabPane tabId="2D TENGAH" tab="2D TENGAH">
                <div style="margin:10px 0;">
                  <div class="row gap-3">
                    <div class="col-md-12">
                      <div class="form-floating">
                        <input
                          bind:this={nomor2dt_input}
                          bind:value={nomor2dt}
                          on:keyup={handleKeyboard_format}
                          on:keypress={handleKeyboard_checkenter}
                          type="text"
                          id="inputNomor2dtMobile"
                          class="form-control form-control-sm fs-5 text-center button-bet-default"
                          class:dark={daylight === false}
                          placeholder="Input 2DT Digit"
                          minlength="2"
                          maxlength="2"
                          tab_index="-1"
                          name="2DT"
                          autocomplete="off"
                          disabled
                        />
                        <span
                          class="help-block"
                          style="float:right;font-size:12px;"
                        />
                        <label for="inputNomor2dtMobile" class="form-label"
                          >Nomor (2 digit)</label
                        >
                        <span
                          class="field-icon"
                          on:click={() => clearNomor423("2dts")}
                          ><UiDeleteLeft /></span
                        >
                      </div>
                      <BtnAngka bind:angka={nomor2dt} maxlength={2} />
                    </div>
                    <div class="col-md-12 mb-3">
                      <BtnBet
                        {daylight}
                        bind:betval={bet_2dt}
                        {maxbet}
                        elemid="inputBetMin2dtsMobile"
                        gametype="2DT"
                        {minimal_bet}
                      />
                    </div>
                    <div class="col-md">
                      <div class="mb-3">
                        <Button
                          id="btn2"
                          class="form-control mt-2"
                          style="border-radius:5px"
                          on:click={() => {
                            handleTambah("pilihan", "2DT");
                          }}>TAMBAH</Button
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </TabPane>
            </TabContent>
          </Col>
        </Row>
      </Container>
    </Offcanvas>
  </CardBody>
</Card>

<Modal modal_id={"modalError"} modal_size={"modal-dialog-centered"}>
  <slot:template slot="header">
    <div class="float-end">
      <img
        style="cursor:pointer;"
        data-bs-dismiss="modal"
        aria-label="Close"
        width="25"
        src="https://i.ibb.co/9yNF25L/outline-close-white-48dp.png"
        alt=""
      />
    </div>
    <h5 class="modal-title" id="exampleModalLabel">ERROR</h5>
  </slot:template>
  <slot:template slot="body">
    {@html temp_bulk_error}
  </slot:template>
</Modal>
<div class="clearfix" />
<br />
<Tablekeranjang
  on:removekeranjang={removekeranjang}
  on:removekeranjangall={removekeranjangall}
  on:handleSave={handleSave}
  on:handlePaymentType={handlePaymentType}
  bind:betHistoryView
  {client_device}
  {count_line_4d}
  {count_line_3d}
  {count_line_3dd}
  {count_line_2d}
  {count_line_2dd}
  {count_line_2dt}
  {keranjang}
  {totalkeranjang}
  {minimal_bet}
  {max4d_bet}
  {max3d_bet}
  {max3dd_bet}
  {max2d_bet}
  {max2dd_bet}
  {max2dt_bet}
  {disc4d_bet}
  {disc3d_bet}
  {disc3dd_bet}
  {disc2d_bet}
  {disc2dd_bet}
  {disc2dt_bet}
  {win4d_bet}
  {win3d_bet}
  {win3dd_bet}
  {win2d_bet}
  {win2dd_bet}
  {win2dt_bet}
  {win4dnodiskon_bet}
  {win3dnodiskon_bet}
  {win3ddnodiskon_bet}
  {win2dnodiskon_bet}
  {win2ddnodiskon_bet}
  {win2dtnodiskon_bet}
  {win4dbb_kena_bet}
  {win3dbb_kena_bet}
  {win3ddbb_kena_bet}
  {win2dbb_kena_bet}
  {win2ddbb_kena_bet}
  {win2dtbb_kena_bet}
  {win4dbb_bet}
  {win3dbb_bet}
  {win3ddbb_bet}
  {win2dbb_bet}
  {win2ddbb_bet}
  {win2dtbb_bet}
  {limitline_4d}
  {limitline_3d}
  {limitline_3dd}
  {limitline_2d}
  {limitline_2dd}
  {limitline_2dt}
  {daylight}
  {agent_home_url}
  {portrait_mode}
/>

<style>
  input,
  input::-webkit-input-placeholder {
    font-size: 13px;
    color: #8a8a8a;
  }
  input::placeholder {
    color: #8a8a8a;
  }

  .field-icon {
    float: right;
    padding-right: 10px;
    margin-top: -32px;
    position: relative;
    z-index: 2;
  }
</style>
