<script lang="ts">
  import { Modal, ModalBody, ModalFooter } from "sveltestrap";
  export let betHistoryView = false;
  // export let footerActive = false;
  export let historyViewStatus;
  export let modalId = "defModal";
  import { onMount } from "svelte";
  let loaded = false;
  let thisHistory;
  onMount(() => {
    thisHistory.onload = () => {
      loaded = true;
    };
  });
  const toggle = () => (betHistoryView = !betHistoryView);
</script>

<div class:loaded bind:this={thisHistory}>
  <Modal
    modalClassName={modalId}
    bind:isOpen={betHistoryView}
    backdrop="static"
    {toggle}
    on:opening={() => (historyViewStatus = "opening")}
    on:open={() => (historyViewStatus = "open")}
    on:closing={() => (historyViewStatus = "closing")}
    on:close={() => (historyViewStatus = "close")}
  >
    <ModalBody class="p-0">
      <p>
        <slot name="body" />
      </p>
    </ModalBody>
  </Modal>
</div>
