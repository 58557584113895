<script>
  import { Button, Icon, TabContent, TabPane } from "sveltestrap";
  import { createEventDispatcher } from "svelte";
  import PanelFull from "../components/Panelfull.svelte";
  import Modal from "../components/Modalfull2.svelte";
  import Lineinfo from "../components/Keranjang.svelte";
  import Swal from "sweetalert2";
  import KeranjangHeader from "../components/KeranjangHeader.svelte";
  import ButtonCart from "../components/KeranjangButton.svelte";
  import FillCart from "../components/KeranjangDetail.svelte";
  import { intToString } from "../lib/encrypter";
  export let keranjang = [];
  export let totalkeranjang = 0;
  export let client_device = "";
  export let count_line_4d = 0;
  export let count_line_3d = 0;
  export let count_line_3dd = 0;
  export let count_line_2d = 0;
  export let count_line_2dd = 0;
  export let count_line_2dt = 0;
  export let minimal_bet = 0;
  export let max4d_bet = 0;
  export let max3d_bet = 0;
  export let max3dd_bet = 0;
  export let max2d_bet = 0;
  export let max2dd_bet = 0;
  export let max2dt_bet = 0;
  export let disc4d_bet = 0;
  export let disc3d_bet = 0;
  export let disc3dd_bet = 0;
  export let disc2d_bet = 0;
  export let disc2dd_bet = 0;
  export let disc2dt_bet = 0;
  export let win4d_bet = 0;
  export let win3d_bet = 0;
  export let win3dd_bet = 0;
  export let win2d_bet = 0;
  export let win2dd_bet = 0;
  export let win2dt_bet = 0;
  export let win4dnodiskon_bet = 0;
  export let win3dnodiskon_bet = 0;
  export let win3ddnodiskon_bet = 0;
  export let win2dnodiskon_bet = 0;
  export let win2ddnodiskon_bet = 0;
  export let win2dtnodiskon_bet = 0;
  export let win4dbb_kena_bet = 0;
  export let win3dbb_kena_bet = 0;
  export let win3ddbb_kena_bet = 0;
  export let win2dbb_kena_bet = 0;
  export let win2ddbb_kena_bet = 0;
  export let win2dtbb_kena_bet = 0;
  export let win4dbb_bet = 0;
  export let win3dbb_bet = 0;
  export let win3ddbb_bet = 0;
  export let win2dbb_bet = 0;
  export let win2ddbb_bet = 0;
  export let win2dtbb_bet = 0;
  export let limitline_4d = 0;
  export let limitline_3d = 0;
  export let limitline_3dd = 0;
  export let limitline_2d = 0;
  export let limitline_2dd = 0;
  export let limitline_2dt = 0;
  export let daylight;
  export let betHistoryView;
  export let agent_home_url;
  export let portrait_mode;

  let line4d = true;
  let count_line = 0;
  let dispatch = createEventDispatcher();
  const swalWithBootstrapButtons = Swal.mixin({
    customClass: {
      confirmButton: "mx-2 rounded-2 btn btn-success",
      cancelButton: "mx-2 rounded-2 btn btn-danger",
    },
    buttonsStyling: false,
  });
  function handleRemoveKeranjang(e) {
    dispatch("removekeranjang", {
      idkeranjang: e.detail.idkeranjang,
      bayar: e.detail.bayar,
      nomor: e.detail.nomor,
    });
  }
  const handleRemoveKeranjang_all = () => {
    dispatch("removekeranjangall", "all");
  };
  const handleSave = () => {
    dispatch("handleSave", "save");
  };

  const handlePaymentType = () => {
    dispatch("handlePaymentType");
  };

  const handleBetHistoryView = () => {
    betHistoryView = !betHistoryView;
  };
  let open_informasi = false;
  let fullscreen = "";
  let mobile = false;
  const toggle = () => {
    fullscreen = "xl";
    open_informasi = !open_informasi;
  };
  $: count_line =
    count_line_4d +
    count_line_3d +
    count_line_3dd +
    count_line_2d +
    count_line_2dd +
    count_line_2dt;

  $: {
    if (client_device !== "WEBSITE") {
      mobile = true;
    }
  }
</script>

<PanelFull
  header={true}
  footer={true}
  body_style="padding:0px;background:{daylight
    ? '#fff'
    : '#181717'};height:450px;"
  {daylight}
>
  <slot:template slot="header">
    <div class="container px-0">
      <div class="row">
        <div class="col-md">
          <KeranjangHeader {daylight} {totalkeranjang} />
        </div>
        <ButtonCart
          {mobile}
          {agent_home_url}
          mode432={true}
          {portrait_mode}
          on:handleRemoveKeranjang_all={handleRemoveKeranjang_all}
          on:handleBetHistoryView={handleBetHistoryView}
          on:handleSave={handleSave}
          on:handlePaymentType={handlePaymentType}
        />
      </div>
    </div>
  </slot:template>
  <slot:template slot="body">
    <FillCart
      {mobile}
      {daylight}
      {keranjang}
      pola432={true}
      on:handleRemoveKeranjang={handleRemoveKeranjang}
    />
  </slot:template>
  <slot:template slot="footer">
    <Lineinfo
      {daylight}
      {count_line}
      {count_line_4d}
      {count_line_3d}
      {count_line_3dd}
      {count_line_2d}
      {count_line_2dd}
      {count_line_2dt}
      {line4d}
    />
  </slot:template>
</PanelFull>

<Modal
  modal_id={"modalInformasi"}
  modal_footer_flag={false}
  modal_body_height={"height:500px;"}
  modal_size={"modal-dialog-centered"}
  {daylight}
>
  <slot:template slot="header">
    <h5 class="modal-title">Info</h5>
  </slot:template>
  <slot:template slot="body">
    <TabContent pills class="nav-custom">
      <TabPane tabId="alpha" tab="Table Win" active>
        <span slot="tab" class="justify-content-center" />
        <table class="table table-dark">
          <thead>
            <tr style="font-size: 10px;">
              <th
                style="background:#303030;border:1px solid #282828;border-bottom:none;"
                >&nbsp;</th
              >
              <th
                style="background:#303030;border:1px solid #282828;text-align:right;border-bottom:none;"
                >4D</th
              >
              <th
                style="background:#303030;border:1px solid #282828;text-align:right;border-bottom:none;"
                >3D</th
              >
              <th
                style="background:#303030;border:1px solid #282828;text-align:right;border-bottom:none;"
                >3DD</th
              >
              <th
                style="background:#303030;border:1px solid #282828;text-align:right;border-bottom:none;"
                >2D</th
              >
              <th
                style="background:#303030;border:1px solid #282828;text-align:right;border-bottom:none;"
                >2DD</th
              >
              <th
                style="background:#303030;border:1px solid #282828;text-align:right;border-bottom:none;"
                >2DT</th
              >
            </tr>
          </thead>
          <tbody>
            <tr>
              <td
                nowrap
                style="background:#303030;border:1px solid #282828;font-size:10px;"
                >MIN BET</td
              >
              <td
                nowrap
                style="border:1px solid #282828;text-align:right;font-size:10px;color:#ffd292;"
              >
                {new Intl.NumberFormat().format(minimal_bet)}</td
              >
              <td
                nowrap
                style="border:1px solid #282828;text-align:right;font-size:10px;color:#ffd292;"
              >
                {new Intl.NumberFormat().format(minimal_bet)}</td
              >
              <td
                nowrap
                style="border:1px solid #282828;text-align:right;font-size:10px;color:#ffd292;"
              >
                {new Intl.NumberFormat().format(minimal_bet)}</td
              >
              <td
                nowrap
                style="border:1px solid #282828;text-align:right;font-size:10px;color:#ffd292;"
              >
                {new Intl.NumberFormat().format(minimal_bet)}</td
              >
              <td
                nowrap
                style="border:1px solid #282828;text-align:right;font-size:10px;color:#ffd292;"
              >
                {new Intl.NumberFormat().format(minimal_bet)}</td
              >
              <td
                nowrap
                style="border:1px solid #282828;text-align:right;font-size:10px;color:#ffd292;"
                >{new Intl.NumberFormat().format(minimal_bet)}</td
              >
            </tr>
            <tr>
              <td
                style="background:#303030;border:1px solid #282828;font-size:10px;"
                >MAX BET</td
              >
              <td
                style="border:1px solid #282828;text-align:right;font-size:10px;color:#ffd292;"
                >{intToString(max4d_bet)}</td
              >
              <td
                style="border:1px solid #282828;text-align:right;font-size:10px;color:#ffd292;"
                >{intToString(max3d_bet)}</td
              >
              <td
                style="border:1px solid #282828;text-align:right;font-size:10px;color:#ffd292;"
                >{intToString(max3dd_bet)}</td
              >
              <td
                style="border:1px solid #282828;text-align:right;font-size:10px;color:#ffd292;"
                >{intToString(max2d_bet)}</td
              >
              <td
                style="border:1px solid #282828;text-align:right;font-size:10px;color:#ffd292;"
                >{intToString(max2dd_bet)}</td
              >
              <td
                style="border:1px solid #282828;text-align:right;font-size:10px;color:#ffd292;"
                >{intToString(max2dt_bet)}</td
              >
            </tr>
            <tr>
              <td
                style="background:#303030;border:1px solid #282828;font-size:10px;"
                >DISKON</td
              >
              <td
                style="border:1px solid #282828;text-align:right;font-size:10px;color:#ffd292;"
                >{Math.ceil(disc4d_bet * 100)}%</td
              >
              <td
                style="border:1px solid #282828;text-align:right;font-size:10px;color:#ffd292;"
                >{Math.ceil(disc3d_bet * 100)}%</td
              >
              <td
                style="border:1px solid #282828;text-align:right;font-size:10px;color:#ffd292;"
                >{Math.ceil(disc3dd_bet * 100)}%</td
              >
              <td
                style="border:1px solid #282828;text-align:right;font-size:10px;color:#ffd292;"
                >{Math.ceil(disc2d_bet * 100)}%</td
              >
              <td
                style="border:1px solid #282828;text-align:right;font-size:10px;color:#ffd292;"
                >{Math.ceil(disc2dd_bet * 100)}%</td
              >
              <td
                style="border:1px solid #282828;text-align:right;font-size:10px;color:#ffd292;"
                >{Math.ceil(disc2dt_bet * 100)}%</td
              >
            </tr>
            <tr>
              <td
                style="background:#303030;border:1px solid #282828;font-size:10px;"
                >HADIAH</td
              >
              <td
                style="border:1px solid #282828;text-align:right;font-size:10px;color:#ffd292;"
                >{win4d_bet}x</td
              >
              <td
                style="border:1px solid #282828;text-align:right;font-size:10px;color:#ffd292;"
                >{win3d_bet}x</td
              >
              <td
                style="border:1px solid #282828;text-align:right;font-size:10px;color:#ffd292;"
                >{win3dd_bet}x</td
              >
              <td
                style="border:1px solid #282828;text-align:right;font-size:10px;color:#ffd292;"
                >{win2d_bet}x</td
              >
              <td
                style="border:1px solid #282828;text-align:right;font-size:10px;color:#ffd292;"
                >{win2dd_bet}x</td
              >
              <td
                style="border:1px solid #282828;text-align:right;font-size:10px;color:#ffd292;"
                >{win2dt_bet}x</td
              >
            </tr>
            <tr>
              <td
                style="background:#303030;border:1px solid #282828;font-size:10px;"
                >HADIAH FULL</td
              >
              <td
                style="border:1px solid #282828;text-align:right;font-size:10px;color:#ffd292;"
                >{win4dnodiskon_bet}x</td
              >
              <td
                style="border:1px solid #282828;text-align:right;font-size:10px;color:#ffd292;"
                >{win3dnodiskon_bet}x</td
              >
              <td
                style="border:1px solid #282828;text-align:right;font-size:10px;color:#ffd292;"
                >{win3ddnodiskon_bet}x</td
              >
              <td
                style="border:1px solid #282828;text-align:right;font-size:10px;color:#ffd292;"
                >{win2dnodiskon_bet}x</td
              >
              <td
                style="border:1px solid #282828;text-align:right;font-size:10px;color:#ffd292;"
                >{win2ddnodiskon_bet}x</td
              >
              <td
                style="border:1px solid #282828;text-align:right;font-size:10px;color:#ffd292;"
                >{win2dtnodiskon_bet}x</td
              >
            </tr>
            <tr>
              <td
                style="background:#303030;border:1px solid #282828;font-size:10px;"
                >HADIAH BB KENA</td
              >
              <td
                style="border:1px solid #282828;text-align:right;font-size:10px;color:#ffd292;"
                >{win4dbb_kena_bet}x</td
              >
              <td
                style="border:1px solid #282828;text-align:right;font-size:10px;color:#ffd292;"
                >{win3dbb_kena_bet}x</td
              >
              <td
                style="border:1px solid #282828;text-align:right;font-size:10px;color:#ffd292;"
                >{win3ddbb_kena_bet}x</td
              >
              <td
                style="border:1px solid #282828;text-align:right;font-size:10px;color:#ffd292;"
                >{win2dbb_kena_bet}x</td
              >
              <td
                style="border:1px solid #282828;text-align:right;font-size:10px;color:#ffd292;"
                >{win2ddbb_kena_bet}x</td
              >
              <td
                style="border:1px solid #282828;text-align:right;font-size:10px;color:#ffd292;"
                >{win2dtbb_kena_bet}x</td
              >
            </tr>
            <tr>
              <td
                style="background:#303030;border:1px solid #282828;font-size:10px;"
                >HADIAH BB</td
              >
              <td
                style="border:1px solid #282828;text-align:right;font-size:10px;color:#ffd292;"
                >{win4dbb_bet}x</td
              >
              <td
                style="border:1px solid #282828;text-align:right;font-size:10px;color:#ffd292;"
                >{win3dbb_bet}x</td
              >
              <td
                style="border:1px solid #282828;text-align:right;font-size:10px;color:#ffd292;"
                >{win3ddbb_bet}x</td
              >
              <td
                style="border:1px solid #282828;text-align:right;font-size:10px;color:#ffd292;"
                >{win2dbb_bet}x</td
              >
              <td
                style="border:1px solid #282828;text-align:right;font-size:10px;color:#ffd292;"
                >{win2ddbb_bet}x</td
              >
              <td
                style="border:1px solid #282828;text-align:right;font-size:10px;color:#ffd292;"
                >{win2dtbb_bet}x</td
              >
            </tr>
            <tr>
              <td
                style="background:#303030;border:1px solid #282828;font-size:10px;"
                >LIMIT LINE</td
              >
              <td
                style="border:1px solid #282828;text-align:right;font-size:10px;color:#ffd292;"
              >
                {new Intl.NumberFormat().format(limitline_4d)}</td
              >
              <td
                style="border:1px solid #282828;text-align:right;font-size:10px;color:#ffd292;"
              >
                {new Intl.NumberFormat().format(limitline_3d)}</td
              >
              <td
                style="border:1px solid #282828;text-align:right;font-size:10px;color:#ffd292;"
              >
                {new Intl.NumberFormat().format(limitline_3dd)}</td
              >
              <td
                style="border:1px solid #282828;text-align:right;font-size:10px;color:#ffd292;"
              >
                {new Intl.NumberFormat().format(limitline_2d)}</td
              >
              <td
                style="border:1px solid #282828;text-align:right;font-size:10px;color:#ffd292;"
                >{new Intl.NumberFormat().format(limitline_2dd)}</td
              >
              <td
                style="border:1px solid #282828;text-align:right;font-size:10px;color:#ffd292;"
                >{new Intl.NumberFormat().format(limitline_2dt)}</td
              >
            </tr>
          </tbody>
        </table>

        <table class="table table-borderless bg-light">
          <thead>
            <tr>
              <th colspan="4">Contoh Penulisan 4D/3D/2D:</th>
            </tr>
          </thead>
          <tbody>
            <tr style="text-align: left;">
              <td class="border-2 border-end border-top-0 border-bottom-0"
                >1234 = 4D</td
              >
              <td class="border-3 border-end border-top-0 border-bottom-0"
                >*123 = 3D</td
              >
              <td class="border-2 border-end border-top-0 border-bottom-0"
                >12 = 2D</td
              >
              <td>12** = 2DD</td>
            </tr>
            <tr>
              <td class="border-2 border-end border-top-0 border-bottom-0"
                >123 = 3D</td
              >
              <td class="border-3 border-end border-top-0 border-bottom-0"
                >1238 = 3DD</td
              >
              <td class="border-2 border-end border-top-0 border-bottom-0"
                >**12 = 2D</td
              >
              <td>*12* = 2DT</td>
            </tr>
          </tbody>
        </table>
      </TabPane>
      <TabPane tabId="bravo" class="justify-content-center" tab="Cara Bermain">
        <img
          src="/howto/432.jpg"
          class="img-fluid"
          style="width:100%"
          alt="howto-432"
        />
      </TabPane>
    </TabContent>
  </slot:template>
</Modal>
