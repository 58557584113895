<script>
  import { tweened } from "svelte/motion";
  import { cubicOut } from "svelte/easing";

  export let cssstyle = "";
  export let mobile = false;
  export let daylight = true;
  export let preloader = true;

  const progress = tweened(0, {
    duration: 400,
    easing: cubicOut,
  });
  let time = 0;
  let sleep = 100;
  $: {
    const timeout = setTimeout(function () {
      time += 5;
      progress.set(time);
    }, sleep);
    if (time >= 100) {
      setTimeout(function () {
        preloader = false;
      }, 1000);
      clearTimeout(timeout);
    }
  }
</script>

<div
  class="loader-default"
  class:hide={!preloader}
  class:dark={!daylight}
  class:mobile
  style={cssstyle}
>
  <center>
    <div
      class="progress progress-default"
      role="progressbar"
      aria-label="Success example"
      aria-valuenow={$progress}
      aria-valuemin="0"
      aria-valuemax="100"
    >
      <div
        class="progress-bar progress-bar-animated progress-bar-striped"
        class:bg-success={daylight}
        class:bg-warning={!daylight}
        style="width: {$progress}%"
      />
    </div>
    <br />
    <img alt="SDSB4D" src="logo-green.svg" style="margin-top: 10px;" />
  </center>
</div>

<style>
  .progress-default {
    width: 35%;
  }
  .loader-default {
    position: fixed;
    z-index: 9999;
    display: block;
    margin: auto;
    right: 0;
    left: 0;
    height: 100%;
    opacity: 1;
    padding-top: 40vh;
    background-image: url("/bg-light.svg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    background-attachment: scroll;
    min-height: 100%;
  }

  .dark {
    background-image: url("/bg-dark.svg");
  }

  .hide {
    display: none;
  }

  .mobile {
    background-position: center right;
  }
</style>
